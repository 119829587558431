import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  cartAdd,
  flavors,
  getAdditionalIngredients,
  getIngredientUser,
  sweeteners,
} from "../../../config/makeRequests/ingredientsmixing";
import { getRequest } from "../../../Auth";
import { getUserId } from "../../../utils/common";
import { updateCart } from "../../../config/makeRequests/cartRequests";
import { getCustomerCartProducts } from "../cartSlice/cart";

// below apis for addProductIntoCart , getadditional Ingredients list , getFlavors , getSweeteners , getOffers

export const addProductIntoCart = createAsyncThunk(
  "addProductIntoCart/ingredientsSlice",
  async ({ data, offerHandler }, { getState }) => {
    try {
      const userId = getUserId();

      let response = await cartAdd(userId, data);
      if (response.status === true) {
        offerHandler();
        return response;
      }
    } catch (error) {
      console.log("removeAddress Error ", error);
    }
  }
);
export const getIngredients = createAsyncThunk(
  "getIngredients/ingredientsSlice",
  async (productId) => {
    try {
      let response = await getAdditionalIngredients(productId);
      if (response.status === true) {
        return response;
      }
    } catch (error) {
      console.log("removeAddress Error ", error);
    }
  }
);
export const getFlavors = createAsyncThunk(
  "getFlavors/ingredientsSlice",
  async () => {
    try {
      let response = await flavors();
      if (response.status === true) {
        return response;
      }
    } catch (error) {
      console.log("removeAddress Error ", error);
    }
  }
);
export const getSweeteners = createAsyncThunk(
  "getSweeteners/ingredientsSlice",
  async () => {
    try {
      let response = await sweeteners();
      if (response.status === true) {
        return response;
      }
    } catch (error) {
      console.log("removeAddress Error ", error);
    }
  }
);

export const getOffers = createAsyncThunk(
  "getOffers/ingredientsSlice",
  async () => {
    try {
      let response = await getRequest("/api/users/products/offers");
      if (response.status === true) {
        return response;
      }
    } catch (error) {
      console.log("removeAddress Error ", error);
    }
  }
);
export const updateCartProduct = createAsyncThunk(
  "updateCartProduct/ingredientsSlice",
  async ({ payload, setShowSuccess }, { dispatch }) => {
    try {
      const userId = getUserId();

      let response = await updateCart(
        userId,
        payload?.cartProductId,
        payload?.data
      );
      if (response.status === true) {
        setShowSuccess(true);
        dispatch(getCustomerCartProducts());
        return response;
      }
    } catch (error) {
      console.log("removeAddress Error ", error);
    }
  }
);
export const getIngredientsAll = createAsyncThunk(
  "getIngredientsAll/ingredientsSlice",
  async () => {
    try {
      let response = await getIngredientUser();
      if (response.status === true) {
        return response?.ingredients;
      }
    } catch (error) {
      console.log("removeAddress Error ", error);
    }
  }
);

const initialState = {
  isPopupVisible: false,
  addProductWithoutIngredients: false,
  cancelClick: false,

  // start mixing from scratch
  isProductCreateFromScratch: false,
  letsCreateScratchProduct: false,
  isScratchProduct: false,

  isIngredientVisible: false,
  offersVisibility: false,
  isLoading: false,

  // below states for get ingredients , flavour , sweeteners data from api
  additionalIngredients: null,
  originalProduct: null,
  flavours: null,
  sweeteners: null,

  productMixing: null,
  productFlavour: [],
  productSweetness: [],

  offers: null,

  selectedOfferDays: 0,
  isProductAdded: false,

  mixProductName: null,

  // product update States

  updateProduct: null,
  updatemixing: [],
  updateIngredients: null,
};

const ingredientsSlice = createSlice({
  name: "ingredientsSlice",
  initialState,
  reducers: {
    askForIngredients: (state, { payload }) => {
      state.isPopupVisible = payload;
    },
    handleMixScratch: (state, { payload }) => {
      state.isProductCreateFromScratch = payload;
    },
    setCancelClick: (state, action) => {
      state.cancelClick = action.payload;
    },
    handleStartMixingFromScratch: (state, { payload }) => {
      state.letsCreateScratchProduct = payload;
    },
    handleScratchProduct: (state, { payload }) => {
      state.isScratchProduct = payload;
    },
    rejectIngredients: (state, { payload }) => {
      state.addProductWithoutIngredients = payload;
    },
    ingredientsVisibility: (state, { payload }) => {
      // state.additionalIngredients = null
      state.isIngredientVisible = payload;
    },
    setOffersVisibility: (state, { payload }) => {
      state.offersVisibility = payload;
    },
    addtoCartProduct: (state, { payload }) => {
      state.originalProduct = payload;
    },
    mixProductNameHandle: (state, { payload }) => {
      state.mixProductName = payload;
    },
    storeMixedProduct: (state, { payload }) => {
      const isProductUpdateQuery = state.updateProduct;
      if (isProductUpdateQuery) {
        const updateItems = payload?.items;
        state.updateIngredients = {
          ...state.updateIngredients,
          items: updateItems,
        };
      }
      state.productMixing = payload;
    },
    storeMixedSweetness: (state, { payload }) => {
      state.productSweetness = payload;
    },
    storeMixedFlavour: (state, { payload }) => {
      state.productFlavour = payload;
    },
    offerDaysSelectHandler: (state, { payload }) => {
      state.selectedOfferDays = payload;
    },

    //update original products actions

    handleUpdateProduct: (state, { payload }) => {
      state.originalProduct = null;
      state.updateProduct = payload;

      const mixing = payload?.mixing;
      if (mixing) {
        const mixingItems = mixing.ingredients?.items;
        state.updateIngredients = mixing?.ingredients;
        state.updatemixing = mixingItems;
        if (mixing?.flavors?.length > 0) {
          state.productFlavour = [...mixing?.flavors];
        }
        if (mixing?.sweeteners?.length > 0) {
          state.productSweetness = [...mixing?.sweeteners];
        }
      }

      // state.productMixing = {items:mixingItems}
    },
    handleEmptyMixing: (state) => {
      state.productFlavour = [];
      state.productSweetness = [];
      state.productMixing = null;
      state.selectedOfferDays = 0;
      state.updateProduct = null;
      state.updatemixing = [];
      state.updateIngredients = null;
      state.additionalIngredients = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIngredients.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getIngredients.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const data = state.updateProduct;

        state.additionalIngredients = payload?.ingredients;
      })
      .addCase(getIngredients.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getFlavors.fulfilled, (state, { payload }) => {
        state.flavours = payload?.flavors;
      })
      .addCase(getSweeteners.fulfilled, (state, { payload }) => {
        state.sweeteners = payload?.sweeteners;
      })
      .addCase(getOffers.fulfilled, (state, { payload }) => {
        state.offers = payload?.offers;
      })
      .addCase(addProductIntoCart.pending, (state, { payload }) => {
        state.isProductAdded = true;
      })
      .addCase(addProductIntoCart.fulfilled, (state, { payload }) => {
        state.productFlavour = [];
        state.productSweetness = [];
        state.productMixing = null;
        state.isProductAdded = false;
        state.selectedOfferDays = 0;
        state.originalProduct = null;
        state.isPopupVisible = false;
        // state.addProductWithoutIngredients = false;
      })
      .addCase(addProductIntoCart.rejected, (state, { payload }) => {
        state.isProductAdded = false;
      })
      .addCase(updateCartProduct.pending, (state, { payload }) => {
        state.isProductAdded = true;
      })
      .addCase(updateCartProduct.fulfilled, (state, { payload }) => {
        state.isProductAdded = false;
        state.productFlavour = [];
        state.productSweetness = [];
        state.productMixing = null;
        state.updatemixing = [];
        state.isProductAdded = false;
        state.selectedOfferDays = 0;
        state.originalProduct = null;
        state.updateIngredients = null;
        state.additionalIngredients = null;

        state.isPopupVisible = false;
      })
      .addCase(updateCartProduct.rejected, (state) => {
        state.isProductAdded = false;
      })
      .addCase(getIngredientsAll.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getIngredientsAll.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.additionalIngredients = payload;
      })
      .addCase(getIngredientsAll.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  askForIngredients,
  handleMixScratch,
  handleStartMixingFromScratch,
  rejectIngredients,
  ingredientsVisibility,
  setOffersVisibility,
  addtoCartProduct,
  storeMixedProduct,
  storeMixedFlavour,
  handleScratchProduct,
  storeMixedSweetness,
  offerDaysSelectHandler,
  handleEmptyMixing,
  mixProductNameHandle,
  setCancelClick,
  //update original products actions
  handleUpdateProduct,
} = ingredientsSlice.actions;

export default ingredientsSlice.reducer;
