import client from ".";

const updateProfile = async (userId, updatedData) => {
  const res = await client.put(`/api/users/${userId}/details`, updatedData);
  return res;
};

const getProfile = async (userId) => {
  const res = await client.get(`/api/users/${userId}`);
  return res;
};

const getCountry = async() =>{
  const res = await client.get(`https://restcountries.com/v3.1/all`);
  return res;
}
const getCountryCode = async(countryCode) =>{
  const res = await client.get(`https://restcountries.com/v3/alpha/${countryCode}`);
  return res;
}

const updateProfileImage = async (userId, imageFile) => {
  const formData = new FormData();
  formData.append("image", imageFile);
  try {
    const res = await client.put(
      `/api/users/${userId}/profile-image`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res;
  } catch (error) {
    throw error;
  }
};


const updatePassword = async (userId, passwordData) => {
  const res = await client.put(
    `/api/users/${userId}/change-password`,
    passwordData
  );
  return res;
};

const getFavorites = async (userId, gender, weight) => {
  const encodedWeightRange = encodeURIComponent(weight).replace(/%20/g, "+");
  const res = await client.get(
    `/api/users/products/${userId}/favorites?gender=${gender}&weightRange=${encodedWeightRange}`
  );
  return res;
};
const getOrders = async (userId,status,from,to) => {
  const res = await client.get(
    `/api/users/${userId}/order/all-orders?page=1&perPage=15&status=${status}&from=${from}&to=${to}`
  );
  return res;
};
const getAddress = async (userId) => {
  const res = await client.get(`/api/users/${userId}/address/all`);
  return res;
};

const getCity = async (countryCode) => {
  const res = await client.get(`/api/users/${countryCode}/states`);
  return res;
};

const addNewAddress = async (userId, addressFormData) => {
  const res = await client.post(
    `/api/users/${userId}/address/add`,
    addressFormData
  );
  return res;
};
const updateNewAddress = async (userId, addressId, addressFormData) => {
  const res = await client.put(
    `/api/users/${userId}/address/${addressId}`,
    addressFormData
  );
  return res;
};

const setNotification = async (userId, value) => {
  const res = await client.put(
    `/api/users/${userId}/change-notifications?action=${value}`
  );
  return res;
};
const getOrderDetailId = async (userId, id) => {
  const res = await client.get(
    `/api/users/${userId}/order/all-orders-detail?orderId=${id}`
  );
  return res;
};
const getAddressInfo = async (inputString) => {
  const res = await client.get(`/placeId?input=${inputString}`);
  return res;
};

const getAddressDetail = async (inputId) => {
  const res = await client.get(`place-details?place_id=${inputId}`);
  return res;
};
const getDeleteAddress = async (userId, id) => {
  const res = await client.delete(`/api/users/${userId}/address/${id}`);
  return res;
};
const sendReview = async (userId, orderId, orderItemId, data) => {
  const res = await client.post(
    `/api/users/${userId}/order/${orderId}/review?orderItemId=${orderItemId}`,
    data
  );
  return res;
};
export {
  getProfile,
  updateProfile,
  getCountryCode,
  updatePassword,
  getFavorites,
  getOrders,
  getAddress,
  getCity,
  sendReview,
  getCountry,
  addNewAddress,
  updateProfileImage,
  setNotification,
  getOrderDetailId,
  updateNewAddress,
  getAddressInfo,
  getDeleteAddress,
  getAddressDetail,
  };
  

