import React, { useEffect, useState } from "react";
import MixingIngredients from "../IngredientsSteps/MixingIngredients";
import { useSelector, useDispatch } from "react-redux";
import AvailableOffers from "../AskIngredientPopup/AvailableOffers";
import {
  addProductIntoCart,
  askForIngredients,
  handleEmptyMixing,
  ingredientsVisibility,
  rejectIngredients,
} from "../../../store/slices/mixingIngredients/ingredientsSlice";
import { useNavigate } from "react-router-dom";
import { calculateTotalPrice } from "../../../utils/common";
import CartSuccess from "../AskIngredientPopup/CartSuccess";
import { proceedToCheckout } from "../../../store/slices/cartSlice/cart";

const WithoutIngredients = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [price, setPrice] = useState(0);
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    originalProduct,
    selectedOfferDays,
    isProductAdded,
    productMixing,
    mixProductName,
  } = useSelector((state) => state.ingredientsSlice);

  useEffect(() => {
    if (productMixing) {
      const mixing = {
        flavors: null,
        ingredients: productMixing,
        sweeteners: null,
      };
      setPrice(calculateTotalPrice(mixing, originalProduct));
    }
  }, [productMixing]);

  const handleNext = () => {};
  const handlePrev = () => {
    dispatch(rejectIngredients(false));
    dispatch(askForIngredients(false));
  };
  const handleSkip = () => {};

  const extraOffers = () => {
    setStep(2);
  };

  const offerHandler = () => {
    setShowSuccess(true);
  };

  const addIngredientWithout = () => {
    const productTypeId =
      originalProduct?.type === "ingredient" ? "ingredientId" : "productId";
    const productType =
      originalProduct?.productType === "Product"
        ? "Product"
        : originalProduct?.productType === "PreMixBlend"
        ? "PreMixBlend"
        : "";
    const removePriceTag = productMixing?.items?.map((value) => {
      return {
        id: value.id,
        type: value?.type,
        quantity: Number(value?.quantity),
      };
    });

    const data = {
      [productTypeId]: originalProduct?.id,
      mixFromScratchName: mixProductName,
      mixing: { ingredients: { ...productMixing, items: removePriceTag } },
      offerDays: selectedOfferDays,
      quantity: 1,
      productType: productType,
    };
    dispatch(addProductIntoCart({ data, offerHandler }));
  };

  const addIngredientOfferHandler = () => {
    const productTypeId =
      originalProduct?.type === "ingredient" ? "ingredientId" : "productId";
    const productType =
      originalProduct?.productType === "Product"
        ? "Product"
        : originalProduct?.productType === "PreMixBlend"
        ? "PreMixBlend"
        : "";
    const data = {
      mixFromScratchName: mixProductName,
      [productTypeId]: originalProduct?.id,
      offerDays: selectedOfferDays,
      quantity: 1,
      productType: productType,
    };
    dispatch(addProductIntoCart({ data, offerHandler }));
  };

  const cancelHandler = () => {
    dispatch(ingredientsVisibility(false));
    dispatch(rejectIngredients(false));
    dispatch(askForIngredients(false));
    dispatch(handleEmptyMixing());
  };

  const cartSuccessHandleOk = () => {
    dispatch(rejectIngredients(false));
    dispatch(proceedToCheckout(false));
    navigate("/cart");
  };
  return (
    <>
     {/**/} {originalProduct?.type === "ingredient" && step === 1 && (
        <MixingIngredients
          handleNext={handleNext}
          handlePrev={handlePrev}
          handleSkip={handleSkip}
          isExtraIngredients={false}
          handleNoExtraIngredients={extraOffers}
        />
      )}
      {originalProduct?.type === "ingredient" && step === 2 && (
        <AvailableOffers
          offerHandler={addIngredientWithout}
          price={price}
          btnLoading={isProductAdded}
          crossHandler={cancelHandler}
        />
      )}

      {originalProduct?.type === "product" && (
        <AvailableOffers
          offerHandler={addIngredientOfferHandler}
          price={originalProduct?.product?.salePrice}
          btnLoading={isProductAdded}
          crossHandler={cancelHandler}
        />
      )}

      {showSuccess && (
        <CartSuccess handleOk={cartSuccessHandleOk} updateCart={null} />
      )}
    </>
  );
};

export default WithoutIngredients;
