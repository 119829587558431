import React, { useEffect, useRef, useState } from 'react';
import { textElips } from '../../utils/common/ingredientsUtils';
import { IoIosArrowDown } from "react-icons/io";



const CustomDropdown = ({ data, value, name, handleChange }) => {
    const [toggle, setToggle] = useState(false)
    const [dropDownValue, setDropDownValue] = useState("")
    const dropdownRef = useRef(null)

    useEffect(() => {
        if (value) {
            setDropDownValue(value)
        } else {
            setDropDownValue('--Select--')
        }
    }, [value])

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setToggle(false);
            }
        }
        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }

    }, [])

    const handleOnclick = (v) => {
        const data = {
            value:v,
            name
        }
        setDropDownValue(v)
        handleChange(data)
        setToggle(false); 
    }

    const handleButtonClick = () => {
        setToggle(!toggle);
    }

    return (
        <>
         <button ref={dropdownRef} style={{textTransform:"capitalize"}} className="filter-dropdown" onClick={handleButtonClick}>
         {dropDownValue} <IoIosArrowDown />
              <div className={ `mixing-dropdown ${toggle &&  "active" }` }>
                <ul>
                {data?.map((item) => (
                                    <li key={item} onClick={() => handleOnclick(item)}>
                                        {item}  <img src="images/yellow_right.svg" alt="" />
                                    </li>
                                ))}
                </ul>
              </div>
            </button>
          
    
         
        </>
    );
}

export default CustomDropdown;
