import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  mixItems: [],
};

const mixSlice = createSlice({
  name: "mixOne",
  initialState,
  reducers: {
    removeItem: (state, action) => {
      state.items = state.items.filter((item) => item.id !== action.payload.id);
    },
    removeFromMixOne: (state, action) => {
      const itemIdToRemove = action.payload;
      state.mixItems = state.mixItems?.filter(
        (item) => item.id !== itemIdToRemove
      );
    },

    updateCart: (state, action) => {
      state.items = action.payload;
    },
  },
});

export const { addItem, removeItem, updateCart, removeFromMixOne } =
  mixSlice.actions;
export const selectCartItems = (state) => state.mix.items;
export default mixSlice.reducer;
