export const getUserId = () => localStorage.getItem("userId");
export const getToken = () => localStorage.getItem("token");

export function textElips(text, words = 15) {
  return text?.length > words ? `${text.slice(0, words)}...` : text;
}

export function getPriceTrunc(argPrice) {
  let price = parseFloat(argPrice)

  if (price?.toString().includes("0.000")) {
    return price?.toFixed(5);
  } else if (price?.toString()?.includes("0.00")) {
    return price.toFixed(4);
  } else if (price?.toString()?.includes("0.0")) {
    return price?.toFixed(3);
  } else {
    return price?.toFixed(2);
  }
};

function calculatedIngredientPrice(
  type,
  perGram,
  price,
  selectedQuantity,
  days
) {
  if (type === "mg") {
    let total = (price / perGram) * selectedQuantity * 30;
    return total;
  } else {
    let total = (price / perGram) * selectedQuantity * 30;
    return total;
  }
}


export function ingredientPrice(cartItem) {
  let offerDays = cartItem.offerDays || 1;
  let ingredientQuantity = 0;
  let flavorsPrice = 0;
  let sweetenersPrice = 0;
  let ingredientPrice = 0;
 
  if (cartItem.mixing && cartItem.mixing.ingredients) {
    for (
      let i = 0;   i < (cartItem.mixing.ingredients.items ? cartItem.mixing.ingredients.items.length  : 0); i++ ) {
      if (cartItem.mixing.ingredients.items[i].type === "g") {
        ingredientQuantity += cartItem.mixing.ingredients.items[i].quantity || 0;
      } else {
        ingredientQuantity += (cartItem.mixing.ingredients.items[i].quantity || 0)/1000;
      }
    }
    flavorsPrice =  ingredientQuantity *  offerDays * (cartItem.mixing.flavors ? cartItem.mixing.flavors.length : 0) * 0.1;
    sweetenersPrice = ingredientQuantity * offerDays * (cartItem.mixing.sweeteners ? cartItem.mixing.sweeteners.length : 0) * 0.15;
  }
 
  if (cartItem.mixing && cartItem.mixing.ingredients) {
    for (
      let i = 0;  i <  (cartItem.mixing.ingredients.items  ? cartItem.mixing.ingredients.items.length : 0); i++ ) {
        const price =  cartItem.mixing.ingredients.items[i].price || 0;
        const perGram =  cartItem.mixing.ingredients.items[i].pricePerWeight || 0;
        const selectedQuantity =  cartItem.mixing.ingredients.items[i].quantity || 0;
        ingredientPrice += (price / perGram) * selectedQuantity * offerDays;
    }
  }
 
  let totalPrice = flavorsPrice + sweetenersPrice + ingredientPrice;
  let percentagePrice = ((cartItem.percentage || 1) * totalPrice) / 100;
  totalPrice = totalPrice - percentagePrice;
  return getPriceTrunc(totalPrice);
}


export function productPrice(cartItem) {

  let price = 0;
  price = cartItem?.product?.price ?? 0;
  let offerDays = (cartItem?.offerDays ?? 1) / 30;


  let productQuantity =
    cartItem?.product?.ingredientUnit === 'mg'
      ? (cartItem?.product?.ingredientWeight ?? 0) * 0.001
      : cartItem?.product?.ingredientWeight ?? 0;

  let ingredientQuantity = 0;
  let flavorsPrice = 0;
  let sweetenersPrice = 0;
  let ingredientPrice = 0;
  let totalquantity = cartItem?.quantity

  if (cartItem?.mixing?.ingredients != null) {
    for (let i = 0; i < (cartItem?.mixing?.ingredients?.items?.length ?? 0); i++) {
      if (cartItem?.mixing?.ingredients?.items[i].type === 'mg') {
        ingredientQuantity +=
          (cartItem?.mixing?.ingredients?.items[i].quantity ?? 0) * 0.001;
      } else {
        ingredientQuantity += cartItem?.mixing?.ingredients?.items[i].quantity ?? 0;
      }
    }

    flavorsPrice = ingredientQuantity * 30 * (cartItem?.mixing?.flavors?.length ?? 0) * 0.1;
    sweetenersPrice =
      ingredientQuantity * 30 * (cartItem?.mixing?.sweeteners?.length ?? 0) * 0.15;

  }

  if (cartItem?.mixing?.ingredients != null) {
    for (let i = 0; i < (cartItem?.mixing?.ingredients?.items?.length ?? 0); i++) {
      ingredientPrice += calculatedIngredientPrice(
        cartItem?.mixing?.ingredients?.items[i].type ?? '',
        cartItem?.mixing?.ingredients?.items[i].pricePerWeight ?? 0,
        cartItem?.mixing?.ingredients?.items[i].price ?? 0,
        cartItem?.mixing?.ingredients?.items[i].quantity ?? 0,
        cartItem?.offerDays ?? 1
      );
    }
  }

  flavorsPrice += productQuantity * (cartItem?.mixing?.flavors?.length ?? 0) * 0.1;
  sweetenersPrice += productQuantity * (cartItem?.mixing?.sweeteners?.length ?? 0) * 0.15;

  let totalPrice = price + flavorsPrice + sweetenersPrice + ingredientPrice;
  let percentagePrice = ((cartItem?.percentage ?? 1) * totalPrice) / 100;
  totalPrice = (totalPrice - percentagePrice) * offerDays;
  const finalResult = totalPrice * totalquantity

      return getPriceTrunc(finalResult);
}






// Function to calculate total cost and total sale price
export function calculateTotalPrice(itemObject , productDetail) {
  
 
  let ingredientQuantity = 0;

  let productQuantity = productDetail?.product?.ingredientUnit === "mg" ?
    (productDetail?.product?.ingredientWeight || 0) * 0.001 :
    (productDetail?.product?.ingredientWeight || 0);


  let productPrice = productDetail?.product?.salePrice;
  let flavorsPrice = 0;
  let sweetenersPrice = 0;
  let ingredientPrice = 0;

  // Iterate through each item
  if(itemObject?.ingredients?.items?.length > 0 ){
    itemObject?.ingredients?.items.forEach(item => {

      const quantityInGrams =( item?.type === 'mg' ? item.quantity / 1000 : item.quantity) * 30
  
  
      let itemTotalSalePrice = 0;
      if (item?.type === 'mg'){
         itemTotalSalePrice = (item?.price?.sale / item?.price?.weight) * quantityInGrams * 1000;
      }else{
         itemTotalSalePrice = (item?.price?.sale / item?.price?.weight) * quantityInGrams;
      }
  
    
  
      ingredientQuantity += quantityInGrams;
   
      ingredientPrice += itemTotalSalePrice;
    });
  }



    flavorsPrice = (productQuantity + ingredientQuantity) * (itemObject?.flavors?.length || 0) * 0.1;
    sweetenersPrice = ( productQuantity + ingredientQuantity )* (itemObject?.sweeteners?.length || 0) * 0.15;

    const totalPrice = productPrice + flavorsPrice + sweetenersPrice + ingredientPrice;

  return getPriceTrunc(totalPrice);

}


// Function to calculate total cost and total sale price
export function calculateMixProductPrice(itemObject) {
  
  let ingredientQuantity = 0;

  // let productQuantity = productDetail?.product?.ingredientUnit === "mg" ?
  //   (productDetail?.product?.ingredientWeight || 0) * 0.001 :
  //   (productDetail?.product?.ingredientWeight || 0);


  // let productPrice = productDetail?.product?.salePrice;
  let flavorsPrice = 0;
  let sweetenersPrice = 0;
  let ingredientPrice = 0;

  // Iterate through each item
  if(itemObject?.ingredients?.items?.length > 0 ){
    itemObject?.ingredients?.items.forEach(item => {

      const quantityInGrams =( item?.type === 'mg' ? item.quantity / 1000 : item.quantity) * 30
  
  
      let itemTotalSalePrice = 0;
      if (item?.type === 'mg'){
         itemTotalSalePrice = (item.price.sale / item.price.weight) * quantityInGrams * 1000;
      }else{
         itemTotalSalePrice = (item.price.sale / item.price.weight) * quantityInGrams;
      }
  
    
  
      ingredientQuantity += quantityInGrams;
   
      ingredientPrice += itemTotalSalePrice;
    });
  }



    flavorsPrice = (ingredientQuantity) * (itemObject?.flavors?.length || 0) * 0.1;
    sweetenersPrice = (ingredientQuantity )* (itemObject?.sweeteners?.length || 0) * 0.15;

    const totalPrice =  flavorsPrice + sweetenersPrice + ingredientPrice;

  return getPriceTrunc(totalPrice);

}










export const getRecomendedDose = ( quantity) => {
  const gender = localStorage.getItem("gender_weight");
    const currentGender = JSON.parse(gender);
    const genderValue = currentGender?.gender;
    const userWeightValue = currentGender?.weight;
  if (genderValue && userWeightValue ) {
    if (genderValue?.toLowerCase() === "female") {
      const weightValue = userWeightValue?.match(/\d+/g);
      const singleDigitWeight = weightValue?.length === 1;
      const rangeArray = quantity?.female;
      let matchedWeight = rangeArray?.find((_w) => {
        const weightRangeValue = _w?.weightRange.match(/\d+/g);
        if (singleDigitWeight) {
          return weightRangeValue[1] == weightValue[1];
        } else {
          return (
            weightRangeValue[1] == weightValue[1] &&
            weightRangeValue[2] == weightValue[2]
          );
        }
      });
      return matchedWeight;
    } else {
      const rangeArray = quantity?.male;
      const weightValue = userWeightValue?.match(/\d+/g);

      const singleDigitWeight = weightValue?.length === 1;
      let matchedWeight = rangeArray?.find((_w) => {
        const weightRangeValue = _w?.weightRange.match(/\d+/g);

        if (singleDigitWeight) {
          return weightRangeValue[1] == weightValue[1];
        } else {
          return (
            weightRangeValue[1] == weightValue[1] &&
            weightRangeValue[2] == weightValue[2]
          );
        }
      });
      return matchedWeight;
    }
  }
};