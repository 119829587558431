import { Fragment, useState } from "react";
import Header from "./Header";
import "../styles/style.css";
import Footer from "./Footer";

const email = "example@example.com";
const subject = "Subject of the email";
const body = "Body of the email";

const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
  subject
)}&body=${encodeURIComponent(body)}`;

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    phone: "",
  });
  const thanksAndRegards = "Thanks and Regards";
  const { name, email, phone, subject, message } = formData;

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const validatePhone = (phone) => {
    const phonePattern = /^\d{10}$/;
    return phonePattern.test(phone);
  };

  const mailtoLink = `mailto:Tech@lavimd.com?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(
    `${message}\n\nName: ${name}\nEmail: ${email}\nPhone: ${phone}\n\n${thanksAndRegards}`
  )}`;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const numericValue = value.replace(/\D/g, "");
      if (numericValue.length <= 12) {
        setFormData({
          ...formData,
          [name]: numericValue,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    if (name === "email") {
      if (!validateEmail(value)) {
        setErrors({
          ...errors,
          email: "Invalid email format",
        });
      } else {
        setErrors({
          ...errors,
          email: "",
        });
      }
    }
  };

  const handleSendEmail = () => {
    if (!validateEmail(formData.email)) {
      setErrors({
        ...errors,
        email: "Invalid email format",
      });
      return;
    }
    if (!validatePhone(formData.phone)) {
      setErrors({
        ...errors,
        phone: "Invalid phone number format",
      });
    }

    window.location.href = mailtoLink;
  };

  return (
    <Fragment>
      <div className="for-overlay">
        <section id="banner-main">
          <div className="banner-img mobile-hide-banner">
            <img src="images/contact_bg_img.jpeg" alt="" />
          </div>
          <div className="banner-text text-for-mobile">
            <div className="container">
              <div className="banner banner-design contact-banner">
                <h1>Contact Us</h1>
              </div>
            </div>
          </div>
        </section>

        <section id="contact-main">
          <div className="container">
            <div className="row">
              <div className="contact-details-col">
                <div className="contct-details">
                  <div className="contact-head">
                    <h2>Let’s connect with us!</h2>
                    <p>
                      Have questions about pricing? Fill out the form and our
                      product analytics expert will be in touch directly.
                    </p>
                  </div>
                  <div className="contact-social">
                    <ul>
                      <li>
                        <span>
                          <img src="images/mail_icon.svg" alt="" />
                        </span>
                        <div className="social-left">
                          <label>Email:</label>
                          <a href="#">Tech@lavimd.com</a>
                        </div>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <span>
                          <img src="images/phone_icon.svg" alt="" />
                        </span>
                        <div className="social-left">
                          <label>Phone:</label>
                          <a href="tel:+4581733781">+1 561 794-3450</a>
                        </div>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <span>
                          <img src="images/Location_icon.svg" alt="" />
                        </span>
                        <div className="social-left">
                          <label>Address:</label>
                          <p>
                          3196 North Federal Highway,
                            <p>Boca Raton, Florida 33431</p>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="contact-form-col">
                <div className="contact-form">
                  <form>
                    <h3>How can we help you?</h3>
                    <div className="input-group">
                      <input
                        type="text"
                        name="name"
                        placeholder="Your name"
                        value={name}
                        required={true}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="input-group">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email address"
                        value={email}
                        required={true}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="input-group">
                      <input
                        type="text"
                        name="phone"
                        placeholder="Phone number"
                        value={phone}
                        required={true}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="input-group">
                      <input
                        type="text"
                        name="subject"
                        placeholder="Subject"
                        value={subject}
                        required={true}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="input-group">
                      <input
                        type="text"
                        name="message"
                        className="textarea"
                        placeholder="Type something..."
                        value={message}
                        required={true}
                        onChange={handleInputChange}></input>
                    </div>
                    <div className="input-group">
                      <input
                        onClick={handleSendEmail}
                        type="submit"
                        value="Send Message"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};
export default Contact;
