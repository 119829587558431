import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  askForIngredients,
  handleMixScratch,
  handleStartMixingFromScratch,
  ingredientsVisibility,
  rejectIngredients,
  setCancelClick,
} from "../../../store/slices/mixingIngredients/ingredientsSlice";
import RedAlert from "../../../assets/svg/RedAlert";

const AskIngredient = () => {
  const dispatch = useDispatch();
  const { originalProduct, isScratchProduct } = useSelector(
    (state) => state.ingredientsSlice
  );

  console.log("ooooooooo", originalProduct);

  const confirmHandler = () => {
    dispatch(setCancelClick("Yes"));
    if (isScratchProduct) {
      dispatch(handleStartMixingFromScratch(true));
      dispatch(handleMixScratch(true));
      dispatch(askForIngredients(false));
    } else {
      dispatch(askForIngredients(false));
      dispatch(ingredientsVisibility(true));
    }
  };

  const cancelHandler = () => {
    dispatch(setCancelClick("No"));
    if (isScratchProduct) {
      dispatch(handleStartMixingFromScratch(false));
      dispatch(handleMixScratch(true));
      dispatch(askForIngredients(false));
    } else {
      dispatch(rejectIngredients(true));
      dispatch(askForIngredients(false));
    }
  };

  return (
    <div className="payment-form-overlay">
      <div className="cart-main active  ">
        <span>
          <RedAlert />
        </span>
        <h2>Please confirm!</h2>
        <p>
          {originalProduct?.product
            ? originalProduct?.product?.taste === "Bitter"
              ? "We recommend adding flavoring or sweeteners as this mix has a stronger taste."
              : "Do you want to add ingredients, flavoring and sweeteners."
            : "Do you want flavoring and sweeteners."}
        </p>
        <div className="logout-btn">
          <button className="no-btn" onClick={() => cancelHandler()}>
            No
          </button>
          <button className="yes-btn" onClick={() => confirmHandler()}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default AskIngredient;
