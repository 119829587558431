import React, { useEffect } from "react";
import { getOffers, mixProductNameHandle, offerDaysSelectHandler } from "../../../store/slices/mixingIngredients/ingredientsSlice";
import { useSelector , useDispatch } from "react-redux";
import Spinner from "../../common/Spinner"
import OfferIconSvg from "../../../assets/svg/OfferIconSvg";
import CalendarSvg from "../../../assets/svg/CalendarSvg";
import { getPriceTrunc } from "../../../utils/common";
// import { MdOutlineCalendarMonth } from "react-icons/md";



const AvailableOffers = ({ offerHandler, price , btnLoading , crossHandler}) => {
  const dispatch = useDispatch();
  const {selectedOfferDays , offers} = useSelector(
    (state) => state.ingredientsSlice
  );

  useEffect(() => {
    dispatch(getOffers());
  }, [])

  useEffect(() => {
    // Select the first item by default
    if (!selectedOfferDays && offers?.length > 0) {
      const firstOfferDays = offers[0]?.days;
      dispatch(offerDaysSelectHandler(firstOfferDays));
    }
  }, [offers, selectedOfferDays]); 

  const calculateOfferPrice = (originalPrice, percentage) => {
    return originalPrice - (originalPrice * percentage) / 100;
  };
  const calculateDaysPrice = (p, days) => {
    const value = Number(days) / 30;
    return p * Number(value);
  };

 const offerSelect = (day)=>{
  dispatch(offerDaysSelectHandler(day))
 }


 const submitHandle = ()=>{
  dispatch(mixProductNameHandle(""));
  offerHandler()
 }


  return (
    <div className='payment-form-overlay'>
    <div className="offers-main offer-active ">
      <p onClick={()=>crossHandler()} className="availableCrossIcon">x</p>
      <span>
        <OfferIconSvg/>
      </span>
      <h2>Available offers!</h2>
     
      <ul>
        {offers?.map((item, i) => {
          const daysPrice = calculateDaysPrice(price, item?.days);
          const offerPrice = calculateOfferPrice(daysPrice, item.percentage); 

          return (
            <li key={i} onClick={()=>offerSelect(item?.days)} className={selectedOfferDays === item?.days ? "active-offer" : ""} >
              <span className="days">
              {/* <MdOutlineCalendarMonth color={"gray"}/> */}
                {item?.days} Servings (days)
                <span className={i === 0  ? "" : `default-offer`}>{i === 0  ? "" : `${item?.percentage}%off`}</span>
              </span>
              <span className="price">
                <span className="cutoff">${getPriceTrunc(daysPrice)}</span> ${getPriceTrunc(offerPrice)}
              </span>
            </li>
          );
        })}
        
      </ul>
      <div className="continue-btn">
        <button onClick={submitHandle} disabled={btnLoading}>{btnLoading ?  <Spinner width={20} height={20}  /> : "Continue" } </button>
      </div>
    </div>
    </div>
  );
};

export default AvailableOffers;