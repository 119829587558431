import { Fragment, useEffect, useRef, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleClose, handleOpenLogin, handleOpenResetPassword, setGenderModule, storeEmail } from '../../store/slices/Auth';
import { forgotPasswordAPI, verifyOtpAPI } from '../../Auth/api';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import { CustomToast } from '../common';
import Spinner from "../common/Spinner";

const VerifyOtp = () => {
    const openVerifyOtp = useSelector((state) => state.auth.openVerifyOtp)
    const email = useSelector((state) => state.auth.email);
    const [isLoading, setIsLoading] = useState(false);
    const [otpResend , setOtoResend] = useState("Resend code")
    const dispatch = useDispatch();
    const [otpValue, setOtpValue] = useState(new Array(6).fill(""))
    const inputRef = useRef([]);


    useEffect(() => {
        setOtpValue(new Array(6).fill(""))
    }, [])
  
    // Otp handler
function otpHandler(value, i) {
    const customerInput = value.substring(value.length -1)
    setOtpValue((prevArray) => {
        const newArray = [...prevArray];
        newArray[i] = customerInput;
        return newArray;
    });

    // move focus to the next input
    if (inputRef.current && i < otpValue.length - 1 && value !== "" && inputRef.current[i + 1]) {
        inputRef.current[i + 1].focus(); 
    }
}

const handleKeyPress = (e , i)=>{

    if(e.key == "Backspace"){
        // move focus to the previous  input
        if(!otpValue[i] && i > 0 ){
            inputRef.current[i - 1].focus(); 
        }

    }

}



    const handleFormSubmit = async e => {
        e.preventDefault()
        setIsLoading(true)
        const otp = otpValue.join("")
        if (otp.length < 6) {
            CustomToast("OTP cannot be less than 6 characters.","error")
            setIsLoading(false);
            return
        }
        try {
          const res =   await verifyOtpAPI(email, otp)
          const {status} = await res?.data;
          if(status){
            setIsLoading(false);
            CustomToast("Successfully verified OTP.","success")
            dispatch(handleOpenResetPassword());
            setOtpValue(new Array(6).fill(""))
          }
          
        } catch (err) {
            if (err instanceof AxiosError) {
                setIsLoading(false);
               CustomToast(err.response.data.message,"error") 
            } else {
                setIsLoading(false);
                CustomToast("Something went wrong.","error") 
            }
        }
    }

    const handleResendOtp = async() =>{
        try {
            setOtoResend("loading...")
            await forgotPasswordAPI(email)
                dispatch(storeEmail(email))
                CustomToast("Sent Otp successfully.","success")
               
                setOtoResend("Resend code")
        } catch (err) {
            setOtoResend("Resend code")
            if (err instanceof AxiosError) {
               CustomToast(err.response.data.message,"error") 
            } else {
               CustomToast("Something went wrong.","error") 
            }
        }
    }
    const handleCloseVerify = () =>{
        setOtpValue(new Array(6).fill(""))
        dispatch(handleClose())
        dispatch(setGenderModule(true));
    }
    const handleBackToLogin = () =>{
        setOtpValue(new Array(6).fill(""))
        dispatch(handleOpenLogin());
       
    }


    return (
        <Fragment>
            <div
                style={{ position: 'fixed' }}
                className={`overlay ${openVerifyOtp ? 'otp-active ' : ''}`}
            />
            <ToastContainer />
            <div className={`verify-mail-main ${openVerifyOtp ? "otp-active" : " "}`}>
                <div className="logo">
                    <a href="#"><img src="images/Logo.svg" alt="Logo" /></a>
                    <span><img src="images/times_icon.svg" alt="" onClick={handleCloseVerify} />
                    </span>
                </div>
                <div className="login-field">
                    <h2>Verify your email</h2>
                    <p>We have sent a code to {email}</p>
                    <div className="input-field">
                        <div className="verify-otp">
                        {
                                otpValue?.map((_item, i) =>
                                    <input
                                        key={i} 
                                        value={otpValue[i]}
                                        onChange={(event) => otpHandler(event.target.value, i)} 
                                        className="form-control"
                                        type="number"
                                        ref={(input) => {
                                            inputRef.current[i] = input
                                        }
                                        }
                                        onKeyDown={(e) => handleKeyPress(e , i)}
                                    />)
                            }
                            {/* {otps?.map((digit, idx) => (
                                <input
                                    key={idx}
                                    type="text"
                                    className="form-control"
                                    maxLength={1}
                                    minLength={1}
                                    ref={input => (inputRefs.current[idx] = input)}
                                    onChange={e => handleInputChange(idx, e.target.value)}
                                    onKeyDown={e => handleInputKeyDown(e, idx)}
                                />
                            ))} */}
                        </div>
                    </div>
                    <div className="login-btn">
                        <a href="#" className="confirm-btn" onClick={handleFormSubmit}>{isLoading?(<Spinner width={20} height={20}/>):"Confirm"}</a>
                    </div>
                    <div className="log-footer for-verify">
                        <p>Didn't receive the code?<a onClick={handleResendOtp}>{otpResend}</a></p>
                    </div>
                    <div className="back-login" onClick={() => handleBackToLogin()}>
                        <a href="#"><img src="images/back_btn.svg" alt="Back icon" />Back to login</a>
                    </div>
                </div>
            </div>
        </Fragment>

    );
}

export default VerifyOtp;