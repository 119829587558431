import React from 'react';
import { CiSearch } from "react-icons/ci";

const Search = ({ value, changeHandle, onKeyPress, onBlur }) => {
  return (
    < >
      <CiSearch size={20} />
      <input
        type="text"
        class="search-input"
        placeholder="Search for a product and enter..."
        value={value}
        onChange={changeHandle}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
      />
    </>
  );
}

export default Search;
