import React, { Fragment } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import AskIngredient from "../component/IngredientMixing/AskIngredientPopup/AskIngredient";
import {useSelector} from "react-redux"
import IngredientMainStep from "../component/IngredientMixing/IngredientMainStep";
import WithoutIngredients from "../component/IngredientMixing/withoutIngredients/WithoutIngredients";
import ScratchMixing from "../component/IngredientMixing/ScratchMixing/ScratchMixing";

const FrontendLayout = ({ children }) => {

	const {isPopupVisible , isProductCreateFromScratch , addProductWithoutIngredients , isIngredientVisible  } = useSelector((state)=> state.ingredientsSlice )


  return (
    <Fragment>
     {isPopupVisible  &&  <AskIngredient />}
     {isIngredientVisible  &&  <IngredientMainStep/>}
     {addProductWithoutIngredients  &&  <WithoutIngredients/>}
     {isProductCreateFromScratch  &&  <ScratchMixing/>}
     
     
      <div className="header-sticky">
        <Header />
      </div>

      {children}

      <div>
        <Footer />
      </div>
    </Fragment>
  );
};

export default FrontendLayout;
