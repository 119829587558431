import { Fragment, useState } from "react";
import OrderStatusBadge from "../layout/OrderStatusBadge";
import { getOrderDetailId } from "../Auth/profile";
import { useDispatch, useSelector } from "react-redux";
import UserOrderListDetails from "./UserOrderListDetails";
import MixLoader from "../layout/MixLoader";
import IllustrationNoProduct from "../component/common/IllustrationNoProduct";
import OrdersFilter from "./OrdersFilter";
import { setOpenFilter } from "../store/slices/common";
import NoProductFound from "../assets/images/noingredients.png";
import { getPriceTrunc } from "../utils/common";

 
const UserOrderList = ({ orderList , isDataLoaded }) => {
  const currentUser = useSelector((state) => state.login);
  const { openFilter } = useSelector((state) => state.common);
  const [orderDetail, setOrderDetail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [showMore, setShowMore] = useState(10);
  const dispatch = useDispatch();

  const handleClickDetail = async (orderId) => {
    
    setIsLoading(true);
    try {
      const response = await getOrderDetailId(currentUser?.id, orderId);
      setOrderDetail(response?.data?.request);
      setActive(true);
      window.scrollTo(0, 0);
    } catch (error) {
      console.error("Error fetching order detail:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    setActive(false);
  };

  const handleShowMore = () => {
    setShowMore(showMore + 10);
  };

  const handleShowLess = () => {
    setShowMore(showMore - 10);
  };

  const handleOpenFilter = () => {
    dispatch(setOpenFilter(true));
  }


  return (
    <Fragment>

      <>
        <div
          className={`cart-item-main wishlist-main my-order-main ${active ? "active" : ""
            }`}
        >
          <div className="cart-itme-head">
            <span>My Orders ({orderList?.total})</span>
          <span className="filter" onClick={handleOpenFilter}>Filters</span>
          </div>
          {isDataLoaded ?  <MixLoader />  : (
            <>
              {orderList && orderList?.ordersRes === null ? (
                <div style={{ marginTop: 100 }}>
                  {" "}
                  <IllustrationNoProduct  tittle="No product found!" imgLink={NoProductFound} subTittle={null}/>{" "}
                </div>
              ) : (
                <>
                  {orderList?.ordersRes?.slice(0, showMore).map((order, idx) => (
                    <div
                      className="cart-item"
                      key={idx}
                      onClick={() => handleClickDetail(order?.id)}
                    >
                      <div className="cart-details">
                        <div className="cart-details-head">
                          <h3> Order ID : {order?.orderId}</h3>
                        </div>
                        <p>
                          {order?.firstOrderItem?.productOrIngredient === null
                            ? order?.firstOrderItem?.mixFromScratchName
                            : order?.firstOrderItem?.productOrIngredient?.title}
                          {order.totalOrderItems - 1 === 0
                            ? ""
                            : ` +${order.totalOrderItems - 1} more`}
                        </p>
                        <div className="cart-quantity">
                          <div className="quantity-btn">
                            <span>${getPriceTrunc(order?.price)}</span>
                          </div>
                          <div className="cart-item-remove">
                            <span>
                              <OrderStatusBadge
                                status={
                                  order?.status?.[order?.status?.length - 1]
                                    ?.status
                                }
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}</>
          )}
          <div className="show_more-less" style={{ paddingRight: "0px" }}>
            {orderList?.ordersRes?.length > showMore && (
              <button className="show_more-button" onClick={handleShowMore}>
                Show More
              </button>
            )}
            {showMore > 10 && (
              <button className="show_less-button" onClick={handleShowLess}>
                Show Less
              </button>
            )}
          </div>
        </div>
        <div
          className={`cart-item-main my-order-details ${active ? "active" : ""
            }`}
        >
          <UserOrderListDetails
            orderDetail={orderDetail}
            handleBack={handleBack}
          />
        </div>

        {openFilter ? <OrdersFilter /> : ''}
      </>

    </Fragment>
  );
};

export default UserOrderList;
