import client from ".";
import { getToken } from "../utils/common";

const getAllProducts = async (
  search,
  availablilty,
  category,
  doctorId,
  rating,
  priceMin,
  priceMax,
  forcePageToOne,
  perPage,
  gender,
  weight,
  userId
) => {
  const encodedWeightRange = encodeURIComponent(weight).replace(/%20/g, "+");
  const supplierId = doctorId === undefined ? [] : doctorId;
  const res =
    await client.get(`api/users/products/all?search=${search}&availablilty=${availablilty}&category=${category}&doctor=${supplierId}&gender=${gender}&weightRange=${encodedWeightRange}&rating=${rating}&priceMin=${priceMin}&priceMax=${priceMax}&page=${forcePageToOne}&perPage=${perPage}&userId=${userId}`);
    return res;
};

const getAllProductsNew = async (
  search,
  availablilty,
  category,
  doctorId,
  rating,
  priceMin,
  priceMax,
  forcePageToOne,
  perPage,
  gender,
  weight
) => {
  const encodedWeightRange = encodeURIComponent(weight).replace(/%20/g, "+");
  const supplierId = doctorId === undefined ? [] : doctorId;

  const res =
    await client.get(`/api/website/products/all?search=${search}&availablilty=${availablilty}&category=${category}&doctor=${supplierId}&gender=${gender}&weightRange=${encodedWeightRange}&rating=${rating}&priceMin=${priceMin}&priceMax=${priceMax}&page=${forcePageToOne}&perPage=${perPage}`);
  return res;
};

const getRecommendedProducts = async (userId, gender, weight) => {
  const res = await client.get(
    `/api/website/banners-and-best-selling?userId=${userId}&gender=${gender}&weightRange=${weight}`
  );
  return res;
};

const addToFavoriteProducts = async (userId, productId, action) => {
  const res = await client.put(`api/users/products/${userId}/${productId}/favorite?action=${action}`);
  return res;
};
const getAllCategory = async (value) => {
  const res = await client.get(`api/users/products/categories?all=${value}`);
  return res;
};
const getAllDoctors = async () => {
  const res = await client.get(`/api/admin/doctors/names`);
  return res;
};
const getAllFilters = async (gender, weight) => {
  const encodedWeightRange = encodeURIComponent(weight).replace(/%20/g, "+");
  const res = await client.get(
    `/api/users/products/filters?gender=${gender}&weightRange=${encodedWeightRange}`
  );
  return res;
};
const getAllFiltersNew = async (gender, weight) => {
  const encodedWeightRange = encodeURIComponent(weight).replace(/%20/g, "+");
  const res = await client.get(
    `/api/website/products/filters?gender=${gender}&weightRange=${encodedWeightRange}`
  );
  return res;
};

const getProductByCategory = async (category) => {
  const res = await client.get(`api/users/products/all?category=${category}`);
  return res;
};
const getReviewsData = async (id, type) => {
  const token =  getToken()
  if(token){
    const res = await client.get(
      `/api/users/products/${id}/reviews?type=${type}`
    );
    return res;
  }else{
    const res = await client.get(
      `/api/website/products/${id}/reviews?type=${type}`
    );
    return res;
  }
 
};

const getReviewsDataNew = async (id, type) => {
  const res = await client.get(
    `/api/website/products/${id}/reviews?type=${type}`
  );
  return res;
};

export {
  getAllProducts,
  getAllCategory,
  getAllDoctors,
  getAllFilters,
  getReviewsData,
  getAllFiltersNew,
  getReviewsDataNew,
  getAllProductsNew,
  getRecommendedProducts,
  addToFavoriteProducts,
  getProductByCategory,
};
