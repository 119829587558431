import client from ".";

const categoryAPI = async (flag) => {
  const res = await client.get(`api/website/categories?all=${flag}`);
  return res;
};

const questionAPI = async (category) => {
  const res = await client.get(`api/website/questions?category=${category}`);
  return res;
};

const productAPI = async (id, questionsData, gender, weight) => {
  const res = await client.post(
    `api/website/questions/products?userId=${id}&gender=${gender}&weightRange=${weight}`,
    {
      questions: questionsData,
    }
  );
  return res;
};

const ingredientAPI = async (queryString) => {
  const res = await client.get(`api/website/ingredients/all?search${queryString}`);
  return res;
};

export { categoryAPI, questionAPI, productAPI, ingredientAPI };
