import React from 'react';

const NoCartProduct = () => {
  return (
    <div className='empty-cart-container'>
      <div>
        <img src="images/empty_cart.svg" alt="empty cart image not found " />
        <div>Your cart is Feeling a bit light. Time to add some weight with your favorite items!</div>
      </div>
    </div>
  );
}

export default NoCartProduct;
