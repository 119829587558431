import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CartProductCard from "../../component/pagesComponents/cart/CartProductCard";
import {
  proceedToCheckout,
  removeProduct,
  updateQuantity,
} from "../../store/slices/cartSlice/cart";
import {
  getFlavors,
  getIngredients,
  getIngredientsAll,
  getSweeteners,
  handleUpdateProduct,
  ingredientsVisibility,
} from "../../store/slices/mixingIngredients/ingredientsSlice";
import { setIsLoading } from "../../store/slices/common";

const CartProducts = () => {

  // redux hooks
  const dispatch = useDispatch();
  const { cartItems, isProductRemove, quantityUpdate, isCheckoutProceed } =
    useSelector((state) => state.cart);

  const handleRemove = (productId) => {
    dispatch(setIsLoading(true));
    dispatch(removeProduct(productId));
  };

  const updateCartProductQuantity = (productId, productQuantity) => {
    const data = {
      productId,
      quantity: productQuantity,
    };
    dispatch(updateQuantity(data));
  };

  const proceed = () => {
    dispatch(proceedToCheckout(true));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const addIngredients = (data) => {
    const id = data?.hasOwnProperty("ingredient")  ? data?.ingredient?.id  : data?.hasOwnProperty("product") ? data?.product?.id  : "";
    const isMixing = !data?.hasOwnProperty("ingredient") && !data?.hasOwnProperty("product");

    if (isMixing) {
      dispatch(getIngredientsAll());
    }
    if (id) {
      dispatch(getIngredients(id));
    }
    dispatch(handleUpdateProduct(data));
    dispatch(ingredientsVisibility(true));
  };

  return (
    <>
      <div className="cart-item-main">
        {/* <div className="cart-itme-head"> */}
        {/* <span>Products ({cartItems?.length})</span> */}
        {/* </div> */}
        {cartItems?.map((item, index) => {
          return (
            <CartProductCard
              key={index}
              item={item}
              handleRemove={handleRemove}
              isProductRemove={isProductRemove}
              quantityUpdate={quantityUpdate}
              updateCartProductQuantity={updateCartProductQuantity}
              shippingProcess={isCheckoutProceed}
              addIngredients={addIngredients}
            />
          );
        })}
      </div>

      {!isCheckoutProceed && (
        <div className="Checkout-btn">
          <button onClick={proceed}>Proceed to Checkout</button>
        </div>
      )}
    </>
  );
};

export default CartProducts;
