import { Fragment } from 'react'

const OrderStatusBadge = ({ status }) => {
  const variant =
    status === 'order-created'
      ? 'pending'
      : status === 'order-confirmed'
      ? 'pending'
      : status === 'order-cancelled'
      ? 'danger'
      : status === 'order-completed'
      ? 'success'
      : status==='order-shipped'
      ? 'pending'
      : status === 'order-out-for-delivery'
      ? 'pending'
      : status==='order-delivered'
      ? 'success'
      : 'pending'
  const text =
    status === 'order-created'
      ? 'In-progress'
      : status === 'order-confirmed'
      ? 'In-progress'
      : status === 'order-cancelled'
      ? 'Cancelled'
      : status==='order-shipped'
      ? 'Shipped'
      : status === 'order-out-for-delivery'
      ? 'Out for delivery'
      : status==='order-delivered'
      ? 'Delivered'
      : status==='order-completed'
      ?'Delivered'
      : 'UNKNOWN'

  return (
    <Fragment>
      <label
        htmlFor=""
        style={{display:"flex",gap:"inherit"}}
        >
        <div className={`circle-${variant}`}></div>
        {text}
      </label>
    </Fragment>
  )
}

export default OrderStatusBadge
