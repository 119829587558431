import React, { useEffect } from 'react';
import AddressComponent from './AddressComponent';
import { emptyKeys, handlePickup } from '../../../store/slices/cartSlice/cart';
import {useDispatch} from "react-redux"

const BillingShipping = () => {

  const dispatch = useDispatch()

  useEffect(() => {
  dispatch(emptyKeys())
  }, [])

 
  return (
    <>
        <div className="checkout-shipping">
                      <AddressComponent />
                        
                     
                    </div>
    </>
  );
}

export default BillingShipping;
