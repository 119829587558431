
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Picker from "react-scrollable-picker";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { registerApi } from "../../Auth/api";
import { CustomToast, convertHeightToCm, customStyles } from "../common";
import { loginAdmin } from "../../store/slices/login";
import { handleOpenLogin, setGenderModule, setOpenLogin, setRegisterOpen } from "../../store/slices/Auth";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { setWeightLocalStorage } from "../../utils/common/ingredientsUtils";
import Spinner from "../../component/common/Spinner";

const Register = () => {
  const openRegister = useSelector((state) => state.auth.openRegister);
  const weightOptions = [];
  for (let i = 1; i <= 500; i++) {
    weightOptions.push({ value: i.toString(), label: i.toString() });
  }

  const initialFormValues = {
    name: "",
    email: "",
    password: "",
    phone: {
      countryCode:"us",
      dialCode: "",
      number: "",
    },
    country: {
      code: "us",
      dialCode: "",
      name: "",
    },
    gender: "",
    heightFt: "",
    heightIn: "",
    weight: "",
  };

  const [formData, setFormData] = useState(initialFormValues);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [clickCheckbox, setClickCheckbox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openPicker, setOpenPicker] = useState(false);
  const [openPickerNew, setOpenPickerNew] = useState(false);

  const dispatch = useDispatch();

  const [valueGroups, setValueGroups] = useState({
    heightFt: "5",
    titleFt: "Ft",
    heightIn: "5",
    titleIn: "In",
  });
 

  const [valueGroupsNew, setValueGroupsNew] = useState({
    weight: "150",
    title:"lbs"
  });


  const optionGroups = {
    heightFt: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
      { value: "6", label: "6" },
      { value: "7", label: "7" },
    ],
    titleFt: [
      { value: "Ft", label: "Ft" },
    ],
    heightIn: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
      { value: "6", label: "6" },
      { value: "7", label: "7" },
      { value: "8", label: "8" },
      { value: "9", label: "9" },
      { value: "10", label: "10" },
      { value: "11", label: "11" },
    ],
    titleIn: [
      { value: "In", label: "In" },
    ],
  };


  const optionGroupsNew = {
    weight: weightOptions,
    title: [
      { value: "lbs", label: "lbs" },
    ],
  };


  const handleChangePicker = (name, value) => {
    setValueGroups((prevValueGroups) => ({
      ...prevValueGroups,
      [name]: value
    }));
  };


  const handleChangePickerNew = (name, value) => {
    setValueGroupsNew((prevValue) => ({
      ...prevValue,
      [name]: value
    }));
  };
  
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      heightFt: valueGroups?.heightFt+" " +" "+valueGroups?.titleFt,
      heightIn: valueGroups?.heightIn+" " +" "+ valueGroups?.titleIn,
      weight:valueGroupsNew?.weight+" " +" "+ valueGroupsNew?.title
    }));
  }, [valueGroups]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      weight: valueGroupsNew?.weight + " " + " " + valueGroupsNew?.title,
    }));
  }, [valueGroupsNew]);


  const handleCloseRegister = () => {
    const gender = localStorage.getItem("filter");
    if (gender?.gender === "male" || gender?.gender === "female") {
      dispatch(setGenderModule(false));
    }
    dispatch(setGenderModule(true));
    dispatch(setOpenLogin(false));
    dispatch(setRegisterOpen(false));
    setFormData(initialFormValues)
  };
  const handleClick = () => {
    dispatch(handleOpenLogin());
  };

  const handleSelectCountry = (value,country) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: {
        ...prevFormData.phone,
        number: value,
        dialCode:country?.dialCode,
        countryCode:country?.countryCode
      },
      country:{
        ...prevFormData.country,
        name:country?.name,
        dialCode:country?.dialCode,
        code:country?.countryCode
      }
    }));
  };
  // const handleSelectCountry = async (value,country) => {
    
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     phone: {
  //       ...prevFormData.phone,
  //       number:value
  //     },
  //   }));
  // };

  const validateForm = () => {
    const requiredFields = [
      { key: "name", label: "Name" },
      { key: "email", label: "Email" },
      { key: "password", label: "Password" },
      { key: "weight", label: "Weight" },
      { key: "gender", label: "Gender" },
    ];

    const firstMissingField = requiredFields.find(
      (field) => !formData[field.key]
    );
    if (firstMissingField) {
      const errorMessage = `Please fill the ${firstMissingField.label} field.`;
      CustomToast(errorMessage, "error");
      return false;
    }

    if(!formData?.phone?.number){
      const errorMessage = `Please fill the number field.`;
      CustomToast(errorMessage, "error");
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      CustomToast("Please enter a valid email address!", "error");
      return false;
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(formData.password)) {
      CustomToast(
        "Password must be at least 8 characters and include at least one uppercase letter, one lowercase letter, and one number!",
        "error"
      );
      return false;
    }
    return true;
  };

  const handleRegister = async () => {
    
    
   
    if (validateForm()) {
      if (!clickCheckbox) {
        CustomToast("Please select the Terms and Privacy Policy", "error");
        return false;
      }
      const heightInCm = convertHeightToCm(formData?.heightFt, formData?.heightIn);
    const weightRange = formData?.weight?.split(' ')[0];
    
    const registerData = {
      name: formData.name,
      email: formData.email,
      password: formData.password,
      gender: formData.gender,
      height: Math.floor(heightInCm),
      weight: Number(weightRange),
      phone: {
        countryCode:formData?.country?.code,
        dialCode: formData?.phone?.dialCode,
        number: formData?.phone?.number,
      },
    
    };

    if (formData?.phone?.number.startsWith(formData?.phone?.dialCode)) {
      registerData.phone.number = formData?.phone?.number.substring(formData?.phone?.dialCode?.length);
    }

      
      try {
        setIsLoading(true);
        const { data } = await registerApi(registerData);
        const { status, message, user } = data;
        const { token } = data;
        localStorage.setItem("token", token);
        setIsLoading(false);
        localStorage.setItem("userId", user.id);

        const { weight, gender } = user;
        setWeightLocalStorage(weight , gender )

        dispatch(
          loginAdmin({
            id: user.id,
            name: user.name,
            email: user.email,
            image: user.image,
            notifications: user.notifications,
            isLoggedIn: true,
            phone: user.phone,
            height: user.height,
            weight: user.weight,
            gender: user.gender,
            country: formData?.country,
            createdAt: user.createdAt,
            updatedAt: user.updatedAt,
          })
        );
        setFormData(initialFormValues);
        handleCloseRegister(true);
        setIsLoading(false);
        CustomToast("Registration Successful", "success");
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        CustomToast(error.response.data.message, "error");
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "number" || name === "dialCode") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        phone: {
          ...prevFormData.phone,
          [name]: value,
        },
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };


  const handleClickIcon = () =>{
    setPasswordVisible(!passwordVisible)
  }
  const handleChangeHeight = () =>{
      setOpenPicker(!openPicker)
      setOpenPickerNew(false)
  }
  const handleChangeWeight = () =>{
    setOpenPickerNew(!openPickerNew)
    setOpenPicker(false)
}

useEffect(() => {
  if (openRegister) {
    document.body.style.overflow = "hidden";
  }else{
    document.body.style.overflow = "auto";
  }
  return () => {
    document.body.style.overflow = "auto";
  };

}, [openRegister]);
  
  return (
    <Fragment>
      <div
        style={{ position: "fixed" }}
        className={`overlay ${openRegister ? "active " : ""}`}
      />
      <ToastContainer />
      <div
        className={`register-main ${openRegister ? "register-active" : " "}`}>
        <div className="logo">
          <a href="#">
            <img src="/images/Logo.svg" alt="Logo" />
          </a>
          <span>
            <img
              src="/images/times_icon.svg"
              alt=""
              onClick={handleCloseRegister}
            />
          </span>
        </div>
        <div className="login-field">
          <h2>Register</h2>
          <p>Enter your details to create account</p>
          <div className="input-field">
            <input
              type="name"
              className="form-control"
              name="name"
              placeholder="Your name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="input-field">
            <input
              type="email"
              className="form-control"
              name="email"
              placeholder="yourmail@example.com"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="input-field">
            <div className="country-select">
            <PhoneInput
            country={"us"}
                value={formData.phone.number}
                placeholder="+1"
                onChange={(value ,  country) => {handleSelectCountry(value , country)}}
                inputStyle={{ paddingLeft: '48px' }}
              />
            </div>
          </div>
          <div className="input-field">
            <select
              type="text"
              className="select-btn"
              name="gender"
              value={formData.gender}
              onChange={handleChange}>
              <option value="" disabled>
                Select gender
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="input-field">
          <div className="height-devide">
          <span>optional</span>
          <input
              type="text"
              className="form-control"
              name="height"
              placeholder="Your height(in)"
              value={formData?.heightFt +" "+" " +formData?.heightIn}
              onClick={handleChangeHeight}
            />
            <input
              type="text"
              className="form-control"
              name="weight"
              placeholder="Your weight(lbs)"
              value={formData?.weight}
              onClick={handleChangeWeight}
            />
            </div>
          </div>
          {openPicker  && 
            <Picker
            optionGroups={optionGroups}
            valueGroups={valueGroups}
            onChange={handleChangePicker}
            className="picker-container"
          />
          }
          {openPickerNew && 
                  <Picker
                  optionGroups={optionGroupsNew}
                  valueGroups={valueGroupsNew}
                  onChange={handleChangePickerNew}
                  className="picker-container"
                />
            }
          <div className="input-field">
            <input
              className="form-control"
              name="password"
              placeholder="Enter Password"
              value={formData.password}
              onChange={handleChange}
              type={passwordVisible ? "text" : "password"}
              style={{ paddingRight: 55 }}
            />
            <button class="eye-btn" onClick={handleClickIcon}>
                {passwordVisible ? <img src="images/eye_icon.svg" alt="" /> : <img src="/images/Hide_password_icon.svg" alt="" />}
            </button>
          </div>
          <div className="input-field">
            <div className="input-check">
              <input
                type="checkbox"
                onClick={(e) => setClickCheckbox(!clickCheckbox)}
              />
              <label>
                I agree to all{" "}
                <a target="_blank" href="https://abbsii.com/privacy">
                  Terms & Conditions
                </a>{" "}
                and{" "}
                <a target="_blank" href="https://abbsii.com/privacy">
                  Privacy Policy
                </a>
              </label>
            </div>
          </div>
          <div className="login-btn" onClick={handleRegister}>
            <a href="#"> {isLoading ? <Spinner width={18} height={18}  /> : "Register"}</a>
          </div>
          <div className="log-footer">
            <p>
              Already a member yet?
              <a className="register-login" onClick={handleClick}>
                Login
              </a>
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Register;
