import React from 'react';

const OrderCompletePopup = ({handleOk}) => {
  return (
    <div className='completedOrder'>
 <div className="order-confirm-main ">
    <span className="party-popup"><img src="images/party_popper.png" alt="" /></span>
    <h2>Your order is complete!</h2>
    <p>You will receive a notification once your order is confirmed .</p>
    <div className="track-path">
        <span>For more details, track your delivery status under</span>
        <h3>My Account  {">"} My Orders</h3>
    </div>
    <div className="back">
      <button className="back-btn" onClick={handleOk}>Back to home</button> 
    </div>
</div>
</div>
  );
}

export default OrderCompletePopup;
