import React, { Fragment, useEffect, useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import PersonalDetail from "./PersonalDetail";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAddress,
  getDeleteAddress,
  getFavorites,
  getOrders,
  setNotification,
  updatePassword,
} from "../Auth/profile";
import { useDispatch, useSelector } from "react-redux";
import { CustomToast } from "../component/common";
import UserOrderList from "./UserOrderList";
import {
  setAddressDataById,
  setFrom,
  setIsLoading,
  setOpenAddAddress,
  setStatus,
  setTo,
} from "../store/slices/common";
import AddNewAddress from "./AddNewAddress";
import { useNavigate } from "react-router";
import { addToFavoriteProducts } from "../Auth/product";
import Loader from "../layout/Loader";
import { useLocation } from "react-router-dom";
import MixLoader from "../layout/MixLoader";
import IllustrationNoProduct from "../component/common/IllustrationNoProduct";
import NoAddressFound from "../assets/brand/noaddress.svg";
import NoProductFound from "../assets/images/noingredients.png";
import { getPriceTrunc } from "../utils/common";
import CartDeleteSpinner from "../component/common/CartDeleteSpinner";
import { IoEyeOffOutline } from "react-icons/io5";
import { IoEyeOutline } from "react-icons/io5";

const MenuItem = ({ label, isActive, onClick, activeImage, inactiveImage }) => (
  <li className={isActive ? "active" : ""} onClick={onClick}>
    {isActive ? (
      <img className="active-icon" src={activeImage} alt="" />
    ) : (
      <img src={inactiveImage} alt="" />
    )}
    {label}
  </li>
);

const AccountSettings = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [favoritesList, setFavoritesList] = useState([]);
  const [orderList, setOrderList] = useState();
  const [addressDetail, setAddressDetail] = useState([]);
  const [isNavigating, setIsNavigating] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isfavoriteLoaded, setIsfavoriteLoaded] = useState(false);
  const [toggleButton, setToggleButton] = useState(false);
  const [isRemoveID, setIsRemoveId] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isRemoveLoaded, setIsRemoveLoaded] = useState(false);
  const currentUser = useSelector((state) => state.login);
  const { isLoading, status, from, to } = useSelector((state) => state.common);
  const activeIndexMenu = useSelector((state) => state.common.activeIndexMenu);
  const [showMore, setShowMore] = useState(10);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = window.innerWidth < 992;

  useEffect(() => {
    if (activeIndexMenu) {
      setActiveIndex(activeIndexMenu);
    }
  }, [activeIndexMenu]);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };
  useEffect(() => {
    const activeIndexFromState = location.state?.activeIndex;
    if (activeIndexFromState !== undefined) {
      setActiveIndex(activeIndexFromState);
    }
  }, [location.state]);

  const toggleShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const validateForm = () => {
    if (!currentPassword || !newPassword || !confirmPassword) {
      CustomToast("Please fill in all required fields!", "error");
      return false;
    }
    if (newPassword !== confirmPassword) {
      CustomToast("Confirm password does not match", "error");
      return false;
    }
    return true;
  };

  const handleChangePassword = async () => {
    if (validateForm()) {
      const passwordData = {
        currentPassword: currentPassword,
        newPassword: newPassword,
      };
      try {
        await updatePassword(currentUser?.id, passwordData);
        localStorage.setItem(
          "creds",
          JSON.stringify({
            password: newPassword,
          })
        );
        setNewPassword("");
        setCurrentPassword("");
        setConfirmPassword("");
        CustomToast("Successfully changed user's password", "success");
      } catch (error) {
        CustomToast(error.response.data.message, "error");
      }
    }
  };

  useEffect(() => {
    const activeIndexFromState = location.state?.activeIndex;
    if (activeIndexFromState !== undefined) {
      setActiveIndex(activeIndexFromState);
    }
  }, [location.state]);

  const fetchFavoritesList = async () => {
    const storedData = localStorage.getItem("gender_weight");
    const { gender, weight } = JSON.parse(storedData) ?? {
      gender: "",
      weight: "",
    };
    try {
      setIsfavoriteLoaded(true);
      const response = await getFavorites(currentUser?.id, gender, weight);
      setFavoritesList(response?.data?.products);
      setIsfavoriteLoaded(false);
      dispatch(setIsLoading(false));
    } catch (error) {
      setIsfavoriteLoaded(false);
      console.error("Error fetching getFavorites", error);
    }
  };

  const fetchData = async () => {
    try {
      setIsDataLoaded(true);
      const response = await getOrders(currentUser?.id, status, from, to);
      const { data } = response?.data;
      setOrderList(data);
      setIsDataLoaded(false);
    } catch (error) {
      setIsDataLoaded(false);
      console.error("Error fetching country code:", error);
    }
  };

  const fetchAddressData = async () => {
    try {
      const response = await getAddress(currentUser?.id);
      setAddressDetail(response?.data?.addresses);
      dispatch(setIsLoading(false));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    dispatch(setFrom(""));
    dispatch(setTo(""));
    dispatch(setStatus(""));
    fetchData();
  }, [activeIndex === 3]);

  useEffect(() => {
    fetchData();
  }, [currentUser, status, from, to]);

  useEffect(() => {
    fetchAddressData();
    fetchFavoritesList();
  }, [currentUser]);

  const handleToggleButton = async () => {
    setToggleButton(!toggleButton);
    if (toggleButton) {
      await setNotification(currentUser?.id, "disable");
    } else {
      await setNotification(currentUser?.id, "enable");
    }
  };
  const openAddNewAddress = () => {
    dispatch(setOpenAddAddress());
  };

  const handleClickDetail = (id) => {
    if (!isNavigating) {
      navigate(`/product/detail/${id}`);
      setIsNavigating(true);
    }
  };

  const handleDeleteAddress = async (id) => {
    dispatch(setIsLoading(true));
    await getDeleteAddress(currentUser?.id, id);
    await fetchAddressData();
  };

  const handleEditAddress = async (id) => {
    dispatch(setOpenAddAddress());
    const data = addressDetail?.filter((data) => data?.id === id);
    dispatch(setAddressDataById(data?.[0]));
  };

  const handleRemoveFavorites = async (event, id) => {
    event.stopPropagation();
    setIsRemoveId(id);
    try {
      setIsRemoveLoaded(true);
      const res = await addToFavoriteProducts(currentUser?.id, id, "remove");
      const { status } = await res?.data;
      if (status) {
        setFavoritesList((prev) => {
          const value = [...prev];
          const newValue = value?.filter((item) => {
            return item?.id !== id;
          });
          return newValue;
        });
        setIsRemoveLoaded(false);
        setIsRemoveId("");
      }
    } catch (error) {
      setIsRemoveLoaded(false);
    }

    // fetchFavoritesList();
  };

  const handleShowMore = () => {
    setShowMore(showMore + 10);
  };

  const handleShowLess = () => {
    setShowMore(showMore - 10);
  };

  const truncateDescription = (description, maxLength) => {
    if (description.length > maxLength) {
      return description.substring(0, maxLength) + "...";
    }
    return description;
  };

  return (
    <Fragment>
      <ToastContainer />
      <section id="profile-main">
        <div className="container">
          <div className="profile-head">
            <h2>Account Settings</h2>
          </div>
          <div className="setting-detail">
            <div className="setting-option">
              <ul>
                <MenuItem
                  label="Personal Information"
                  isActive={activeIndex === 0}
                  onClick={() => handleItemClick(0)}
                  activeImage="images/User_icon_y.svg"
                  inactiveImage="images/user_icon.svg"
                />
                <MenuItem
                  label="Change Password"
                  isActive={activeIndex === 1}
                  onClick={() => handleItemClick(1)}
                  activeImage="images/User_icon_y.svg"
                  inactiveImage="images/password_icon.svg"
                />
                <MenuItem
                  label="My Wishlist"
                  isActive={activeIndex === 2}
                  onClick={() => handleItemClick(2)}
                  activeImage="images/save_icon_y.svg"
                  inactiveImage="images/save_icon.svg"
                />
                <MenuItem
                  label="My Orders"
                  isActive={activeIndex === 3}
                  onClick={() => handleItemClick(3)}
                  activeImage="images/order_bag_icon_y.svg"
                  inactiveImage="images/order_bag_icon.svg"
                />
                <MenuItem
                  label="Help & Support"
                  isActive={activeIndex === 5}
                  onClick={() => handleItemClick(5)}
                  activeImage="images/bx_support_y.svg"
                  inactiveImage="images/bx_support.svg"
                />
                <MenuItem
                  label="Manage Addresses"
                  isActive={activeIndex === 6}
                  onClick={() => handleItemClick(6)}
                  activeImage="images/address_icon_y.svg"
                  inactiveImage="images/address_icon.svg"
                />
              </ul>
            </div>
            <div className="setting-view-main">
              <div
                className={`setting-view ${activeIndex === 0 ? "active" : ""}`}
              >
                <PersonalDetail />
              </div>
              <div
                className={`setting-view ${activeIndex === 1 ? "active" : ""}`}
              >
                <div className="change-password">
                  <h3>Change Password</h3>
                  <div className="input-group">
                    <div className="input-field">
                      <label for="">Current Password</label>
                      <input
                        type={showCurrentPassword ? "text" : "password"}
                        className="form-control"
                        name="currentPassword"
                        placeholder="**********"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                      />
                      {showCurrentPassword ? (
                        <IoEyeOutline onClick={toggleShowCurrentPassword} />
                      ) : (
                        <IoEyeOffOutline onClick={toggleShowCurrentPassword} />
                      )}
                    </div>
                    <div className="input-field">
                      <label for="">New Password</label>
                      <input
                        type={showNewPassword ? "text" : "password"}
                        className="form-control"
                        name="newPassword"
                        placeholder="**********"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                      {showNewPassword ? (
                        <IoEyeOutline onClick={toggleShowNewPassword} />
                      ) : (
                        <IoEyeOffOutline onClick={toggleShowNewPassword} />
                      )}
                    </div>
                    <div className="input-field">
                      <label for="">Confirm Password</label>
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        className="form-control"
                        name="confirmPassword"
                        placeholder="**********"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      {showConfirmPassword ? (
                        <IoEyeOutline onClick={toggleShowConfirmPassword} />
                      ) : (
                        <IoEyeOffOutline onClick={toggleShowConfirmPassword} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="setting-btn">
                  <button className="cancel-btn">Cancel</button>
                  <button className="update-btn" onClick={handleChangePassword}>
                    Update
                  </button>
                </div>
              </div>
              <div
                className={`setting-view ${activeIndex === 2 ? "active" : ""}`}
              >
                <div className="cart-item-main wishlist-main">
                  <div className="cart-itme-head">
                    <span>My Wishlist ({favoritesList?.length})</span>
                  </div>
                  {isfavoriteLoaded ? (
                    <MixLoader />
                  ) : (
                    <>
                      {favoritesList && favoritesList?.length === 0 ? (
                        <div style={{ marginTop: 100 }}>
                          {" "}
                          <IllustrationNoProduct
                            tittle={"No product found!"}
                            imgLink={NoProductFound}
                          />{" "}
                        </div>
                      ) : (
                        <>
                          {favoritesList
                            ?.slice(0, showMore)
                            .map((favorites, idx) => (
                              <div
                                className="cart-item"
                                onClick={(e) =>
                                  handleClickDetail(favorites?.id)
                                }
                                key={favorites?.id}
                              >
                                <div className="cart-img">
                                  <img
                                    src={
                                      favorites?.product?.images[0] ||
                                      favorites?.ingredient?.images[0] ||
                                      "images/placeholder_image.png"
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="cart-details">
                                  <div className="cart-details-head">
                                    <h3>
                                      {favorites?.product?.title ||
                                        favorites?.ingredient?.title}
                                    </h3>
                                  </div>
                                  <p className="description-limit">
                                    {truncateDescription(
                                      favorites?.product?.description ||
                                        favorites?.ingredient?.description,
                                      150
                                    )}
                                  </p>
                                  <div
                                    style={{
                                      margin: "10px 0px",
                                      fontSize: "14px",
                                    }}
                                  >
                                    <div>
                                      {favorites?.type === "product"
                                        ? "Price for 30 servings (Days)"
                                        : `Price for 1  ${favorites?.quantity?.type} * 30 servings (Days)`}
                                    </div>
                                  </div>
                                  <div className="cart-quantity">
                                    <div className="quantity-btn">
                                      <span>
                                        ${" "}
                                        {favorites?.type == "product"
                                          ? getPriceTrunc(
                                              favorites?.product?.salePrice
                                            )
                                          : getPriceTrunc(
                                              favorites?.ingredientPrice
                                                ?.salePricePerMonth
                                            )}
                                        {/* {getPriceTrunc(
                                          favorites?.ingredientPrice
                                            ?.salePricePerMonth
                                        ) ||
                                          getPriceTrunc(
                                            favorites?.ingredient?.salePrice
                                          ) ||  getPriceTrunc(
                                            favorites?.product?.salePrice
                                          )
                                        } */}
                                      </span>
                                    </div>
                                    <div></div>
                                    <div
                                      className="cart-item-remove"
                                      onClick={(e) =>
                                        handleRemoveFavorites(e, favorites?.id)
                                      }
                                    >
                                      <button className="remove-item">
                                        {isRemoveID === favorites?.id &&
                                        isRemoveLoaded ? (
                                          <CartDeleteSpinner />
                                        ) : (
                                          <>
                                            <img
                                              src="images/delete_grey_icon.svg"
                                              alt=""
                                            />
                                            Remove
                                          </>
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </>
                      )}
                    </>
                  )}
                </div>

                <div className="show_more-less" style={{ paddingRight: "0px" }}>
                  {favoritesList?.length > showMore && (
                    <button
                      className="show_more-button"
                      onClick={handleShowMore}
                    >
                      Show More
                    </button>
                  )}
                  {showMore > 10 && (
                    <button
                      className="show_less-button"
                      onClick={handleShowLess}
                    >
                      Show Less
                    </button>
                  )}
                </div>
              </div>
              <div
                className={`setting-view ${activeIndex === 3 ? "active" : ""}`}
              >
                <UserOrderList
                  orderList={orderList}
                  isDataLoaded={isDataLoaded}
                />
              </div>
              <div
                className={`setting-view ${activeIndex === 5 ? "active" : ""}`}
              >
                <div className="cart-item-main wishlist-main manage-address help-support">
                  <div className="cart-itme-head">
                    <span>Help & Support</span>
                  </div>
                </div>
                <div className="information">
                  <div className="input-group">
                    <div className="input-field">
                      <label>Email</label>
                      <input type="email" value="Tech@lavimd.com" disabled />
                    </div>
                    <div className="input-field">
                      <label>Phone Number</label>
                      <input type="text" value="+1 561 794-3450" readOnly />
                    </div>
                  </div>
                  <div className="help-note">
                    <p>
                      Please note that phone calls for help and support are
                      subject to the availability of our support team. We strive
                      to assist you promptly, but there may be times when
                      immediate availability is limited
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={`setting-view ${activeIndex === 6 ? "active" : ""}`}
              >
                <div className="cart-item-main wishlist-main manage-address">
                  <div className="cart-itme-head">
                    <span>Manage Addresses</span>
                  </div>
                  {isLoading ? (
                    <MixLoader />
                  ) : (
                    <div className="checkout-shipping-main">
                      <div className="checkout-shipping">
                        {addressDetail && addressDetail?.length === 0 ? (
                          <div style={{ marginTop: 20 }}>
                            {" "}
                            <IllustrationNoProduct
                              imgLink={NoAddressFound}
                              tittle={"Lets's get started"}
                              subTittle={"Add your delivery addresses"}
                            />{" "}
                          </div>
                        ) : (
                          <>
                            {addressDetail?.map((address) => (
                              <>
                                <div className="address">
                                  <div className="head">
                                    <span>
                                      <img
                                        src={
                                          address?.addressType === "Home"
                                            ? "images/home_icon.svg"
                                            : "images/corporate_fare.svg"
                                        }
                                        alt=""
                                      />{" "}
                                      {address?.addressType}
                                    </span>
                                    <div className="address-manage">
                                      <button
                                        className="edit-btn"
                                        onClick={() =>
                                          handleEditAddress(address?.id)
                                        }
                                      >
                                        <img
                                          src="images/edit_icon.svg"
                                          alt=""
                                        />
                                      </button>
                                      <button
                                        className="delete-btn"
                                        onClick={() =>
                                          handleDeleteAddress(address?.id)
                                        }
                                      >
                                        <img
                                          src="images/delete_icon_grey.svg"
                                          alt=""
                                        />
                                      </button>
                                    </div>
                                  </div>
                                  <h4>
                                    {address?.name}{" "}
                                    <span className="circle"></span>
                                    <span>
                                      {"+"}
                                      {address?.phone?.dialCode}{" "}
                                      {address?.phone?.number}
                                    </span>
                                  </h4>
                                  <p>{address?.address}</p>
                                </div>
                              </>
                            ))}
                          </>
                        )}
                        <div
                          className="add-address-btn"
                          onClick={openAddNewAddress}
                        >
                          <button>
                            <img src="images/plus_icon_yellow.svg" alt="" /> Add
                            new address
                          </button>
                        </div>
                        <AddNewAddress fetchAddressData={fetchAddressData} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default AccountSettings;
