import React from 'react';
import SuccessIcon from '../../../assets/svg/SuccessIcon';
import { getCustomerCartProducts } from '../../../store/slices/cartSlice/cart';
import {useDispatch} from "react-redux";

const CartSuccess = ({handleOk , updateCart}) => {

   const dispatch = useDispatch()
 

  const okHandler = ()=>{
    if(updateCart){
      dispatch(getCustomerCartProducts());
    }
    handleOk()
  }
  return (
    <div className="payment-form-overlay">
       <div className="cart-sucess-main active">
            <span><SuccessIcon/></span>
            <h2>Success!</h2>
            <p>{updateCart ? "Your Mix from Scratch has been updated!" : "Your item has been added successfully to your cart section."}</p>
            <div className="logout-btn">
                <button className="yes-btn" onClick={okHandler}>ok</button>
            </div>
        </div>
        </div>
   
  );
}

export default CartSuccess;
