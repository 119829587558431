import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  costs: [],
};

const costSlice = createSlice({
  name: "cost",
  initialState,
  reducers: {
    setCosts: (state, action) => {
      state.costs = action.payload;
    },
    setTopSellingCost: (state, action) => {
      state.costs = action.payload;
    },
  },
});

export const { setCosts, setTopSellingCost } = costSlice.actions;
export const selectCosts = (state) => state.cost.costs;
export default costSlice.reducer;
