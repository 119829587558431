import React from 'react';
import { getPriceTrunc, getToken, textElips } from '../../../utils/common';
import unlike_icon from "../../../assets/images/unlike_icon.svg"
import like_icon from "../../../assets/images/like_icon.svg"
import { useNavigate } from 'react-router-dom';

const ProductComponent = ({data , handleRemoveFromFavorite , handleAddToFavorite , handleCart}) => {
      // below useEffect for check token present in local Storage
      const token = getToken();
      const navigate = useNavigate()

      const handleClickDetail = (id) => {
        navigate(`/product/detail/${id}`);
      };
  return (
    <>
      <div class="product-col" onClick={() => handleClickDetail(data?.id)}>
        <div class="product">
         
            {token && (
              <button
                className="like-btn"
                onClick={(event) => {
                  event.stopPropagation(); // Prevent event propagation
                  data.isFavorite
                    ? handleRemoveFromFavorite(data.id)
                    : handleAddToFavorite(data.id);
                }}
              >
                <img src={data?.isFavorite ? unlike_icon : like_icon} alt="" />
              </button>
            )}
            {/* <img src="images/like_icon.svg" alt="" /> */}
          
          <span className="product-img">
            <img
              src={data?.product?.images?.[0] || "images/placeholder_image.png"}
              alt=""
            />
          </span>
          <div className="detail" style={{ cursor: "pointer" }}>
            {data?.product?.category?.map((item, index) => (
              <React.Fragment key={index}>
                <span>{item?.categoryName}</span>
                {index !== data?.product?.category?.length - 1 && (
                  <span>,</span>
                )}
              </React.Fragment>
            ))}
            <h3>{textElips(data?.product?.title, 45)}</h3>
            <span className="rating">
              <img src="images/star_icon.svg" alt="" />
              {data?.review?.avg.toFixed(2)} (
              {data?.review?.star5 +
                data?.review?.star4 +
                data?.review?.star3 +
                data?.review?.star2 +
                data?.review?.star1}{" "}
              reviews)
            </span>
          </div>
          <div class="product-detail">
          <span> $ {data?.type === "product" ? getPriceTrunc(data?.product?.salePrice) : getPriceTrunc(data?.ingredientPrice?.salePricePerMonth)}  </span>
            <button onClick={(e) => handleCart(e, data)}>
              <img src="images/cart_icon.svg" alt="" />
              Add to cart
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductComponent;
