const initialState = {
  mixItems: [],
};

const mixReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_TO_MIX":
      return {
        ...state,
        mixItems: [...state.mixItems, action.payload],
      };
    case "REMOVE_FROM_MIX":
      return {
        ...state,
        mixItems: state.mixItems.filter(
          (item) => item.id !== action.payload.id
        ),
      };
    case "REMOVE_ALL_FROM_MIX":
      return {
        ...state,
        mixItems: [],
      };
    case "UPDATE_MIX_ITEM":
      return {
        ...state,
        mixItems: state.mixItems.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    default:
      return state;
  }
};

export default mixReducer;
