import { createSlice } from "@reduxjs/toolkit";

const recommendedDoseSlice = createSlice({
  name: "recommendedDose",
  initialState: {
    recommendedDose: [],
    priceOne: [],
    recommendedDoseOne: [],
  },
  reducers: {
    setRecommendedDose: (state, action) => {
      state.recommendedDose = action.payload;
    },
    setPriceOne: (state, action) => {
      state.priceOne = action.payload;
    },
    setRecommendedDoseOne: (state, action) => {
      state.recommendedDoseOne = action.payload;
    },
  },
});

export const { setRecommendedDose, setPriceOne, setRecommendedDoseOne } =
  recommendedDoseSlice.actions;
export default recommendedDoseSlice.reducer;
