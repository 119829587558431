import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userIngredients } from "../../../config/makeRequests/reviewRequest";
import { postReview } from "../../../config/makeRequests/reviewRequest";
import { getUserId } from "../../../utils/common";
import { CustomToast } from "../../../component/common";




export const getUserIngredients = createAsyncThunk(
  "getUserIngredients/reviewSlice",
  async () => {
    try {
      let userId = getUserId()
      let response  = await userIngredients(userId)
      if (response.status === true) {
        return response?.data;
      }
    } catch (error) {
      console.log("removeAddress Error ", error);
    }
  }
);
export const postIngredientsReview = createAsyncThunk(
  "postIngredientsReview/reviewSlice",
  async ({data , setClickReview , setStarsCount , setReviewText}) => {
    try {

      const userId = getUserId()
      let response  = await postReview(userId , data )
      if (response.status === true) {
        setClickReview(false)
        setStarsCount(0)
        setReviewText("")
        CustomToast("Review added successfully" , "success")
        const actualResponse = {
          data,
          review:response?.review
        }
        return actualResponse;
      }
    } catch (error) {
      console.log("postIngredientsReview Error ", error);
    }
  }
);




const initialState = {
    isLoading:false,
    ingredientsList:[],

    postReviewIsLoading:false

};


const reviewSlice = createSlice({
  name: "reviewSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserIngredients.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserIngredients.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.ingredientsList= payload
        
      })
      .addCase(getUserIngredients.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(postIngredientsReview.pending, (state, { payload }) => {
        state.postReviewIsLoading = true;
      })
      .addCase(postIngredientsReview.fulfilled, (state, { payload }) => {

        const data = payload?.data
        const updatedId = data?.ingredientId ? data?.ingredientId : data?.productId

        const updatedValue = payload?.review;
        const prevList = state.ingredientsList
        const result = prevList.map((item)=>{
          return item?.id === updatedId ? {...item , "rating" : updatedValue?.rating }: item
        })
        state.ingredientsList = result
        state.postReviewIsLoading = false;
        state.postReviewIsLoading = false;
      })
      .addCase(postIngredientsReview.rejected, (state, { payload }) => {
        state.postReviewIsLoading = false;
      })
  },
});


export default reviewSlice.reducer;
