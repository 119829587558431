import React, { useEffect, useState } from "react";
import {
  getUserIngredients,
  postIngredientsReview,
} from "../../store/slices/reviewSlice/reviewSlice";
import { useDispatch, useSelector } from "react-redux";
import RatingComponent from "../../component/common/Rating/RatingComponent";
import IllustrationNoProduct from "../../component/common/IllustrationNoProduct";
import PostReview from "../../component/common/Reviews/PostReview";
import { CustomToast } from "../../component/common";
import MixLoader from "../../layout/MixLoader";

const LeaveReview = () => {


  // redux hooks 
  const { isLoading, ingredientsList, postReviewIsLoading } = useSelector(
    (state) => state.reviewSlice
  );

  const dispatch = useDispatch();

  const [checkboxValues, setCheckboxValues] = useState("product");

  const [filteredIngredients, setFilteredIngredients] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemsToShow, setItemsToShow] = useState(10);
  const [reviewText, setReviewText] = useState("");
  const [ingredientId, setIngredientId] = useState(null);
  const [productId, setProductId] = useState(null);


  const [clickReview, setClickReview] = useState(false);

  const [starsCount, setStarsCount] = useState(0);

  const handleChange = (e) => {
    setStarsCount(e);
  };

  const handleShowMore = () => {
    setItemsToShow(itemsToShow + 10);
  };

  const handleShowLess = () => {
    setItemsToShow(Math.max(itemsToShow - 10, 10));
  };
  const handleGiveReview = () => {
    if (!starsCount || !reviewText) {
      CustomToast("Please provide both rating and review.", "error");
      return;
    }
    const data = {
      rating: starsCount,
      review: reviewText,
      title: "title",
      ingredientId,
      productId,
    };

    dispatch(
      postIngredientsReview({
        data,
        setClickReview,
        setStarsCount,
        setReviewText,
      })
    );
  };

  useEffect(() => {
    if (ingredientsList?.length > 0) {
      setFilteredIngredients(ingredientsList);
    }
  }, [ingredientsList]);

  useEffect(() => {

    dispatch(getUserIngredients());
  }, []);

  useEffect(() => {
    // Filter the ingredients based on checkbox values
    const filtered = ingredientsList?.filter((item) => {
      if (checkboxValues === "product") {
        return item.type === "product";
      } else {
        return item.type === "ingredient";
      }
    });

    setFilteredIngredients(filtered);
  }, [checkboxValues, ingredientsList]);


  const handleIngredientChange = (event) => {
    const { name, value } = event.target;
    setCheckboxValues(value);
  };

  const ListedIngredients = filteredIngredients?.filter((item) => {
    return item.title?.toLowerCase().includes(searchQuery?.toLowerCase());
  });

  const handleNo = () => {
    setClickReview(false);
    setReviewText("");
    setStarsCount(0);
  };

  return (
    <>
      <section id="banner-main">
        <div className="banner-img mobile-hide-banner">
          <img src="images/leave_review_img.png" alt="" />
        </div>
        <div className="banner-text text-for-mobile">
          <div className="container">
            <div className="banner">
              <h1>Leave a review</h1>
            </div>
          </div>
        </div>
      </section>
      <div className="mix-main">
        <div className="container">
          <div className="creat-mix-main">
            <div className="creat-mix-overview active">
              <div className="filter-mix-main">
                <div className="mix-filter-main">
                  <h2>Filter by</h2>
                  <div className="filter">
                    <div className="gender">
                      <button>Type</button>
                      <div className="input-field">
                        <input
                        className={checkboxValues === "product" ? "active" : ""}
                          type="radio"
                          name="ingredient"
                          value="product"
                          onChange={handleIngredientChange}
                          defaultChecked={checkboxValues === "product"}
                        />
                        <label>Products</label>
                      </div>
                      <div className="input-field">
                        <input
                        className={checkboxValues === "ingredient" ? "active" : ""}
                          type="radio"
                          name="ingredient"
                          value="ingredient"
                          onChange={handleIngredientChange}
                          Checked={checkboxValues === "ingredient"}
                        />
                        <label>Ingredients</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mix-scartch-main">
                  <div className="mix-scartch-head">
                    <div className="mix-scartch-left">
                      <h2>List of ingredients</h2>
                    </div>
                    <div className="mix-scratch-right">
                      <div className="search-bar">
                        <span>
                          <img src="images/search_icon.svg" alt="" />
                        </span>
                        <input
                          type="search"
                          placeholder="Search"
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mix-scratch">
                    <table
                      border="0"
                      cellpadding="0"
                      style={{
                        width: "100%",

                        // borderBottom: "1px solid #e0e0e0",
                      }}
                    >
                      <thead>
                        <tr style={{ borderBottom: "1px solid #EDEDED" }}>
                          <th className="heading">Ingredients</th>
                          <th
                            style={{ textAlign: "center" }}
                            className="heading"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                    </table>
                    {
                      isLoading ? <MixLoader /> : (
                        <>
                          <table
                            border="0"
                            className="mix-scratch-mobile"
                            cellpadding="0"
                            style={{ width: "100%", padding: "0 45px 38px" }}
                          >
                            <tbody>
                              <tr>
                                <td>
                                  <table
                                    border="0"
                                    cellpadding="0"
                                    style={{
                                      width: "100%",
                                      // borderBottom: "1px solid #EDEDED",
                                    }}
                                  >
                                    <tbody>
                                      {ListedIngredients?.length === 0 ? (
                                        <div style={{ transform: "scale(0.8)" }}>
                                          <div className="leave-review-not">
                                            {" "}
                                            <IllustrationNoProduct
                                              tittle={"No Products found!"}
                                              imgLink="/images/noingredients.png"
                                              subTittle={null}
                                            />{" "}
                                          </div>
                                        </div>
                                      ) : (
                                        ListedIngredients?.slice(0, itemsToShow)?.map(
                                          (item, index) => {
                                            return (
                                              <React.Fragment key={item?.id}>
                                                <tr
                                                  style={{
                                                    borderBottom: "1px solid #EDEDED",
                                                  }}
                                                >
                                                  <td
                                                    className="table-col"
                                                    style={{ padding: "20px 45px" }}
                                                  >
                                                    {item?.title}
                                                  </td>
                                                  <td
                                                    className="add-btn"
                                                    style={{ textAlign: "center" }}
                                                  >
                                                    {item?.rating !== 0 ? (
                                                      <RatingComponent
                                                        value={item?.rating}
                                                      />
                                                    ) : (
                                                      <>
                                                        <button
                                                          className="give-review-btn"
                                                          onClick={() => {
                                                            setClickReview(true);
                                                            if (item.type === "ingredient") {
                                                              setIngredientId(item?.id);
                                                              setProductId(null)
                                                            } else {
                                                              setIngredientId(null)
                                                              setProductId(item?.id)
                                                            }

                                                          }}
                                                        >
                                                          <img
                                                            src="images/start_green_icon.svg"
                                                            alt="Icon"
                                                          />{" "}
                                                          Give review
                                                        </button>
                                                      </>
                                                    )}
                                                  </td>
                                                </tr>
                                              </React.Fragment>
                                            );
                                          }
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              {ListedIngredients?.length > 0 && (
                                <>
                                  <div>
                                    <div className="show_more-less">
                                      {ListedIngredients?.length > itemsToShow && (
                                        <button
                                          className="show_more-button"
                                          onClick={handleShowMore}
                                        >
                                          Show More
                                        </button>
                                      )}
                                      {itemsToShow > 10 && (
                                        <button
                                          className="show_less-button"
                                          onClick={handleShowLess}
                                        >
                                          Show Less
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </tbody>
                          </table>
                        </>
                      )
                    }



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PostReview
        clickReview={clickReview}
        starsCount={starsCount}
        handleChange={handleChange}
        setReviewText={setReviewText}
        reviewText={reviewText}
        setClickReview={setClickReview}
        handleGiveReview={handleGiveReview}
        isLoading={postReviewIsLoading}
        handleNo={handleNo}
      />
    </>
  );
};

export default LeaveReview;
