export function findMaleRangeByWeight(weight) {
    if (weight >= 80 && weight <= 140) {
      return "80-140 (LBS)";
    } else if (weight >= 141 && weight < 200) {
      return "141-200 (LBS)";
    } else if (weight >= 200) {
      return "200 (LBS)+";
    } 
  }

export function findFemaleRangeByWeight(weight) {
    if (weight >= 50 && weight <= 100) {
      return "50-100 (LBS)";
    } else if (weight >= 101 && weight <= 150) {
      return "101-150 (LBS)";
    } else if (weight >= 200) {
      return "151 (LBS)+";
    } 
  }

  export function textElips(text, words = 15) {
    return text.length > words ? `${text.slice(0, words)}...` : text
}






export function setWeightLocalStorage(weight, gender) {
  let weightRange;
  let weightRangeOne;

  if (gender?.toLowerCase() === "female") {
      if (weight >= 50 && weight <= 100) {
          weightRange = "50-100";
          weightRangeOne = "50-100 (LBS) weight";
      } else if (weight > 100 && weight <= 150) {
          weightRange = "101-150";
          weightRangeOne = "101-150 (LBS) weight";
      } else {
          weightRange = "150+";
          weightRangeOne = "151+ (LBS) weight";
      }
  } else {
      if (weight <= 140) {
          weightRange = "80-140";
          weightRangeOne = "80-140 (LBS) weight";
      } else if (weight > 140 && weight <= 200) {
          weightRange = "141-200";
          weightRangeOne = "141-200 (LBS) weight";
      } else {
          weightRange = "200+";
          weightRangeOne = "200+ (LBS) weight";
      }
  }

  localStorage.setItem(
      "filter",
      JSON.stringify({
          weight: weightRange,
          gender: gender.toLowerCase(),
      })
  );
  localStorage.setItem(
      "gender_weight",
      JSON.stringify({
          weight: weightRangeOne,
          gender: gender.toLowerCase(),
      })
  );
}
