import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
 
import authReducer from "./slices/Auth";
import loginReducer from "./slices/login";
import commonReducer from "./slices/common";
import productReducer from "./slices/productSlice";
import categoryReducer from "./slices/categorySlice";
import questionReducer from "./slices/questionSlice";
import ingredientSlice from "./slices/ingredientSilce";
import costReducer from "./slices/ingredentCostSlice";
import mixReducer from "../reducers/mixReducer";
import cart from "./slices/cartSlice/cart";
import checkoutSlice from "./slices/checkoutSlice/checkout";
import recommendedDoseSlice from "./slices/recommendedDoseSlice";
import ingredientsSlice from "./slices/mixingIngredients/ingredientsSlice";
import mixSlice from "./slices/mixSlice";
import reviewSlice from "./slices/reviewSlice/reviewSlice";
const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['auth'] // persist only 'auth' slice
};
 
const loginPersistConfig = {
  key: 'login',
  storage,
  whitelist: ['login'] // persist only 'login' slice
};
 
const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  login: persistReducer(loginPersistConfig, loginReducer),
  common: commonReducer,
  product: productReducer,
  category: categoryReducer,
  question: questionReducer,
  ingredient: ingredientSlice,
  cost: costReducer,
  mix: mixReducer,
  mixOne: mixSlice,
  recommendedDose: recommendedDoseSlice,
  cart,
  checkoutSlice,
  ingredientsSlice,
  reviewSlice
});
 
const persistedReducer = persistReducer({
  key: 'root',
  storage,
  whitelist: ['auth', 'login'] // persist only 'auth' and 'login' slices
}, rootReducer);
 
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
 
// Create a persistor object
export const persistor = persistStore(store);
 
export default store;