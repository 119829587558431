export const addToMix = (item) => {
  return {
    type: "ADD_TO_MIX",
    payload: item,
  };
};

export const removeFromMix = (item) => {
  return {
    type: "REMOVE_FROM_MIX",
    payload: item,
  };
};

export const updateMixItem = (updatedItem) => ({
  type: "UPDATE_MIX_ITEM",
  payload: updatedItem,
});

export const removeAllFromMix = () => ({
  type: "REMOVE_ALL_FROM_MIX",
});

export const updateFavoriteProducts = (updatedProducts) => ({
  type: "UPDATE_FAVORITE_PRODUCTS",
  payload: updatedProducts,
});

export const setRecommendedDose = (recommendedDose) => ({
  type: "SET_RECOMMENDED_DOSE",
  payload: recommendedDose,
});

export const setPrice = (price) => ({
  type: "SET_PRICE",
  payload: price,
});
