import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { sendReview } from "../Auth/profile";
import { useSelector } from "react-redux";
import HomeLoader from "../layout/HomeLoader";
import { CustomToast, errorAlert } from "../component/common";
import Spinner from "../component/common/Spinner";
import { reOrder } from "../config/makeRequests/cartRequests";
import LoaderOne from "../layout/LoaderOne";
import CartDeleteSpinner from "../component/common/CartDeleteSpinner";
import { useNavigate } from "react-router-dom";
import { getPriceTrunc } from "../utils/common";
import shoppintCart from "../assets/svg/shopping_cart.svg"

const UserOrderListDetails = ({ orderDetail, handleBack }) => {
  const [localOrderDetail, setLocalOrderDetail] = useState(orderDetail);
  const [status, setStatus] = useState(false);
  const [clickReview, setClickReview] = useState(false);
  const [orderItemId, setOrderItemId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [reviewText, setReviewText] = useState("");
  const [ratingValue, setRatingValue] = useState(0);
  const [showFullDescription, setShowFullDescription] = useState(false); // * sachin
  const rating = localOrderDetail?.[0]?.review?.rating;
  const currentUser = useSelector((state) => state.login);
  const [showMore, setShowMore] = useState(10);
  const [reviewGiven, setReviewGiven] = useState(false);
  const [reorderLoading, setReorderLoading] = useState(false);
  const [starIcons, setStarIcons] = useState(
    Array.from({ length: 5 }, (_, index) => (
      <img
        style={{ height: "20px" }}
        key={index}
        src={index < 0 ? "images/star_icon.svg" : "images/star_icon_grey.svg"}
        alt=""
      />
    ))
  );

  useEffect(() => {
    if (
      (localOrderDetail?.[0]?.hasOwnProperty("status") &&
        localOrderDetail?.[0]?.status?.[2]?.status === "order-completed") ||
      localOrderDetail?.[0]?.status?.[1]?.status === "order-cancelled"
    ) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  }, [localOrderDetail]);

  // const starIcons = Array.from({ length: rating }, (_, index) => (
  //   <img key={index} src="images/star_icon.svg" alt="" />
  // ));
  const starIconsOne = Array.from({ length: 5 }, (_, index) => (
    <img
      style={{ height: "20px" }}
      key={index}
      src={
        index < rating ? "images/star_icon.svg" : "images/star_icon_grey.svg"
      }
      alt=""
    />
  ));

  const handleSetReview = (orderItemId) => {
    setClickReview(true);
    setOrderItemId(orderItemId);
  };


  const handleGiveReview = async () => {
    if (!ratingValue || !reviewText) {
      CustomToast("Please provide both rating and review.", "error");
      return;
    }
    setIsLoading(true);
    const data = {
      rating: ratingValue,
      review: reviewText,
      title: "title",
    };
    try {
    await sendReview(
        currentUser?.id,
        orderDetail?.[0]?.id,
        orderItemId,
        data
      );
      setIsLoading(false);
      setClickReview(false);
      setReviewGiven(true);
      
    } catch (error) {
      // errorAlert("")
      console.log(error , "_______________________________");
      setIsLoading(false);
    }
  };

  const truncateDescriptionWithMaxLength = (description, maxLength) => {
    if (description?.length > maxLength) {
      return `${description?.substring(0, maxLength)}...`;
    } else {
      return description;
    }
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const handleStarClick = (value) => {
    setRatingValue(value);
    const updatedIcons = Array.from({ length: 5 }, (_, index) => (
      <img
        style={{ height: "20px" }}
        key={index}
        src={
          index < value ? "images/star_icon.svg" : "images/star_icon_grey.svg"
        }
        alt=""
      />
    ));
    setStarIcons(updatedIcons);
  };

  const navigate = useNavigate()

  const handleOrder = async ()=>{
    if(reorderLoading) return


    try {
      setReorderLoading(true)
    
    let res = await reOrder(orderDetail[0]?.id)
    navigate("/cart")


    setReorderLoading(false)
    } catch (error) {
      setReorderLoading(false)
      console.log("error" , error)
      
    }


  }


  return (
    <Fragment>
      {orderDetail && (
        <>
          <div className="head">
            <a  onClick={handleBack}>
              <img src="images/back_icon_black.svg" alt="" /> {orderDetail && orderDetail[0]?.orderId} 
            </a>
            <div onClick={handleOrder} style={{display:"flex" , justifyContent:"center" , alignItems:"center" , gap:4 , color:"#34d2ff" , cursor:"pointer" }}>{reorderLoading ? <CartDeleteSpinner/>:<img src={shoppintCart} alt="shopping cart "/>} Reorder</div>
          </div>
          {orderDetail?.map((detail) => (
            <div className="cart-item" key={detail.id}>
              <div className="cart-img">
                <img
                  src={
                    detail?.product?.images?.[0] ||
                    detail?.ingredient?.images?.[0] ||
                    "/images/placeholder_image.png"
                  }
                  alt=""
                />
              </div>
              <div className="cart-details">
                <div className="cart-details-head">
                  <h3>
                    {detail?.product?.title ||
                      detail?.ingredient?.title ||
                      detail?.mixFromScratchName}
                  </h3>
                  {detail.status &&
                  detail.status[2]?.status === "order-completed" &&
                  !detail.review ? (
                    <div>
                      {!reviewGiven ? (
                        <button
                          onClick={() => handleSetReview(detail.orderItemId)}
                          className="give_review-button"
                        >
                          <img
                            style={{ marginRight: "5px" }}
                            src={"images/green_star.svg"}
                            alt=""
                          />{" "}
                          Give Review
                        </button>
                      ) : (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {[1, 2, 3, 4, 5].map((index) => (
                            <img
                              style={{ height: "20px" }}
                              key={index}
                              src={
                                index <= ratingValue
                                  ? "images/star_icon.svg"
                                  : "images/star_icon_grey.svg"
                              }
                              alt=""
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  ) : (
                    detail?.status &&
                    detail.status[2]?.status === "order-completed" && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {[1, 2, 3, 4, 5].map((index) => (
                          <img
                            style={{ height: "20px" }}
                            key={index}
                            src={
                              index <= detail?.review?.rating
                                ? "images/star_icon.svg"
                                : "images/star_icon_grey.svg"
                            }
                            alt=""
                          />
                        ))}
                      </div>
                    )
                  )}
                </div>
                <p>
                  {showFullDescription
                    ? detail?.product?.description ||
                      detail?.ingredient?.description
                    : truncateDescriptionWithMaxLength(
                        detail?.product?.description ||
                          detail?.ingredient?.description,
                        150
                      )}
                  {((
                    detail?.product?.description ||
                    detail?.ingredient?.description
                  )?.length || 0) > 150 && (
                    <span>
                      <button
                        className="read-more-button"
                        onClick={toggleDescription}
                      >
                        {showFullDescription ? "Read Less" : "Read More"}
                      </button>
                    </span>
                  )}
                </p>
                <span className="quantity">Quantity: {detail?.quantity}</span>
                <div className="cart-quantity">
                  <div className="quantity-btn">
                    <span>${getPriceTrunc(detail?.itemPrice?.subTotal)}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="checkout-shipping-main">
            <div className="checkout-shipping">
              <div className="address">
                <div className="head">
                  <span>PAYMENT SUMMARY</span>
                </div>
                <div className="order-track-main">
                  <div className="track-head1">
                    <h4>Subtotal</h4>
                    <p>
                      <span>
                        ${orderDetail?.[0]?.orderPrice?.subTotal.toFixed(2)}
                      </span>
                    </p>
                  </div>
                  <div className="track-head1">
                    <h4>Tax</h4>
                    <p>
                      <span>
                        ${orderDetail?.[0]?.orderPrice?.tax.toFixed(2)}
                      </span>
                    </p>
                  </div>
                  <div className="track-head1">
                    <h4>Packaging and Mixing</h4>
                    <p>
                      <span>
                        <p>
                          <span>
                            $
                            {(
                              orderDetail?.[0]?.orderPrice?.packaging +
                              orderDetail?.[0]?.orderPrice?.mixing
                            ).toFixed(2)}
                          </span>
                        </p>
                      </span>
                    </p>
                  </div>
                  <div className="track-head1">
                    <h4>Delivery</h4>
                    <p>
                      <span>
                        {orderDetail?.[0]?.orderPrice?.delivery === 0
                          ? "Free"
                          : orderDetail?.[0]?.orderPrice?.delivery?.toFixed(2)}
                      </span>
                    </p>
                  </div>

                  <div className="track-head">
                    <h5>Total paid by customer</h5>
                    <p>
                      <span>
                        ${orderDetail?.[0]?.orderPrice?.total.toFixed(2)}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="address">
                <div className="head">
                  <span>
                    <img src="images/home_icon.svg" alt="" />
                    DELIVERY ADDRESS
                  </span>
                </div>
                <h4>
                  {orderDetail?.[0]?.address?.name}
                  <span className="circle"></span>
                  <span>
                  {"+"}{orderDetail?.[0]?.address?.phone?.dialCode.replaceAll("+", "")}{" "}
                    {orderDetail?.[0]?.address?.phone?.number}
                  </span>
                </h4>
                <p>
                  {orderDetail?.[0]?.address?.pincode},
                  {orderDetail?.[0]?.address?.address}
                </p>
              </div>
            </div>
          </div>
          <div className="checkout-shipping-main">
            <div className="checkout-shipping">
              <div className="address">
                <div className="order-track-main">
                  <div className="track-head">
                    <h5>ORDER TIMELINE</h5>
                  </div>
                  <div class="track-path">
                    <div class="order-track-main">
                      <ul
                        class={`track-path ${
                          orderDetail?.[0]?.status?.[2]?.status ? "active" : ""
                        }`}
                      >
                        <li class="current-status">
                          <h5>Order Created</h5>
                          <div class="current-circle"></div>
                          <span class="date">
                            {moment(orderDetail?.[0]?.status?.[0]?.time).format(
                              "DD MMM, YYYY"
                            )}
                          </span>
                        </li>
                        {orderDetail?.[0]?.status?.[1]?.status ===
                        "order-cancelled" ? (
                          <li>
                            <h5 style={{ color: "red" }}>Order Cancelled</h5>
                            <div class="current-circle-cancelled"></div>
                            <span class="date">
                              {moment(
                                orderDetail?.[1]?.status?.[1]?.time
                              ).format("DD MMM, YYYY")}
                            </span>
                          </li>
                        ) : (
                          <>
                            <li>
                              <h5>Order Confirmed</h5>
                              <div
                                className={
                                  orderDetail?.[0]?.status?.[1]?.status ===
                                  "order-confirmed"
                                    ? "current-circle"
                                    : "circle"
                                }
                              ></div>
                              <span className="date">
                                {orderDetail?.[0]?.status?.[1]?.status ===
                                "order-confirmed"
                                  ? moment(
                                      orderDetail?.[0]?.status?.[1]?.time
                                    ).format("DD MMM, YYYY")
                                  : ""}
                              </span>
                            </li>
                            <li>
                              <h5>Order Completed</h5>
                              <div
                                className={
                                  orderDetail?.[0]?.status?.[2]?.status ===
                                  "order-completed"
                                    ? "current-circle"
                                    : "circle"
                                }
                              ></div>
                              <span className="date">
                                {orderDetail?.[0]?.status?.[2]?.status ===
                                "order-completed"
                                  ? moment(
                                      orderDetail?.[0]?.status?.[2]?.time
                                    ).format("DD MMM, YYYY")
                                  : ""}
                              </span>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div
        style={{ position: "fixed" }}
        className={`overlay ${clickReview ? "active " : ""}`}
      />
      <div className={`rating_review-main ${clickReview ? "active " : ""}`}>
      
            <span>
              <img src="images/rate_review_icno.svg" alt="" />
            </span>
            <h2>Rate and Review</h2>
            <div className="input-field">
              <div className="input">
                <label>Rating ({ratingValue}/5)</label>
                <div className="star-icons">
                  {[1, 2, 3, 4, 5].map((value) => (
                    <span
                      key={value}
                      data-value={value}
                      onClick={() => handleStarClick(value)}
                    >
                      {starIcons[value - 1]}
                    </span>
                  ))}
                </div>
              </div>
              <div className="input">
                <label>Review</label>
                <textarea
                  placeholder="Add your comments"
                  value={reviewText}
                  onChange={(e) => setReviewText(e.target.value)}
                />
              </div>
            </div>
            <div className="logout-btn">
              <button className="no-btn" onClick={() => setClickReview(false)}>
                No
              </button>
              <button className="yes-btn" onClick={handleGiveReview}>
              {isLoading ? <Spinner width={20} height={20} /> : "Post" }  
              </button>
            </div>
      </div>
    </Fragment>
  );
};

export default UserOrderListDetails;
