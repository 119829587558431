import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { setGenderModule, setOpenLogin } from "../store/slices/Auth";
import {
  handlePickup,
  proceedToCheckout,
} from "../store/slices/cartSlice/cart";
import { getUserDetails, logoutAdmin } from "../store/slices/login";
import { CustomToast, generateInitials } from "./common";
import SelectGender from "./common/SelectGender";
import "../styles/style.css";
import { getToken } from "../utils/common";
import Forgot from "./Authentication/Forgot";
import Login from "./Authentication/Login";
import Register from "./Authentication/Register";
import ResetPassword from "./Authentication/ResetPassword";
import VerifyOtp from "./Authentication/VerifyOtp";
import { setActiveIndexMenu, setIsLoading } from "../store/slices/common";
import Shopping from "../assets/svg/Shopping";
import { getCustomerCartProducts } from "../store/slices/cartSlice/cart.js";
import Logo from "../assets/brand/Logo.svg";
import { setProductData } from "../store/slices/productSlice.js";
import { getAllProducts, getAllProductsNew } from "../Auth/product.js";
import { FaSearch } from "react-icons/fa";
import Search from "./common/search/Search.jsx";
import { CiSearch } from "react-icons/ci";

// /product
const Header = () => {
  const currentUser = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("home");
  const [activeMenu, setActiveMenu] = useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [openLogout, setOpenLogout] = useState(false);
  const profileMenuRef = useRef(null);
  const [totalCartProducts, setTotalCartProducts] = useState(0);
  const [barBtnActive, setBarBtnActive] = useState(false);
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTabPosition, setActiveTabPosition] = useState({
    left: 0,
    width: 0,
  });
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { supplier } = useSelector((state) => state.product);
  const {
    rating = "",
    availability = "",
    category = "",
    minPrice,
    rangeValue,
  } = useSelector((state) => state.product);

  useEffect(() => {
    if (pathname !== "/product") {
      setSearchTerm("");
    }
  }, [pathname]);
  const filterData = localStorage.getItem("gender_weight");
  useEffect(() => {
    if (!filterData) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "hidden";
    };
  }, [currentUser, filterData]);

  useEffect(() => {
    if (currentUser) {
      setProfilePic(currentUser?.image);
    }
  }, [currentUser]);

  // below useEffect for get customer cart products
  const token = getToken();

  useEffect(() => {
    if (token) {
      dispatch(getCustomerCartProducts());
    }
  }, [currentUser]);

  // below function update currentUser Data
  useEffect(() => {
    if (token) {
      dispatch(getUserDetails());
    }
  }, []);

  const handleTabClick = (tab, event) => {
    setActiveTab(tab);
    const rect = event.target.getBoundingClientRect();
    setActiveTabPosition({
      left: rect.left,
      width: rect.width,
    });
  };

  const fetchData = async () => {
    const storedData = localStorage.getItem("gender_weight");
    const { gender, weight } = JSON.parse(storedData) ?? {
      gender: "",
      weight: "",
    };
    const page = 1;
    const perPage = 12;
    dispatch(setIsLoading(true));
    if (currentUser && currentUser?.email) {
      const doctorId = supplier.join(",");

      try {
        const response = await getAllProducts(
          searchTerm,
          availability,
          category,
          doctorId,
          rating,
          minPrice,
          rangeValue,
          page,
          perPage,
          gender,
          weight,
          currentUser?.id
        );
        const { data } = response;
        dispatch(setProductData(data));
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setIsLoading(false));
      }
    } else {
      try {
        const doctorId = supplier.join(",");
        const response = await getAllProductsNew(
          searchTerm,
          availability,
          category,
          doctorId,
          rating,
          minPrice,
          rangeValue,
          page,
          perPage,
          gender,
          weight
        );
        const { data } = response;
        dispatch(setProductData(data));
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setIsLoading(false));
      }
    }
  };

  // search Handler

  const handleSearch = (e) => {
    if (e?.key === "Enter") {
      if (pathname === "/product") {
        navigate(`/product`);
        fetchData();
      } else {
        navigate(`/product?${searchTerm}`);
      }
    }
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleBlur = () => {
    if (!searchTerm.trim()) {
      navigate("/product");
      fetchData();
    }
  };

  const handleClickLogin = () => {
    dispatch(setOpenLogin(true));
  };

  const handleProfileClick = () => {
    if (window.innerWidth <= 991) {
      setActiveMenu(!activeMenu);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target)
      ) {
        setActiveMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileMenuRef]);

  const { cartItems, isCheckoutProceed } = useSelector((state) => state.cart);

  const countOfCart = () => {
    let count = 0;
    if (cartItems) {
      for (let item of cartItems) {
        if (item?.ingredient) {
          count++;
        } else {
          count += item.quantity;
        }
      }
      setTotalCartProducts(count);
    }
  };

  useEffect(() => {
    countOfCart();
  }, [cartItems]);

  useEffect(() => {
    if (currentUser?.isLoggedIn === false) {
      dispatch(setGenderModule(true));
    }
  }, [currentUser]);

  const handleClickMenu = (id) => {
    dispatch(setActiveIndexMenu(id));
    if (window.innerWidth <= 991) {
      setActiveMenu(false);
    }
    navigate("/account-setting");
  };
  const handleClickLogout = (click) => {
    setOpenLogout(click);
    setActiveMenu(false);
    setBarBtnActive(false);
  };
  const handleLogout = () => {
    localStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("filter");
    localStorage.removeItem("gender_weight");
    localStorage.removeItem("persist:root");
    dispatch(logoutAdmin());
    setOpenLogout(false);
    navigate("/");
    dispatch(proceedToCheckout(false));
    dispatch(setGenderModule(false));
  };

  const cartHandler = () => {
    let token = getToken();
    if (token) {
      navigate("/cart");
      dispatch(proceedToCheckout(false));
      dispatch(handlePickup(false));
    } else {
      dispatch(setOpenLogin(true));
    }
  };

  const dynamicLinks = [
    { to: "/", label: "Home" },
    { to: "/mix", label: "Mix" },
    { to: "/product", label: "Products" },
    { to: "/contact-us", label: "Contact Us" },
  ];

  const handleLogoClick = () => {
    setActiveTab("home");
  };
  const handleMobileLogoClick = () => {
    setActiveTab("home");
    setBarBtnActive(false);
  };

  // TODO -
  useEffect(() => {
    if (openLogout) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [openLogout]);

  // TODO -
  return (
    <header id="header-main">
      <div
        onClick={() => setBarBtnActive(false)}
        className={`overlay ${barBtnActive ? "active" : ""}`}
      ></div>
      <div className="header">
        <div className="logo">
          <Link to="/" onClick={handleLogoClick}>
            <img src={Logo} alt="Brand Logo not Found" />
          </Link>
        </div>
        <nav>
          <ul className={barBtnActive ? "menu-active" : ""}>
            <div className="mobile-logo_button">
              <div className="mobile-logo">
                <Link to="/" onClick={handleMobileLogoClick}>
                  <img style={{ width: "80%" }} src="/images/Logo.svg" alt="" />
                </Link>
              </div>

              <div>
                <button
                  style={{ background: "none", border: "none" }}
                  onClick={() => setBarBtnActive(false)}
                >
                  <img src="images/times_icon.svg" alt="" />
                </button>
              </div>
            </div>
            {dynamicLinks.map((link, index) => (
              <li
                onClick={() => setBarBtnActive(false)}
                // style={{ paddingBottom: "10px" }}
                key={index}
              >
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : ""
                  }
                  to={link.to}
                  onClick={(event) =>
                    handleTabClick(link.label.toLowerCase(), event)
                  }
                >
                  {link.label}
                </NavLink>
              </li>
            ))}

            <button
              onClick={() => handleClickLogout(true)}
              className="logout-btn_header"
            >
              Logout
            </button>
          </ul>
          <div
            className="active-tab-border"
            style={{
              left: activeTabPosition.left,
              width: activeTabPosition.width,
            }}
          ></div>
        </nav>
        <div className="user">
          <ul>
            <li>
              <div class="search-container">
                <Search
                  value={searchTerm}
                  changeHandle={handleChange}
                  onKeyPress={handleSearch}
                  onBlur={handleBlur}
                />
              </div>
            </li>
            <li className={`bar-btn ${barBtnActive ? "active" : ""}`}>
              <button onClick={() => setBarBtnActive(!barBtnActive)}>
                <img src="/images/bar_icon.svg" alt="" />
              </button>
            </li>
            <li
              className="search_icon_mobile"
              onClick={() => setIsSearchBarVisible(!isSearchBarVisible)}
            >
              <CiSearch size={20} />
            </li>
            <li onClick={cartHandler} className="cart-icon">
              <Shopping />
              {totalCartProducts > 0 && getToken() && (
                <span className="cart-order-num">{totalCartProducts}</span>
              )}
            </li>
            {currentUser && currentUser?.id ? (
              <li className="user-profile-main">
                <a
                  href="#"
                  className="user-profile"
                  onClick={handleProfileClick}
                  onMouseEnter={(e) => setActiveMenu(true)}
                >
                  {profilePic ? (
                    <img src={`${profilePic}?timestamp=${Date.now()}`} alt="" />
                  ) : (
                    <div className="user-profile-img">
                      {generateInitials(currentUser?.name)}
                    </div>
                  )}
                </a>

                <div
                  ref={profileMenuRef}
                  className={`profile-main ${
                    activeMenu ? "profile-active" : " "
                  }`}
                  onMouseLeave={(e) => setActiveMenu(false)}
                >
                  <span>
                    {profilePic ? (
                      <img
                        src={`${profilePic}?timestamp=${Date.now()}`}
                        alt=""
                      />
                    ) : (
                      <div className="user-profile-img">
                        {generateInitials(currentUser?.name)}
                      </div>
                    )}
                  </span>
                  <h2>{currentUser?.name}</h2>
                  <a href="#">
                    {currentUser?.email && currentUser.email.length > 25
                      ? `${currentUser.email.substring(0, 25)}...`
                      : currentUser.email}
                  </a>
                  <div className="profile-menu">
                    <ul>
                      <li>
                        <a
                          onClick={() => handleClickMenu(0)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="profile_icon">
                            <img src="images/profile_user_icon.svg" alt="" />
                          </div>
                          My Profile
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => handleClickMenu(3)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="profile_icon">
                            <img src="images/order_icon.svg" alt="" />
                          </div>
                          Orders
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => handleClickMenu(2)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="profile_icon">
                            <img src="images/wishlist_icon.svg" alt="" />
                          </div>
                          My Wishlist
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="logout-btn"
                    onClick={() => handleClickLogout(true)}
                  >
                    <a href="#">
                      <img src="images/Logout_icon.svg" alt="" />
                      Logout
                    </a>
                  </div>
                </div>
              </li>
            ) : (
              <li>
                <a className="user-btn" onClick={handleClickLogin}>
                  <img src="images/user_icon.svg" alt="" />
                </a>
              </li>
            )}
          </ul>
        </div>
        {isSearchBarVisible && (
          <div className="mobile-search-bar">
            <Search
              value={searchTerm}
              changeHandle={handleChange}
              onKeyPress={handleSearch}
              onBlur={handleBlur}
            />
          </div>
        )}
      </div>
      <div>
        <Login />
        <Register />
        <Forgot />
        <VerifyOtp />
        <ResetPassword />
        <div className={`overlay ${openLogout ? "logout-active" : " "}`}></div>
        <div className={`logout-main ${openLogout ? "logout-active" : " "}`}>
          <span>
            <img src="images/red_alert.png" alt="" />
          </span>
          <h2>Please confirm!</h2>
          <p>Are you sure, you want to logout?</p>
          <div className="logout-btn">
            <button className="no-btn" onClick={() => handleClickLogout(false)}>
              No
            </button>
            <button className="yes-btn" onClick={handleLogout}>
              Yes
            </button>
          </div>
        </div>
        {currentUser?.isLoggedIn === false ? <SelectGender /> : ""}
      </div>
    </header>
  );
};

export default Header;
