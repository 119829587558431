import { Fragment, useEffect, useState } from "react"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { setPasswordAPI } from "../../Auth/api";
import { useNavigate } from "react-router-dom";
import { handleOpenLogin, setResetPassword } from "../../store/slices/Auth";
import { AxiosError } from "axios";
import { CustomToast, errorAlert } from "../common";
import Spinner from "../common/Spinner";

const ResetPassword = () => {
    const openResetPassword = useSelector((state) => state.auth.openResetPassword)
    const email = useSelector((state) => state.auth.email);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [newPasswordEyeIcon, setNewPasswordEyeIcon] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();



   
    useEffect(() => {
        setIsLoading(false)
        setConfirmPassword("")
        setNewPassword("")
        return () => {
            setConfirmPassword("");
            setNewPassword("");
        };
    }, []);
    const validateForm = () => {
        if (!confirmPassword || !newPassword) {
            CustomToast("Both fields required!", "error");
            return false;
        }
    
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
        if (!passwordRegex.test(newPassword)) {
            CustomToast("Confirm password must be at least 8 characters and include at least one uppercase letter, one lowercase letter, and one number!","error");
            return false;
        }
    
        if (confirmPassword !== newPassword) {
            CustomToast("Passwords do not match!", "error");
            return false;
        }
    
        return true; // Return true if all validations pass
    };

    const handleResetPassword = async () => {
       
        if (validateForm()) {
            setIsLoading(true);
            try {
              const res = await setPasswordAPI(email , newPassword)
              const {status} = await res?.data;

              if(status){
                setIsLoading(false);
                setConfirmPassword("")
                setNewPassword("")
                CustomToast("Successfully changed password.", "success")
                dispatch(handleOpenLogin());
              }
             
            } catch (err) {
                if (err instanceof AxiosError) {
                    setIsLoading(false);
                    CustomToast(err.response.data.message, "error")

                } else {
                    setIsLoading(false);
                    CustomToast("Something went wrong.", "error")

                }
            }
        }
    }
    const handleCloseReset = () => {
        dispatch(setResetPassword(false))
    }
    const handleClickIcon = () => {
        setPasswordVisible(!passwordVisible)
    }
    const handleClickNewPasswordIcon = () => {
        setNewPasswordEyeIcon(!newPasswordEyeIcon)
    }
    const handleBackToLogin = () => {
        dispatch(handleOpenLogin());
    }

    return (
        <Fragment>
            <div
                style={{ position: 'fixed' }}
                className={`overlay ${openResetPassword ? 'active ' : ''}`}
            />
            <ToastContainer />
            <div className={`new-password-main ${openResetPassword ? "password-active" : " "}`}>
                <div className="logo">
                    <a href="#"><img src="images/Logo.svg" alt="Logo" /></a>
                    <span><img src="images/times_icon.svg" alt="" onClick={handleCloseReset} />
                    </span>
                </div>
                <div className="login-field">
                    <h2>Set new password</h2>
                    <p>Must be at least 8 characters</p>
                    <div className="input-field">
                    <input
                            className="form-control"
                            name="newPassword"
                            placeholder="New password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            type={newPasswordEyeIcon ? 'text' : 'password'}
                            style={{ paddingRight: 55 }}
                        />
                         <button className="eye-btn" onClick={handleClickNewPasswordIcon}>
                            {newPasswordEyeIcon ? <img src="/images/eye_icon.svg" alt="" /> : <img src="/images/Hide_password_icon.svg" alt="" />}
                        </button>
                       
                    </div>
                    <div className="input-field">
                    <input
                            className="form-control"
                            type={passwordVisible ? 'text' : 'password'}
                            name="confirmNewPassword"
                            placeholder="Confirm password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            style={{ paddingRight: 55 }}
                        />
                        
                        <button className="eye-btn" onClick={handleClickIcon}>
                            {passwordVisible ? <img src="/images/eye_icon.svg" alt="" /> : <img src="/images/Hide_password_icon.svg" alt="" />}
                        </button>
                    </div>
                    <div className="login-btn">
                        <a href="#" className="reset-btn" onClick={handleResetPassword}>{isLoading ? (<Spinner width={20} height={20} />) : "Reset password"} </a>
                    </div>
                    <div className="back-login" onClick={() => handleBackToLogin()}>
                        <a href="#"><img src="images/back_btn.svg" alt="Back icon" />Back to login</a>
                    </div>
                </div>
            </div>
            <div className="complete-reset">
                <div className="logo">
                    <a href="#"><img src="images/Logo.svg" alt="Logo" /></a>
                    <span><img src="images/times_icon.svg" alt="" />
                    </span>
                </div>
                <div className="login-field">
                    <h2>Reset complete <img src="images/party_popper.png" alt="party popper image" /></h2>
                    <p>Your password has been reset. You can now use your new password to log in to your account</p>
                    <div className="login-btn">
                        <a href="#" className="return-login">Return to login</a>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ResetPassword;