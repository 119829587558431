import "./spinner.css";
const CartDeleteSpinner = ({ width, height }) => {
  return (
    <span
      className="small_spinner-cart"
      style={{ width: width, height: height }}></span>
  );
};

export default CartDeleteSpinner;
