import {React,Fragment, useState} from "react";
import { useDispatch, useSelector } from 'react-redux'
import "../../styles/style.css";
import { handleClose, handleOpenLogin,handleOpenVerifyOtps, setGenderModule, storeEmail } from '../../store/slices/Auth'
import { forgotPasswordAPI } from "../../Auth/api";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CustomToast } from "../common";
import Spinner from '../common/Spinner'

const Forgot = () => {
    const openForgot = useSelector((state) => state.auth.openForgot)
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();


    const handleCloseForgot = () =>{
       dispatch(handleClose()) 
       dispatch(setGenderModule(true));
    }
    const handleClick = () =>{
        dispatch(handleOpenLogin())
    }
    const handleForgotClick = async() =>{
        if(!email) return;
        setIsLoading(true)
            try {
              const { data } = await forgotPasswordAPI(email);
              setIsLoading(false)
              dispatch(storeEmail(email))
              CustomToast("Sent Otp successfully","success")
              dispatch(handleOpenVerifyOtps());
              setEmail('')
            } catch (err) {
              setIsLoading(false)
              CustomToast(err?.response?.data?.message,"error")
            }
    }

    return (
        <Fragment>
            <div
                style={{ position: 'fixed' }}
                className={`overlay ${openForgot ? 'active ' : ''}`}
            />
            <ToastContainer/>
            <div className={`forgot-main ${openForgot ? "forgot-active" : " "}`}>
                <div className="logo">
                    <a><img src="images/Logo.svg" alt="Logo" /></a>
                    <span><img src="images/times_icon.svg" alt="" onClick={handleCloseForgot}/>
                    </span>
                </div>
                <div className="login-field">
                    <h2>Forgot password?</h2>
                    <p>No problem. Just enter your email address below -
                        we’ll send you a OTP to reset it.</p>
                    <div className="input-field">
                    <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="yourmail@example.com"
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                    />
                    </div>
                    <div className="login-btn " onClick={handleForgotClick}>
                        <a className="forgot-link" >{isLoading ? (<Spinner width={20} height={20}/>) :"Send OTP"}</a>
                    </div>
                    <div className="back-login" onClick={handleClick} >
                        <a><img src="images/back_btn.svg" alt="Back icon"/>Back to login</a>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default Forgot;