import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleClose,
  handleOpenLogin,
  setGenderModule,
  // setGenderModule,
  setOpenLogin,
} from "../../store/slices/Auth"; // * sachin
import { setGenderOpen } from "../../store/slices/Auth";
import { CustomToast } from "../common";
import { useNavigate } from "react-router-dom";
import vectorImage from "../../assets/images/Vector.svg";

const SelectGender = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { openLogin } = useSelector((state) => state.auth);
  const openRegister = useSelector((state) => state.auth.openRegister);
  const genderModule = useSelector((state) => state.auth.genderModule);
  const [showInputs, setShowInputs] = useState(false);
  useEffect(() => {
    dispatch(handleClose());
  }, [dispatch]);

  const [selectedGender, setSelectedGender] = useState("");
  const [selectedWeight, setSelectedWeight] = useState("");
  const [containerHeight, setContainerHeight] = useState("auto");

  useEffect(() => {
    dispatch(handleClose());
  }, [dispatch]);

  useEffect(() => {
    if (genderModule || openLogin || openRegister || !showInputs) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "hidden";
    };
  }, [genderModule, openLogin, openRegister, showInputs]);


  const handleSaveDetail = () => {
    let genderToStore = selectedGender.toLowerCase();

    if (!selectedGender || selectedGender === "other") {
      genderToStore = "male";
    }

    if (selectedGender && selectedWeight) {
      const formatWeight = selectedWeight;
      const formattedWeight = `${selectedWeight} (LBS) weight`;

      const newFilter = {
        gender: genderToStore,
        weight: formattedWeight,
      };
      const newFilterOne = {
        gender: genderToStore,
        weight: formatWeight,
      };

      localStorage.setItem("gender_weight", JSON.stringify(newFilter));
      localStorage.setItem("filter", JSON.stringify(newFilterOne));
      navigate("/");
      dispatch(setGenderModule(false));
      dispatch(setGenderOpen(false));
      setContainerHeight("auto");
      document.body.style.overflow = "auto";
    } else {
      CustomToast("Select gender and weight both", "error");
    }
  };

  const renderWeightOptions = () => {
    if (selectedGender === "male" || selectedGender === "other") {
      return ["80-140", "141-200", "200+"];
    } else if (selectedGender === "female") {
      return ["50-100", "101-150", "151+"];
    } else {
      return [];
    }
  };
  const handleLogin = () => {
    dispatch(handleOpenLogin());
  };

  const handleContinueAsGuest = () => {
    setShowInputs(true);
    setContainerHeight("auto");
    // document.body.style.overflow = "hidden";
  };

  return (
    <Fragment>
      <div className={`overlay ${genderModule ? "logout-active" : ""}`}></div>
      <div
        className={`logout-main-one ${genderModule ? "logout-active" : " "}`}
        style={{ height: containerHeight }}>
        <div
          className="card-details"
          style={{ paddingBottom: "22px", marginBottom: "10px" }}>
          <img src={vectorImage} alt="" />
          <h5>Dynamic Personalized Pricing</h5>
          <p>
            Discover personalized pricing at ABBSI. Select your gender and input
            your weight to see tailored prices on every visit. Uncover exclusive
            deals designed just for you!
          </p>
          {!showInputs && (
            <div className="gender_male-input">
              <div>
                <button onClick={handleContinueAsGuest} className="guest">
                  Continue as guest
                </button>
              </div>
              <div>
                <button onClick={handleLogin} className="login">
                  Login
                </button>
              </div>
            </div>
          )}
          {showInputs && (
            <>
              <div className="gender_male-input">
                <div className="input-field">
                  <select
                    name="gender"
                    placeholder="Your gender"
                    value={selectedGender}
                    onChange={(e) => setSelectedGender(e.target.value)}>
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div className="input-field">
                  <select
                    name="weight"
                    placeholder="Your weight"
                    disabled={!selectedGender}
                    value={selectedWeight}
                    onChange={(e) => {
                      setSelectedWeight(e.target.value);
                    }}>
                    <option value="">Select your weight (LBS)</option>
                    {renderWeightOptions().map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="logout-btn">
                <button className="yes-btn" onClick={handleSaveDetail}>
                  Done
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default SelectGender;
