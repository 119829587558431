import { getRequest, postRequest } from "../../Auth"




export const getAdditionalIngredients  = (productId)=>{
    return getRequest(`/api/users/products/${productId}/additionalIngredients`)
}

export const flavors  = ()=>{
    return getRequest(`/api/users/products/flavors`)
}

export const sweeteners  = ()=>{
    return getRequest(`/api/users/products/sweeteners`)
}

export const cartAdd  = (userId , data)=>{
    return postRequest(`/api/users/${userId}/cart/add` , data)
}
export const getIngredientUser  = ()=>{
    return getRequest(`/api/users/ingredients/all`)
}