import React, { useEffect, useState } from "react";
import Spinner from "../../../common/Spinner";
import { PaymentForm, CreditCard } from "react-square-web-payments-sdk";
import OrderCompletePopup from "../../../common/OrderCompletePopup";
import { useDispatch, useSelector } from "react-redux";
import {
  emptyCartItems,
  emptyKeys,
  handlePickup,
  paymentHandle,
  proceedToCheckout,
} from "../../../../store/slices/cartSlice/cart";
import { selectDeliveryAddress } from "../../../../store/slices/checkoutSlice/checkout";
import { useNavigate } from "react-router-dom";

const Payment = ({ setPaymentModal, orderObject, paymentSuccess }) => {
  const [isPaymentElementLoading, setIsPaymentElementLoading] = useState(true);

  const APPLICATION_ID = process.env.REACT_APP_SQUARE_APPLICATION_ID;
  const LOCATION_ID = process.env.REACT_APP_SQUARE_LOCATION_ID;

  const navigate = useNavigate();

  // Redux hooks
  const dispatch = useDispatch();
  const { paymentLoading } = useSelector((state) => state.cart);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsPaymentElementLoading(false);
    }, 6000); // You can adjust the timeout as per your needs or remove it if not needed.
    return () => clearTimeout(timeout);
  }, []);

  // const  handleOk = ()=>{
  //   dispatch(emptyKeys())
  //   navigate("/")
  //   dispatch(selectDeliveryAddress(null))
  //   dispatch(proceedToCheckout(false))
  //   dispatch(handlePickup(false))
  //   dispatch(emptyCartItems());

  // }

  return (
    <>
      <div className="payment-form-overlay">
        <div className="payment-container">
          <span className="cross-btn" onClick={() => setPaymentModal(false)}>
            X
          </span>
          {isPaymentElementLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Spinner width={30} height={30} />{" "}
            </div>
          ) : (
            <PaymentForm
              applicationId={APPLICATION_ID}
              cardTokenizeResponseReceived={(token, verifiedBuyer) => {
                dispatch(
                  paymentHandle({
                    data: {
                      ...orderObject,
                      cardSourceId: token?.token,
                      isCashPaymentUser: false,
                    },
                    paymentSuccess,
                  })
                );
              }}
              locationId={LOCATION_ID}
            >
              {/* <CreditCard
                buttonProps={{
                  isLoading: paymentLoading,
                  css: {
                    backgroundColor: "#efc441",
                    fontSize: "14px",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#efc441",
                    },
                  },
                }}
              /> */}
              <CreditCard
                buttonProps={{
                  isLoading: paymentLoading,
                  css: {
                    backgroundColor: "#efc441",
                    fontSize: "14px",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#efc441",
                    },
                  },
                }}
              >
                {paymentLoading ? <Spinner width={18} height={18}/> : "Pay Now"}
              </CreditCard>
            </PaymentForm>
          )}
        </div>
      </div>
    </>
  );
};

export default Payment;
