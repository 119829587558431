import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/style.css";
import {
  handleOpenForgot,
  handleOpenRegister,
  setGenderModule,
  setOpenLogin,
} from "../../store/slices/Auth";
import { loginAPI } from "../../Auth/api";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginAdmin } from "../../store/slices/login";
import { CustomToast } from "../common";
import Spinner from "../common/Spinner";
import SelectGender from "../common/SelectGender";
import { setWeightLocalStorage } from "../../utils/common/ingredientsUtils";
// import { setGenderModule } from "../../store/slices/productSlice";
// import Loading from "../../layout/MixLoaderOne";

const Login = () => {
  const { openLogin } = useSelector((state) => state.auth);
  const { openGender } = useSelector((state) => state.auth);
  const initialFormValues = {
    email: "",
    password: "",
  };
  const [formData, setFormData] = useState(initialFormValues);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [clickCheckbox, setClickCheckbox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const savedData = localStorage.getItem("creds");

  useEffect(() => {
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setFormData({
        email: parsedData.email,
        password: parsedData.password,
      });
    }
  }, [savedData]);

  const handleCloseLogin = () => {
    const gender = localStorage.getItem("filter");
    if (gender?.gender === "male" || gender?.gender === "female") {
      dispatch(setGenderModule(false));
    }
    dispatch(setGenderModule(true));
    dispatch(setOpenLogin(false));
    dispatch(setGenderModule(true));
  };

  const handleClick = () => {
    dispatch(handleOpenRegister());
  };
  const handleClickForgot = () => {
    dispatch(handleOpenForgot());
  };

  const validateForm = () => {
    if (!formData.email || !formData.password) {
      CustomToast("Please fill in all required fields!", "error");
      setIsLoading(false);
      return false;
    }
    // Email validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      CustomToast("Please enter a valid email address!", "error");
      setIsLoading(false);
      return false;
    }
    // Password validation: At least 8 characters, at least one uppercase letter, one lowercase letter, and one number
    // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    // if (!passwordRegex.test(formData.password)) {
    //   toast.error("Password must be at least 8 characters and include at least one uppercase letter, one lowercase letter, and one number!", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   return false;
    // }
    return true;
  };

  const handleLogin = async () => {
    setIsLoading(true);
    if (validateForm()) {
      try {
        const { data } = await loginAPI(formData.email, formData.password);
        const { status, message, user } = data;
        if (!status) throw new Error(message);
        localStorage.setItem("userId", user.id);
        localStorage.setItem("token", data?.token);

        const { weight, gender } = user;
        if(status){
        
          setWeightLocalStorage(weight , gender )
         
        }
      
       

        if (clickCheckbox) {
          localStorage.setItem(
            "creds",
            JSON.stringify({
              email: formData.email,
              password: formData.password,
              id: user.id,
              clickCheckbox,
            })
          );
        } else {
          localStorage.removeItem("creds");
        }

        dispatch(
          loginAdmin({
            id: user.id,
            name: user.name,
            email: user.email,
            image: user.image,
            notifications: user.notifications,
            isLoggedIn: true,
            phone: user.phone,
            height: user.height,
            weight: user.weight,
            gender: user.gender,
            counrty: user.country,
            createdAt: user.createdAt,
            updatedAt: user.updatedAt,
          })
        );
        handleCloseLogin(true);
        setIsLoading(false);
        CustomToast("Login Successful", "success");
      } catch (err) {
        CustomToast("Invalid Crediential", "error");
        setIsLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleClickIcon = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    if (openLogin) {
      document.body.style.overflow = "hidden";
    }else{
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };

  }, [openLogin]);

  return (
    <Fragment>
      <div
        style={{ position: "fixed" }}
        className={`overlay ${openLogin ? "active " : ""}`}
      />
      <ToastContainer />
      <div className={`login-main ${openLogin ? "active" : " "}`}>
        <>
          <div class="logo">
            <a href="#">
              <img src="/images/Logo.svg" alt="Logo" />
            </a>
            <span>
              <img
                src="/images/times_icon.svg"
                alt=""
                onClick={handleCloseLogin}
              />
            </span>
          </div>
          <div class="login-field">
            <h2>Account login</h2>
            <p>Enter your details to login</p>
            <div class="input-field">
              <input
                type="email"
                className="form-control"
                name="email"
                placeholder="Yourmail@example.com"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div class="input-field">
              <input
                className="form-control"
                name="password"
                placeholder="Enter Password"
                value={formData.password}
                onChange={handleChange}
                type={passwordVisible ? "text" : "password"}
                style={{ paddingRight: 55 }}
              />
              <button class="eye-btn" onClick={handleClickIcon}>
                {passwordVisible ? (
                  <img src="/images/eye_icon.svg" alt="" />
                ) : (
                  <img src="/images/Hide_password_icon.svg" alt="" />
                )}
              </button>
            </div>
            <div class="input-field">
              <div class="input-check" style={{visibility: "hidden"}}> 
                <input
                  type="checkbox"
                  onClick={(e) => setClickCheckbox(!clickCheckbox)}
                />
                <label>Keep me logged in</label>
              </div>
              <a href="#" class="forgot-btn" onClick={handleClickForgot}>
                Forgot password?
              </a>
            </div>
            <div class="login-btn" onClick={handleLogin}>
              <a href="#">
                {isLoading ? <Spinner width={20} height={20} /> : "Login"}
              </a>
            </div>
            <div class="log-footer">
              <p>
                Not a member yet?
                <a class="register-btn" onClick={handleClick}>
                  Register Now
                </a>
              </p>
            </div>
          </div>
        </>
      </div>

      {openGender === true ? <SelectGender /> : ""}
    </Fragment>
  );
};

export default Login;
