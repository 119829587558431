import React, { Fragment, useEffect, useId, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  addToFavoriteProducts,
  getReviewsData,
  getReviewsDataNew,
} from "../Auth/product";
import { generateInitials } from "./common";
import { setAddToFavorite } from "../store/slices/productSlice";
import {
  addtoCartProduct,
  askForIngredients,
  getIngredients,
  handleEmptyMixing,
  rejectIngredients,
} from "../store/slices/mixingIngredients/ingredientsSlice";
import { getPriceTrunc, getToken } from "../utils/common";
import { setIsLoading } from "../store/slices/common";
import { setOpenLogin } from "../store/slices/Auth";
import { FaRegStar } from "react-icons/fa";
import StarRatings from "react-star-ratings";
import { getRecomendedDose } from "../utils/common/index";
import { getProductDetails } from "../config/makeRequests/cartRequests";
import HomeLoader from "../layout/HomeLoader";

const ProductDetail = () => {
  const { id } = useParams();
  const productData = useSelector((state) => state.product.productData);
  const addToFavorite = useSelector((state) => state.product.addToFavorite);
  const currentUser = useSelector((state) => state.login);
  const [showFullReview, setShowFullReview] = useState(false);
  const [nutrientsList, setNutrientsList] = useState([]);

  const recommendedProduct = useSelector(
    (state) => state.product.recommendedProduct
  );
  const foundProductData = useSelector(
    (state) => state.product.foundProductData
  );
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [activeTab, setActiveTab] = useState("Description");
  const [reviews, setReviews] = useState("");
  const [imageClick, setImageClick] = useState(0);
  const [displayedReviews, setDisplayedReviews] = useState(6);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getData = async () => {
    try {
      setIsLoading(true);
      const storedData = localStorage.getItem("gender_weight");
      const { gender, weight } = JSON.parse(storedData) ?? {
        gender: "",
        weight: "",
      };
      let res = await getProductDetails(id, gender, weight);
      if (res?.status) {
        setIsLoading(false);
        setSelectedProduct(res?.productDetail);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  // useEffect(() => {
  //   const foundProductMatch = foundProductData?.products?.find(
  //     (product) => product.id === id
  //   );
  //   console.log("foundProductMatch___________________" , foundProductMatch)
  //   if (foundProductMatch) {
  //     setSelectedProduct(foundProductMatch);
  //   } else {
  //     const recommendedProductMatch = recommendedProduct?.find(
  //       (product) => product.id === id
  //     );
  //     console.log("recommendedProductMatch" , recommendedProductMatch)
  //     if (recommendedProductMatch) {
  //       setSelectedProduct(recommendedProductMatch);
  //     } else {
  //       const productDataMatch = productData?.products?.find(
  //         (product) => product?.id === id
  //       );
  //       console.log("productDataMatch" , productDataMatch)
  //       if (productDataMatch) {
  //         setSelectedProduct(productDataMatch);
  //       } else {
  //         setSelectedProduct(null);
  //       }
  //     }
  //   }
  // }, [id, foundProductData, productData, recommendedProduct]);

  useEffect(() => {
    if (currentUser?.id) {
      const fetchData = async () => {
        const response = await getReviewsData(
          selectedProduct?.id,
          selectedProduct?.type
        );
        const { data } = response;
        setReviews(data?.reviews);
      };
      if (selectedProduct) {
        fetchData();
      }
    } else {
      const fetchData = async () => {
        const response = await getReviewsDataNew(
          selectedProduct?.id,
          selectedProduct?.type
        );
        const { data } = response;
        setReviews(data?.reviews);
      };
      if (selectedProduct) {
        fetchData();
      }
    }
  }, [selectedProduct]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleFavoriteData = () => {
    if (addToFavorite) {
      addToFavoriteProducts(currentUser?.id, id, "add");
    } else {
      addToFavoriteProducts(currentUser?.id, id, "remove");
    }
  };

  // const handleAddToFavorite = async () => {
  //   console.log("added");
  //   dispatch(setAddToFavorite(!addToFavorite));
  //   handleFavoriteData();
  // };

  const handleOpenLoginPopUp = (event) => {
    dispatch(setOpenLogin(true));
  };

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 575,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleLoadMore = () => {
    setDisplayedReviews(displayedReviews + 10);
  };

  const average =
    selectedProduct?.review?.star1 +
    selectedProduct?.review?.star2 +
    selectedProduct?.review?.star3 +
    selectedProduct?.review?.star4 +
    selectedProduct?.review?.star5 / 5;

  // add product into cart
  // let token = getToken();
  // const handleCart = (data) => {
  //   if (token) {
  //     dispatch(askForIngredients(true));
  //     dispatch(getIngredients(data?.id));
  //     dispatch(addtoCartProduct(data));
  //     dispatch(handleEmptyMixing());
  //   } else {
  //     dispatch(setOpenLogin(true));
  //   }
  // };
  let token = getToken();
  const handleCart = (data) => {
    if (token) {
      if (data?.productType === "Product") {
        dispatch(rejectIngredients(true));
        dispatch(addtoCartProduct(data));
        dispatch(handleEmptyMixing());
      } else {
        dispatch(askForIngredients(true));
        dispatch(getIngredients(data?.id));
        dispatch(addtoCartProduct(data));
        dispatch(handleEmptyMixing());
      }
    } else {
      dispatch(setOpenLogin(true));
    }
  };
  const handleImageClick = (id) => {
    setImageClick(id);
  };

  const truncateDescriptionWithMaxLength = (description, maxLength) => {
    if (description?.length > maxLength) {
      return `${description?.substring(0, maxLength)}...`;
    } else {
      return description;
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const MAX_DESCRIPTION_LENGTH = 600;

  const toggleDescription = () => {
    if (
      selectedProduct?.product?.description &&
      selectedProduct?.product?.description.length > MAX_DESCRIPTION_LENGTH
    ) {
      setShowFullDescription(!showFullDescription);
    }
  };

  const handleAddToFavorite = async () => {
    try {
      const userId = currentUser.id;
      const action = "add";
      await addToFavoriteProducts(userId, id, action);
      setSelectedProduct((prevProduct) => ({
        ...prevProduct,
        isFavorite: true,
      }));
    } catch (error) {
      console.error("Error adding to favorites:", error);
      dispatch(setIsLoading(false));
    }
  };

  const handleRemoveFromFavorite = async () => {
    try {
      const userId = currentUser.id;
      const action = "remove";
      await addToFavoriteProducts(userId, id, action);
      setSelectedProduct((prevProduct) => ({
        ...prevProduct,
        isFavorite: false,
      }));
    } catch (error) {
      console.error("Error removing from favorites:", error);
      dispatch(setIsLoading(false));
    }
  };

  const factsTabCondition = () => {
    // selectedProduct?.nutrients ,  selectedProduct?.doctor?.supplimentFactServingSize
    let nutrients = [];
    const value = selectedProduct?.nutrients;
    for (let i = 0; i < value.length; i++) {
      const item = value[i];
      if (
        item?.title &&
        item?.unit !== undefined &&
        item?.unit !== null &&
        item?.unit.trim() !== "" &&
        item?.amount !== "" &&
        item?.amount !== undefined &&
        item?.amount !== null
      ) {
        nutrients.push(item);
      }
    }
    setNutrientsList(nutrients);
  };

  useEffect(() => {
    if (selectedProduct) {
      factsTabCondition();
    }
  }, [selectedProduct]);

  const getWidthPercentage = (starCount) => {
    const totalReviews = Number(reviews?.length);
    const count = Number(starCount);
    if (!isNaN(totalReviews) && !isNaN(count) && totalReviews > 0) {
      const rating = (count / totalReviews) * 100;
      return rating;
    } else {
      return 0;
    }
  };

  console.log("v", selectedProduct);

  return (
    <Fragment>
      {isLoading ? (
        <div
          style={{
            height: "60vh",
            display: "flex",
            alignItems: "center",
            padding: "85px 0 0",
          }}>
          <HomeLoader />
        </div>
      ) : (
        <div className="for-overlay">
          <section id="product-details-main">
            <div className="container">
              <div className="back-btn">
                <Link onClick={handleGoBack}>
                  <img src="/images/arrow_left.svg" alt="" /> Go back
                </Link>
              </div>
              <div className="product-bio-main">
                <div className="product-profile">
                  <span>
                    <img
                      id="mainImage"
                      src={
                        selectedProduct?.product?.images?.[imageClick] ||
                        "/images/placeholder_image.png"
                      }
                      alt=""
                    />
                    {token && (
                      <img
                        style={{
                          width: "30px",
                          height: "30px",
                          cursor: "pointer",
                          position: "absolute",
                          top: "15px",
                          right: "15px",
                        }}
                        onClick={() =>
                          selectedProduct?.isFavorite
                            ? handleRemoveFromFavorite()
                            : handleAddToFavorite()
                        }
                        src={
                          selectedProduct?.isFavorite
                            ? "/images/unlike_icon.svg"
                            : "/images/like_icon.svg"
                        }
                        alt=""
                      />
                    )}
                  </span>
                  {selectedProduct?.product?.images?.[0] ? (
                    <ul className="product-imgages">
                      {selectedProduct?.product?.images?.map((image, idx) => (
                        <li
                          className="active"
                          onClick={() => handleImageClick(idx)}>
                          <img src={image} alt="" />
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <ul className="product-imgages">
                      <li className="active">
                        <img src="/images/placeholder_image.png" alt="" />
                      </li>
                    </ul>
                  )}
                </div>
                <div className="product-bio">
                  <h2>{selectedProduct?.product?.title}</h2>
                  <span className="rating">
                    <img src="/images/star_icon.svg" alt="" />
                    {selectedProduct?.review?.avg || 0} ({reviews?.length || 0}{" "}
                    reviews)
                    {/* {selectedProduct?.sold?.total} Sold */}
                  </span>
                  <span>
                    {selectedProduct?.type === "product"
                      ? "Price for 30 servings (Days)"
                      : `Price for 1  ${selectedProduct?.quantity?.type} * 30 servings (Days)`}
                  </span>
                  <span className="price">
                    $
                    {selectedProduct?.type == "ingredient"
                      ? getPriceTrunc(
                          selectedProduct?.ingredientPrice?.salePricePerMonth
                        )
                      : getPriceTrunc(selectedProduct?.product?.salePrice)}
                  </span>
                  <p>
                    {selectedProduct?.product?.description &&
                      (showFullDescription
                        ? selectedProduct?.product?.description
                        : truncateDescriptionWithMaxLength(
                            selectedProduct?.product?.description,
                            MAX_DESCRIPTION_LENGTH
                          ))}
                    {selectedProduct?.product?.description &&
                      selectedProduct?.product?.description.length >
                        MAX_DESCRIPTION_LENGTH && (
                        <span>
                          <button
                            className="read-more-button"
                            onClick={toggleDescription}>
                            {showFullDescription ? "Read Less" : "Read More"}
                          </button>
                        </span>
                      )}
                  </p>
                  <p className="category">
                    <label>Category: </label>
                    {selectedProduct?.product?.category?.map((item, index) => (
                      <React.Fragment key={index}>
                        <span>{item?.categoryName}</span>
                        {index !==
                          selectedProduct?.product?.category?.length - 1 && (
                          <span>,</span>
                        )}
                      </React.Fragment>
                    ))}
                  </p>
                  {/* <p>
                  <label>Availability:</label>{" "}
                  {selectedProduct?.stock?.stockAmount > 0
                    ? "In-Stock"
                    : "Out of Stock"}
                </p> */}
                  <span className="quantity-label"></span>
                  <div className="quantity">
                    <button
                      onClick={() => handleCart(selectedProduct)}
                      className="cart-btn">
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
              <div className="product-label">
                <ul class="product-discription">
                  <li
                    className={activeTab === "Description" ? " active-bio" : ""}
                    onClick={() => handleTabClick("Description")}>
                    Description
                  </li>
                  {selectedProduct?.type == "ingredient" &&
                  selectedProduct?.nutrients?.length === 0 &&
                  selectedProduct?.doctor?.supplimentFactServingSize == "" ? (
                    ""
                  ) : (
                    <>
                      {nutrientsList?.length > 0 ||
                      selectedProduct?.doctor?.supplimentFactServingSize !=
                        "" ? (
                        <li
                          className={
                            activeTab === "Supplement facts" ? "active-bio" : ""
                          }
                          onClick={() => handleTabClick("Supplement facts")}>
                          Supplement facts
                        </li>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  <li
                    className={
                      activeTab === "Ratings & Reviews" ? "active-bio" : ""
                    }
                    onClick={() => handleTabClick("Ratings & Reviews")}>
                    Ratings
                  </li>
                </ul>
                <div className="discription">
                  {activeTab === "Description" ? <h3>Specifications</h3> : ""}
                  <ul
                    className={
                      activeTab === "Description" ? " active-bio" : ""
                    }>
                    <div className="display-discription">
                      {selectedProduct?.type === "product" ? (
                        ""
                      ) : (
                        <li>
                          <label>Recommended Dosage</label>
                          <p>
                            {
                              getRecomendedDose(selectedProduct?.quantity)
                                ?.recommendedDose
                            }{" "}
                            {selectedProduct?.quantity?.type}
                          </p>
                        </li>
                      )}
                      <li>
                        <label>Daily recommendation</label>
                        <p>{selectedProduct?.product?.dailyReq}</p>
                      </li>
                      {selectedProduct?.productType !== "Product" && (
                        <li>
                          <label>Solubility</label>
                          <p>{selectedProduct?.product?.solubility}</p>
                        </li>
                      )}

                      <li>
                        <label>Taste</label>
                        <p>{selectedProduct?.product?.taste}</p>
                      </li>
                      <li>
                        <label>Direction consumption</label>
                        <p>{selectedProduct?.doctor?.directionConsumption}</p>
                      </li>
                      <li>
                        <label>Color</label>
                        <p>{selectedProduct?.product?.color}</p>
                      </li>
                      <li>
                        <label>Recommended for children</label>
                        <p>
                          {selectedProduct?.product?.recommendedForChildren ===
                          false
                            ? "No"
                            : "Yes"}
                        </p>
                      </li>
                      <li>
                        <label>Type</label>
                        <p>{selectedProduct?.product?.type}</p>
                      </li>
                      <li>
                        <label>Expiry Date</label>
                        <p>
                          {moment(selectedProduct?.stock?.expiryDate).format(
                            "DD MMM, YYYY"
                          )}
                        </p>
                      </li>
                      <li>
                        <label>Storage</label>
                        <p>{selectedProduct?.stock?.storage}</p>
                      </li>
                      <li>
                        <label>Side Effects</label>
                        <p>{selectedProduct?.doctor?.sideAffects}</p>
                      </li>
                      <li>
                        <label>Doctor Opinion</label>
                        <p>{selectedProduct?.doctor?.drOpinion}</p>
                      </li>
                    </div>
                  </ul>
                </div>
                {/* {
                 selectedProduct?.doctor?.supplimentFactServingSize && selectedProduct?.nutrients?.length > 0 
              } */}
                <div className="discription">
                  {activeTab === "Supplement facts" ? (
                    <h3> Supplement facts</h3>
                  ) : (
                    ""
                  )}
                  <ul
                    className={
                      activeTab === "Supplement facts" ? " active-bio" : ""
                    }>
                    <div className="display-discription">
                      {selectedProduct?.doctor?.supplimentFactServingSize && (
                        <li>
                          <label>Serving Size</label>
                          <p>
                            {selectedProduct?.doctor?.supplimentFactServingSize}
                          </p>
                        </li>
                      )}

                      {nutrientsList?.map((item) => {
                        return (
                          <>
                            {item?.title &&
                            item?.unit !== undefined &&
                            item?.unit !== null &&
                            item?.unit.trim() !== "" &&
                            item?.amount !== "" &&
                            item?.amount !== undefined &&
                            item?.amount !== null ? (
                              <li>
                                <label>{item?.title}</label>
                                <p>
                                  {item?.amount}
                                  {item?.unit}
                                </p>
                              </li>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })}
                    </div>
                  </ul>
                </div>
                <div className="discription">
                  <ul
                    className={
                      activeTab === "Ratings & Reviews" ? " active-bio" : ""
                    }>
                    <div class="rating-review-main">
                      <div class="rating-review">
                        <div className="product-rating">
                          <h1 style={{ textAlign: "center", margin: "0px" }}>
                            {selectedProduct?.review?.avg}
                          </h1>
                          <StarRatings
                            rating={selectedProduct?.review?.avg || 0}
                            starRatedColor="#efc441"
                            starEmptyColor="gray"
                            numberOfStars={5}
                            starDimension="20px"
                            starSpacing="2px"
                          />
                          <span style={{ textAlign: "center" }}>
                            <h4>{reviews?.length} Ratings</h4>
                          </span>
                        </div>
                        <div className="percent-rating">
                          <div>
                            <label>5</label>{" "}
                            <span className="percent-line">
                              <div
                                className="percent-line-after"
                                style={{
                                  width: `${getWidthPercentage(
                                    selectedProduct?.review?.star5
                                  )}%`,
                                }}></div>
                            </span>
                          </div>
                          <div>
                            <label>4</label>{" "}
                            <span className="percent-line">
                              <div
                                className="percent-line-after"
                                style={{
                                  width: `${getWidthPercentage(
                                    selectedProduct?.review?.star4
                                  )}%`,
                                }}></div>
                            </span>
                          </div>
                          <div>
                            <label>3</label>{" "}
                            <span className="percent-line">
                              <div
                                className="percent-line-after"
                                style={{
                                  width: `${getWidthPercentage(
                                    selectedProduct?.review?.star3
                                  )}%`,
                                }}></div>
                            </span>
                          </div>
                          <div>
                            <label>2</label>{" "}
                            <span className="percent-line">
                              <div
                                className="percent-line-after"
                                style={{
                                  width: `${getWidthPercentage(
                                    selectedProduct?.review?.star2
                                  )}%`,
                                }}></div>
                            </span>
                          </div>
                          <div>
                            <label>1</label>{" "}
                            <span className="percent-line">
                              <div
                                className="percent-line-after"
                                style={{
                                  width: `${getWidthPercentage(
                                    selectedProduct?.review?.star1
                                  )}%`,
                                }}></div>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="rating-review-main all-reviews">
                        {reviews &&
                          reviews
                            .slice(0, displayedReviews)
                            .map((review, index) => (
                              <div key={index} className="customer-review">
                                <span>
                                  {Array.from(
                                    { length: review?.rating },
                                    (_, index) => (
                                      <img
                                        key={index}
                                        src="/images/star_icon.svg"
                                        alt=""
                                      />
                                    )
                                  )}
                                  ({review?.rating})
                                </span>
                                <div>
                                  <p>
                                    {/* Display truncated review with ellipsis if longer than 20 words */}
                                    {review?.review &&
                                      (showFullReview ? (
                                        review.review // If showFullReview is true, display full review
                                      ) : (
                                        <>
                                          {review.review
                                            .split(/\s+/)
                                            .slice(0, 20)
                                            .join(" ")}
                                          {review.review.split(/\s+/).length >
                                            20 && "..."}
                                        </>
                                      ))}
                                    {review?.review &&
                                      review.review.split(/\s+/).length >
                                        20 && (
                                        <button
                                          className="read-more-button"
                                          onClick={() =>
                                            setShowFullReview(!showFullReview)
                                          }>
                                          {showFullReview
                                            ? "Read Less"
                                            : "Read More"}
                                        </button>
                                      )}
                                  </p>
                                </div>
                                <div class="profile">
                                  <div class="profile-img">
                                    <div className="user-profile-img">
                                      {generateInitials(review?.user?.name)}
                                    </div>
                                  </div>
                                  <div class="name">
                                    <span>{review?.user?.name}</span>
                                    {/* <p>
                                      <span>Posted on:</span>{" "}
                                      {moment(review?.createdAt).format(
                                        "DD MMM, YYYY"
                                      )}
                                    </p> */}
                                  </div>
                                </div>
                              </div>
                            ))}

                        {reviews && reviews.length > displayedReviews && (
                          <div
                            className="load-more-btn"
                            onClick={handleLoadMore}>
                            <button>Load More</button>
                          </div>
                        )}
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </Fragment>
  );
};
export default ProductDetail;
