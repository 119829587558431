import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { removeFromMix } from "../../action/mixActions";
import { getPriceTrunc, getToken } from "../../utils/common";
import {
  addtoCartProduct,
  askForIngredients,
  getIngredients,
  handleEmptyMixing,
  handleMixScratch,
  handleScratchProduct,
  mixProductNameHandle,
} from "../../store/slices/mixingIngredients/ingredientsSlice";
import { setOpenLogin } from "../../store/slices/Auth";
import "../../styles/style.css";

Modal.setAppElement("#root");

const MixCartModal = ({ setShowCartMix, isOpen, onClose }) => {
  const mixItems = useSelector((state) => state.mix.mixItems);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const dispatch = useDispatch();

  const [namePopup, setNamePopup] = useState(false);
  const [mixProductName, setMixProductName] = useState("");

  const handleRemoveFromMix = (item) => {
    dispatch(removeFromMix(item));
  };

  const totalCost = mixItems.reduce((acc, item) => acc + item.cost, 0);

  function transformArrayToObject(array) {
    let ingredients = {
      gender: "",
      items: [],
      weightRange: "",
      totalCost: totalCost,
    };

    array.forEach((obj) => {
      if (!ingredients.gender) {
        ingredients.gender = obj?.gender?.gender;
        ingredients.weightRange = obj?.gender?.weight || "";
      }

      ingredients.items.push({
        id: obj.id,
        quantity: obj?.recommendedDose,
        type: obj.unit,
        price: obj.price,
      });
    });

    return ingredients;
  }

  const handleCart = () => {
    let token = getToken();
    if (token) {
      setNamePopup(true);
    } else {
      dispatch(setOpenLogin(true));
    }
    onClose();
  };

  const submitHandle = () => {
    if (!mixProductName) return;
    const data = transformArrayToObject(mixItems);
    setNamePopup(false);

    dispatch(askForIngredients(true));
    dispatch(addtoCartProduct(data));
    dispatch(mixProductNameHandle(mixProductName));
    dispatch(handleScratchProduct(true));
    dispatch(handleEmptyMixing());
    onClose();
  };

  const cancelHandle = () => {
    setMixProductName("");
    setShowCartMix(true);
    setNamePopup(false);
    dispatch(mixProductNameHandle(""));
  };

  const formatPrice = (value) => {
    const numericValue = parseFloat(value);

    if (numericValue >= 0.01) {
      return numericValue.toFixed(2);
    } else {
      const roundedValue = numericValue.toFixed(5);
      const formattedValue = Math.max(parseFloat(roundedValue), 0.001)
        .toFixed(4)
        .replace(/\.?0+$/, "");

      return formattedValue === "0.0010" ? "0.00" : formattedValue;
    }
  };

  useEffect(() => {
    if (mixItems.length === 0 && isOpen) {
      onClose();
    }
  }, [mixItems, isOpen, onClose]);

  return (
    <>
      {namePopup ? (
        <div className="payment-form-overlay">
          <div class="mixname-main">
            <div class="head">
              <h5>Name your mix</h5>
            </div>
            <div class="input-field">
              <div class="input-group">
                <label for="">Name</label>
                <input
                  placeholder="Enter Name"
                  type="text"
                  onChange={(e) => setMixProductName(e.target.value)}
                  value={mixProductName}
                />
              </div>
              <div class="btn-group">
                <button onClick={cancelHandle}>Cancel</button>
                <button
                  class="submit-btn"
                  disabled={!mixProductName}
                  onClick={submitHandle}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Modal
          isOpen={isOpen}
          onRequestClose={onClose}
          contentLabel="Error Modal"
          className="modal-content"
          overlayClassName="modal-overlay_one">
          <div className="modal-body">
            <div className="cart-item-head">
              <h2>Your mixing ingredients ({mixItems.length})</h2>
            </div>
            <table
              border="0"
              cellPadding="0"
              style={{ width: "100%", borderBottom: "1px solid #e0e0e0" }}>
              <thead>
                <tr style={{ borderBottom: "1px solid #EDEDED" }}>
                  <th
                    className="heading heading-cart"
                    style={{ padding: "27px 0 14px" }}>
                    Ingredients
                  </th>
                  <th className="heading heading-cart">Amount</th>
                  <th
                    style={{ textAlign: "center" }}
                    className="heading heading-cart">
                    Action
                  </th>
                </tr>
              </thead>
            </table>
            <div className="cart_item_table">
              <table
                border="0"
                cellPadding="0"
                style={{ width: "100%", padding: "0 0 38px" }}>
                <tbody>
                  <tr>
                    <td>
                      <table
                        border="0"
                        cellPadding="0"
                        style={{
                          width: "100%",
                          borderBottom: "1px solid #EDEDED",
                        }}>
                        {mixItems?.map((item) => (
                          <tbody>
                            <tr style={{ borderBottom: "1px solid #EDEDED" }}>
                              <td className="table-col">
                                {item.title}{" "}
                                <span>
                                  ({item.recommendedDose}
                                  {item.unit} for 30 Servings)
                                </span>
                              </td>
                              <td
                                className="price"
                                style={{ padding: "0 15px" }}>
                                ${getPriceTrunc(item.cost)} {/* // * sachin */}
                              </td>
                              <td
                                className="add-btn py"
                                style={{
                                  textAlign: "center",
                                  padding: "18px 40px 18px 0",
                                }}>
                                <button>
                                  <img
                                    onClick={() => handleRemoveFromMix(item)}
                                    style={{ cursor: "pointer" }}
                                    src="images/delete_icon_grey.svg"
                                    alt="delete_cart_mix"
                                  />
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="recommended_dose total-value">
              <h5>Total:</h5>
              <h5 style={{ color: "#828282" }}>${getPriceTrunc(totalCost)}</h5>
            </div>
            <div
              className="cart-react-btn"
              style={{
                width: "95%",
                marginTop: "50px",
                position: "absolute",
                bottom: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <button className="modal-close-btn" onClick={onClose}>
                Cancel
              </button>
              <button className="modal-submit-btn" onClick={() => handleCart()}>
                Add to Cart
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default MixCartModal;
