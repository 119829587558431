import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setFrom,
  setIsLoading,
  setOpenFilter,
  setStatus,
  setTo,
} from "../store/slices/common";
import moment from "moment";

const OrdersFilter = () => {
  const [deliveredFilters, setDeliveredFilters] = useState(false);
  const [cancelledFilters, setCancelledFilters] = useState(false);
  const [filter, setFilter] = useState("");
  const [dateRange, setDateRange] = useState({
    fromDate: "",
    toDate: "",
  });

  const [inputType, setInputType] = useState("text");
  const [inputTypeTo, setInputTypeTo] = useState("text");

  const handleFocus = () => {
    setInputType("date");
  };
  const handleFocusToDate = () => {
    setInputTypeTo("date");
  };

  const openFilter = useSelector((state) => state.common);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setOpenFilter(false));
  };

  const handleStatusChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      if (value === "delivered") {
        setDeliveredFilters(true);
        setCancelledFilters(false);
        setFilter(value);
      } else {
        setCancelledFilters(true);
        setDeliveredFilters(false);
        setFilter(value);
      }
    }
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    setDateRange((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleApply = async () => {
    const from = dateRange?.fromDate
      ? moment(dateRange.fromDate).format("DD/MM/YYYY")
      : "";
    const to = dateRange?.toDate
      ? moment(dateRange.toDate).format("DD/MM/YYYY")
      : "";
    dispatch(setFrom(from));
    dispatch(setTo(to));
    dispatch(setStatus(filter));
    dispatch(setOpenFilter(false));
  };
  const resetFilter = async () => {
    dispatch(setFrom(""));
    dispatch(setTo(""));
    dispatch(setStatus(""));
    dispatch(setOpenFilter(false));
  };

  return (
    <Fragment>
      <div
        style={{ position: "fixed" }}
        className={`overlay ${openFilter ? "active " : ""}`}
      />
      <div className="order-filter-main">
        <div className="filter-head">
          <h2>Filter by</h2>
          <button className="close-btn">
            <img src="images/times_icon.svg" alt="" onClick={handleClose} />
          </button>
        </div>
        <div className="status">
          <h3>By status</h3>
          <div className="input-group">
            <div className="input-field">
              <input
                type="checkbox"
                value="delivered"
                checked={deliveredFilters}
                onChange={handleStatusChange}
              />
              <label>Delivered</label>
            </div>
            <div className="input-field">
              <input
                type="checkbox"
                value="cancelled"
                checked={cancelledFilters}
                onChange={handleStatusChange}
              />
              <label>Cancelled</label>
            </div>
          </div>
        </div>
        <div className="date-range">
          <h3>Date range</h3>
          <div className="input-date">
            <label>From</label>
            <input
              type={inputType}
              placeholder="MM/DD/YYYY"
              onFocus={handleFocus}
              name="fromDate"
              value={dateRange.fromDate}
              onChange={handleDateChange}
            />
          </div>
          <div className="input-date">
            <label>To</label>
            <input
              type={inputTypeTo}
              placeholder="MM/DD/YYYY"
              onFocus={handleFocusToDate}
              name="toDate"
              value={dateRange.toDate}
              onChange={handleDateChange}
            />
          </div>
        </div>
        <div className="filter-btn">
        <button onClick={resetFilter}>Reset</button>
          <button className="apply-btn" onClick={handleApply}>
            Apply
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default OrdersFilter;
