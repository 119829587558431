import React, { useEffect, useState } from "react";
import ProductComponent from "../../component/common/product/ProductComponent";
import { Link, useNavigate } from "react-router-dom";
import { getToken } from "../../utils/common";
import { setOpenLogin } from "../../store/slices/Auth";
import {
  addtoCartProduct,
  askForIngredients,
  getIngredients,
  handleEmptyMixing,
  ingredientsVisibility,
  rejectIngredients,
} from "../../store/slices/mixingIngredients/ingredientsSlice";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading } from "../../store/slices/common";
import {
  addToFavoriteProducts,
  getRecommendedProducts,
} from "../../Auth/product";
import {
  setBanner,
  setRecommendedProduct,
} from "../../store/slices/productSlice";
import { selectCosts } from "../../store/slices/ingredentCostSlice";
import HomeLoader from "../../layout/HomeLoader";

const HomePage = () => {
  const isLoading = useSelector((state) => state.common.isLoading);
  const currentUser = useSelector((state) => state.login);
  const { genderModule } = useSelector((state) => state.product);
  const recommendedProduct = useSelector(
    (state) => state.product.recommendedProduct
  );
  const banner = useSelector((state) => state.product.banner);
  const [recommendedDose, setRecommendedDose] = useState([]);
  const dispatch = useDispatch();
  const cost = useSelector(selectCosts);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(ingredientsVisibility(false));
    dispatch(handleEmptyMixing());
  }, []);

  const filterData = localStorage.getItem("gender_weight");

  const fetchData = async () => {
    try {
      if (!filterData) {
        dispatch(setIsLoading(true));
      } else {
        dispatch(setIsLoading(true));

        const parsedFilterData = JSON.parse(filterData);

        let { weight, gender } = parsedFilterData ?? { weight: "", gender: "" };

        weight = weight.replaceAll("+", "%2B");

        const response = await getRecommendedProducts(
          currentUser.id,
          gender,
          weight
        );
        const { data } = response;

        localStorage.setItem("banner", JSON.stringify(data.banners));
        dispatch(setRecommendedProduct(data?.products));
        dispatch(setBanner(data?.banners));
      }
    } catch (error) {
      console.log(error);
      dispatch(setIsLoading(false));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
  useEffect(() => {
    fetchData();
  }, [filterData]);

  useEffect(() => {
    if (!recommendedProduct) {
      return;
    }
    if (currentUser.gender === "male" && currentUser.weight <= 140) {
      const maleIngredients = recommendedProduct?.filter(
        (ingredient) =>
          ingredient &&
          ingredient?.type === "ingredient" &&
          ingredient?.quantity &&
          ingredient?.quantity?.male &&
          ingredient?.quantity?.male[0] &&
          ingredient?.quantity?.male[0]?.recommendedDose
      );

      const recommendedDoses = maleIngredients.map(
        (ingredient) => ingredient.quantity.male[0].recommendedDose
      );
      setRecommendedDose(recommendedDoses);
    } else if (
      currentUser.gender === "male" &&
      currentUser.weight >= 141 &&
      currentUser.weight <= 200
    ) {
      const maleIngredients = recommendedProduct.filter(
        (ingredient) =>
          ingredient &&
          ingredient.type === "ingredient" &&
          ingredient.quantity &&
          ingredient.quantity.male &&
          ingredient.quantity.male[1] &&
          ingredient.quantity.male[1].recommendedDose
      );

      const recommendedDoses = maleIngredients.map(
        (ingredient) => ingredient.quantity.male[1].recommendedDose
      );

      setRecommendedDose(recommendedDoses);
    } else if (currentUser.gender === "male" && currentUser.weight >= 201) {
      const maleIngredients = recommendedProduct.filter(
        (ingredient) =>
          ingredient &&
          ingredient.type === "ingredient" &&
          ingredient.quantity &&
          ingredient.quantity.male &&
          ingredient.quantity.male[2] &&
          ingredient.quantity.male[2].recommendedDose
      );

      const recommendedDoses = maleIngredients.map(
        (ingredient) => ingredient.quantity.male[2].recommendedDose
      );

      setRecommendedDose(recommendedDoses);
    } else if (
      currentUser.gender === "female" &&
      currentUser.weight >= 50 &&
      currentUser.weight <= 100
    ) {
      const femaleIngredients = recommendedProduct.filter(
        (ingredient) =>
          ingredient &&
          ingredient.type === "ingredient" &&
          ingredient.quantity &&
          ingredient.quantity.female &&
          ingredient.quantity.female[0] &&
          ingredient.quantity.female[0].recommendedDose
      );

      const recommendedDoses = femaleIngredients.map(
        (ingredient) => ingredient.quantity.female[0].recommendedDose
      );

      setRecommendedDose(recommendedDoses);
    } else if (
      currentUser.gender === "female" &&
      currentUser.weight >= 101 &&
      currentUser.weight <= 150
    ) {
      const femaleIngredients = recommendedProduct.filter(
        (ingredient) =>
          ingredient &&
          ingredient.type === "ingredient" &&
          ingredient.quantity &&
          ingredient.quantity.female &&
          ingredient.quantity.female[1] &&
          ingredient.quantity.female[1].recommendedDose
      );

      const recommendedDoses = femaleIngredients.map(
        (ingredient) => ingredient.quantity.female[1].recommendedDose
      );
      setRecommendedDose(recommendedDoses);
    } else if (currentUser.gender === "female" && currentUser.weight >= 151) {
      const femaleIngredients = recommendedProduct.filter(
        (ingredient) =>
          ingredient &&
          ingredient.type === "ingredient" &&
          ingredient.quantity &&
          ingredient.quantity.female &&
          ingredient.quantity.female[2] &&
          ingredient.quantity.female[2].recommendedDose
      );

      const recommendedDoses = femaleIngredients.map(
        (ingredient) => ingredient.quantity.female[2].recommendedDose
      );

      setRecommendedDose(recommendedDoses);
    }
  }, [currentUser, recommendedProduct]);

  const handleAddToFavorite = async (productId) => {
    try {
      const userId = currentUser.id;
      const action = "add";
      await addToFavoriteProducts(userId, productId, action);
      fetchData();
    } catch (error) {
      console.error("Error adding to favorites:", error);
      dispatch(setIsLoading(false));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const handleRemoveFromFavorite = async (productId) => {
    try {
      const userId = currentUser.id;
      const action = "remove";
      await addToFavoriteProducts(userId, productId, action);
      fetchData();
    } catch (error) {
      console.error("Error removing from favorites:", error);
      dispatch(setIsLoading(false));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const handleCart = (e, data) => {
    let token = getToken();
    if (token) {
      e.stopPropagation();
      if (data?.productType === "Product") {
        dispatch(rejectIngredients(true));
        dispatch(addtoCartProduct(data));
        dispatch(handleEmptyMixing());
      } else {
        dispatch(askForIngredients(true));
        dispatch(getIngredients(data?.id));
        dispatch(addtoCartProduct(data));
        dispatch(handleEmptyMixing());
      }
    } else {
      dispatch(setOpenLogin(true));
    }
  };

  const arrowHandle = () => {
    const element = document.getElementById("products");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <section id="new-banner-main">
        <div class="container">
          <span class="free-shipping-btn">FREE Shipping on orders $100+</span>
          <div class="banner-circle">
            <div class="circle">
              <h2>Welcome To</h2>
              <a>
                <img src="images/new_logo.svg" alt="" />
              </a>
              <button onClick={arrowHandle}>
                <a>
                  {" "}
                  <img src="images/angle_right_icon.svg" alt="" />
                </a>
              </button>
            </div>
          </div>
        </div>
      </section>

      <section id="journy-main">
        <div class="container">
          <div class="journy-head">
            <h2>Choose a Journey Below and Enhance Your Health Today!</h2>
          </div>
          <div class="journy-boxes">
            <Link to="/mix?question">
              <div class="box">
                <h3>Looking for personalized supplements?</h3>

                <div class="box-redirect">
                  <a href="">
                    <img src="images/angle_right_icon.svg" alt="" />
                  </a>
                  <p>
                    Answer a few questions and get blends tailored to your need
                  </p>
                </div>

                <span>
                  <img src="images/suppliment_img.png" alt="" />
                </span>
              </div>
            </Link>
            <Link to="/mix?mixIngredients">
              <div class="box box-reverse">
                <h3>Tired of swallowing toxic capsules and pills?</h3>
                <div class="box-redirect">
                  <p>
                    Add as many ingredients as you want and create your own
                    blend
                  </p>
                  <a href="">
                    <img src="images/angle_right_icon.svg" alt="" />
                  </a>
                </div>
                <span>
                  <img src="images/user_toxic_img.svg" alt="" />
                </span>
              </div>
            </Link>
          </div>
        </div>
      </section>

      <section id="top-sell-grab">
        <div class="container">
          <h2>
            Looking For Something to Grab and Go?
            <span>Shop Some of Our Best Selling Blends</span>
          </h2>
        </div>
      </section>

      <section id="product-main" class="product-sell-main">
        <div class="container">
          <div class="row" id="products">
            <div class="shop-header-col">
              <div class="shop-header">
                {/* <button class="scroll-bottom-btn"><img src="images/angle_down_new.svg" alt="" /></button> */}
                <Link to={"/product"}>
                  {" "}
                  See all <img src="images/angle_down.svg" />{" "}
                </Link>
              </div>
            </div>
            <div class="product-hide">
              {isLoading ? (
                <div>
                  <HomeLoader />
                </div>
              ) : (
                recommendedProduct &&
                recommendedProduct?.map((data, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <ProductComponent
                        data={data}
                        handleRemoveFromFavorite={handleRemoveFromFavorite}
                        handleAddToFavorite={handleAddToFavorite}
                        handleCart={handleCart}
                      />
                    </React.Fragment>
                  );
                })
              )}
            </div>
            {/* <div class="product-main-slider">
                        <div class="product-slider">
                            <div>
                                <div class="product-col">
                                    <div class="product">
                                        <button class="like-btn"><img src="images/like_icon.svg" alt=""></button>
                                        <span class="product-img"><img src="images/product_img.png" alt="">
                                        </span>
                                        <span>PROTEIN</span>
                                        <h3>Intelligent Wool Bottle</h3>
                                        <span class="rating"><img src="images/star_icon.svg" alt="">4.9 (12 reviews)</span>
                                        <div class="product-detail">
                                            <span>$11.00</span>
                                            <button><img src="images/cart_icon.svg" alt="">Add to cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="product-col">
                                    <div class="product">
                                        <button class="like-btn"><img src="images/like_icon.svg" alt=""></button>
                                        <span class="product-img"><img src="images/protien_first.png" alt="">
                                        </span>
                                        <span>PROTEIN</span>
                                        <h3>Intelligent Wool Bottle</h3>
                                        <span class="rating"><img src="images/star_icon.svg" alt="">4.9 (12 reviews)</span>
                                        <div class="product-detail">
                                            <span>$11.00</span>
                                            <button><img src="images/cart_icon.svg" alt="">Add to cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="product-col">
                                    <div class="product">
                                        <button class="like-btn"><img src="images/like_icon.svg" alt=""></button>
                                        <span class="product-img"><img src="images/protien_two.png" alt="">
                                        </span>
                                        <span>Muscle and Stamina</span>
                                        <h3>Intelligent Wool Bottle</h3>
                                        <span class="rating"><img src="images/star_icon.svg" alt="">4.9 (12 reviews)</span>
                                        <div class="product-detail">
                                            <span>$89.00</span>
                                            <button><img src="images/cart_icon.svg" alt="">Add to cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="product-col">
                                    <div class="product">
                                        <button class="like-btn"><img src="images/like_icon.svg" alt=""></button>
                                        <span class="product-img"><img src="images/protien_third.png" alt="">
                                        </span>
                                        <span>Stress Management</span>
                                        <h3>Intelligent Wool Bottle</h3>
                                        <span class="rating"><img src="images/star_icon.svg" alt="">4.9 (12 reviews)</span>
                                        <div class="product-detail">
                                            <span>$110.00</span>
                                            <button><img src="images/cart_icon.svg" alt="">Add to cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="product-col">
                                    <div class="product">
                                        <button class="like-btn"><img src="images/like_icon.svg" alt=""></button>
                                        <span class="product-img"><img src="images/protien_four.png" alt="">
                                        </span>
                                        <span>PROTEIN</span>
                                        <h3>Intelligent Wool Bottle</h3>
                                        <span class="rating"><img src="images/star_icon.svg" alt="">4.7 (12 reviews)</span>
                                        <div class="product-detail">
                                            <span>$11.00</span>
                                            <button><img src="images/cart_icon.svg" alt="">Add to cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="product-col">
                                    <div class="product">
                                        <button class="like-btn"><img src="images/like_icon.svg" alt=""></button>
                                        <span class="product-img"><img src="images/protien_five.png" alt="">
                                        </span>
                                        <span>PROTEIN</span>
                                        <h3>Intelligent Wool Bottle</h3>
                                        <span class="rating"><img src="images/star_icon.svg" alt="">4.9 (12 reviews)</span>
                                        <div class="product-detail">
                                            <span>$11.00</span>
                                            <button><img src="images/cart_icon.svg" alt="">Add to cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="product-col">
                                    <div class="product">
                                        <button class="like-btn"><img src="images/like_icon.svg" alt=""></button>
                                        <span class="product-img"><img src="images/protien_six.png" alt="">
                                        </span>
                                        <span>PROTEIN</span>
                                        <h3>Intelligent Wool Bottle</h3>
                                        <span class="rating"><img src="images/star_icon.svg" alt="">4.9 (12 reviews)</span>
                                        <div class="product-detail">
                                            <span>$11.00</span>
                                            <button><img src="images/cart_icon.svg" alt="">Add to cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="product-col">
                                    <div class="product">
                                        <button class="like-btn"><img src="images/like_icon.svg" alt=""></button>
                                        <span class="product-img"><img src="images/protien_seven.png" alt="">
                                        </span>
                                        <span>PROTEIN</span>
                                        <h3>Intelligent Wool Bottle</h3>
                                        <span class="rating"><img src="images/star_icon.svg" alt="">4.9 (12 reviews)</span>
                                        <div class="product-detail">
                                            <span>$11.00</span>
                                            <button><img src="images/cart_icon.svg" alt="">Add to cart</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePage;
