import React, { Suspense, useEffect, useLayoutEffect } from "react";
import AllRoutes from "./routes/AllRoutes";
import { useLocation } from "react-router-dom";
import "../src/styles/style.css";
import "../src/styles/responsive.css";
import { useSelector } from "react-redux";
function App() {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  

  const {
    isPopupVisible,
    isProductCreateFromScratch,
    addProductWithoutIngredients,
    isIngredientVisible,
  } = useSelector((state) => state.ingredientsSlice);

  useEffect(() => {
    if (
      isPopupVisible ||
      isProductCreateFromScratch ||
      addProductWithoutIngredients ||
      isIngredientVisible
    )
      document.body.style.overflow = "hidden";
    else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [
    isPopupVisible,
    isProductCreateFromScratch,
    addProductWithoutIngredients,
    isIngredientVisible,
   
  ]);

  return (
    <div className="main-body">
      <Suspense fallback={null}>
        <AllRoutes />
      </Suspense>
    </div>
  );
}

export default App;
