import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  customerCartProducts,
  deleteAddress,
  getAllAddresses,
} from "../../../config/makeRequests/cartRequests";
import { getUserId } from "../../../utils/common";

export const getAllAddress = createAsyncThunk(
  "getAllAddress/checkoutSlice",
  async () => {
    try {
      const userId = getUserId();
      let response = await getAllAddresses(userId);
      if (response.status === true) {
        return response;
      }
    } catch (error) {
      console.log("getAllAddress Error ", error);
    }
  }
);
export const removeAddress = createAsyncThunk(
  "removeAddress/checkoutSlice",
  async ({ id, fetchAddressData }) => {
    try {
      const userId = getUserId();
      let response = await deleteAddress(userId, id);
      if (response.status === true) {
        fetchAddressData();
        return response;
      }
    } catch (error) {
      console.log("removeAddress Error ", error);
    }
  }
);

const initialState = {
  isLoading: false,
  addressList: [],
  isAddressRemove: false,
  deliveryAddress: null,
};

const checkoutSlice = createSlice({
  name: "checkoutSlice",
  initialState,
  reducers: {
    selectDeliveryAddress: (state, { payload }) => {
      state.deliveryAddress = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAddress.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllAddress.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.addressList = payload?.addresses;
      })
      .addCase(getAllAddress.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(removeAddress.pending, (state) => {
        state.isAddressRemove = true;
      })
      .addCase(removeAddress.fulfilled, (state, { payload }) => {
        const deletedAddress = payload?.address;
        state.addressList = state.addressList.filter((item) => {
          return item?.id !== deletedAddress?.id;
        });
        state.isAddressRemove = false;
      })
      .addCase(removeAddress.rejected, (state) => {
        state.isAddressRemove = false;
      });
  },
});

export const { selectDeliveryAddress } = checkoutSlice.actions;

export default checkoutSlice.reducer;
