import { deleteRequest, getRequest, postRequest, putRequest } from "../../Auth"
import { getUserId } from "../../utils/common"


export const customerCartProducts = (userId)=>{
    return getRequest(`/api/users/${userId}/cart/all`)
}
export const removeProductFromCart = (userId , cartItemId)=>{
    return deleteRequest(`/api/users/${userId}/cart/${cartItemId}/delete`)
}
export const updateProductQuantity = (userId , cartItemId , data)=>{
    return putRequest(`/api/users/${userId}/cart/${cartItemId}/update-quantity` , data)
}




// check out Requests 


export const getAllAddresses = (userId)=>{
    return getRequest(`/api/users/${userId}/address/all`)
}
export const deleteAddress = (userId , addressId )=>{
    return deleteRequest(`/api/users/${userId}/address/${addressId}`)
}


// create order 

export const createOrder = (userId , data)=>{
    return postRequest(`/api/users/${userId}/order/create` , data)
}

export const makeCartEmpty = (userId)=>{
    return deleteRequest(`api/users/${userId}/cart/delete`)
}

export const updateCart = (userId , cartItemId , data)=>{
    return putRequest(`/api/users/${userId}/cart/${cartItemId}/update` , data)
}
export const getPaymentTypeUser = ()=>{
    const id = getUserId()
    return getRequest(`/api/users/${id}/order/isCashPaymentUser`)
}



//* get product detail by id 
export const getProductDetails = (productId , gender , weightRange )=>{
    const id = getUserId()
    if(id){
        return getRequest(`/api/users/products/${productId}/detail?gender=${gender}&weightRange=${weightRange}&userId=${id}`)

    }else{
        return getRequest(`/api/users/products/${productId}/detail?gender=${gender}&weightRange=${weightRange}`)
    }
}



export const reOrder = (orderObjectId)=>{
    const id = getUserId()
    return postRequest(`/api/users/${id}/cart/reorder?orderId=${orderObjectId}`)
}