import "../styles/style.css";

const Faq = () => {
    return (
            <div className="for-overlay">

                <section id="banner-main">
                    <div className="banner-img">
                        <img src="images/faq_img.png" alt="" />
                    </div>
                    <div className="banner-text">
                        <div className="container">
                            <div className="banner banner-design">
                                <h1>Frequently Asked Questions</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="faq-main">
                    <div className="container">
                        <div className="faq-head">
                            <ul>
                                <li className="active">All</li>
                                <li>Cancellations and Returns</li>
                                <li>Payment</li>
                                <li>Shopping</li>
                            </ul>
                        </div>
                        <div className="faq">
                            <ul>
                                <li className="active">
                                    <h2>1. Why do I see different prices for the same product?</h2>
                                    <div className="add-hide-btn">
                                        <button className="show-btn"><img src="images/Plus-circle_icon.svg" alt="" /></button>
                                        <button className="hide-btn"><img src="images/minus_circle_icon.svg" alt="" /></button>
                                    </div>
                                    <div className="faq-detail">
                                        <p>You could see different prices for the same product, as it could be listed by many
                                            Sellers.</p>
                                    </div>
                                </li>
                                <li>
                                    <h2>2. Why do I see different prices for the same product?</h2>
                                    <div className="add-hide-btn">
                                        <button className="show-btn"><img src="images/Plus-circle_icon.svg" alt="" /></button>
                                        <button className="hide-btn"><img src="images/minus_circle_icon.svg" alt="" /></button>
                                    </div>
                                    <div className="faq-detail">
                                        <p>You could see different prices for the same product, as it could be listed by many
                                            Sellers.</p>
                                    </div>
                                </li>
                                <li>
                                    <h2>3. Why do I see different prices for the same product?</h2>
                                    <div className="add-hide-btn">
                                        <button className="show-btn"><img src="images/Plus-circle_icon.svg" alt="" /></button>
                                        <button className="hide-btn"><img src="images/minus_circle_icon.svg" alt="" /></button>
                                    </div>
                                    <div className="faq-detail">
                                        <p>You could see different prices for the same product, as it could be listed by many
                                            Sellers.</p>
                                    </div>
                                </li>
                                <li>
                                    <h2>4. Why do I see different prices for the same product?</h2>
                                    <div className="add-hide-btn">
                                        <button className="show-btn"><img src="images/Plus-circle_icon.svg" alt="" /></button>
                                        <button className="hide-btn"><img src="images/minus_circle_icon.svg" alt="" /></button>
                                    </div>
                                    <div className="faq-detail">
                                        <p>You could see different prices for the same product, as it could be listed by many
                                            Sellers.</p>
                                    </div>
                                </li>
                                <li>
                                    <h2>5. Why do I see different prices for the same product?</h2>
                                    <div className="add-hide-btn">
                                        <button className="show-btn"><img src="images/Plus-circle_icon.svg" alt="" /></button>
                                        <button className="hide-btn"><img src="images/minus_circle_icon.svg" alt="" /></button>
                                    </div>
                                    <div className="faq-detail">
                                        <p>You could see different prices for the same product, as it could be listed by many
                                            Sellers.</p>
                                    </div>
                                </li>
                                <li>
                                    <h2>6. Why do I see different prices for the same product?</h2>
                                    <div className="add-hide-btn">
                                        <button className="show-btn"><img src="images/Plus-circle_icon.svg" alt="" /></button>
                                        <button className="hide-btn"><img src="images/minus_circle_icon.svg" alt="" /></button>
                                    </div>
                                    <div className="faq-detail">
                                        <p>You could see different prices for the same product, as it could be listed by many
                                            Sellers.</p>
                                    </div>
                                </li>
                                <li>
                                    <h2>7. Why do I see different prices for the same product?</h2>
                                    <div className="add-hide-btn">
                                        <button className="show-btn"><img src="images/Plus-circle_icon.svg" alt="" /></button>
                                        <button className="hide-btn"><img src="images/minus_circle_icon.svg" alt="" /></button>
                                    </div>
                                    <div className="faq-detail">
                                        <p>You could see different prices for the same product, as it could be listed by many
                                            Sellers.</p>
                                    </div>
                                </li>
                                <li>
                                    <h2>8. Why do I see different prices for the same product?</h2>
                                    <div className="add-hide-btn">
                                        <button className="show-btn"><img src="images/Plus-circle_icon.svg" alt="" /></button>
                                        <button className="hide-btn"><img src="images/minus_circle_icon.svg" alt="" /></button>
                                    </div>
                                    <div className="faq-detail">
                                        <p>You could see different prices for the same product, as it could be listed by many
                                            Sellers.</p>
                                    </div>
                                </li>
                                <li>
                                    <h2>9. Why do I see different prices for the same product?</h2>
                                    <div className="add-hide-btn">
                                        <button className="show-btn"><img src="images/Plus-circle_icon.svg" alt="" /></button>
                                        <button className="hide-btn"><img src="images/minus_circle_icon.svg" alt="" /></button>
                                    </div>
                                    <div className="faq-detail">
                                        <p>You could see different prices for the same product, as it could be listed by many
                                            Sellers.</p>
                                    </div>
                                </li>
                                <li>
                                    <h2>10. Why do I see different prices for the same product?</h2>
                                    <div className="add-hide-btn">
                                        <button className="show-btn"><img src="images/Plus-circle_icon.svg" alt="" /></button>
                                        <button className="hide-btn"><img src="images/minus_circle_icon.svg" alt="" /></button>
                                    </div>
                                    <div className="faq-detail">
                                        <p>You could see different prices for the same product, as it could be listed by many
                                            Sellers.</p>
                                    </div>
                                </li>
                            </ul>
                            <div className="next-previous-slide">
                                <div className="slides-heading">
                                    <span>Showing 1 to 8 of 90</span>
                                </div>
                                <div className="slides-button">
                                    <ul>
                                        <li><a href="#"><img src="images/angle-lef.svg" alt="" />
                                        </a></li>
                                        <li><a className="active-slide" href="#">1</a></li>
                                        <li><a href="#">2</a></li>
                                        <li><a href="#">3</a></li>
                                        <li><a href="#">4</a></li>
                                        <li><a href="#"><img src="images/angle_right.svg" alt="" />
                                        </a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
    );
}
export default Faq;