import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const admin_token = localStorage.getItem("filter");
  if (admin_token) {
    return children;
  } else {
    return <Navigate to="/" replace={true} />;
  }
};

export default PrivateRoute;
