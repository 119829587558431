import React, { useEffect, useState } from "react";
import {
  ingredientPrice,
  productPrice,
  textElips,
} from "../../../utils/common";
import { updateQuantity } from "../../../store/slices/cartSlice/cart";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../common/Spinner";
import Loading from "../../../layout/MixLoaderOne";
import { setIsLoading } from "../../../store/slices/common";
import CartDeleteSpinner from "../../common/CartDeleteSpinner";

const CartProductCard = ({
  item,
  handleRemove,
  isProductRemove,
  quantityUpdate,
  updateCartProductQuantity,
  shippingProcess,
  addIngredients,
}) => {
  // const [isIngredient, setIsIngredient] = useState(false);
  const { quantity, id } = item;
  const isLoading = useSelector((state) => state.common.isLoading);
  const dispatch = useDispatch();

  const [productQuantity, setProductQuantity] = useState(0);
  const [productId, setProductId] = useState("");
  const [removeProductId, setRemoveProductId] = useState("");

  useEffect(() => {
    if (quantity) {
      setProductQuantity(quantity);
    }
  }, [quantity]);

  const handleIncreaseProductCount = (itemId) => {
    setProductId(itemId);
    setProductQuantity((prev) => prev + 1);
  };

  const handleDecreaseProductCount = (itemId) => {
    if (productQuantity === 1) return;
    setProductId(itemId);
    setProductQuantity((prev) => prev - 1);
  };

  useEffect(() => {
    if (productId) {
      updateCartProductQuantity(productId, productQuantity);
    }
  }, [productId, productQuantity]);

  const getMixingDescription = (cartItem) => {
    const items = cartItem?.mixing?.ingredients?.items;
    let description = "";
    if (items) {
      items.forEach((value, index) => {
        description += value?.title;
        if (index < items.length - 1) {
          description += ", ";
        }
      });
    }
    return description;
  };
  const handleRemoveItem = (itemId) => {
    // dispatch(setIsLoading(true));
    handleRemove(itemId);
    setRemoveProductId(itemId);
    // dispatch(setIsLoading(false));
  };

  function getItemTitle(item) {
    if (item?.ingredient) {
      return item?.ingredient?.title;
    } else if (
      item?.mixing?.ingredients?.items?.length > 0 &&
      !item?.hasOwnProperty("ingredient") &&
      !item?.hasOwnProperty("product")
    ) {
      return item?.mixFromScratchName;
    } else {
      return item?.product?.title;
    }
  }

  return (
    <div className="cart-item">
      <div className="cart-img">
        {item?.mixing && !item?.ingredient && !item?.product ? (
          <img src="/images/placeholder_image.png" alt="Mixed Product" />
        ) : (
          <img
            src={
              item?.ingredient?.image
                ? item?.ingredient?.image
                : item?.product?.image
                ? item?.product?.image
                : "/images/placeholder_image.png"
            }
            alt=""
          />
        )}
      </div>
      <div className="cart-details">
        <div className="cart-details-head">
          <h3>{textElips(getItemTitle(item), 40)}</h3>
          {!shippingProcess && (
            <div onClick={() => addIngredients(item)}>
              {item?.hasOwnProperty("product") &&
              (item?.mixing?.ingredients?.items?.length > 0 ||
                item?.mixing?.flavors?.length > 0 ||
                item?.mixing?.sweeteners?.length > 0) ? (
                <span>Show Ingredient Mix</span>
              ) : item?.mixing?.ingredients?.items?.length === 1 &&
                item?.mixing?.flavors == null &&
                item?.mixing?.sweeteners == null &&
                item?.product?.productType !== "Product" ? (
                <span>Add Ingredient mix</span>
              ) : item?.mixing?.ingredients?.items?.length > 0 &&
                (item?.mixing?.flavors?.length !== 0 ||
                  item?.mixing?.sweeteners.length !== 0) ? (
                <span>Show Ingredient mix </span>
              ) : item?.mixing?.ingredients?.items?.length > 0 &&
                !item?.hasOwnProperty("ingredient") &&
                !item?.hasOwnProperty("product") ? (
                <span>Show Ingredient mix</span>
              ) : (
                item?.product?.productType !== "Product" && (
                  <span>Add Ingredient Mix</span>
                )
              )}
            </div>
          )}
        </div>
        <p>
          {item?.ingredient
            ? textElips(item?.ingredient?.description, 200)
            : item?.mixing && !item?.ingredient && !item?.product
            ? textElips(getMixingDescription(item), 200)
            : textElips(item?.product?.description, 200)}
        </p>
        <div className="cart-quantity">
          {shippingProcess && <span> Quantity : {productQuantity} </span>}

          {!shippingProcess && (
            <div className="quantity-btn">
              {item?.product && (
                <>
                  <button
                    disabled={quantityUpdate}
                    className="quantity-low"
                    onClick={() => handleDecreaseProductCount(id)}>
                    <img src="images/quantity_low.svg" alt="" />
                  </button>

                  <button>{productQuantity}</button>
                  <button
                    disabled={quantityUpdate}
                    className="quantity-high"
                    onClick={() => handleIncreaseProductCount(id)}>
                    <img src="images/quantity_high.svg" alt="" />
                  </button>
                </>
              )}
              <span>
                ${item?.ingredient ? ingredientPrice(item) : productPrice(item)}
              </span>
            </div>
          )}
          {shippingProcess && (
            <span>
              ${item?.ingredient ? ingredientPrice(item) : productPrice(item)}
            </span>
          )}

          {!shippingProcess && (
            <>
              {removeProductId === id && isLoading ? (
                <CartDeleteSpinner />
              ) : (
                <div className="cart-item-remove">
                  <button
                    // disabled={isProductRemove}
                    className="remove-item"
                    onClick={() => handleRemoveItem(id)}>
                    <img src="images/delete_grey_icon.svg" alt="" />
                    Remove
                  </button>
                  {/*  <span className="days-title">{item?.offerDays}days</span> */}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CartProductCard;
