

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { proceedToCheckout } from '../../../store/slices/cartSlice/cart';
import { selectDeliveryAddress } from '../../../store/slices/checkoutSlice/checkout';

const CartNav = () => {
    const [totalCartProducts, setTotalCartProducts] = useState(0);

    // redux hooks
const dispatch = useDispatch();
const { cartItems, isCheckoutProceed  } = useSelector((state) => state.cart);

const countOfCart = () => {
  let count = 0;
  if(cartItems){
    for (let item of cartItems) {
      if (item?.ingredient) {
        count++;
      } else {
        count += item.quantity;
      }
    }
    setTotalCartProducts(count);
  }
 
};

useEffect(() => {
  countOfCart();
}, [cartItems]);

    return (
        <>
           <div className="cart-head-col">
                <div className="cart-head">
                  <h2> {isCheckoutProceed ? "Checkout"  : `Your Cart(${totalCartProducts})`}</h2>
                
                   {isCheckoutProceed && <div onClick={()=> { 
                     dispatch(selectDeliveryAddress(null))
                      dispatch(proceedToCheckout(false))}
                       }> <img src="images/back_icon_black.svg" alt="" /> <div>Back to cart </div></div> }
                
                </div>
              </div>
        </>
      );
    }

export default CartNav;

