import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isQuestionLoading: true,
  isIngredientLoading: true,
  isCategoryLoading: true, // * sachin
  activeDetailIndex: "",
  openAddAddress: false,
  isLoading: true,
  countryCode: [],
  citiesData: "",
  activeIndexMenu: "",
  openFilter:"",
  from: '',
  to: '',
  status: '',
  addressFormData: {
    name: "",
    country: {
      countryCode: "",
      name: "",
    },
    phone: {
      dialCode: "",
      number: "",
    },
    latitude: "",
    longitude: "",
    address: "",
    city: "",
    pinCode: "",
    state: "",
    addressType: "",
    addressDataById: "",
    apartmentOrSuite: "",
  },
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setOpenAddAddress: (state) => {
      state.openAddAddress = true;
    },
    setCloseAddAddress: (state) => {
      state.openAddAddress = false;
    },
    setAddressFormData: (state, action) => {
      state.addressFormData = action.payload;
    },
    clearAddressFormData: (state) => {
      state.addressFormData = initialState.addressFormData;
    },
    setCountryCode: (state, action) => {
      state.countryCode = action.payload;
    },
    setCities: (state, action) => {
      state.citiesData = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsQuestionLoading: (state, action) => {
      state.isQuestionLoading = action.payload;
    },
    setIsIngredientsLoading: (state, action) => {
      state.isIngredientLoading = action.payload;
    },

    // * sachin
    setIsCategoryLoading: (state, action) => {
      state.isCategoryLoading = action.payload;
    },
    // * sachin
    setActiveDetailIndex: (state, action) => {
      state.activeDetailIndex = action.payload;
    },
    setActiveIndexMenu: (state, action) => {
      state.activeIndexMenu = action.payload;
    },
    setAddressDataById: (state, action) => {
      state.addressDataById = action.payload;
    },
    setOpenFilter: (state, action) => {
      state.openFilter = action.payload;
    },
    setFrom: (state, action) => {
      state.from = action.payload;
    },
    setTo: (state, action) => {
      state.to = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const {
  setOpenAddAddress,
  setCloseAddAddress,
  setAddressFormData,
  clearAddressFormData,
  setCountryCode,
  setOpenFilter,
  setCities,
  setIsLoading,
  setFrom,
  setTo,
  setStatus,
  setActiveDetailIndex,
  setIsQuestionLoading,
  setIsIngredientsLoading,
  setActiveIndexMenu,
  setAddressDataById, // * sachin
  setIsCategoryLoading, // * sachin
} = commonSlice.actions;

export default commonSlice.reducer;
