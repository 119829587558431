import React, { useEffect, useState } from 'react';
import AddSweetener from "../IngredientsSteps/AddSweetener"
import AvailableOffers from '../AskIngredientPopup/AvailableOffers';
import AddFlavour from '../IngredientsSteps/AddFlavour';
import { addProductIntoCart, addtoCartProduct, askForIngredients, handleEmptyMixing, handleMixScratch, handleScratchProduct, ingredientsVisibility } from '../../../store/slices/mixingIngredients/ingredientsSlice';
import { proceedToCheckout } from '../../../store/slices/cartSlice/cart';
import { useDispatch  , useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import CartSuccess from '../AskIngredientPopup/CartSuccess';
import { calculateMixProductPrice, calculateTotalPrice } from '../../../utils/common';
import { removeAllFromMix } from '../../../action/mixActions';
import { ToastContainer } from 'react-toastify';


const ScratchMixing = () => {
      // navigate react-router hook 
  const navigate = useNavigate()

  // hooks 
  const [step, setStep] = useState(1);
  const [showSuccess, setShowSuccess] = useState(false);
  const [price, setPrice] = useState();

   // redux hooks
   const dispatch = useDispatch();
   const {letsCreateScratchProduct , originalProduct , isProductAdded , selectedOfferDays , productFlavour , productSweetness , mixProductName}  = useSelector((state)=> state.ingredientsSlice)







    const handleSkip = ()=>{
        if (step === 4) return;
          setStep(step + 1);
      }
      
        const handleNext = () => {
          if (step === 4) return;
          setStep(step + 1);
        };
      
        const cancelHandler = ()=>{
          dispatch(handleMixScratch(false));
          dispatch(askForIngredients(false));
          dispatch(ingredientsVisibility(false));
          dispatch(handleEmptyMixing());
          dispatch(handleScratchProduct(false));
          dispatch(addtoCartProduct(null));
        }
        const handlePrev = () => {
          setStep(step - 1);
        };
      
        const offerHandler = () => {
          setStep(0);
          setShowSuccess(true);
          dispatch(removeAllFromMix());
          // dispatch(handleEmptyMixing())
        };
      
        const handleOk = () => {
          dispatch(askForIngredients(false));
          dispatch(handleScratchProduct(false));
          dispatch(ingredientsVisibility(false));
          dispatch(handleMixScratch(false));
          dispatch(proceedToCheckout(false))
          setShowSuccess(false);
          navigate("/cart")
        };

  
      
      
        useEffect(() => {
          if(letsCreateScratchProduct && ( step === 3) ){
            const mixing = {
              flavors: productFlavour,
              ingredients: originalProduct,
              sweeteners: productSweetness,
            };
           
          setPrice(calculateMixProductPrice(mixing));
          }
        }, [productFlavour , originalProduct , step]);


        const scratchMixingProduct = () => {
        
          const itemValue = originalProduct?.items?.map((item)=>{
            return {...item , "quantity" : Number(item?.quantity)}
          })
            const data = {
              mixFromScratchName:mixProductName,
            mixing: {
              flavors: productFlavour,
              ingredients: {gender:originalProduct?.gender , items:itemValue , weightRange:originalProduct?.weightRange},
              sweeteners: productSweetness,
            },
            offerDays: selectedOfferDays,
            quantity: 1,
          };
          dispatch(addProductIntoCart({ data, offerHandler }));
        };
       

        // const scratchMixingWithoutFlavours = ()=>{

        //     const data = {
        //       offerDays:selectedOfferDays,
        //       quantity:1
        //     }
        //   dispatch(addProductIntoCart({data , offerHandler}))
        
        //   }
        

  return (
    <div>
        {
            letsCreateScratchProduct ? (
                <>
                      {step === 1 && (
                        <AddFlavour handleNext={handleNext} handlePrev={handlePrev}  handleSkip={handleSkip} cancelHandler={cancelHandler} formCount={"1/2"} />
                      )}
                      {step === 2 && (
                        <AddSweetener handleNext={handleNext} handlePrev={handlePrev}  handleSkip={handleSkip} cancelHandler={cancelHandler} setShowSuccess={null} formCount={"2/2"} />
                      )}
                      {step === 3 && (
                        <AvailableOffers offerHandler={scratchMixingProduct} price={price} btnLoading={isProductAdded} crossHandler={cancelHandler} />
                      )}
                       </>
            ) :  <AvailableOffers offerHandler={scratchMixingProduct} price={originalProduct?.totalCost} btnLoading={isProductAdded} crossHandler={cancelHandler} />
        }
      


        {showSuccess && <CartSuccess handleOk={handleOk} updateCart={null} />}
        <ToastContainer />

    </div>
  );
}

export default ScratchMixing;
