import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getFlavors,
  handleEmptyMixing,
  storeMixedFlavour,
} from "../../../store/slices/mixingIngredients/ingredientsSlice";
import { errorAlert } from "../../common";
import SkipButton from "../../common/buttons/SkipButton"

const AddFlavour = ({ handleNext, handlePrev, handleSkip , cancelHandler , formCount}) => {
 
  const [selectedCheckboxData, setSelectedCheckboxData] = useState([]);

  //* Redux hooks 
  const dispatch = useDispatch();
  const { flavours, productFlavour } = useSelector(
    (state) => state.ingredientsSlice
  );

  useEffect(() => {
    dispatch(getFlavors());
    
  }, [])


  useEffect(() => {
    if (productFlavour && productFlavour.length > 0) {
      const defaultSelectedData = productFlavour.map((flavor) => {
        const index = flavours.indexOf(flavor.split("(")[0].trim()); // Extracting flavor name from 'Vanilla(Normal)'
        const mode = flavor.split("(")[1].replace(")", "").trim(); // Extracting mode from 'Vanilla(Normal)'
        return { index, flavor, mode };
      });
      setSelectedCheckboxData(defaultSelectedData);
    } else {
      setSelectedCheckboxData([]);
    }
  }, [flavours, productFlavour]);

  const handleCheckboxChange = (index, flavor) => {
    setSelectedCheckboxData((prevData) => {
      if (prevData.length >= 5 && !isSelected(index)) {
        toast.error("You can select only up to 5 flavors");
        return prevData;
      }

      if (isSelected(index)) {
        // Unselect the flavor
        return prevData.filter((data) => data.index !== index);
      } else {
        // Select the flavor
        return [...prevData, { index, flavor, mode: "" }];
      }
    });
  };


  const skipHandler = ()=>{
    dispatch(storeMixedFlavour([]));
    handleNext();

  }

  const handleModeButtonClick = (index, mode) => {
    setSelectedCheckboxData((prevData) =>
      prevData.map((data) => (data.index === index ? { ...data, mode } : data))
    );
  };

  const isSelected = (index) => {
    return selectedCheckboxData.some((data) => data.index === index);
  };

  const handleSaveAndClose = () => {
    // if (selectedCheckboxData.length === 0) {
    //   errorAlert("Select at least one flavor");
    // } else if (selectedCheckboxData.some((data) => !data.mode)) {
    //   errorAlert("Please select any weight for flavour");
    // } else {
    //   const selectedData = selectedCheckboxData.map(
    //     (data) => `${flavours[data.index]}(${data.mode})`
    //   );
 
    //   dispatch(storeMixedFlavour(selectedData));
    //   handleNext();
    // }

    const selectedData = selectedCheckboxData?.map(
      (data) => `${flavours[data.index]}(${data.mode})`
    );

    dispatch(storeMixedFlavour(selectedData));
    handleNext();

  };

  return (
    <div className="payment-form-overlay">
      <div className="flavor-main">
      <div className="flavor-head">
                <h2>Add flavor <p>Step {formCount}</p>
                </h2>
                {
                  selectedCheckboxData?.length === 0  && <SkipButton skipHandler={skipHandler}/> 
                }
               
            </div>
        <div className="flavor-select-main">
          <div className="flavor-select">
            {flavours?.map((item, i) => {
              const index = i;
              return (
                <div className="flavor-category" key={i}>
                  <div className="field">
                    <div className="input-checkbox">
                      <input
                        type="checkbox"
                        checked={isSelected(index)}
                        onChange={() => handleCheckboxChange(index, item)}
                        disabled={
                          selectedCheckboxData.length >= 5 && !isSelected(index)
                        }
                      />
                      <label>{item}</label>
                    </div>
                    {isSelected(index) && (
                      <div className="mode-select">
                        <button
                          onClick={() => handleModeButtonClick(index, "Light")}
                          className={
                            selectedCheckboxData.find(
                              (data) =>
                                data.index === index && data.mode === "Light"
                            )
                              ? "active"
                              : ""
                          }>
                          Light
                        </button>
                        <button
                          onClick={() => handleModeButtonClick(index, "Normal")}
                          className={
                            selectedCheckboxData.find(
                              (data) =>
                                data.index === index && data.mode === "Normal"
                            )
                              ? "active"
                              : ""
                          }>
                          Normal
                        </button>
                        <button
                          onClick={() => handleModeButtonClick(index, "Heavy")}
                          className={
                            selectedCheckboxData.find(
                              (data) =>
                                data.index === index && data.mode === "Heavy"
                            )
                              ? "active"
                              : ""
                          }>
                          Heavy
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flavor-btn">
          <button className="cancle-btn" onClick={cancelHandler}>
            Cancel
          </button>
          <div className="sweetner-btn">
          <button className="sweetback-btn" onClick={handlePrev}>Back</button>
            <button className="save-btn" onClick={handleSaveAndClose}>
            Save & Next
            </button>
          </div>
        </div>
      </div>
      </div>
 
  );
};

export default AddFlavour;
