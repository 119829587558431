import React, { Fragment, useEffect, useState } from "react";
import {
  categoryAPI,
  ingredientAPI,
  productAPI,
  questionAPI,
} from "../Auth/mix";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsIngredientsLoading,
  setIsLoading,
  setIsQuestionLoading,
} from "../store/slices/common";
import { setCategoryData } from "../store/slices/categorySlice";
import { setQuestionData } from "../store/slices/questionSlice";
import { setFoundProductData } from "../store/slices/productSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setIngredientData } from "../store/slices/ingredientSilce";
import IngredientModal from "./common/IngredientModal";
import { selectCosts, setCosts } from "../store/slices/ingredentCostSlice";
import MixCartModal from "./common/MixCartModal";
import { removeItem } from "../store/slices/mixSlice";
import { removeAllFromMix } from "../action/mixActions";
import MixLoader from "../layout/MixLoader";
import { setRecommendedDoseOne } from "../store/slices/recommendedDoseSlice";
import NoProductFound from "../assets/images/noingredients.png";
import { CustomToast } from "./common";
import IllustrationNoProduct from "./common/IllustrationNoProduct";
import { getPriceTrunc } from "../utils/common";

const Mix = () => {
  const [isChecked, setIsChecked] = useState("");
  const [itemsToShow, setItemsToShow] = useState(10);
  const [price, setPrice] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [userResponses, setUserResponses] = useState([]);
  const [mixOverviewVisible, setMixOverviewVisible] = useState(false);
  const isLoading = useSelector((state) => state.common.isLoading);
  const [filteredIngredients, setFilteredIngredients] = useState([]);
  const [costOne, setCostOne] = useState("");
  const [weight, setWeight] = useState("");
  const [addedIngredients, setAddedIngredients] = useState([]);
  const [isMaleChecked, setIsMaleChecked] = useState(false);
  const [showCartMix, setShowCartMix] = useState(false);
  const [isFemaleChecked, setIsFemaleChecked] = useState(false);
  const [isOtherChecked, setIsOtherChecked] = useState(false);
  const cost = useSelector(selectCosts);
  const mixItems = useSelector((state) => state.mix.mixItems);
  const isIngredientLoading = useSelector(
    (state) => state.common.isIngredientLoading
  );
  const isQuestionLoading = useSelector(
    (state) => state.common.isQuestionLoading
  );

  const [pageLoading , setPageLoading] = useState(false)




  const { genderModule } = useSelector((state) => state.product);
  const [showIngredientsModal, setShowIngredientsModal] = useState(false);
  const categoryData = useSelector((state) => state.category.categoryData);
  const currentUser = useSelector((state) => state.login);
  const ingredientData = useSelector(
    (state) => state.ingredient.ingredientData
  );
  const recommendedDoseOne = useSelector(
    (state) => state.recommendedDose.recommendedDoseOne
  );
  const questionData = useSelector((state) => state.question.questionData);
  const [recommendedDose, setRecommendedDose] = useState([]);
  const [recommendedDosess, setRecommendedDosess] = useState([]);
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFilter, setSelectedFilter] = useState({
    gender: "",
    weight: "",
  });
  const [areAllRadioButtonsSelected, setAreAllRadioButtonsSelected] =
    useState(false);

    useEffect(() => {
      localStorage.setItem("recommendedDose", JSON.stringify(recommendedDose));
    }, [recommendedDose]);


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { search } = useLocation();

  
  const handleRadioCheck = (value) => {
    setIsChecked(value);
  };
  const handleQuestion = () => {
    switchToQuestionView(); 
    handleRadioCheck("question"); 
  };

  const handleMix = ()=>{
    switchToMixView();
    handleRadioCheck("mix"); 
  }


  useEffect(() => {
    if (search) {
      const searchValue = search.replace("?", "");
      if(searchValue == "question"){
        handleQuestion()
      }else{
        handleMix()
      }
     
    }
  }, [search]);

  useEffect(() => {
    fetchIngredients();
  }, [genderModule]);

  const switchToMixView = async () => {
    setIsChecked("mix");
    dispatch(setIsIngredientsLoading(true));
    fetchIngredients();
    setMixOverviewVisible(true);
  };

  const switchToQuestionView = () => {
    setSelectedCategories([]);
    setIsChecked("question");
    fetchData();
    dispatch(setIsIngredientsLoading(false));
    dispatch(setIsQuestionLoading(false));
  };

  

  const fetchData = async () => {
    try {
      dispatch(setIsLoading(true));
      const response = await categoryAPI(false);
      const { data } = response;
      dispatch(setCategoryData(data?.categories));
      dispatch(setIsLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCategoryClick = (category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(
        selectedCategories?.filter((cat) => cat !== category)
      );
    } else {
      if (selectedCategories.length < 2) {
        setSelectedCategories([...selectedCategories, category]);
      }
    }
  };

  const fetchQuestion = async () => {
    try {
      dispatch(setIsQuestionLoading(true));
      const selectedCategoriesString = selectedCategories.join(",");
      const response = await questionAPI(selectedCategoriesString);
      const { data } = response;

      dispatch(setQuestionData(data?.questions));
      dispatch(setIsQuestionLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setIsQuestionLoading(false));
    }
  };

  useEffect(() => {
    if (selectedCategories.length > 0) {
      fetchQuestion();
    }
  }, [selectedCategories]);

  const handleResponseChange = (questionId, answer) => {
    const formattedAnswer =
      answer.charAt(0).toUpperCase() + answer.slice(1).toLowerCase();
      

    setUserResponses((prevResponses) => {

      const existingResponseIndex = prevResponses.findIndex(
        (response) => response.id === questionId
      );

      if (existingResponseIndex !== -1) {
        const updatedResponses = [...prevResponses];
        updatedResponses[existingResponseIndex].answer = formattedAnswer;
        return updatedResponses;
      } else {
        return [...prevResponses, { id: questionId, answer: formattedAnswer }];
      }
    });
  };
  useEffect(() => {
    const isAllSelected = questionData.every((question) =>
      userResponses.some((response) => response.id === question.id)
    );

    setAreAllRadioButtonsSelected(isAllSelected);
  }, [questionData, userResponses]);

  const handleSubmit = async () => {
    if (!areAllRadioButtonsSelected) {
      CustomToast("Please select an option for each question.", "error");
      return;
    }

    try {
      const filterData = localStorage.getItem("gender_weight");

      const parsedFilterData = JSON.parse(filterData);
      const { weight, gender } = parsedFilterData;

      const response = await productAPI(
        currentUser.id,
        userResponses,
        gender,
        weight
      );

      if (response.status) {
        dispatch(setFoundProductData(response.data));
        navigate("/product-found");
      }
      dispatch(setIsLoading(true));
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const handleShowMore = () => {
    setItemsToShow(itemsToShow + 10);
  };

  const handleShowLess = () => {
    setItemsToShow(Math.max(itemsToShow - 10, 10));
  };

  const fetchIngredients = async () => {
    try {
      dispatch(setIsIngredientsLoading(true));
      const queryString = buildQueryString(selectedFilter);
      const response = await ingredientAPI(queryString);
      const { data } = response;
      dispatch(setIngredientData(data));
      dispatch(setIsIngredientsLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setIsIngredientsLoading(false));
    }
  };
  useEffect(() => {
    fetchIngredients();
  }, [genderModule]);
  const buildQueryString = (filter) => {
    const { gender, weight } = filter;
    let queryString = "";

    if (gender) {
      queryString += `gender=${gender}`;
    }

    if (weight) {
      queryString += queryString ? `&weight=${weight}` : `weight=${weight}`;
    }

    return queryString;
  };

  const handleFilterChange = (filterType, value) => {
    setSelectedFilter((prevFilter) => {
      const newFilter = { ...prevFilter, [filterType]: value };
      localStorage.setItem("filter", JSON.stringify(newFilter));
      return newFilter;
    });
    dispatch(removeAllFromMix());
  };

  // useEffect(() => {
  //   fetchIngredients();
  // }, []);

  const cancelDelete = () => {
    setShowIngredientsModal(false);
    setShowCartMix(false);
  };

  const ingredientsModal = (ingredient, price) => {
    setSelectedIngredient(ingredient);
    setCostOne(price);
    setShowIngredientsModal(true);

    const isAlreadyAdded = addedIngredients.includes(ingredient.id);

    if (!isAlreadyAdded) {
      setAddedIngredients([...addedIngredients, ingredient.id]);
    }
  };

  useEffect(() => {
    if (currentUser) {
      setIsMaleChecked(currentUser.gender === "male");
      setIsFemaleChecked(currentUser.gender === "female");
      setIsOtherChecked(currentUser.gender === "other");
    }
  }, [currentUser]);

  const handleGenderChange = (gender) => {
    handleFilterChange("gender", gender);

    // setIsOtherChecked(gender === "other");

    const userWeight = currentUser.weight;

    let selectedWeightRange = "";
    if (gender !== currentUser.gender) {
      selectedWeightRange = gender === "female" ? "50-100" : "80-140";
    } else if (gender === "male" || gender === "other") {
      if (userWeight >= 80 && userWeight <= 140) {
        selectedWeightRange = "80-140";
      } else if (userWeight > 140 && userWeight <= 200) {
        selectedWeightRange = "141-200";
      } else if (userWeight > 200) {
        selectedWeightRange = "200+";
      }
    } else if (gender === "female") {
      if (userWeight >= 50 && userWeight <= 100) {
        selectedWeightRange = "50-100";
      } else if (userWeight > 100 && userWeight <= 140) {
        selectedWeightRange = "101-150";
      } else if (userWeight > 200) {
        selectedWeightRange = "150+";
      }
    }

    handleFilterChange("weight", selectedWeightRange);
  };

  const handleOptionChange = (selectedValue) => {
    const updatedFilter = {
      ...selectedFilter,
      weight: selectedValue,
    };
    setWeight(selectedValue);

    localStorage.setItem("filter", JSON.stringify(updatedFilter));

    setSelectedFilter(updatedFilter);
    return updatedFilter;
  };


  useEffect(() => {
    if (!price || !price.length || !cost || !cost.length || !recommendedDose || !recommendedDose.length) {
      setPageLoading(true);
    } else {
      setPageLoading(false);
    }
  }, [price, cost]);

  

  const renderOptions = () => {
    const savedFilter = JSON.parse(localStorage.getItem("filter"));

    const currentFilter = selectedFilter.gender
      ? selectedFilter
      : savedFilter || { gender: "", weight: "" };

    const userWeight = currentUser.weight || "";

    if (currentFilter.gender === "female") {
      return (
        <>
          <option
            value="50-100"
            selected={userWeight >= 50 && userWeight <= 100}
            onClick={() => handleOptionChange("50-100")}>
            50-100 (LBS)
          </option>
          <option
            value="101-150"
            selected={userWeight > 100 && userWeight <= 150}
            onClick={() => handleOptionChange("101-150")}>
            101-150 (LBS)
          </option>
          <option
            value="150+"
            selected={userWeight > 150}
            onClick={() => handleOptionChange("150+")}>
            150+ (LBS)
          </option>
        </>
      );
    } else if (
      currentFilter.gender === "male" ||
      currentFilter.gender === "other"
    ) {
      return (
        <>
          <option
            value="80-140"
            selected={userWeight >= 80 && userWeight <= 140}
            onClick={() => handleOptionChange("80-140")}>
            80-140 (LBS)
          </option>
          <option
            value="141-200"
            selected={userWeight > 140 && userWeight <= 200}
            onClick={() => handleOptionChange("141-200")}>
            141-200 (LBS)
          </option>
          <option
            value="200+"
            // disabled={!userWeight}
            selected={userWeight > 200}
            onClick={() => handleOptionChange("200+")}>
            200+ (LBS)
          </option>
        </>
      );
    } else {
      return <option value="">Select an option</option>;
    }
  };

  useEffect(() => {
    const gender = localStorage.getItem("filter");
    if (gender) {
      const parsedGender = JSON.parse(gender);
      const userGender = currentUser.gender.toLowerCase();
      const isGenderMatched = parsedGender.gender.toLowerCase() === userGender;

      if (isGenderMatched) {
        setSelectedFilter(userGender);
      }
    } else {
      const filter = { gender: currentUser.gender.toLowerCase() };
      localStorage.setItem("filter", JSON.stringify(filter));
    }
  }, [currentUser.gender]);

  useEffect(() => {
    const storedFilter = localStorage.getItem("filter");
    if (storedFilter) {
      const parsedFilter = JSON.parse(storedFilter);

      const updatedFilter = parsedFilter.weight
        ? parsedFilter
        : { ...parsedFilter, weight: currentUser.weight.toString() };

      setSelectedFilter(updatedFilter);
      localStorage.setItem("filter", JSON.stringify(updatedFilter));
    }
  }, [currentUser.weight]);

  useEffect(() => {
    if (currentUser.gender === "male") {
      setIsMaleChecked(true);
    } else if (currentUser.gender === "female") {
      setIsFemaleChecked(true);
    } else if (currentUser.gender === "other") {
      setIsOtherChecked(true);
    }
  }, [currentUser.gender]);

  const CartMix = () => {
    if (mixItems.length === 0) {
      CustomToast("Please add ingredients!", "error");
    } else {
      setShowCartMix(true);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const savedFilter = JSON.parse(localStorage.getItem("filter"));
    const initialFilterState = savedFilter || { gender: "", weight: "" };
    setIsMaleChecked(
      initialFilterState.gender === "male" ||
      initialFilterState.gender === "other"
    );
    setIsFemaleChecked(initialFilterState.gender === "female");

    const filtered = ingredientData?.ingredients?.filter((ingredient) => {
      const isMale =
        isMaleChecked &&
        ingredient.quantity.male &&
        ingredient.quantity.male.length > 0 &&
        ingredient.quantity.male.some((dose) => dose.recommendedDose > 0);

      const isFemale =
        isFemaleChecked &&
        ingredient.quantity.female &&
        ingredient.quantity.female.length > 0 &&
        ingredient.quantity.female.some((dose) => dose.recommendedDose > 0);

      const passesSearchQuery = ingredient.title
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

      return (isMale || isFemale) && passesSearchQuery;
    });

    const removedItems = mixItems?.filter((item) => {
      const isMaleIngredient =
        item?.recommendedDose &&
        item?.recommendedDose.length > 0 &&
        !isMaleChecked;
      const isFemaleIngredient =
        item?.recommendedDose &&
        item?.recommendedDose.length > 0 &&
        !isFemaleChecked;

      return !(isMaleIngredient || isFemaleIngredient);
    });

    removedItems.forEach((removedItem) => {
      dispatch(removeItem(removedItem));
    });

    const filteredIngredients = filtered?.filter((ingredient) => {
      if (isMaleChecked) {
        const maleWeightRange = initialFilterState.weight;
        if (maleWeightRange === "80-140") {
          return (
            !(ingredient.quantity.male[0]?.recommendedDose === 0) &&
            !(ingredient.quantity.male[0]?.recommendedDose === 0)
          );
        } else if (maleWeightRange === "140-200") {
          return (
            !(ingredient.quantity.male[1]?.recommendedDose === 0) &&
            !(ingredient.quantity.male[1]?.recommendedDose === 0)
          );
        } else if (maleWeightRange === "200+") {
          return (
            !(ingredient.quantity.male[2]?.recommendedDose === 0) &&
            !(ingredient.quantity.male[2]?.recommendedDose === 0)
          );
        }
      } else if (isFemaleChecked) {
        const femaleWeightRange = initialFilterState.weight;
        if (femaleWeightRange === "150+") {
          return (
            !(ingredient.quantity.female[2]?.recommendedDose === 0) &&
            !(ingredient.quantity.male[2]?.recommendedDose === 0)
          );
        } else if (femaleWeightRange === "100-150") {
          return (
            !(ingredient.quantity.female[1]?.recommendedDose === 0) &&
            !(ingredient.quantity.male[1]?.recommendedDose === 0)
          );
        } else if (femaleWeightRange === "50-100") {
          return (
            !(ingredient.quantity.female[0]?.recommendedDose === 0) &&
            !(ingredient.quantity.male[0]?.recommendedDose === 0)
          );
        }
      }

      return true;
    });

    setFilteredIngredients(filteredIngredients);
  }, [
    isMaleChecked,
    isFemaleChecked,
    isOtherChecked,
    ingredientData,
    searchQuery,
    mixItems,
    genderModule,
  ]);

  useEffect(() => {
    const savedFilter = JSON.parse(localStorage.getItem("filter"));
    if (
      (currentUser.gender.toLowerCase() === "male" &&
        currentUser.weight <= 140) ||
      (weight === "80-140" && (isMaleChecked || isOtherChecked)) ||
      ((savedFilter.gender === "male" || savedFilter.gender === "other") &&
        savedFilter.weight === "80-140")
    ) {
      const maleIngredients = filteredIngredients?.filter(
        (ingredient) =>
          ingredient &&
          ingredient.quantity &&
          ingredient.quantity.male &&
          ingredient.quantity.male[0] &&
          ingredient.quantity.male[0].recommendedDose
      );

      const recommendedDoses = maleIngredients?.map(
        (ingredient) => ingredient.quantity.male[0].recommendedDose
      );
      const recommendedPrice = maleIngredients?.map(
        (ingredient) => ingredient.quantity.male[0].recommendedMonthlySalePrice
      );
      // localStorage.setItem("recommendedDose", JSON.stringify(recommendedDoses));
      dispatch(setRecommendedDoseOne(recommendedDoses));
      setPrice(recommendedPrice);
      // localStorage.setItem("recommendedDose", JSON.stringify(recommendedDoses));
      setRecommendedDose(recommendedDoses);
    } else if (
      (currentUser.gender.toLowerCase() === "male" &&
        currentUser.weight >= 141 &&
        currentUser.weight <= 200) ||
      (weight === "141-200" && (isMaleChecked || isOtherChecked)) ||
      ((savedFilter.gender === "male" || savedFilter.gender === "other") &&
        savedFilter.weight === "141-200")
    ) {
      const maleIngredients = filteredIngredients?.filter(
        (ingredient) =>
          ingredient &&
          ingredient.quantity &&
          ingredient.quantity.male &&
          ingredient.quantity.male[1] &&
          ingredient.quantity.male[1].recommendedDose
      );

      const recommendedDoses = maleIngredients?.map(
        (ingredient) => ingredient.quantity.male[1].recommendedDose
      );
      const recommendedPrice = maleIngredients?.map(
        (ingredient) => ingredient.quantity.male[1].recommendedMonthlySalePrice
      );
      // localStorage.setItem("recommendedDose", JSON.stringify(recommendedDoses));
      setPrice(recommendedPrice);
      dispatch(setRecommendedDoseOne(recommendedDoses));
      setRecommendedDose(recommendedDoses);
    } else if (
      (currentUser.gender.toLowerCase() === "male" &&
        currentUser.weight >= 201) ||
      (weight === "200+" && (isMaleChecked || isOtherChecked)) ||
      ((savedFilter.gender === "male" || savedFilter.gender === "other") &&
        savedFilter.weight === "200+")
    ) {
      const maleIngredients = filteredIngredients?.filter(
        (ingredient) =>
          ingredient &&
          ingredient.quantity &&
          ingredient.quantity.male &&
          ingredient.quantity.male[2] &&
          ingredient.quantity.male[2].recommendedDose
      );

      const recommendedDoses = maleIngredients?.map(
        (ingredient) => ingredient.quantity.male[2].recommendedDose
      );
      const recommendedPrice = maleIngredients?.map(
        (ingredient) => ingredient.quantity.male[2].recommendedMonthlySalePrice
      );
      // localStorage.setItem("recommendedDose", JSON.stringify(recommendedDoses));
      dispatch(setRecommendedDoseOne(recommendedDoses));
      setPrice(recommendedPrice);
      setRecommendedDose(recommendedDoses);
    }
    if (
      (currentUser.gender.toLowerCase() === "female" &&
        currentUser.weight <= 100) ||
      (weight === "50-100" && isFemaleChecked) ||
      (savedFilter.gender === "female" && savedFilter.weight === "50-100")
    ) {
      const femaleIngredients = filteredIngredients?.filter(
        (ingredient) =>
          ingredient &&
          ingredient.quantity &&
          ingredient.quantity.female &&
          ingredient.quantity.female[0] &&
          ingredient.quantity.female[0].recommendedDose
      );

      const recommendedDoses = femaleIngredients?.map(
        (ingredient) => ingredient.quantity.female[0].recommendedDose
      );
      const recommendedPrice = femaleIngredients?.map(
        (ingredient) =>
          ingredient.quantity.female[0].recommendedMonthlySalePrice
      );
      // localStorage.setItem("recommendedDose", JSON.stringify(recommendedDoses));
      setPrice(recommendedPrice);
      dispatch(setRecommendedDoseOne(recommendedDoses));
      setRecommendedDose(recommendedDoses);
    } else if (
      (currentUser.gender.toLowerCase() === "female" &&
        currentUser.weight >= 101 &&
        currentUser.weight <= 150) ||
      (weight === "101-150" && isFemaleChecked) ||
      (savedFilter.gender === "female" && savedFilter.weight === "101-150")
    ) {
      const femaleIngredients = filteredIngredients?.filter(
        (ingredient) =>
          ingredient &&
          ingredient.quantity &&
          ingredient.quantity.female &&
          ingredient.quantity.female[1] &&
          ingredient.quantity.female[1].recommendedDose
      );

      const recommendedDoses = femaleIngredients?.map(
        (ingredient) => ingredient.quantity.female[1].recommendedDose
      );
      const recommendedPrice = femaleIngredients?.map(
        (ingredient) =>
          ingredient.quantity.female[1].recommendedMonthlySalePrice
      );
      // localStorage.setItem("recommendedDose", JSON.stringify(recommendedDoses));
      dispatch(setRecommendedDoseOne(recommendedDoses));
      setPrice(recommendedPrice);
      setRecommendedDose(recommendedDoses);
    } else if (
      (currentUser.gender.toLowerCase() === "female" &&
        currentUser.weight <= 150) ||
      (weight === "150+" && isFemaleChecked) ||
      (savedFilter.gender === "female" && savedFilter.weight === "151+")
    ) {
      const femaleIngredients = filteredIngredients?.filter(
        (ingredient) =>
          ingredient &&
          ingredient.quantity &&
          ingredient.quantity.female &&
          ingredient.quantity.female[2] &&
          ingredient.quantity.female[2].recommendedDose
      );

      const recommendedDoses = femaleIngredients?.map(
        (ingredient) => ingredient.quantity.female[2].recommendedDose
      );
      const recommendedPrice = femaleIngredients?.map(
        (ingredient) =>
          ingredient.quantity.female[2].recommendedMonthlySalePrice
      );
      setPrice(recommendedPrice);
      dispatch(setRecommendedDoseOne(recommendedDoses));
      // localStorage.setItem("recommendedDose", JSON.stringify(recommendedDoses));

      setRecommendedDose(recommendedDoses);
    }

    mixItems?.forEach((mixItem) => {
      const index = filteredIngredients.findIndex(
        (ingredient) => ingredient.id === mixItem.id
      );

      if (index !== -1 && mixItem.recommendedDose) {
        setRecommendedDose((prevRecommendedDose) => {
          const newRecommendedDose = [...prevRecommendedDose];
          newRecommendedDose[index] = mixItem.recommendedDose;
          return newRecommendedDose;
        });
      }
    });
    totalPriceOne();
  }, [currentUser, filteredIngredients, mixItems, weight, genderModule]);

  const calculateCost = (calculatedPrice, weight, recommendedDose) => {
    return (calculatedPrice / weight) * recommendedDose * 30;
  };

  const totalPriceOne = () => {
    const savedFilter = JSON.parse(localStorage.getItem("filter"));
    if (
      (savedFilter.gender === "male" || savedFilter.gender === "other") &&
      savedFilter.weight === "80-140"
    ) {
      const maleIngredients = filteredIngredients?.filter(
        (ingredient) =>
          ingredient &&
          ingredient?.quantity &&
          ingredient?.quantity?.male &&
          ingredient?.quantity?.male[0] &&
          ingredient?.quantity?.male[0]?.recommendedDose
      );

      const recommendedDoses = maleIngredients?.map(
        (ingredient) => ingredient.quantity.male[0].recommendedDose
      );

      setRecommendedDosess((prevDosess) => recommendedDoses || prevDosess);
    } else if (
      (savedFilter.gender === "male" || savedFilter.gender === "other") &&
      savedFilter.weight === "141-200"
    ) {
      const maleIngredients = filteredIngredients?.filter(
        (ingredient) =>
          ingredient &&
          ingredient?.quantity &&
          ingredient?.quantity?.male &&
          ingredient?.quantity?.male[1] &&
          ingredient?.quantity?.male[1]?.recommendedDose
      );

      const recommendedDoses = maleIngredients?.map(
        (ingredient) => ingredient.quantity.male[1].recommendedDose
      );

      setRecommendedDosess((prevDosess) => recommendedDoses || prevDosess);
    } else if (
      (savedFilter.gender === "male" || savedFilter.gender === "other") &&
      savedFilter.weight === "200+"
    ) {
      const maleIngredients = filteredIngredients?.filter(
        (ingredient) =>
          ingredient &&
          ingredient?.quantity &&
          ingredient?.quantity?.male &&
          ingredient?.quantity?.male[2] &&
          ingredient?.quantity?.male[2]?.recommendedDose
      );

      const recommendedDoses = maleIngredients?.map(
        (ingredient) => ingredient.quantity.male[2].recommendedDose
      );
      setRecommendedDosess((prevDosess) => recommendedDoses || prevDosess);
    } else if (
      savedFilter.gender === "female" &&
      savedFilter.weight === "50-100"
    ) {
      const femaleIngredients = filteredIngredients?.filter(
        (ingredient) =>
          ingredient &&
          ingredient?.quantity &&
          ingredient?.quantity?.female &&
          ingredient?.quantity?.female[0] &&
          ingredient?.quantity?.female[0]?.recommendedDose
      );

      const recommendedDoses = femaleIngredients?.map(
        (ingredient) => ingredient?.quantity?.female[0]?.recommendedDose
      );
      setRecommendedDosess((prevDosess) => recommendedDoses || prevDosess);
    } else if (
      savedFilter.gender === "female" &&
      savedFilter.weight === "101-150"
    ) {
      const femaleIngredients = filteredIngredients?.filter(
        (ingredient) =>
          ingredient &&
          ingredient?.quantity &&
          ingredient?.quantity?.female &&
          ingredient?.quantity?.female[1] &&
          ingredient?.quantity?.female[1]?.recommendedDose
      );

      const recommendedDoses = femaleIngredients?.map(
        (ingredient) => ingredient.quantity.female[1].recommendedDose
      );

      setRecommendedDosess((prevDosess) => recommendedDoses || prevDosess);
    } else if (
      savedFilter.gender === "female" &&
      savedFilter.weight === "151+"
    ) {
      const femaleIngredients = filteredIngredients?.filter(
        (ingredient) =>
          ingredient &&
          ingredient?.quantity &&
          ingredient?.quantity?.female &&
          ingredient?.quantity?.female[2] &&
          ingredient?.quantity?.female[2]?.recommendedDose
      );

      const recommendedDoses = femaleIngredients?.map(
        (ingredient) => ingredient?.quantity?.female[2].recommendedDose
      );
      setRecommendedDosess((prevDosess) => recommendedDoses || prevDosess);
    }

    if (filteredIngredients) {
      const costs = filteredIngredients?.map((ingredient, index) => {
        if (
          ingredient &&
          ingredient?.quantity &&
          ingredient?.quantity?.price &&
          ingredient?.quantity?.price?.sale &&
          ingredient?.quantity?.price?.weight
        ) {
          const sale = ingredient.quantity.price.sale;
          const weight = ingredient.quantity.price.weight;
          const dose = recommendedDosess[index];

          const mixItem = mixItems?.find((item) => item.id === ingredient.id);
          if (mixItem) {
            return mixItem.cost || 0;
          }
          return calculateCost(sale, weight, dose) || 0;
        }
        return 0;
      });
      dispatch(setCosts(costs));
    }
  };

  useEffect(() => {
    totalPriceOne();
  }, [filteredIngredients, mixItems, genderModule, recommendedDosess]);

  const formatPrice = (value) => {
    const numericValue = Number(value);

    if (numericValue >= 0.01) {
      return numericValue.toFixed(2);
    } else {
      const formattedValue = Math.max(numericValue, 0.001)
        .toFixed(4)
        .replace(/\.?0+$/, "");

      return formattedValue === "0.001" ? "0.00" : formattedValue;
    }


  };



  return (
    <Fragment>
      <div className="for-overlay">
        <section id="banner-main">
          <div className="banner-img mobile-hide-banner">
            <img src="images/mix_product.png" alt="" />
          </div>
          <div className="banner-text text-for-mobile">
            <div className="container">
              <div className="banner">
                <h1>Create your blend</h1>
              </div>
            </div>
          </div>
        </section>

        <div className="mix-main">
          <div className="container">
            <div className="create-mix-main">
              <h2>Let's create your own blend</h2>
              <div className="create-mix">
                <div onClick={handleQuestion}
                  className={`create ${isChecked === "question" ? "active" : ""}`}>
                  <div className="creta-head" >
                    <img src="images/Question.png" alt="" />
                    <input
                      name="selectionType"
                      type="radio"
                      className="btn btn-warning"
                      checked={isChecked === "question"}
                      onChange={() => { }} // Empty onChange
                    />
                  </div>
                  <h4>Answer a few questions</h4>
                  <p>
                    and have a blend created specific to your needs, weight and
                    sex
                  </p>
                </div>
                <div onClick={handleMix}
                  className={`create ${isChecked === "mix" ? "active" : ""}`}>
                  <div className="creta-head" >
                    <img src="images/mix.png" alt="" />
                    <input
                      name="selectionType"
                      type="radio"
                      checked={isChecked === "mix"}
                      onChange={() => { }}
                    />
                  </div>
                  <h4>Mix</h4>
                  <p> <span>from scratch!</span> </p>
                </div>
              </div>

              {isChecked === "question" && (
                <div
                  className={`creat-mix-overview ${isChecked ? "active" : ""}`}>
                  <div className="select-category">
                    <h3>
                      Select categories <span>(Max 2)</span>
                    </h3>
                    <ul>
                      {isLoading ? (
                        <p
                          style={{
                            width: "100%",
                            height: "200px !important",
                            display: "flex",
                            justifyContent: "center",
                          }}>
                          <MixLoader />
                        </p>
                      ) : (
                        categoryData?.map((category) => (
                          <li
                            key={category.id}
                            onClick={() => handleCategoryClick(category)}
                            className={
                              selectedCategories.includes(category)
                                ? "active"
                                : ""
                            }>
                            {category}
                          </li>
                        ))
                      )}
                    </ul>
                  </div>
                  {isQuestionLoading ? (
                    <p
                      style={{
                        width: "100%",
                        height: "200px !important",
                        display: "flex",
                        justifyContent: "center",
                      }}>
                      <MixLoader />
                    </p>
                  ) : (
                    selectedCategories.length > 0 &&
                    questionData.length > 0 && (
                      <div>
                        <div className="qna-main">
                          <h3>Answer a few questions:</h3>

                          <ul>
                            {questionData?.map((question, index) => (
                              <li key={question.id}>
                                <div className="question-container">
                                  <span className="question-number">
                                    {index + 1}.{" "}
                                  </span>
                                  <Link to="#">{question.question}</Link>
                                </div>
                                <div className="selectoption">
                                  <div className="input-field">
                                    <input
                                      type="radio"
                                      name={`hunger-${index}`}
                                      value="yes"
                                      id={`hunger-yes-${index}`}
                                      onChange={() =>
                                        handleResponseChange(question.id, "yes")
                                      }
                                    />
                                    <label htmlFor={`hunger-yes-${index}`}>
                                      {question.options[0]}
                                    </label>
                                  </div>
                                  <div className="input-field">
                                    <input
                                      type="radio"
                                      name={`hunger-${index}`}
                                      value="no"
                                      id={`hunger-no-${index}`}
                                      onChange={() =>
                                        handleResponseChange(question.id, "no")
                                      }
                                    />
                                    <label htmlFor={`hunger-no-${index}`}>
                                      {question.options[1]}
                                    </label>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="submit-btn">
                          <button onClick={() => handleSubmit()}>Submit</button>
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
              {isChecked === "mix" && (
                <div
                  id="mix-className"
                  style={{ display: mixOverviewVisible ? "block" : "none" }}
                  className={`creat-mix-overview ${isChecked ? "" : "active"}`}>
                  <div className="filter-mix-main">
                    <div className="mix-filter-main">
                      <h2>Filter by</h2>
                      <div className="filter">
                        <div className="gender">
                          <button>
                            Gender <img src="images/angle_down.svg" alt="" />
                          </button>
                          <div className="input-field">
                            <input
                              type="checkbox"
                              style={{ accentColor: "#efc441" }}
                              checked={selectedFilter.gender === "male"}
                              onChange={() => handleGenderChange("male")}
                            />
                            <label>Male</label>
                          </div>
                          <div className="input-field">
                            <input
                              type="checkbox"
                              style={{ accentColor: "#efc441" }}
                              checked={selectedFilter.gender === "female"}
                              onChange={() => handleGenderChange("female")}
                            />
                            <label>Female</label>
                          </div>
                          <div className="input-field">
                            <input
                              type="checkbox"
                              style={{ accentColor: "#efc441" }}
                              checked={selectedFilter.gender === "other"}
                              onChange={() => handleGenderChange("other")}
                            />
                            <label>Other</label>
                          </div>
                        </div>

                        <div className="height-weight">
                          <button>
                            Weight <img src="images/angle_down.svg" alt="" />
                          </button>
                          <select
                            style={{
                              width: "100%",
                              border: "1px solid #e0e0e0",
                              height: "50px",
                            }}
                            name="gender"
                            id="cars"
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              handleOptionChange(selectedValue);
                              dispatch(removeAllFromMix());
                            }}>
                            {renderOptions()}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="mix-scartch-main">
                      <div className="mix-scartch-head">
                        <div className="mix-scartch-left">
                          <h2>
                            Mix from scratch{" "}
                            <span>
                              <img src="images/right_icon.svg" alt="" />
                              {mixItems.length} ingredients selected
                            </span>
                          </h2>
                        </div>
                        <div className="mix-scratch-right">
                          <button className="cart-btn" onClick={CartMix}>
                            <img src="images/cart_bucket_icon.svg" alt="" />
                            <span className="order-num">{mixItems.length}</span>
                          </button>
                          <div className="search-bar">
                            <span>
                              <img src="images/search_icon.svg" alt="" />
                            </span>
                            <input
                              type="search"
                              placeholder="Search"
                              value={searchQuery}
                              onChange={handleSearchChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mix-scratch mix-scratch-table">
                        <table
                          border="0"
                          cellPadding="0"
                          style={{
                            width: "100%",
                            borderBottom: "1px solid #e0e0e0",
                          }}>
                          <thead>
                            <tr style={{ borderBottom: "1px solid #EDEDED" }}>
                              <th className="heading">Ingredients</th>
                              <th
                                style={{ paddingLeft: "35px" }}
                                className="heading">
                                Amount
                              </th>
                              <th
                                style={{
                                  textAlign: "right",
                                }}
                                className="heading">
                                Action
                              </th>
                            </tr>
                          </thead>
                        </table>
                        <table
                          border="0"
                          className="mix-scratch-mobile"
                          cellPadding="0"
                          style={{ width: "100%", padding: "0 45px 38px" }}>
                          {isIngredientLoading || pageLoading  ? (
                            <tbody style={{ height: "200px !important" }}>
                              <tr
                                style={{
                                  width: "100%",
                                  height: "200px !important",
                                  display: "flex",
                                  justifyContent: "center",
                                }}>
                                <td>
                                  <MixLoader />
                                </td>
                              </tr>
                            </tbody>
                          ) :  filteredIngredients?.length === 0 ? (
                            <tbody>
                              <tr>
                                <td colSpan="3" style={{ textAlign: "center" }}>
                                  <div style={{ marginTop: "50px" }}></div>
                                  <IllustrationNoProduct tittle={"No products found!"} imgLink={NoProductFound} />

                                </td>
                              </tr>
                            </tbody>
                          ) : (
                            price.length > 0 && cost.length > 0 && filteredIngredients?.length > 0 &&  filteredIngredients
                              ?.slice(0, itemsToShow)
                              .map((ingredient, index) => (
                                <tbody key={ingredient?.id}>
                                  <tr>
                                    <td>
                                      <table
                                        border="0"
                                        cellPadding="0"
                                        style={{
                                          width: "100%",
                                          borderBottom: "1px solid #EDEDED",
                                        }}>
                                        <tbody>
                                          <tr
                                            style={{
                                              borderBottom: "1px solid #EDEDED",
                                            }}>
                                            <td
                                              className="table-col"
                                              style={{ padding: "20px 45px" }}>
                                              {ingredient?.title}{" "}
                                              <span>
                                                (
                                                  {
                                                 Array.from(recommendedDose || [])[index]
                                                  }
                                                {ingredient?.quantity?.type} for 30 Servings)
                                              </span>
                                            </td>
                                            <td
                                              className="price"
                                              style={{ padding: "0 15px" }}>
                                              $
                                              {price[index]
                                                ? getPriceTrunc(cost[index])
                                                : "0.00"}
                                            </td>
                                            <td
                                              className="add-btn"
                                              style={{ textAlign: "center" }}>
                                              <button
                                                onClick={() => {
                                                  if (
                                                    !mixItems
                                                      ?.map((item) =>
                                                        String(item?.id)
                                                      )
                                                      .includes(
                                                        String(ingredient?.id)
                                                      )
                                                  ) {
                                                    ingredientsModal(
                                                      ingredient,
                                                      price[index],
                                                      JSON.parse(
                                                        localStorage.getItem(
                                                          "filter"
                                                        )
                                                      ).gender
                                                    );
                                                  } else {
                                                    ingredientsModal(
                                                      ingredient,
                                                      price[index],
                                                      JSON.parse(
                                                        localStorage.getItem(
                                                          "filter"
                                                        )
                                                      ).gender,
                                                      true
                                                    );
                                                  }
                                                }}>
                                                {mixItems
                                                  ?.map((item) =>
                                                    String(item?.id)
                                                  )
                                                  .includes(
                                                    String(ingredient?.id)
                                                  )
                                                  ? "Added"
                                                  : "Add"}
                                              </button>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              ))
                          )}
                        </table>
                        <div className="show_more-less">
                          {filteredIngredients?.length > itemsToShow && (
                            <button
                              className="show_more-button"
                              onClick={handleShowMore}>
                              Show More
                            </button>
                          )}
                          {itemsToShow > 10 && (
                            <button
                              className="show_less-button"
                              onClick={handleShowLess}>
                              Show Less
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {showIngredientsModal && <div className="overlay_one"></div>}
        {showCartMix && <div className="overlay_one"></div>}
        <IngredientModal
          isOpen={showIngredientsModal}
          closeModal={cancelDelete}
          onClose={cancelDelete}
          costOne={costOne}
          gender={selectedFilter}
          selectedIngredient={selectedIngredient}
        />
        <MixCartModal setShowCartMix={setShowCartMix} isOpen={showCartMix} onClose={cancelDelete} />
      </div>
    </Fragment>
  );
};

export default Mix;
