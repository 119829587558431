import React, { useEffect, useState } from "react";
import "react-range-slider-input/dist/style.css";
import { useDispatch, useSelector } from "react-redux";
import LoaderOne from "../../../layout/LoaderOne";
import { storeMixedProduct } from "../../../store/slices/mixingIngredients/ingredientsSlice";
import { textElips } from "../../../utils/common/index.js";
import {
  findFemaleRangeByWeight,
  findMaleRangeByWeight,
} from "../../../utils/common/ingredientsUtils";
import { errorAlert } from "../../common";
import CustomDropdown from "../../common/CustomDropdown";
import CustomRange from "../../common/RangeSlider/CustomRange";
import SkipButton from "../../common/buttons/SkipButton";

const MixingIngredients = ({
  handleNext,
  handlePrev,
  handleSkip,
  isExtraIngredients,
  handleNoExtraIngredients,
}) => {
  const {
    originalProduct,
    productMixing,
    isLoading,
    additionalIngredients,
    updateProduct,
    updateIngredients,
  } = useSelector((state) => state.ingredientsSlice);

  const [gender, setGender] = useState("");
  const [weight, setWeight] = useState("");
  const cancelClick = useSelector(
    (state) => state.ingredientsSlice.cancelClick
  );

  const [preIngredientQuantity, setPreIngredientQuantity] = useState(null);
  const [preIngredientDoseRange, setPreIngredientDoseRange] = useState(null);

  const [newIngredients, setNewIngredients] = useState([]);
  const [ingredientsItem, setIngredientsItem] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [ingredientDose, setIngredientDose] = useState("");

  const [genderOption, setGenderOption] = useState(["Male", "Female", "Other"]);

  const femaleWeight = ["50-100 (LBS)", "101-150 (LBS)", "151 (LBS)+"];
  const maleWeight = ["80-140 (LBS)", "141-200 (LBS)", "200 (LBS)+"];

  const [weightValue, setWeightValue] = useState();
  const [listOfIngredients, setListOfIngredients] = useState([]);

  const [updateProductRangeValue, setUpdateProductRangValue] = useState();

  useEffect(() => {
    if (!updateProduct) return;
    if (
      additionalIngredients &&
      updateProduct &&
      updateProduct?.mixing &&
      updateProduct?.mixing?.ingredients &&
      updateProduct?.mixing?.ingredients?.items
    ) {
      const preAddedIngredients = updateProduct?.mixing?.ingredients?.items;

      const result = additionalIngredients?.filter((_value) => {
        return preAddedIngredients?.some((item) => item?.id === _value?.id);
      });
      setNewIngredients([...result]);
    }
  }, [updateProduct, additionalIngredients]);

  function changePositionIngredients() {
    const filteredIngredients = additionalIngredients?.filter((ingredient) => {
      return updateIngredients?.items.some((item) => item.id === ingredient.id);
    });

    if (filteredIngredients.length > 0) {
      // Filter out ingredients that are included in updateIngredients.items
      const removeIngredients = additionalIngredients?.filter((ingredient) => {
        return !updateIngredients?.items.some(
          (item) => item.id === ingredient.id
        );
      });

      // below filter condition for when some ingredients not for specific gender
      const data = removeIngredients?.filter((item) => {
        if (updateIngredients?.gender?.toLowerCase() === "female") {
          return item?.quantity?.female?.length > 0;
        } else {
          return item?.quantity?.male?.length > 0;
        }
      });

      // Create a new array combining filtered and removed ingredients
      const newListOfIngredients = [
        ...filteredIngredients,
        ...data?.sort((a, b) => a.title.localeCompare(b.title)),
      ];

      // Update the state with the new list of ingredients
      setListOfIngredients(newListOfIngredients);
    } else {
      // If there are no filtered ingredients, set the list to additionalIngredients
      const data = additionalIngredients?.filter((item) => {
        if (updateIngredients?.gender?.toLowerCase() === "female") {
          return item?.quantity?.female?.length > 0;
        } else {
          return item?.quantity?.male?.length > 0;
        }
      });
      const ingredientsValues = [...data];
      const sortIngredients = [
        ...ingredientsValues?.sort((a, b) => {
          const nameA = a.title.toUpperCase(); // ignore upper and lowercase
          const nameB = b.title.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        }),
      ];

      setListOfIngredients([...sortIngredients]);
    }
  }

  useEffect(() => {
    if (additionalIngredients?.length > 0) {
      changePositionIngredients();
    }
    if (updateProduct && !updateIngredients) {
      getUserCurrentGender();
    }

    updateProductValues();
  }, [additionalIngredients, updateIngredients]);

  useEffect(() => {
    if (productMixing) {
      setGender(productMixing?.gender);
      setWeight(productMixing?.weightRange);
      if (productMixing?.items?.length > 0) {
        const filteredIngredients = additionalIngredients?.filter(
          (ingredient) => {
            return productMixing?.items?.some(
              (item) => item.id === ingredient.id
            );
          }
        );
        if (filteredIngredients?.length > 0) {
          setNewIngredients([...filteredIngredients]);
          setIngredientsItem([...productMixing?.items]);
        }
      }
    }
  }, [productMixing]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (updateProduct) {
      if (updateProduct.hasOwnProperty("ingredient")) {
        setPreIngredientQuantity(updateProduct?.ingredient?.quantity);
        getRangeValue(gender, weight);
      }
    }

    if (originalProduct) {
      setPreIngredientQuantity(originalProduct?.quantity);
    }
  }, [originalProduct, weight, gender, ingredientsItem]);

  function updateProductValues() {
    if (updateIngredients) {
      setGender(updateIngredients?.gender);
      setWeight(updateIngredients?.weightRange);
      if (updateIngredients?.gender?.toLowerCase() === "female") {
        setGender(updateIngredients?.gender);
        setWeightValue(femaleWeight);
        const productNotForMale = updateProduct?.ingredient?.quantity?.male;
        if (!productNotForMale) {
          setGenderOption(["Female"]);
        }
      } else {
        const productNotForFemale = updateProduct?.ingredient?.quantity?.female;
        if (!productNotForFemale) {
          setGenderOption(["Male", "Other"]);
        }
        setGender(updateIngredients?.gender);
        setWeightValue(maleWeight);
      }
    }
    if (updateIngredients?.items?.length > 0) {
      if (updateProduct.hasOwnProperty("ingredient")) {
        setIngredientsItem([...updateIngredients?.items]);
      } else {
        const filteredIngredients = additionalIngredients?.filter(
          (ingredient) => {
            return updateIngredients?.items.some(
              (item) => item.id === ingredient.id
            );
          }
        );

        setNewIngredients(filteredIngredients);
        setIngredientsItem([...updateIngredients?.items]);
      }
    }
  }

  function getUserCurrentGender() {
    const gender = localStorage.getItem("gender_weight");
    const currentGender = JSON.parse(gender);
    const genderValue = currentGender?.gender;
    const userWeightValue = currentGender?.weight;
    const breakWeight = userWeightValue?.match(/\d+/g);
    if (genderValue?.toLowerCase() === "female") {
      const isForMale = originalProduct?.quantity?.male;
      const isForFemale = originalProduct?.quantity?.female;
      setWeightValue(femaleWeight);
      setWeight(findFemaleRangeByWeight(breakWeight[0]));
      setGender(genderValue);
      if (isForMale && isForFemale) {
        setWeightValue(femaleWeight);
        setGender(genderValue);
        setWeight(findFemaleRangeByWeight(breakWeight[0]));
        setGenderOption(["Female", "Male", "Other"]);
      } else if (isForFemale) {
        setGender("Female");
        setWeight("50-100 (LBS)");
        setWeightValue(femaleWeight);
        setGenderOption(["Female"]);
      } else {
        setWeight(findMaleRangeByWeight(breakWeight[0]));
        setWeightValue(maleWeight);
        setGender(genderValue);
        setGenderOption(["Male", "Other"]);
      }
    } else {
      const isForMale = originalProduct?.quantity?.male;
      const isForFemale = originalProduct?.quantity?.female;

      if (isForMale && isForFemale) {
        setWeightValue(maleWeight);
        setGender(genderValue);
        setWeight(findMaleRangeByWeight(breakWeight[0]));
        setGenderOption(["Female", "Male", "Other"]);
      } else if (isForFemale) {
        setGender("Female");
        setWeight("50-100 (LBS)");
        setWeightValue(femaleWeight);
        setGenderOption(["Female"]);
      } else {
        setWeight(findMaleRangeByWeight(breakWeight[0]));
        setWeightValue(maleWeight);
        setGender(genderValue);
        setGenderOption(["Male", "Other"]);
      }

      // if(isForMale){
      //   if(originalProduct?.quantity?.female){

      //     setWeight(findMaleRangeByWeight(breakWeight[0]));
      //     setWeightValue(maleWeight);
      //     setGender(genderValue);
      //   }else{

      //     setWeight(findMaleRangeByWeight(breakWeight[0]));
      //     setWeightValue(maleWeight);
      //     setGender(genderValue);
      //     setGenderOption(["Male" , "Other"])
      //   }

      // }else{

      //   setGender("Female");
      //   setWeight("50-100 (LBS)")
      //   setWeightValue(femaleWeight);
      //   setGenderOption(["Female"])

      // }
    }
  }

  // useEffect for get current user Gender
  useEffect(() => {
    // below condition is for when user try to update product and add ingredients from intitial
    if (!updateProduct || updateIngredients?.items?.length === 0) {
      if (!productMixing) {
        getUserCurrentGender();
      }
    }
  }, []);

  function filterMixIngredients(selectedGender) {
    const isMixing =
      updateProduct &&
      !updateProduct?.hasOwnProperty("ingredient") &&
      !updateProduct?.hasOwnProperty("product");
    if (isMixing) {
      if (
        updateIngredients?.gender?.toLowerCase() ===
        selectedGender?.toLowerCase()
      ) {
        changePositionIngredients();
      } else {
        // below filter condition for when some ingredients not for specific gender
        const data = additionalIngredients?.filter((item) => {
          if (selectedGender?.toLowerCase() === "female") {
            return item?.quantity?.female?.length > 0;
          } else {
            return item?.quantity?.male?.length > 0;
          }
        });

        // Create a new array combining filtered and removed ingredients
        const newListOfIngredients = [
          ...data?.sort((a, b) => a.title.localeCompare(b.title)),
        ];

        // Update the state with the new list of ingredients
        setListOfIngredients(newListOfIngredients);
      }
    }
  }
  //  Select Dropdown Handler
  const selectHandler = (v) => {
    setNewIngredients([]);

    if (
      (updateProduct && updateProduct?.hasOwnProperty("product")) ||
      productMixing
    ) {
      setIngredientsItem([]);
    }

    if (v.name === "gender") {
      const gender = v.value;

      // below function for filter ingredients base on gender
      filterMixIngredients(gender);

      if (gender === "Female") {
        if (updateProduct) {
          if (updateIngredients?.gender?.toLowerCase() === "female") {
            updateProductValues();
            setGender(updateIngredients?.gender);
            setWeightValue(femaleWeight);
          } else {
            updateProductValues();
            setGender(updateIngredients?.gender);
            setWeightValue(maleWeight);
          }
        }
        setWeight("50-100 (LBS)");
        setWeightValue(femaleWeight);
      } else {
        setWeight("80-140 (LBS)");
        setWeightValue(maleWeight);
      }
      setGender(v.value);
    } else {
      // setNewIngredients([]);
      getRangeValue(gender, v.value);
      setWeight(v.value);
    }
  };

  // below function for product( type ingredients)
  const getRangeValue = (selectedGender, genderWeight) => {
    if (selectedGender?.toLowerCase() === "female") {
      // const weight = genderWeight.match(/\d+/g);
      const weightValue = genderWeight?.match(/\d+/g);
      const rangeArray = preIngredientQuantity?.female;
      let matchedWeight = rangeArray?.find((_w) => {
        const weightRangeValue = _w?.weightRange.match(/\d+/g);
        return (
          weightRangeValue[1] == weightValue[1] &&
          weightRangeValue[2] == weightValue[2]
        );
      });

      setPreIngredientDoseRange(matchedWeight);
    } else {
      const rangeArray = preIngredientQuantity?.male;
      const weightValue = genderWeight?.match(/\d+/g);
      let matchedWeight = rangeArray?.find((_w) => {
        const weightRangeValue = _w?.weightRange.match(/\d+/g);
        return (
          weightRangeValue[1] == weightValue[1] &&
          weightRangeValue[2] == weightValue[2]
        );
      });
      setPreIngredientDoseRange(matchedWeight);
    }
  };

  // handleCheck

  const handleCheck = (item) => {
    const value = {
      id: "",
      type: "",
      quantity: "",
      price: "",
    };
    if (ingredientsItem.length > 0) {
      const findValue = ingredientsItem?.find((itemed) => {
        return itemed?.id === item?.id;
      });
      if (findValue) {
        const data = ingredientsItem?.filter((itemValue) => {
          return itemValue?.id !== item.id;
        });
        setIngredientsItem(data);
      }
    }

    if (newIngredients?.length > 0) {
      const findValue = newIngredients?.find((itemed) => {
        return itemed?.id === item?.id;
      });

      if (findValue) {
        const data = newIngredients?.filter((itemValue) => {
          return itemValue?.id !== item.id;
        });
        setNewIngredients(data);
      } else {
        setNewIngredients([
          ...newIngredients,
          {
            ...item,
            maximumDose: getDose(weight, item?.quantity)?.recommendedDose,
          },
        ]);
        setIngredientsItem([
          ...ingredientsItem,
          {
            ...value,
            id: item?.id,
            type: item?.quantity.type,
            price: item?.quantity?.price,
            quantity: getDose(weight, item?.quantity)?.recommendedDose,
          },
        ]);
      }
    } else {
      setNewIngredients([
        ...newIngredients,
        {
          ...item,
          maximumDose: getDose(weight, item?.quantity)?.recommendedDose,
        },
      ]);
      setIngredientsItem([
        ...ingredientsItem,
        {
          ...value,
          id: item?.id,
          type: item?.quantity.type,
          price: item?.quantity?.price,
          quantity: getDose(weight, item?.quantity)?.recommendedDose,
        },
      ]);
    }
  };

  const skiphandler = () => {
    const ingredients = {
      gender: gender,
      items: ingredientsItem,
      weightRange: weight,
    };
    dispatch(storeMixedProduct(ingredients));
    handleSkip();
  };

  //* hanlde Range Change
  const handleRangeSlide = (rangeValue, data) => {
    if (!updateProduct) {
      if (originalProduct.id === data?.id) {
        setIngredientDose(rangeValue);
      }
    }
    const updateRange = ingredientsItem?.map((i) => {
      return data?.id === i.id ? { ...i, quantity: rangeValue } : i;
    });
    setIngredientsItem(updateRange);
  };

  useEffect(() => {
    if (originalProduct?.type === "ingredient") {
      if (weight) {
        setIngredientDose(
          getDose(weight, originalProduct?.quantity)?.recommendedDose
        );
      }
    }
  }, [weight, gender]);

  // below useEffect only for Get Pre ingredient Product
  useEffect(() => {
    if (originalProduct) {
      const getUserPreIngredientDose = (data) => {
        const updateRange = ingredientsItem?.find((i) => {
          return data?.id === i.id;
        });
        return updateRange?.quantity;
      };
      setIngredientDose(getUserPreIngredientDose(originalProduct));
    }
  }, []);

  // below function for get maximum range while user select additional ingredients
  const getUserDoseSelected = (data) => {
    const updateRange = ingredientsItem?.find((i) => {
      return data?.id === i.id;
    });
    return updateRange?.quantity;
  };

  useEffect(() => {
    if (!updateProduct) {
      if (gender && weight) {
        getRangeValue(gender, weight);
      }
    }
  }, [gender, weight]);

  const getDose = (genderWeight, quantity) => {
    if (genderWeight) {
      if (gender?.toLowerCase() === "female") {
        const weightValue = genderWeight?.match(/\d+/g);
        const singleDigitWeight = weightValue?.length === 1;
        const rangeArray = quantity?.female;
        let matchedWeight = rangeArray?.find((_w) => {
          const weightRangeValue = _w?.weightRange.match(/\d+/g);
          if (singleDigitWeight) {
            return weightRangeValue[1] == weightValue[1];
          } else {
            return (
              weightRangeValue[1] == weightValue[1] &&
              weightRangeValue[2] == weightValue[2]
            );
          }
        });
        return matchedWeight;
      } else {
        const rangeArray = quantity?.male;
        const weightValue = genderWeight?.match(/\d+/g);

        const singleDigitWeight = weightValue?.length === 1;
        let matchedWeight = rangeArray?.find((_w) => {
          const weightRangeValue = _w?.weightRange.match(/\d+/g);

          if (singleDigitWeight) {
            return weightRangeValue[1] == weightValue[1];
          } else {
            return (
              weightRangeValue[1] == weightValue[1] &&
              weightRangeValue[2] == weightValue[2]
            );
          }
        });
        return matchedWeight;
      }
    }
  };

  const isFlavourChecked = (productItem) => {
    const value = newIngredients?.some(
      (element) => element.id === productItem?.id
    );
    return value;
  };

  // below useEffects for add data into state while ingredient product

  useEffect(() => {
    if (originalProduct?.type === "ingredient") {
      const item = {
        id: originalProduct?.id,
        quantity: ingredientDose,
        price: originalProduct?.quantity?.price,
        type: originalProduct?.quantity?.type,
      };

      setIngredientsItem((prev) => {
        if (Array.isArray(prev) && prev.length > 0) {
          let obj = prev.find((i) => i.id === item.id);
          if (obj) {
            return prev.map((i) =>
              i.id === item.id ? { ...i, quantity: item.quantity } : i
            );
          } else {
            return [...prev, item];
          }
        } else {
          return [item];
        }
      });

      setNewIngredients((prev) => {
        if (Array.isArray(prev) && prev.length > 0) {
          let obj = prev.find((i) => i.id === item.id);
          if (obj) {
            return prev.map((i) =>
              i.id === item.id ? { ...i, quantity: item.quantity } : i
            );
          } else {
            return [...prev, item];
          }
        } else {
          return [item];
        }
      });

      getRangeValue(gender, weight);
    }
  }, [originalProduct, gender, weight, ingredientDose]);

  const saveHandler = () => {
    // if (ingredientsItem?.length > 0) {
    const ingredients = {
      gender: gender,
      items: ingredientsItem,
      weightRange: weight,
    };

    dispatch(storeMixedProduct(ingredients));
    handleNext();
    // } else {
    //   errorAlert("All fields required");
    // }
  };

  // Filtering additionalIngredients based on the search query

  const filteredIngredients = listOfIngredients?.filter((item) => {
    return item.title?.toLowerCase().includes(searchQuery?.toLowerCase());
  });

  // below function when user click No while (asking of ingredients)

  const saveWithoutIngredients = () => {
    if (newIngredients.length > 0) {
      const ingredients = {
        gender: gender,
        items: ingredientsItem,
        weightRange: weight,
      };
      dispatch(storeMixedProduct(ingredients));
      handleNoExtraIngredients();
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // get range value of Ingredient Product while update the Range

  useEffect(() => {
    if (updateProduct) {
      const objectFined = ingredientsItem?.find((itemId) => {
        return updateProduct?.ingredient?.id === itemId.id;
      });
      setUpdateProductRangValue(objectFined?.quantity);
    }
  }, [ingredientsItem, updateProduct]);

  const handleInputRangeChange = (event, data, maxDose) => {
    const { value } = event.target;
    const numericRegex = /^\d*\.?\d*$/;

    if (!numericRegex.test(value)) return;
    if (value > maxDose) return;
    handleRangeSlide(value, data);
  };
  const handleBlurRangeChange = (event, data, minDose) => {
    const { value } = event.target;
    if (value <= minDose) {
      handleRangeSlide(minDose, data);
    }
  };

  const updateValueHandleChange = (event, maxDose) => {
    const { value } = event.target;
    const numericRegex = /^\d*\.?\d*$/;

    if (!numericRegex.test(value)) return;

    if (value > maxDose) return;
    if (value > 0) {
      const updatePreIngredientQuantity = ingredientsItem?.map((i) => {
        return updateProduct?.ingredient?.id === i?.id
          ? { ...i, quantity: parseFloat(value) }
          : i;
      });

      setIngredientsItem(updatePreIngredientQuantity);
    }

    setUpdateProductRangValue(value);
  };
  const updateBlurChange = (event, minDose) => {
    const { value } = event.target;
    const numericRegex = /^\d*\.?\d*$/;

    if (!numericRegex.test(value)) return;

    if (value <= minDose) {
      setUpdateProductRangValue(minDose);
    }
  };

  const handleOriginalProductRange = (event, maxDose) => {
    const { value } = event.target;
    const numericRegex = /^\d*\.?\d*$/;

    if (!numericRegex.test(value)) return;

    if (value > maxDose) return;

    setIngredientDose(value);
  };
  const handleBlurOriginalProductRange = (event, minDose) => {
    const { value } = event.target;
    const numericRegex = /^\d*\.?\d*$/;

    if (!numericRegex.test(value)) return;

    if (value <= minDose) {
      setIngredientDose(minDose);
    }
  };

  return (
    <div className="payment-form-overlay">
      <div className="addmix-ingredients-main">
        {isLoading ? (
          <div
            style={{
              display: "flex",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <LoaderOne />
          </div>
        ) : (
          <>
            <div className="flavor-head">
              {isExtraIngredients ? (
                <>
                  <h2>
                    Add mixing ingredients <p>Step 1/3</p>
                  </h2>

                  {ingredientsItem?.length === 0 &&
                    originalProduct?.type !== "ingredient" && (
                      <SkipButton skipHandler={skiphandler} />
                    )}

                  <div className="search-bar">
                    <input
                      type="search"
                      placeholder="Search ingredients..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                    <span>
                      <img src="images/search_icon.svg" alt="" />
                    </span>
                  </div>
                </>
              ) : (
                <h2> Adjust your mix</h2>
              )}

              <div className="gender-btn">
                <CustomDropdown
                  data={genderOption}
                  name="gender"
                  value={gender}
                  handleChange={selectHandler}
                />
                <CustomDropdown
                  className="lbs-btn"
                  data={weightValue}
                  name="weight"
                  value={weight}
                  handleChange={selectHandler}
                />
              </div>
            </div>
            <div className="addmix-ingredients-head">
              <h3>Ingredients</h3>
              <span>
                Min/max <span className="per-day">(per day)</span>
              </span>
            </div>

            <div className="mix-ingrediants-range-main">
              <div className="mix-ingrediants-range">
                {updateProduct &&
                  updateProduct.hasOwnProperty("ingredient") && (
                    <div className="flavor-select">
                      <div className="flavor-category">
                        <div className="field">
                          <input type="checkbox" checked />
                          <label>
                            {textElips(updateProduct?.ingredient?.title, 45)}
                          </label>
                        </div>
                      </div>
                      <div className="dose">
                        <div className="range">
                          {/* <div className="input-range"> */}
                          <div className="input-range">
                            <CustomRange
                              handleRangeChange={handleRangeSlide}
                              doseRange={preIngredientDoseRange}
                              data={updateProduct?.ingredient}
                              setIngredientsItem={setIngredientsItem}
                              updateProduct={updateProduct}
                              updatedRangeValue={updateProductRangeValue}
                              // updateProduct={null}
                            />
                            <p>
                              <span className="min-range">
                                {preIngredientDoseRange?.minDose}
                              </span>
                              <span className="max-range">
                                {preIngredientDoseRange?.maxDose}
                              </span>
                            </p>
                          </div>

                          <div className="range-count">
                            <div className="extra-ingredients-input">
                              <input
                                style={{ border: "none" }}
                                type="text"
                                value={`${updateProductRangeValue}`}
                                onChange={(event) =>
                                  updateValueHandleChange(
                                    event,
                                    preIngredientDoseRange?.maxDose
                                  )
                                }
                                onBlur={(event) =>
                                  updateBlurChange(
                                    event,
                                    preIngredientDoseRange?.minDose
                                  )
                                }
                              />

                              <input
                                disabled
                                style={{
                                  border: "none",
                                  width: "50px",
                                  height: "30px",
                                  borderRadius: "6px",
                                  backgroundColor: "transparent",
                                }}
                                type="text"
                                name=""
                                // onChange={handleChange}
                                value={`${preIngredientQuantity?.type} * 30`}
                              />
                            </div>

                            <span>
                              <img src="images/info_icon.svg" alt="" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                {originalProduct?.type === "ingredient" && (
                  <div className="flavor-select">
                    <div className="flavor-category">
                      <div className="field">
                        <input type="checkbox" checked />
                        <label>
                          {textElips(originalProduct?.product?.title, 45)}
                        </label>
                      </div>
                    </div>
                    <div className="dose">
                      <div className="range">
                        {/* <div className="input-range"> */}
                        <div className="input-range">
                          <CustomRange
                            handleRangeChange={handleRangeSlide}
                            doseRange={preIngredientDoseRange}
                            data={originalProduct}
                            setIngredientsItem={setIngredientsItem}
                            // updateProduct={null}
                            updatedRangeValue={ingredientDose}
                          />
                          <p>
                            <span className="min-range">
                              {preIngredientDoseRange?.minDose}
                            </span>
                            <span className="max-range">
                              {preIngredientDoseRange?.maxDose}
                            </span>
                          </p>
                        </div>

                        <div className="range-count">
                          <div className="extra-ingredients-input">
                            <input
                              style={{ border: "none" }}
                              type="text"
                              value={`${ingredientDose}`}
                              onChange={(event) =>
                                handleOriginalProductRange(
                                  event,
                                  preIngredientDoseRange?.maxDose
                                )
                              }
                              onBlur={(e) =>
                                handleBlurOriginalProductRange(
                                  e,
                                  preIngredientDoseRange?.minDose
                                )
                              }
                            />

                            <input
                              disabled
                              style={{
                                border: "none",
                                width: "50px",
                                height: "30px",
                                borderRadius: "6px",
                                backgroundColor: "transparent",
                              }}
                              type="text"
                              name=""
                              value={`${preIngredientQuantity?.type} * 30`}
                            />
                          </div>

                          <span>
                            <img src="images/info_icon.svg" alt="" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {cancelClick === "Yes" ? (
                  filteredIngredients?.length === 0 &&
                  searchQuery &&
                  originalProduct?.type !== "ingredient" &&
                  !updateProduct?.hasOwnProperty("ingredient") ? (
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "8px",
                      }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                        }}>
                        <img src="/images/noingredients.png" alt="No search" />{" "}
                        <span style={{ fontSize: 20, fontWeight: 500 }}>
                          No result found!
                        </span>
                      </div>
                    </div>
                  ) : (
                    <>
                      {filteredIngredients?.map((item) => {
                        return (
                          <div className="flavor-select" key={item?.id}>
                            <div className="flavor-category">
                              <div className="field">
                                <input
                                  type="checkbox"
                                  onChange={() => handleCheck(item)}
                                  checked={isFlavourChecked(item)}
                                />
                                <label>{item.title}</label>
                              </div>
                            </div>
                            {newIngredients?.length > 0 &&
                              newIngredients?.map((i) => {
                                let finedValue = null;
                                if (updateIngredients) {
                                  finedValue = updateIngredients?.items?.find(
                                    (findObject) => findObject?.id === i.id
                                  );
                                } else {
                                  finedValue = productMixing?.items?.find(
                                    (findObject) => findObject?.id === i.id
                                  );
                                }

                                const quantityRange = ingredientsItem.filter(
                                  (objQuantity) => {
                                    return objQuantity.id === i.id;
                                  }
                                );

                                return (
                                  <>
                                    {item?.id == i.id && (
                                      <div className="dose">
                                        <div className="range">
                                          <div className="input-range">
                                            <CustomRange
                                              handleRangeChange={
                                                handleRangeSlide
                                              }
                                              doseRange={getDose(
                                                weight,
                                                i?.quantity
                                              )}
                                              data={i}
                                              setIngredientsItem={
                                                setIngredientsItem
                                              }
                                              updateProduct={updateProduct}
                                              updatedRangeValue={
                                                finedValue?.quantity
                                              }
                                              anotherValue={
                                                quantityRange[0]?.quantity
                                              }
                                            />
                                            <p>
                                              <span className="min-range">
                                                {
                                                  getDose(weight, i?.quantity)
                                                    ?.minDose
                                                }
                                              </span>
                                              <span className="max-range">
                                                {
                                                  getDose(weight, i?.quantity)
                                                    ?.maxDose
                                                }
                                              </span>
                                            </p>
                                          </div>

                                          <div className="range-count">
                                            <div className="extra-ingredients-input">
                                              <input
                                                style={{ border: "none" }}
                                                type="text"
                                                value={`${getUserDoseSelected(
                                                  i
                                                )}`}
                                                onChange={(e) =>
                                                  handleInputRangeChange(
                                                    e,
                                                    i,
                                                    getDose(weight, i?.quantity)
                                                      ?.maxDose
                                                  )
                                                }
                                                onBlur={(e) =>
                                                  handleBlurRangeChange(
                                                    e,
                                                    i,
                                                    getDose(weight, i?.quantity)
                                                      ?.minDose,
                                                    getDose(weight, i?.quantity)
                                                      ?.maxDose
                                                  )
                                                }
                                              />

                                              <input
                                                disabled
                                                style={{
                                                  border: "none",
                                                  width: "50px",
                                                  height: "30px",
                                                  borderRadius: "6px",
                                                  backgroundColor:
                                                    "transparent",
                                                }}
                                                type="text"
                                                name=""
                                                // onChange={handleChange}
                                                value={`${i?.quantity?.type} * 30`}
                                              />
                                            </div>
                                            {/* <input
                                            type="text"
                                            value={`${getUserDoseSelected(i)}${
                                              i?.quantity?.type
                                            } * 30 `}
                                            disabled
                                          /> */}
                                            <span>
                                              <img
                                                src="images/info_icon.svg"
                                                alt=""
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                );
                              })}
                          </div>
                        );
                      })}
                    </>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="flavor-btn">
              <button className="cancle-btn" onClick={handlePrev}>
                Cancel
              </button>
              <button
                className="save-btn"
                onClick={
                  !isExtraIngredients ? saveWithoutIngredients : saveHandler
                }>
                Save & Next
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MixingIngredients;
