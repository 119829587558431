import React from "react";
import Contact from "../component/ContactUs";
import Faq from "../component/Faq";
import FoundProduct from "../component/FoundProduct";
import Home from "../component/Home";
import Mix from "../component/Mix";
import Product from "../component/Product";
import ProductDetail from "../component/ProductDetail";
import AccountSettings from "../pages/AccountSettings";
import Cart from "../pages/Cart/Cart";
import LeaveReview from "../pages/LeaveReview/LeaveReview";
import HomePage from "../pages/home/HomePage";

export const baseRoutes = [
  {
    name: "Home",
    path: "/",
    component: <HomePage />,
  },
  {
    name: "product",
    path: "/product",
    component: <Product />,
  },
  {
    name: "product",
    path: "/product/detail/:id",
    component: <ProductDetail />,
  },
  {
    name: "product",
    path: "/product-found",
    component: <FoundProduct />,
  },
  {
    name: "faq",
    path: "/faq",
    component: <Faq />,
  },
  {
    name: "mix",
    path: "/mix",
    component: <Mix />,
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: <Contact />,
  },

  {
    name: "account-setting",
    path: "/account-setting",
    component: <AccountSettings />,
  },
  {
    name: "cart",
    path: "/cart",
    component: <Cart />,
  },
  {
    name: "leaveReview",
    path: "/leave-a-review",
    component: <LeaveReview />,
  },
];

export default baseRoutes;
