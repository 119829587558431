import React, { Fragment, useEffect, useState } from "react";
import {
  addToFavoriteProducts,
  getAllProducts,
  getAllProductsNew,
} from "../Auth/product";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../layout/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading } from "../store/slices/common";
import { setAddToFavorite, setProductData } from "../store/slices/productSlice";
import ProductFilter from "../pages/ProductFilters";
import { getPriceTrunc, getToken, productPrice } from "../utils/common";
import MixLoader from "../layout/MixLoader";
import {
  addtoCartProduct,
  askForIngredients,
  getIngredients,
  handleEmptyMixing,
  ingredientsVisibility,
  rejectIngredients,
} from "../store/slices/mixingIngredients/ingredientsSlice";
import { setOpenLogin } from "../store/slices/Auth";
import Pagination from "@mui/material/Pagination";
import { Stack } from "@mui/material";
import IllustrationNoProduct from "./common/IllustrationNoProduct";
import NoProductFound from "../assets/images/noingredients.png";
import { textElips } from "../../src/utils/common/index";

const Product = () => {
  const isLoading = useSelector((state) => state.common.isLoading);
  const productData = useSelector((state) => state.product.productData);
  const addToFavorite = useSelector((state) => state.product.addToFavorite);
  const { supplier } = useSelector((state) => state.product);
  const currentUser = useSelector((state) => state.login);
  const {
    rating = "",
    availability = "",
    category = "",
    minPrice,
    rangeValue,
  } = useSelector((state) => state.product);

  const [page, setPage] = useState({ page: 1 });
  const [perPage, setPerPage] = useState(12);
  const [totalPages, setTotalPages] = useState(3);
  const [totalProducts, setTotalProducts] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const { search } = useLocation();

  useEffect(() => {
    if (search) {
      const searchValue = search.replace("?", "");
      setSearchTerm(searchValue);
      fetchData(searchValue);
    }
  }, [search]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ingredientsVisibility(false));
    dispatch(handleEmptyMixing());
  }, []);

  const fetchData = async (searchValue) => {
    const storedData = localStorage.getItem("gender_weight");
    const { gender, weight } = JSON.parse(storedData) ?? {
      gender: "",
      weight: "",
    };

    if (currentUser && currentUser?.email) {
      const doctorId = supplier.join(",");
      try {
        const response = await getAllProducts(
          searchValue,
          availability,
          category,
          doctorId,
          rating,
          minPrice,
          rangeValue,
          page.page,
          perPage,
          gender,
          weight,
          currentUser?.id
        );
        const { data } = response;
        dispatch(setProductData(data));
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setIsLoading(false));
      }
    } else {
      try {
        const doctorId = supplier.join(",");

        const response = await getAllProductsNew(
          searchValue,
          availability,
          category,
          doctorId,
          rating,
          minPrice,
          rangeValue,
          page.page,
          perPage,
          gender,
          weight
        );
        const { data } = response;
        dispatch(setProductData(data));
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setIsLoading(false));
      }
    }
  };

  useEffect(() => {
    setPage({ page: 1 });
  }, [category, rating, supplier, rangeValue, minPrice]);

  useEffect(() => {
    const fetchDataProduct = async () => {
      dispatch(setIsLoading(true));
      await fetchData(searchTerm);
    };
    fetchDataProduct();
  }, [page, perPage, searchTerm]);

  useEffect(() => {
    if (productData) {
      // setPage(productData.page || 1);
      // setPerPage(productData.perPage || 1);
      setTotalProducts(productData.totalProducts || 0);
      const totalPages =
        Math.ceil(productData.totalProducts / productData.perPage) || 1;
      setTotalPages(totalPages);
    }
  }, [productData]);

  const handleClickDetail = (id) => {
    navigate(`/product/detail/${id}`);
  };

  const handlePageClick = (clickedPage) => {
    setPage({ page: clickedPage });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleAddToFavorite = async (id, value) => {
    if (!value) {
      await addToFavoriteProducts(currentUser?.id, id, "add");
    } else {
      await addToFavoriteProducts(currentUser?.id, id, "remove");
    }
  };

  const handleFavoriteData = async (event, id, value) => {
    event.stopPropagation();
    dispatch(setAddToFavorite(value));
    await handleAddToFavorite(id, value);
    await fetchData(searchTerm);
  };

  const handleOpenLoginPopUp = (event) => {
    event.stopPropagation();
    dispatch(setOpenLogin(true));
  };

  const handleCart = (e, data) => {
    let token = getToken();
    if (token) {
      e.stopPropagation();
      if (data?.productType === "Product") {
        dispatch(rejectIngredients(true));
        dispatch(addtoCartProduct(data));
        dispatch(handleEmptyMixing());
      } else {
        dispatch(askForIngredients(true));
        dispatch(getIngredients(data?.id));
        dispatch(addtoCartProduct(data));
        dispatch(handleEmptyMixing());
      }
    } else {
      dispatch(setOpenLogin(true));
    }
  };

  return (
    <Fragment>
      <section id="banner-main">
        <div className="banner-img mobile-hide-banner">
          <img src="images/product_page_img.jpeg" alt="" />
        </div>
        <div className="banner-text text-for-mobile">
          <div className="container">
            <div className="banner">
              <h1>Products</h1>
            </div>
          </div>
        </div>
      </section>
      <section id="product-page-main">
        <div className="container">
          <div className="filter-mix-main">
            <ProductFilter />
            <div className="mix-scartch-main">
              <div className="mix-scartch-head product-mix-list">
                <div className="mix-scratch-right">
                  <p>
                    Showing 1 -{" "}
                    {totalProducts < 12 ? totalProducts : perPage * page.page}{" "}
                    of {totalProducts} results{" "}
                  </p>
                </div>
              </div>
              {isLoading ? (
                <div>
                  <MixLoader />
                </div>
              ) : (
                <div className="mix-scratch mix-scratch-product">
                  <div className="product-page-hide">
                    {productData &&
                    productData?.products &&
                    productData?.products?.length === 0 ? (
                      <div style={{ paddingTop: 100 }}>
                        {" "}
                        <IllustrationNoProduct
                          tittle="No product found!"
                          imgLink={NoProductFound}
                          subTittle={null}
                        />{" "}
                      </div>
                    ) : (
                      <>
                        {productData &&
                          productData?.products &&
                          productData?.products?.map((data, idx) => (
                            <div
                              key={data?.id}
                              className="product-col product-col-main"
                              onClick={() => handleClickDetail(data?.id)}>
                              <div className="product">
                                {currentUser?.id ? (
                                  <button
                                    className="like-btn"
                                    onClick={(e) =>
                                      handleFavoriteData(
                                        e,
                                        data.id,
                                        data?.isFavorite
                                      )
                                    }>
                                    <img
                                      src={
                                        data?.isFavorite
                                          ? "/images/unlike_icon.svg"
                                          : "/images/like_icon.svg"
                                      }
                                      alt=""
                                    />
                                  </button>
                                ) : (
                                  <button
                                    className="like-btn"
                                    onClick={(e) => handleOpenLoginPopUp(e)}>
                                    <img
                                      src={
                                        data?.isFavorite
                                          ? "/images/unlike_icon.svg"
                                          : "/images/like_icon.svg"
                                      }
                                      alt=""
                                    />
                                  </button>
                                )}
                                <span className="product-img">
                                  <img
                                    src={
                                      data?.product?.images?.[0] ||
                                      "/images/placeholder_image.png"
                                    }
                                    alt=""
                                  />
                                </span>
                                <div className="detail">
                                  {data?.product?.category?.map(
                                    (item, index) => (
                                      <React.Fragment key={index}>
                                        <span>{item?.categoryName}</span>
                                        {index !==
                                          data?.product?.category?.length -
                                            1 && <span>,</span>}
                                      </React.Fragment>
                                    )
                                  )}
                                  <h3>{textElips(data?.product?.title, 45)}</h3>
                                  <span className="rating">
                                    <img src="images/star_icon.svg" alt="" />
                                    {/* {data?.review?.avg?.toFixed(1)} (0 reviews) */}
                                    {data?.review?.avg.toFixed(1)} (
                                    {data?.review?.star5 +
                                      data?.review?.star4 +
                                      data?.review?.star3 +
                                      data?.review?.star2 +
                                      data?.review?.star1}{" "}
                                    reviews)
                                  </span>
                                  <div style={{ fontSize: 12, marginTop: 8 }}>
                                    {" "}
                                    {data?.type === "product"
                                      ? "Price for 30 servings (Days)"
                                      : `Price for 1  ${data?.quantity?.type} * 30 servings (Days)`}{" "}
                                  </div>
                                </div>

                                <div className="product-detail">
                                  <span>
                                    $
                                    {data.type === "product"
                                      ? getPriceTrunc(data?.product?.salePrice)
                                      : getPriceTrunc(
                                          data?.ingredientPrice
                                            ?.salePricePerMonth
                                        )}
                                  </span>
                                  <button onClick={(e) => handleCart(e, data)}>
                                    <img src="images/cart_icon.svg" alt="" />
                                    Add to cart
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                      </>
                    )}
                  </div>
                  <div className="productpage-main-slider product-page-only">
                    <div className="product-page-slider">
                      <div>
                        {productData &&
                        productData?.products &&
                        productData?.products?.length === 0 ? (
                          <div style={{ paddingTop: 100 }}>
                            {" "}
                            <IllustrationNoProduct
                              tittle="No product found!"
                              imgLink={NoProductFound}
                              subTittle={null}
                            />{" "}
                          </div>
                        ) : (
                          <>
                            {productData?.products?.map((data, idx) => (
                              <div
                                key={data?.id}
                                className="product-col product-col-main"
                                onClick={() => handleClickDetail(data?.id)}>
                                <div className="product">
                                  {currentUser?.id ? (
                                    <button
                                      className="like-btn"
                                      onClick={(e) =>
                                        handleFavoriteData(
                                          e,
                                          data.id,
                                          data?.isFavorite
                                        )
                                      }>
                                      <img
                                        src={
                                          data?.isFavorite
                                            ? "/images/unlike_icon.svg"
                                            : "/images/like_icon.svg"
                                        }
                                        alt=""
                                      />
                                    </button>
                                  ) : (
                                    <button
                                      className="like-btn"
                                      onClick={(e) => handleOpenLoginPopUp(e)}>
                                      <img
                                        src={
                                          data?.isFavorite
                                            ? "/images/unlike_icon.svg"
                                            : "/images/like_icon.svg"
                                        }
                                        alt=""
                                      />
                                    </button>
                                  )}

                                  <span className="product-img">
                                    <img
                                      src={
                                        data?.product?.images?.[0] ||
                                        "images/placeholder_image.png"
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <div className="detail">
                                    {/* <span>{data?.product?.category}</span> */}
                                    {data?.product?.category?.map(
                                      (item, index) => (
                                        <React.Fragment key={index}>
                                          <span>{item?.categoryName}</span>
                                          {index !==
                                            data?.product?.category?.length -
                                              1 && <span>,</span>}
                                        </React.Fragment>
                                      )
                                    )}
                                    <h3>
                                      {textElips(data?.product?.title, 45)}
                                    </h3>
                                    <span className="rating">
                                      <img src="images/star_icon.svg" alt="" />
                                      {/* {data?.review?.avg?.toFixed(1)} (0
                                      reviews) */}
                                      {data?.review?.avg.toFixed(1)} (
                                      {data?.review?.star5 +
                                        data?.review?.star4 +
                                        data?.review?.star3 +
                                        data?.review?.star2 +
                                        data?.review?.star1}{" "}
                                      reviews)
                                    </span>
                                    <div style={{ fontSize: 12, marginTop: 8 }}>
                                      {" "}
                                      {data?.type === "product"
                                        ? "Price for 30 servings (Days)"
                                        : `Price for 1  ${data?.quantity?.type} * 30 servings (Days)`}{" "}
                                    </div>
                                  </div>
                                  <div className="product-detail">
                                    <span>
                                      $
                                      {data.type === "product"
                                        ? getPriceTrunc(
                                            data?.product?.salePrice
                                          ) // Fixed to 2 decimal places
                                        : getPriceTrunc(
                                            data?.ingredientPrice
                                              ?.salePricePerMonth
                                          )}
                                    </span>
                                    <button
                                      onClick={(e) => handleCart(e, data)}>
                                      <img src="images/cart_icon.svg" alt="" />
                                      Add to cart
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {productData &&
          productData?.products &&
          productData?.products?.length > 0 && (
            <div className="pagination">
              <Stack direction="row" spacing={2}>
                <Pagination
                  count={totalPages}
                  page={page.page}
                  onChange={(event, value) => handlePageClick(value)}
                  variant="outlined"
                  shape="rounded"
                  className="active-page"
                />
              </Stack>
            </div>
          )}
      </section>
    </Fragment>
  );
};

export default Product;
