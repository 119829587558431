import { Fragment, useEffect, useState } from "react";
import {
  setRange,
  setRating,
  setCategories,
  setCategory,
  setAllDoctors,
  setSupplier,
  setPriceLimit,
  setMaxPrice,
  setPriceMax,
  setRangeValue,
  setMinPrice,
} from "../store/slices/productSlice";
import { Range, getTrackBackground } from "react-range";
import { useDispatch, useSelector } from "react-redux";
import { getAllFilters, getAllFiltersNew } from "../Auth/product";

const ProductFilter = () => {
  const {
    priceMax,
    priceLimit,
    categories,
    allDoctors,
    supplier,
    category,
    genderModule,
    rating,
  } = useSelector((state) => state.product);
  const [checkedCategories, setCheckedCategories] = useState([]);
  const currentUser = useSelector((state) => state.login);
  const [range, setRange] = useState(Math.round(priceLimit?.max));
  const [rangeMin, setRangeMin] = useState(Math.round(priceLimit?.min));
  const [checkedRating, setCheckedRating] = useState("");
  const [showCategories, setShowCategories] = useState(false);
  const [showDoctorLists, setShowDoctorLists] = useState(false);
  const [showRating, setShowRating] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const isPageRefreshed = performance.navigation.type === 1;
    if (isPageRefreshed) {
      dispatch(setCategory([]));
      dispatch(setSupplier([]));
      dispatch(setRating(""));
    }
  }, []);

  useEffect(() => {
    getFilters();
  }, [genderModule]);

  const handleShowLists = (name) => {
    switch (name) {
      case "categoryList":
        setShowCategories(!showCategories);
        break;
      case "doctorList":
        setShowDoctorLists(!showDoctorLists);
        break;
      case "ratingList":
        setShowRating(!showRating);
        break;
      default:
        return;
    }
  };
        

  useEffect(() => {
    setRange(priceMax);
  }, [priceMax]);

  useEffect(() => {
    dispatch(setRangeValue(range));
    dispatch(setMinPrice(rangeMin));
  }, [range, rangeMin]);

  const getFilters = async () => {
    const storedData = localStorage.getItem("gender_weight");
    const { gender, weight } = JSON.parse(storedData) ?? {
      gender: "",
      weight: "",
    };
    if (currentUser && currentUser?.email) {
      try {
        const response = await getAllFilters(gender, weight);
        const { data } = response;
        dispatch(setCategories(data));
        dispatch(setAllDoctors(data));
        dispatch(setPriceLimit(data?.priceLimit));
        dispatch(setPriceMax(data?.priceLimit?.max));
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await getAllFiltersNew(gender, weight);
        const { data } = response;
        dispatch(setCategories(data));
        dispatch(setAllDoctors(data));
        dispatch(setPriceLimit(data?.priceLimit));
        dispatch(setPriceMax(data?.priceLimit?.max));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleChangeCategory = async (e) => {
    const { name, checked } = e.target;
    const updatedCategory = checked
      ? [...category, name]
      : category?.filter((cat) => cat !== name);
    dispatch(setCategory(updatedCategory));
  };

  const handleDoctorChange = async (e) => {
    const { value, checked } = e.target;
    const updatedDoctors = checked
      ? [...supplier, value]
      : supplier?.filter((doctor) => doctor !== value);
    dispatch(setSupplier(updatedDoctors));
  };

  const handleRatingChange = (e) => {
    const { name, checked } = e.target;
    setCheckedRating(checked ? name : "");
    if (checked) {
      dispatch(setRating(name));
    } else {
      dispatch(setRating(""));
    }
  };

  return (
    <Fragment>
      <div className="mix-filter-main">
        <h2>Filter by</h2>
        <div className="filter">
          <div className="gender product-category-main">
            <button onClick={() => handleShowLists("categoryList")}>
              Product categories <img src="images/angle_down.svg" alt="" />
            </button>
            {showCategories && (
              <>
                {categories &&
                  categories?.map((categoryItem) => (
                    <div className="input-field">
                      <input
                        type="checkbox"
                        className={`custom-checkbox ${
                          checkedCategories.includes(categoryItem)
                            ? "checked"
                            : ""
                        }`}
                        name={categoryItem}
                        onChange={handleChangeCategory}
                        checked={category.includes(categoryItem)}
                      />
                      <label>{categoryItem}</label>
                    </div>
                  ))}
              </>
            )}
          </div>
          {priceLimit && priceLimit?.min !== "" && priceLimit?.max !== "" && (
            <div className="height-weight  product-price-range">
              <button>Price Range </button>
              <div>
                <Range
                  onChange={(values) => {
                    setRangeMin(values[0]);
                    setRange(values[1]);
                  }}
                  values={[rangeMin, range]}
                  min={Math.floor(priceLimit?.min || 0)}
                  max={Math.round(priceLimit?.max || 0)}
                  step={1}
                  renderTrack={({ index, props, children }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        height: "6px",
                        width: "100%",
                        background: getTrackBackground({
                          values: [rangeMin, range],
                          max: Math.round(priceLimit?.max || 0),
                          min: Math.floor(priceLimit?.min || 0),
                          colors: [
                            `#ccc ${
                              ((rangeMin - priceLimit?.min) /
                                (priceLimit?.max - priceLimit?.min)) *
                              100
                            }%`,
                            "var(--yellow)",
                            `#ccc ${
                              ((range - priceLimit?.min) /
                                (priceLimit?.max - priceLimit?.min)) *
                              100
                            }%`,
                          ],
                        }),
                        borderRadius: 9999,
                      }}
                    >
                      {children}
                    </div>
                  )}
                  renderThumb={({ props, isDragged }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        height: "17px",
                        width: "17px",
                        borderRadius: "999px",
                        backgroundColor: "var(--yellow)",
                      }}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <span className="rangePrice">${Math.floor(rangeMin)}</span>
                <span className="rangePrice">${Math.round(range || 0)}</span>
              </div>
            </div>
          )}
          <div className="gender product-category-main">
            <button>
              Rating <img src="images/angle_down.svg" alt="" />
            </button>
            <div className="input-field">
              <input
                type="checkbox"
                className={`custom-checkbox ${
                  checkedCategories.includes(category) ? "checked" : ""
                }`}
                name="4"
                checked={rating === "4"}
                onChange={handleRatingChange}
              />
              <label>
                <span>
                  <img src="images/star_icon.svg" alt="" />
                </span>{" "}
                4 and above
              </label>
            </div>
            <div className="input-field">
              <input
                type="checkbox"
                className={`custom-checkbox ${
                  checkedCategories.includes(category) ? "checked" : ""
                }`}
                name="3"
                checked={rating === "3"}
                onChange={handleRatingChange}
              />
              <label>
                <span>
                  <img src="images/star_icon.svg" alt="" />
                </span>{" "}
                3 and above
              </label>
            </div>
            <div className="input-field">
              <input
                type="checkbox"
                className={`custom-checkbox ${
                  checkedCategories.includes(category) ? "checked" : ""
                }`}
                name="2"
                checked={rating === "2"}
                onChange={handleRatingChange}
              />
              <label>
                <span>
                  <img src="images/star_icon.svg" alt="" />
                </span>{" "}
                2 and above
              </label>
            </div>
            <div className="input-field">
              <input
                type="checkbox"
                className={`custom-checkbox ${
                  checkedCategories.includes(category) ? "checked" : ""
                }`}
                name="1"
                checked={checkedRating === "1"}
                onChange={handleRatingChange}
              />
              <label>
                <span>
                  <img src="images/star_icon.svg" alt="" />
                </span>
                1 and above
              </label>
            </div>
          </div>
          <div className="gender product-category-main">
            <button onClick={() => handleShowLists("doctorList")}>
              Doctors <img src="images/angle_down.svg" alt="" />
            </button>
            {showDoctorLists && (
              <>
                {allDoctors &&
                  allDoctors?.map((doctor) => (
                    <div className="input-field">
                      <input
                        type="checkbox"
                        className={`custom-checkbox ${
                          supplier.includes(doctor?.id) ? "checked" : ""
                        }`}
                        name={doctor?.fullName}
                        value={doctor?.id}
                        onChange={handleDoctorChange}
                        checked={supplier.includes(doctor?.id)}
                      />
                      <label>{doctor?.fullName}</label>
                    </div>
                  ))}
              </>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductFilter;
