import React, { Fragment, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer} from "react-toastify";
import {
  addNewAddress,
  getAddressDetail,
  getAddressInfo,
  updateNewAddress
} from "../Auth/profile";
import {
  clearAddressFormData,
  setAddressDataById,
  setAddressFormData,
  setCloseAddAddress,
} from "../store/slices/common";
import Spinner from "../component/common/Spinner"
import { errorAlert } from "../component/common";

const AddNewAddress = ({ fetchAddressData }) => {
  const openAddAddress = useSelector((state) => state.common.openAddAddress);
  const addressFormData = useSelector((state) => state.common.addressFormData);
  const addressDataById = useSelector((state) => state.common.addressDataById);
  const currentUser = useSelector((state) => state.login);
  const [addressData, setAddressData] = useState("");
  const [newFields, setNewFields] = useState(false);
  const [isLoading , setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setAddressFormData({
        ...addressFormData,
        phone: {
          number: currentUser?.phone?.dialCode + currentUser?.phone?.number,
          dialCode:currentUser?.phone?.dialCode
        },
        country:{
          code:currentUser?.phone?.dialCode,
          countryCode:currentUser?.phone?.countryCode,
          name:currentUser?.country        
        }
      })
    );
  }, [currentUser, openAddAddress]);




  useEffect(() => {
    if (addressDataById) {
      setNewFields(true);

      dispatch(
        setAddressFormData({
          address: addressDataById?.address || "",
          addressType: addressDataById?.addressType || "",
          apartmentOrSuite: addressDataById?.apartmentOrSuite || "",
          latitude: addressDataById?.latitude,
          longitude: addressDataById?.longitude,
          city: addressDataById?.city || "",
          country: {
            code: addressDataById?.country?.code || "",
            dialCode: addressDataById?.phone?.dialCode || "",
            name: addressDataById?.country?.name || "",
          },
          name: addressDataById?.name || "",
          phone: {
            number: addressDataById?.phone?.dialCode + addressDataById?.phone?.number || "",
            dialCode: addressDataById?.phone?.dialCode || "",

          },
          pinCode: addressDataById?.pinCode || "",
          state: addressDataById?.state || "",
        })
      );
    }
  }, [addressDataById]);



  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    dispatch(setAddressFormData({ ...addressFormData, [name]: value }));
  };

  const validateFormData = (formData) => {
    if (!formData?.name) {
      return "Name is required";
    }
    if (!formData?.phone?.number) {
      return "Phone number is required";
    } else if (formData.phone.number.length < 10) {
      return "Phone number should be at least 10 characters long";
    }
    if (!formData?.address) {
      return "Address is required";
    }


    if (!formData?.city) {
      return "City is required";
    }
    if (!formData?.country?.name) {
      return "Country name is required";
    }

    if (!formData?.pinCode) {
      return "Pin code is required";
    }
    if (!formData?.state) {
      return "State is required";
    }
        if (!formData?.addressType) {
      return "Address type is required";
    }
    return null;
  };

  const handleAddressChange = async (e) => {
    const { name, value } = e.target;
    setNewFields(false);
    dispatch(setAddressFormData({ ...addressFormData, [name]: value }));
    if (name === "address") {
      try {
        const response = await getAddressInfo(value);
        const { data } = response?.data;
        setAddressData(data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleSelectCountry =  (value,country) => {
    dispatch(
      setAddressFormData({
        ...addressFormData,
        phone: {
          number: value,
          dialCode:country?.dialCode,
        },
        country: {
          dialCode:country?.dialCode,
          code: country?.countryCode,
          name: country?.name,
        }
      })
    );
  };


  const handleSelectAddress = async (selectedOption) => {
    setNewFields(true);
    try {
      const response = await getAddressDetail(selectedOption?.place_id);
      const { data } = response?.data;
      dispatch(
        setAddressFormData({
          ...addressFormData,
          address: selectedOption?.description,
          appartmentOrSuite: data?.apartment,
          city: data?.city,
          state: data?.state,
          pinCode: data?.pinCode,
          latitude: data?.latitude,
          longitude: data?.longitude,
          country: {
            code: data?.countryCode,
            dialCode: addressFormData?.country?.dialCode,
            name: data?.country,
            flag: addressFormData?.country?.flag,
          },
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleSave = async () => {
    const validationError = validateFormData(addressFormData);
    if (validationError) {
      errorAlert(validationError);
      return;
    }
    if (!addressFormData?.phone?.number) {
      errorAlert("phone number is required");
      return;
    }
   
    const data = {
      address: addressFormData?.address || "",
      addressType: addressFormData?.addressType || "",
      apartmentOrSuite: addressFormData?.apartmentOrSuite || "",
      city: addressFormData?.city || "",
      country: {
        code: addressFormData?.country?.code || "",
        dialCode: addressFormData?.phone?.dialCode || "",
        name: addressFormData?.country?.name || "",
      },
      latitude: String(addressFormData?.latitude) || "",
      longitude: String(addressFormData?.longitude) || "",
      name: addressFormData?.name || "",
      phone: {
        dialCode: addressFormData?.phone?.dialCode || "",
        number: addressFormData?.phone?.number || "",
      },
      pinCode: addressFormData?.pinCode || "",
      state: addressFormData?.state || "",
    };

    if (addressFormData?.phone?.number.startsWith(addressFormData?.phone?.dialCode)) {
      data.phone.number = addressFormData?.phone?.number.substring(addressFormData?.phone?.dialCode?.length);
    }



    try {
      if (addressDataById) {
        setIsLoading(true)
        await updateNewAddress(currentUser?.id, addressDataById?.id, data);
        dispatch(setAddressDataById(""));
        fetchAddressData();
        dispatch(setCloseAddAddress());
        dispatch(clearAddressFormData());
        setIsLoading(false)
      } else {
        setIsLoading(true)
        await addNewAddress(currentUser?.id, data);
        fetchAddressData();
        dispatch(setCloseAddAddress());
        dispatch(clearAddressFormData());
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error);
      // dispatch(clearAddressFormData());
    }
  };

  const handleCancel = () => {
    dispatch(setCloseAddAddress());
    dispatch(clearAddressFormData());
    dispatch(setAddressDataById(""));
    setNewFields(false);
    setAddressData("");
  };

  const handleAddressTypeChange = (e) => {
    const { value } = e.target;
    dispatch(setAddressFormData({ ...addressFormData, addressType: value }));
  };

  useEffect(() => {
    if (openAddAddress) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [openAddAddress]);

  return (
    <Fragment>
      <div
        style={{ position: "fixed" }}
        className={`overlay ${openAddAddress ? "active " : ""}`}
      />
      <div
        className={`add-address-main ${
          openAddAddress ? "address-main-active" : ""
        }`}>
        <div className="head">
          <h2>Add new address</h2>
          <button className="close-btn" onClick={handleCancel}>
            <img src="images/times_icon.svg" alt="" />
          </button>
        </div>
        <div className="card-details">
          <div className="input-field">
            <label for="">Name</label>
            <input
              type="text"
              name="name"
              placeholder="Your name"
              value={addressFormData?.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-field">
            <label>Phone Number</label>
            <div className="country-select">
              <PhoneInput
                value={addressFormData.phone.number}
                onChange={(value , country) => {handleSelectCountry(value , country)}}
                inputStyle={{ paddingLeft: "48px" }}
              />
            </div>
          </div>
          <div className="input-field">
            <label htmlFor="address">Address</label>
            <input
              type="text"
              name="address"
              placeholder="Type your address"
              value={addressFormData?.address}
              onChange={handleAddressChange}
            />
            {addressData?.length > 0 && (
              <div className="autocomplete-dropdown">
                {addressData?.map((address, index) => (
                  <div
                    key={index}
                    className="autocomplete-item"
                    onClick={() => handleSelectAddress(address)}>
                    {newFields ? "" : address?.description}
                  </div>
                ))}
              </div>
            )}
          </div>
          {newFields && (
            <>
              <div className="input-field">
                <label for="">Apartment or Suite(Optional)</label>
                <input
                  type="text"
                  name="apartmentOrSuite"
                  placeholder="Apartment or Suite(Optional)"
                  value={addressFormData?.apartmentOrSuite}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-field">
                <label for="">City</label>
                <input
                  type="text"
                  name="city"
                  placeholder="City"
                  value={addressFormData?.city}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-field">
                <label for="">State</label>
                <input
                  type="text"
                  name="state"
                  placeholder="State"
                  value={addressFormData?.state}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-field">
                <label for="">Pincode</label>
                <input
                  type="text"
                  name="pinCode"
                  placeholder="Pincode"
                  value={addressFormData?.pinCode}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-field">
                <label for="">Country</label>
                <input
                  type="text"
                  name="country"
                  placeholder="Country"
                  value={addressFormData?.country?.name}
                  onChange={handleInputChange}
                />
              </div>
            </>
          )}
          <div className="input-field">
            <label for="">Address type:</label>
            <div className="input-address">
              <input
                type="radio"
                name="addressType"
                value="Home"
                checked={addressFormData?.addressType === "Home"}
                onChange={handleAddressTypeChange}
              />
              <label for="">Home (All day delivery)</label>
            </div>
            <div className="input-address">
              <input
                type="radio"
                name="addressType"
                value="Work"
                checked={addressFormData?.addressType === "Work"}
                onChange={handleAddressTypeChange}
              />
              <label for="">Work (Delivery between 10 AM - 5 PM)</label>
            </div>
          </div>
        </div>
        <div className="card-btn">
          <button className="cancle-btn" onClick={handleCancel}>
            Cancel
          </button>
          <button className="save-btn" onClick={handleSave}>
            {
              isLoading ? <Spinner width={20} height={20}  /> : "  Save"
            }
          
          </button>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default AddNewAddress;
