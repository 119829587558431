import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import {
  getCountryCode,
  getProfile,
  updateProfile,
  updateProfileImage,
} from "../Auth/profile";
import {
  CustomToast,
  cmToFeetAndInches,
  convertHeightToCm,
  generateInitials,
} from "../component/common";
import { getUserDetails, loginAdmin } from "../store/slices/login";
import Picker from "react-scrollable-picker";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Spinner from "../component/common/Spinner"
import { setWeightLocalStorage } from "../utils/common/ingredientsUtils";

const PersonalDetail = () => {
  const initialFormValues = {
    name: '',
    email: '',
    password: '',
    phone: {
      countryCode: '',
      dialCode: '',
      number: '',
    },
    country: {
      code: '',
      dialCode: '',
      name: '',
    },
    gender: '',
    heightFt: '',
    heightIn: '',
    weight: '',
  }

  const [formData, setFormData] = useState(initialFormValues);
  const currentUser = useSelector((state) => state.login);
  const [profileImage, setProfileImage] = useState("");
  const inputFileRef = useRef(null);
  const dispatch = useDispatch();
  const [openPicker, setOpenPicker] = useState(false);
  const [userData, setUserData] = useState('');
  const [openPickerNew, setOpenPickerNew] = useState(false);
  const [isProfileLoaded, setIsProfileLoaded] = useState(false);
  const currentUserHeightInCm = Number(currentUser?.height);
  const heightInFtAndIn = currentUserHeightInCm
    ? cmToFeetAndInches(currentUserHeightInCm)
    : "";
  const [feet, inches] = heightInFtAndIn.split(" ");

  const [isLoading , setIsLoading] = useState(false)

  const weightOptions = [];
  for (let i = 1; i <= 500; i++) {
    weightOptions.push({ value: i.toString(), label: i.toString() });
  }


  useEffect(() => {
    setProfileImage(currentUser?.image)
    
  }, [currentUser?.image])


  useEffect(() => {
    dispatch(getUserDetails());
  }, [])

  const [valueGroups, setValueGroups] = useState({
    heightFt: feet,
    titleFt: "Ft",
    heightIn: inches,
    titleIn: "In",
  });


  const [valueGroupsNew, setValueGroupsNew] = useState({
    weight: "150",
    title: "lbs"
  });


  const optionGroups = {
    heightFt: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
      { value: "6", label: "6" },
      { value: "7", label: "7" },
    ],
    titleFt: [
      { value: "Ft", label: "Ft" },
    ],
    heightIn: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "5", label: "5" },
      { value: "6", label: "6" },
      { value: "7", label: "7" },
      { value: "8", label: "8" },
      { value: "9", label: "9" },
      { value: "10", label: "10" },
      { value: "11", label: "11" },
    ],
    titleIn: [
      { value: "In", label: "In" },
    ],
  };


  const optionGroupsNew = {
    weight: weightOptions,
    title: [
      { value: "lbs", label: "lbs" },
    ],
  };


  const handleChangePicker = (name, value) => {
    setValueGroups((prevValueGroups) => ({
      ...prevValueGroups,
      [name]: value
    }));
  };


  const handleChangePickerNew = (name, value) => {
    setValueGroupsNew((prevValue) => ({
      ...prevValue,
      [name]: value
    }));
  };


  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      heightFt: valueGroups?.heightFt + " " + " " + valueGroups?.titleFt,
      heightIn: valueGroups?.heightIn + " " + " " + valueGroups?.titleIn,
    }));
  }, [valueGroups]);


  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      weight: valueGroupsNew?.weight + " " + " " + valueGroupsNew?.title,
    }));
  }, [valueGroupsNew]);


  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      name: currentUser?.name || "",
      email: currentUser?.email || "",
      phone: {
        number:  currentUser?.phone?.dialCode + currentUser?.phone?.number,
        countryCode: currentUser?.phone?.countryCode || "",
        dialCode: currentUser?.phone?.dialCode || "",
      },
      country: {
        code: currentUser?.country?.code || "",
        dialCode: currentUser?.phone?.dialCode || "",
        name: currentUser?.country?.name || "",
      },
      gender: currentUser?.gender || "",
      weight: currentUser?.weight +" "+ "lbs" || "",
      heightFt: feet+" "+"Ft",
      heightIn: inches+" "+"In",
      
    }));
  }, [currentUser, feet, inches]);


  const handleImageChange = async (event) => {
    const file = inputFileRef.current.files[0];
    if (file) {
      try {
        setIsProfileLoaded(true)
        const userId = currentUser?.id;
        const {data} = await updateProfileImage(userId, file);
        const response = data?.user
        setProfileImage(response?.image);
        dispatch(
          loginAdmin({
            id: response.id,
            name: response.name,
            email: response.email,
            image: response.image,
            notifications: response.notifications,
            isLoggedIn: true,
            phone: response.phone,
            height: response.height,
            weight: response.weight,
            gender: response.gender,
            country: response.country,
            createdAt: response.createdAt,
            updatedAt: response.updatedAt,
          })
        );
      
        setIsProfileLoaded(false)
        CustomToast("Profile image updated successfully", "success");
      } catch (error) {
        setIsProfileLoaded(false)
        CustomToast(error.response.data.message, "error");
      }
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      (name === 'heightFt' && value >= 3 && value <= 9) ||
      (name === 'heightIn' && value >= 0 && value <= 11)
    ) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        height: {
          ...prevFormData.height,
          [name]: value,
        },
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };


  const handleUpdateProfile = async () => {
    const heightInCm = convertHeightToCm(formData?.heightFt, formData?.heightIn);
    const weightRange = formData?.weight?.split(' ')[0];
 
    const updatedData = {
      "fullName": formData.name,
      "email": formData.email,
      "gender": formData.gender,
      "height": Number(heightInCm),
      "weight": Number(weightRange),
      "phone": {
        "countryCode": formData?.phone?.countryCode,
        "dialCode": formData?.phone?.dialCode,
        "number": formData?.phone?.number
      }
    }
    if (formData?.phone?.number.startsWith(formData?.phone?.dialCode)) {
      updatedData.phone.number = formData?.phone?.number.substring(formData?.phone?.dialCode?.length);
    }
    try {
      setIsLoading(true)
      const response = await updateProfile(currentUser?.id, updatedData);
      const { user  , status} = response?.data
      dispatch(loginAdmin({ ...user, isLoggedIn: true }));


      if(status){
        setIsLoading(false)

        setWeightLocalStorage(user?.weight , user?.gender )
        

      CustomToast("User profile updated successfully", "success")

      }

    } catch (error) {
      setIsLoading(false)
      CustomToast(error.response.data.message, "error")
    }
  };

  const handleSelectCountry = (value,country) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: {
        ...prevFormData.phone,
        number: value,
        dialCode:country?.dialCode,
        countryCode:country?.countryCode
      },
    }));
  };

  const handleChangeHeight = () => {
    setOpenPicker(!openPicker)
    setOpenPickerNew(false)
  }
  const handleChangeWeight = () => {
    setOpenPickerNew(!openPickerNew)
    setOpenPicker(false)
  }

  
  return (
    <Fragment>
      <div className="profile">
        <div className="profile-img">
          {isProfileLoaded &&  <div className="spinner_overLay"> <Spinner width={18} height={18}  /> </div> }
          {profileImage ? (
           <img  src={`${profileImage}?timestamp=${Date.now()}`} alt="" />
          ) : (
            <div className="profile-img-initial">
              {generateInitials(currentUser?.name)}
            </div>
          )}
        </div>

        <ToastContainer />
        <div className="upload-img">
          <div className="img-load">
            <input
              type="file"
              ref={inputFileRef}
              onChange={handleImageChange}
            />
            <button>Upload new picture</button>
          </div>
        </div>
      </div>
      <div className="information">
        <h3>Personal information</h3>
        <div className="input-group">
          <div className="input-field">
            <label>Name</label>
            <input
              type="name"
              className="form-control"
              name="name"
              placeholder="Your name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="input-field">
            <label>Phone Number</label>
            <div className="country-select">
            <PhoneInput
                value={formData?.phone?.number}
                inputStyle={{ paddingLeft: '48px' }}
                onChange={(value , country) => {handleSelectCountry(value , country)}}
              />
            </div>
          </div>
        </div>
        <div className="input-group">
          <div className="input-field">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              name="email"
              placeholder="yourmail@example.com"
              value={formData.email}
              onChange={handleChange}
              disabled
            />
          </div>
          <div className="input-field">
            <div className="height">
              <div className="input-field">
                <label className="height-label">
                  Height (ft) <span>optional</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="height"
                  placeholder="Your height(in)"
                  value={formData?.heightFt + " " + " " + formData?.heightIn}
                  onClick={handleChangeHeight}
                />
              </div>
              <div className="input-field">
                <label>Weight (lbs)</label>
                <input
                  type="text"
                  className="form-control"
                  name="weight"
                  placeholder="Your weight(lbs)"
                  value={formData?.weight}
                  onClick={handleChangeWeight}
                />
              </div>
            </div>
            {openPicker &&
                <Picker
                  optionGroups={optionGroups}
                  valueGroups={valueGroups}
                  onChange={handleChangePicker}
                  className="picker-container"
                  style={{
                    marginTop: "10px !important" 
                  }}
                />
              }
              {openPickerNew &&
                <Picker
                  optionGroups={optionGroupsNew}
                  valueGroups={valueGroupsNew}
                  onChange={handleChangePickerNew}
                  className="picker-container"
                  style={{
                    marginTop: "10px !important" 
                  }}
                />
              }
          </div>
        </div>
        <div className="input-group">
          <div className="input-field">
            <label>Gender</label>
            <select
              type="text"
              className="select-btn"
              name="gender"
              value={formData.gender}
              onChange={handleChange}>
              <option value="" disabled>
                Select gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
      </div>
      <div className="setting-btn">
        <button className="update-btn" onClick={handleUpdateProfile}>
          {isLoading ? <Spinner width={18} height={18}  />  : " Update" }
        
        </button>
      </div>
    </Fragment>
  );
};
export default PersonalDetail;
