import React, { useState } from 'react';
import RatingComponent from '../Rating/RatingComponent';
import Spinner from "../../common/Spinner"

const PostReview = ({clickReview , starsCount , handleChange , reviewText , setReviewText , setClickReview , handleGiveReview , isLoading , handleNo}) => {


  return (
    <>
      <div
        style={{ position: "fixed" }}
        className={`overlay ${clickReview ? "active " : ""}`}
      />
      <div className={`rating_review-main ${clickReview ? "active " : ""}`}>
      
            <span>
              <img src="images/rate_review_icno.svg" alt="" />
            </span>
            <h2>Rate and Review</h2>
            <div className="input-field">
              <div className="input">
                <label>Rating ({starsCount}/5)</label>
                <div className="star-icons">
                  
                 <RatingComponent value={starsCount} handleChange={handleChange}/>
                </div>
              </div>
              <div className="input">
                <label>Review</label>
                <textarea
                  placeholder="Add your comments"
                  value={reviewText}
                  onChange={(e) => setReviewText(e.target.value)}
                />
              </div>
            </div>
            <div className="logout-btn">
              <button className="no-btn" onClick={handleNo}>
                No
              </button>
              <button className="yes-btn" onClick={handleGiveReview}>
              {isLoading ? <Spinner width={20} height={20} /> : "Post" }  
              </button>
            </div>
      </div>
      
    </>
  );
}

export default PostReview;
