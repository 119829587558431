import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../../component/common/Spinner";
import BillingShipping from "../../component/pagesComponents/checkout/BillingShipping";
import CheckoutHeader from "../../component/pagesComponents/checkout/CheckoutHeader";
import OrderSummary from "../../component/pagesComponents/checkout/OrderSummary";
import PaymentDetails from "../../component/pagesComponents/checkout/Payment/PaymentDetails";
import {
  emptyCartItems,
  emptyKeys,
  handlePickup,
  paymentHandle,
  proceedToCheckout,
} from "../../store/slices/cartSlice/cart";
import { selectDeliveryAddress } from "../../store/slices/checkoutSlice/checkout";
import { ingredientPrice, productPrice } from "../../utils/common";
import { errorAlert } from "../../component/common";
import { PaymentForm, CreditCard } from "react-square-web-payments-sdk";
import Payment from "../../component/pagesComponents/checkout/squarePayment/Payment";
import { getPaymentTypeUser } from "../../config/makeRequests/cartRequests";
import OrderCompletePopup from "../../component/common/OrderCompletePopup";

const Checkout = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [paymentModal, setPaymentModal] = useState(false);
  const [isOrderComplete, setIsOrderComplete] = useState(false);

  // redux hooks
  const dispatch = useDispatch();
  const { deliveryAddress } = useSelector((state) => state.checkoutSlice);
  const {
    cartItems,
    totalAmount,
    paymentLoading,
    stripeKeys,
    selfPickup,
    price,
  } = useSelector((state) => state.cart);

  const navigate = useNavigate();

  const getPaymentType = async () => {
    try {
      let res = await getPaymentTypeUser();
      if (res?.status) {
        if (res?.isCashPaymentUser) {
          dispatch(
            paymentHandle({
              data: {
                ...orderObject,
                cardSourceId: null,
                isCashPaymentUser: true,
              },
              paymentSuccess,
            })
          );
        } else {
          setPaymentModal(true);
        }
      }
    } catch (error) {
      console.log("errror", error);
    }
  };

  const getIngredientPrice = (itemObject) => {
    return Number(ingredientPrice(itemObject));
  };
  const getIngredientTax = (value) => {
    return Number((ingredientPrice(value) * price?.taxPercentage) / 100);
  };

  const totalIngredientPrice = (value) => {
    return Number(getIngredientPrice(value)) + Number(getIngredientTax(value));
  };

  const getProductPrice = (itemObject) => {
    return Number(productPrice(itemObject));
  };
  const getProductTax = (value) => {
    return Number((getProductPrice(value) * price?.taxPercentage) / 100);
  };

  const totalProductPrice = (value) => {
    return Number(getProductPrice(value)) + Number(getProductTax(value));
  };

  const result = cartItems?.map((value) => {
    if (value.hasOwnProperty("ingredient")) {
      return {
        ingredientId: value?.ingredient?.id,
        itemPrice: {
          subTotal: getIngredientPrice(value),
          tax: getIngredientTax(value),
          total: totalIngredientPrice(value),
        },
        mixFromScratchName: value?.mixFromScratchName || null,
        mixing: value?.mixing,
        offerDays: value?.offerDays,
        quantity: value?.quantity,
        // productType: value?.product?.productType,
      };
    } else {
      return {
        productId: value?.product?.id,
        itemPrice: {
          subTotal: Number(productPrice(value)),
          tax: Number(getProductTax(value)),
          total: totalProductPrice(value),
        },
        productType: value?.product?.productType,
        mixFromScratchName: value?.mixFromScratchName || null,
        mixing: value?.mixing,
        offerDays: value?.offerDays,
        quantity: value?.quantity,
      };
    }
  });

  const orderObject = {
    addressId: deliveryAddress?.id,
    items: result,
    orderPrice: {
      delivery: totalAmount?.shipping,
      mixing: totalAmount?.mixingPrice,
      packaging: totalAmount?.packagingPrice,
      subTotal: parseFloat(totalAmount?.subtotal),
      tax: parseFloat(totalAmount?.tax),
      total: parseFloat(totalAmount?.finalAmount),
    },
    selfPickup: selfPickup,
  };

  const paymentSuccess = () => {
    setPaymentModal(false);
    setIsOrderComplete(true);
  };

  const handleNext = () => {
    if (!deliveryAddress) {
      errorAlert("Please select address first");
      return;
    }
    if (activeStep === 1) {
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 2) {
      getPaymentType();
    }
  };
  const backHandler = () => {
    setActiveStep(activeStep - 1);
  };

  const cancelHandler = () => {
    dispatch(selectDeliveryAddress(null));
    dispatch(proceedToCheckout(false));
    dispatch(handlePickup(false));
  };

  const handleOk = () => {
    dispatch(emptyKeys());
    navigate("/");
    dispatch(selectDeliveryAddress(null));
    dispatch(proceedToCheckout(false));
    dispatch(handlePickup(false));
    dispatch(emptyCartItems());
  };

  return (
    <>
      {isOrderComplete && <OrderCompletePopup handleOk={handleOk} />}
      {paymentModal && (
        <Payment
          setPaymentModal={setPaymentModal}
          orderObject={orderObject}
          paymentSuccess={paymentSuccess}
        />
      )}

      <div className="cart-item-main checkout-shipping-main">
        <CheckoutHeader activeStep={activeStep} />
        {activeStep === 1 && <BillingShipping />}
        {activeStep === 2 && <OrderSummary />}

        <div className="checkout-btn">
          {activeStep === 1 ? (
            <Link to="/cart">
              {" "}
              <button className="cancel-btn" onClick={cancelHandler}>
                Cancel
              </button>{" "}
            </Link>
          ) : (
            <button className="cancel-btn" onClick={backHandler}>
              Back
            </button>
          )}

          <button
            className="save-btn"
            onClick={handleNext}
            disabled={paymentLoading}>
            {" "}
            {activeStep === 2 ? (
              paymentLoading && !paymentModal ? (
                <Spinner width={20} height={20} />
              ) : (
                "Confirm and Pay"
              )
            ) : (
              "Save & Next"
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default Checkout;
