import client from '.';

/**
 * Login
 * @param {string} email Email of user
 * @param {string} password Password of user
 * @returns Response of login
 */
const loginAPI = async (email, password) => {
  const res = await client.post('/api/users/login', { email, password });
  return res;
};

/**
 * Login
 * @param {string} email Email of user
 * @param {string} password Password of user
 * @returns Response of login
 */
const registerApi = async (registerData) => {
  const res = await client.post('/api/users/signup', registerData);
  return res;
};


/**
 * Forgot password
 * @param {string} email Email of doctor
 * @returns Response of Forgot Password
 */
const forgotPasswordAPI = async email => {
  const res = await client.post('/api/users/forgot-password', { email });
  return res;
};

/**
 * Verify OTP
 * @param {string} email Email of doctor
 * @param {string} otp OTP recieved on email
 * @returns Response of verify OTP
 */
const verifyOtpAPI = async (email, otp) => {
  const res = await client.post('/api/users/verify-otp', { email, otp });
  return res;
};

/**
 * Set Password
 * @param {string} email Email of doctor
 * @param {string} newPassword New password of doctor
 * @returns Response of Set Password
 */
const setPasswordAPI = async (email, newPassword) => {
  const res = await client.post('/api/users/set-password', {
    email,
    newPassword,
  });
  return res;
};

/**
 * Change Password
 * @param {string} doctorId Doctor Id
 * @param {string} curPassword Current Password
 * @param {string} newPassword New Password
 */
const changePassword = async (doctorId, curPassword, newPassword) => {
  const res = await client.put(`/api/doctor/auth/${doctorId}/change-password`, {
    currentPassword: curPassword,
    newPassword: newPassword,
  });
  return res;
};

/**
 * Verify Login
 */
async function verifyLoginAPI() {
  const token = localStorage.getItem('token');
  const res = await client.get('/api/doctor/auth/verify-login', {
    headers: { Authorization: `Bearer ${token}` },
  });
  return res;
}

export {
  loginAPI,
  registerApi,
  forgotPasswordAPI,
  verifyOtpAPI,
  setPasswordAPI,
  changePassword,
  verifyLoginAPI,
};
