import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import LoaderOne from "../../../layout/LoaderOne";
import { getSweeteners, storeMixedSweetness, updateCartProduct } from "../../../store/slices/mixingIngredients/ingredientsSlice";
import { errorAlert } from "../../common";
import SkipButton from "../../common/buttons/SkipButton";

const AddSweetener = ({ handleNext, handlePrev ,   cancelHandler, handleSkip , setShowSuccess , formCount}) => {
  const { sweeteners,  productSweetness , isProductAdded  , productMixing , productFlavour , updateProduct} = useSelector(
    (state) => state.ingredientsSlice
  );

  const [selectedSweeteners, setSelectedSweeteners] =
  useState(productSweetness || []);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSweeteners());
  }, [])


  const skipHandler = ()=>{
    let removePriceTag = [];
    if (productMixing?.items?.length > 0) {
        // Mapping over productMixing.items to create removePriceTag array
        const uniqueIds = new Set(); // Set to keep track of unique ids
        removePriceTag = productMixing.items?.map((value) => {
            // Check if the id is unique
            if (!uniqueIds.has(value.id)) {
                uniqueIds.add(value.id); // Add id to the set
                // Return object with id, type, and quantity
                return {
                    id: value.id,
                    type: value?.type,
                    quantity: Number(value?.quantity),
                };
            }
            return null; // Return null for duplicate ids
        }).filter(Boolean); // Filter out null values (duplicates)
    }
    // Create data object
    const data = {
        flavors: productFlavour,
        ingredients: { ...productMixing, items: removePriceTag },
        sweeteners: selectedSweeteners,
    };
    if (updateProduct) {
      // Create payload for updateCartProduct
      const payload = {
          cartProductId: updateProduct?.id,
          data
      };
      dispatch(updateCartProduct({ payload, setShowSuccess }));
  } else {
    handleSkip()
  }

   
  }

  const handleCheckboxChange = (sweetener) => {
    if (isSelected(sweetener)) {
      setSelectedSweeteners((prevSweeteners) =>
        prevSweeteners.filter((selected) => selected !== sweetener)
      );
    } else {
      setSelectedSweeteners((prevSweeteners) => [...prevSweeteners, sweetener]);
    }
  };

  const isSelected = (sweetener) => {
    return selectedSweeteners?.includes(sweetener);
  };

  const handleNextButtonClick = () => {
    // Below logic for updating product from the cart 
    let removePriceTag = [];
    if (productMixing?.items?.length > 0) {
        // Mapping over productMixing.items to create removePriceTag array
        const uniqueIds = new Set(); // Set to keep track of unique ids
        removePriceTag = productMixing.items?.map((value) => {
            // Check if the id is unique
            if (!uniqueIds.has(value.id)) {
                uniqueIds.add(value.id); // Add id to the set
                // Return object with id, type, and quantity
                return {
                    id: value.id,
                    type: value?.type,
                    quantity: Number(value?.quantity),
                };
            }
            return null; // Return null for duplicate ids
        }).filter(Boolean); // Filter out null values (duplicates)
    }
    // Create data object
    const data = {
        flavors: productFlavour,
        ingredients: { ...productMixing, items: removePriceTag },
        sweeteners: selectedSweeteners,
    };
   
    // Check if sweeteners are selected
    // if (selectedSweeteners.length > 0) {
        dispatch(storeMixedSweetness(selectedSweeteners));
        if (updateProduct) {
            // Create payload for updateCartProduct
            const payload = {
                cartProductId: updateProduct?.id,
                data
            };
            dispatch(updateCartProduct({ payload, setShowSuccess }));
        } else {
            handleNext();
        }
    // } else {
      // errorAlert("Please select any sweetener")
    // }
};


return (
  <div className="payment-form-overlay">
    <div className="add-sweetners-main">
      {isProductAdded ? (
        <div
          style={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoaderOne />
        </div>
      ) : (
        <>
          <div className="flavor-head">
            <h2>
              Add sweeteners <p>Step {formCount}</p>
            </h2>
            {selectedSweeteners.length === 0 && (
              <SkipButton skipHandler={skipHandler}/> 
             
            )}
          </div>
          <div className="flavor-select">
            {sweeteners?.map((item, i) => {
              return (
                <div className="flavor-category" key={i}>
                  <div className="field">
                    <input
                      type="checkbox"
                      onChange={() => handleCheckboxChange(item)}
                      checked={isSelected(item)}
                    />
                    <label>{item}</label>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flavor-btn">
            <button className="cancle-btn" onClick={cancelHandler}>
              Cancel
            </button>
            <div className="sweetner-btn">
              <button className="sweetback-btn" onClick={handlePrev}>
                Back
              </button>
              <button className="save-btn" onClick={handleNextButtonClick}>
                Save & Close
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  </div>
);
};

export default AddSweetener;
