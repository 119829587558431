import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllAddress,
  removeAddress,
  selectDeliveryAddress,
} from "../../../store/slices/checkoutSlice/checkout";
import AddNewAddress from "../../../pages/AddNewAddress";
import { setOpenAddAddress } from "../../../store/slices/common";
import { getAddress, getDeleteAddress } from "../../../Auth/profile";
import IllustrationNoProduct from "../../common/IllustrationNoProduct";
import NoAddressFound from "../../../assets/brand/noaddress.svg";
import LoaderOne from "../../../layout/LoaderOne";
import Spinner from "../../../component/common/Spinner";
import { handlePickup } from "../../../store/slices/cartSlice/cart";

const AddressComponent = () => {
  //* redux hooks
  const { addressList, deliveryAddress } = useSelector(
    (state) => state.checkoutSlice
  );
  const currentUser = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const [addressDetail, setAddressDetail] = useState([]);
  const [loading, setIsLoading] = useState(false);

  const fetchAddressData = async () => {
    try {
      setIsLoading(true);
      const response = await getAddress(currentUser?.id);
      setAddressDetail(response?.data?.addresses);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (event) => {
    const { checked } = event?.target;
    dispatch(handlePickup(checked));
  };

  //* get User Addresses
  useEffect(() => {
    fetchAddressData();
    // dispatch(getAllAddress());
  }, []);

  //* add new address model
  const openAddNewAddress = () => {
    dispatch(setOpenAddAddress());
  };

  //* remove Address
  const handleRemove = (e, id) => {
    e.stopPropagation();

    dispatch(removeAddress({ id, fetchAddressData }));
  };

  const handleAddress = (selectedAddress) => {
    dispatch(selectDeliveryAddress(selectedAddress));
  };

  return (
    <>
      <div className="address-heading">
        Select your shipping information below
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            height: 150,
          }}
        >
          <Spinner width={30} height={30} />
        </div>
      ) : (
        <>
          {addressDetail?.length === 0 ? (
            <div style={{ marginTop: 20 }}>
              {" "}
              <IllustrationNoProduct
                imgLink={NoAddressFound}
                tittle={"Lets's get started"}
                subTittle={"Add your delivery addresses"}
              />{" "}
            </div>
          ) : (
            <>
              {addressDetail?.map((address) => (
                <div
                  key={address?.id}
                  className={`address  ${
                    deliveryAddress?.id === address?.id && "active"
                  }`}
                  onClick={(e) => handleAddress(address)}
                >
                  <div className="head">
                    <span>
                      <img src="images/home_icon.svg" alt="" />{" "}
                      {address?.addressType}
                    </span>
                    <div className="address-manage">
                      {/* <button className="edit-btn">
                <img src="images/edit_icon.svg" alt="" />
              </button> */}
                      <button
                        className="delete-btn"
                        onClick={(e) => handleRemove(e, address?.id)}
                      >
                        <img src="images/delete_icon_grey.svg" alt="" />
                      </button>
                    </div>
                  </div>
                  <h4>
                    {address?.name} <span className="circle"></span>
                    <span>
                     {"+"}{address?.phone?.dialCode}{" "}
                      {address?.phone?.number}
                    </span>
                  </h4>
                  <p>{address?.address}</p>
                </div>
              ))}
            </>
          )}
          <div className="add-address-btn">
            <button onClick={openAddNewAddress}>
              <img src="images/plus_icon_yellow.svg" alt="" /> Add new address
            </button>
          </div>
          {addressDetail?.length > 0 && (
            <div className="check-billing-box">
              <div className="field">
                <input type="checkbox" onChange={handleChange} />
                <label>Pick up in store</label>
              </div>
            </div>
          )}
        </>
      )}

      <AddNewAddress fetchAddressData={fetchAddressData} />
    </>
  );
};

export default AddressComponent;
