import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createOrder,
  customerCartProducts,
  removeProductFromCart,
  updateProductQuantity,
} from "../../../config/makeRequests/cartRequests";
import {
  getPriceTrunc,
  getUserId,
  ingredientPrice,
  productPrice,
} from "../../../utils/common";
import { CustomToast } from "../../../component/common";
import { setIsLoading } from "../common";

export const getCustomerCartProducts = createAsyncThunk(
  "getCustomerCartProducts/cartSlice",
  async () => {
    try {
      const userId = getUserId();
      let response = await customerCartProducts(userId);
      if (response.status === true) {
        return response;
      }
    } catch (error) {
      console.log("cart Slice Error ", error);
    }
  }
);

export const removeProduct = createAsyncThunk(
  "removeProduct/cartSlice",
  async (productId, { dispatch }) => {
    try {
      const userId = getUserId();
      let response = await removeProductFromCart(userId, productId);

      if (response.status === true) {
        dispatch(setIsLoading(false));
        return response;
      }
    } catch (error) {
      console.log("cart Slice Error ", error);
    }
  }
);
export const updateQuantity = createAsyncThunk(
  "updateQuantity/cartSlice",
  async (data) => {
    try {
      const { productId, quantity } = data;
      const userId = getUserId();
      let response = await updateProductQuantity(userId, productId, {
        quantity,
      });

      if (response.status === true) {
        return response;
      }
    } catch (error) {
      console.log("cart Slice Error ", error);
    }
  }
);

export const paymentHandle = createAsyncThunk(
  "paymentHandle/cartSlice",
  async ({data , paymentSuccess}) => {
    try {
      const userId = getUserId();
      let response = await createOrder(userId, data);
      if (response.status === true) {
        paymentSuccess()
        return response;
      }
    } catch (error) {
      const { message } = error?.response?.data;
      CustomToast(message, "error");
    }
  }
);

const initialState = {
  isLoading: false,
  cartItems: [],
  price: {},
  selfPickup: false,
  isProductRemove: false,
  quantityUpdate: false,
  isCheckoutProceed: false,

  totalAmount: null,
  paymentLoading: false,
  stripeKeys: null,
};

const cartSlice = createSlice({
  name: "cartSlice",
  initialState,
  reducers: {
    proceedToCheckout: (state, { payload }) => {
      state.isCheckoutProceed = payload;
    },
    handlePickup: (state, { payload }) => {
      state.selfPickup = payload;
    },
    emptyKeys: (state) => {
      state.stripeKeys = null;
    },
    emptyCartItems: (state) => {
      state.cartItems = [];
    },
    calculatedAmount: (state, { payload }) => {
      const obj = {
        subtotal: 0,
        tax: 0,
        packaging: 0,
        shipping: 0,
        finalAmount: 0,
        isMixingProduct: false,
        mixingPrice: 0,
        packagingPrice: 0,
      };

      const IsMixedProduct = () => {
        let isMixing = false;
        if (payload?.length > 0) {
          for (let item of payload) {
            if (item?.ingredient) {
              if (
                item?.mixing?.ingredients?.items?.length === 1 &&
                (item?.mixing?.flavors == null  || item?.mixing?.flavors?.length == 0 ) &&
                (item?.mixing?.sweeteners == null || item?.mixing?.sweeteners.length == 0 )
              ) {
                isMixing = false;
              } else {
                isMixing = true;
              }
            } else if (
              item?.mixing?.ingredients?.items?.length > 0 ||
              item?.mixing?.flavors?.length > 0 ||
              item?.mixing?.sweeteners?.length > 0
            ) {
              isMixing = true;
            }
          }
        }
        return isMixing;
      };

      const isMixing = IsMixedProduct();
      obj.isMixingProduct = isMixing;

      // calculated sub total
      obj.subtotal = getPriceTrunc(payload?.reduce((total, cartItem) => {
        return (
          total +
          Number(
            cartItem?.ingredient
              ? ingredientPrice(cartItem)
              : productPrice(cartItem)
          )
        );
      }, 0));

      obj.mixingPrice = isMixing ? state?.price?.mixingPrice : 0;
      obj.packagingPrice = state?.price?.packagingPrice;

      // calculated tax
      obj.tax = getPriceTrunc((obj?.subtotal * state?.price?.taxPercentage) / 100);

      // calculated packaging and mixing price?
      obj.packaging = isMixing
        ? state?.price?.mixingPrice + state?.price?.packagingPrice
        : state?.price?.packagingPrice;

      // calculated shipping price
      obj.shipping =
        obj?.subtotal <= state?.price?.shipping?.limit
          ? state?.price?.shipping?.price
          : 0;

      // calculated final amount
      obj.finalAmount = getPriceTrunc(parseFloat(obj.subtotal) + parseFloat(obj.tax) + obj.packaging + obj.shipping );


      state.totalAmount = obj;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getCustomerCartProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomerCartProducts.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.cartItems = payload?.cartItems;
        state.price = payload?.price;
      })
      .addCase(getCustomerCartProducts.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(removeProduct.pending, (state) => {
        state.isProductRemove = true;
      })
      .addCase(removeProduct.fulfilled, (state, { payload }) => {
        const filteredProduct = state.cartItems.filter(
          (item) => item?.id !== payload?.cartProduct?.id
        );
        state.cartItems = filteredProduct;
        state.isProductRemove = false;
      })
      .addCase(removeProduct.rejected, (state) => {
        state.isProductRemove = false;
      })
      .addCase(updateQuantity.pending, (state) => {
        state.quantityUpdate = true;
      })
      .addCase(updateQuantity.fulfilled, (state, { payload }) => {
        const updatedCart = payload?.cartProduct;
        state.quantityUpdate = false;
        state.cartItems = state.cartItems.map((item) => {
          return item?.id == updatedCart?.id
            ? { ...item, quantity: updatedCart?.quantity }
            : item;
        });
      })
      .addCase(updateQuantity.rejected, (state) => {
        state.quantityUpdate = false;
      })
      .addCase(paymentHandle.pending, (state) => {
        state.paymentLoading = true;
      })
      .addCase(paymentHandle.fulfilled, (state, { payload }) => {
        state.paymentLoading = false;
        state.stripeKeys = payload;
      })
      .addCase(paymentHandle.rejected, (state) => {
        state.paymentLoading = false;
      });
  },
});

export const { calculatedAmount, proceedToCheckout, handlePickup, emptyKeys , emptyCartItems } =
  cartSlice.actions;
export default cartSlice.reducer;
