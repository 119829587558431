import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CartNav from "../../component/pagesComponents/cart/CartNav";
import NoCartProduct from "../../component/pagesComponents/cart/NoCartProduct";
import TotalAmount from "../../component/pagesComponents/cart/TotalAmount";
import LoaderOne from "../../layout/LoaderOne";
import { getCustomerCartProducts } from "../../store/slices/cartSlice/cart";
import CheckoutPage from "../Checkout/Checkout";
import CartProducts from "./CartProducts";
import {
  handleEmptyMixing,
  ingredientsVisibility,
} from "../../store/slices/mixingIngredients/ingredientsSlice";

const Cart = () => {
  // redux hooks
  const dispatch = useDispatch();
  const { isLoading, cartItems, isCheckoutProceed } = useSelector(
    (state) => state.cart
  );
  console.log("cart", cartItems);
  useEffect(() => {
    dispatch(ingredientsVisibility(false));
    dispatch(handleEmptyMixing());
  }, []);
  useEffect(() => {
    dispatch(getCustomerCartProducts());
  }, []);
  const {
    originalProduct,
    productMixing,
    productFlavour,
    productSweetness,
    selectedOfferDays,
    isProductAdded,
    mixProductName,
  } = useSelector((state) => state.ingredientsSlice);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "-1",
          }}>
          <LoaderOne />
        </div>
      ) : (
        <section id="cart-main">
          <div className="container">
            {cartItems?.length > 0 ? (
              <div className="row">
                <CartNav />
                <div className="cart-item-col">
                  {isCheckoutProceed ? <CheckoutPage /> : <CartProducts />}
                </div>
                {cartItems && cartItems?.length > 0 && <TotalAmount />}
              </div>
            ) : (
              <NoCartProduct />
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default Cart;
