import React, { useEffect, useState } from "react";

const CustomRange = ({
  handleRangeChange,
  doseRange,
  data,
  updateProduct,
  updatedRangeValue,
  anotherValue
}) => {
  const max = doseRange?.maxDose;
  const min = doseRange?.minDose;
  const recommend = doseRange?.recommendedDose;

  const [actualValue, setValue] = useState(0);
  const [inputRangeDisable, setInputRangeDisable] = useState(false);


  useEffect(() => {
    if(anotherValue){
      setValue(anotherValue)
    }
  }, [anotherValue])


  useEffect(() => {
    if (updatedRangeValue !== undefined) {
      setValue(updatedRangeValue);
    } else {
      if (recommend === 0) {
        setInputRangeDisable(true);
      } else {
        setInputRangeDisable(false);
        setValue(recommend);
      }
    }
  }, [recommend, updatedRangeValue]);

  // useEffect(() => {
  //   if (actualValue) {
  //     handleRangeChange(actualValue, data);
  //   }
  // }, [actualValue, data]);

  const handleChange = (e) => {
    const { value } = e.target;
    if(value < min){
      setValue(min);
      handleRangeChange(min, data);
    }else{
      setValue(value);
      handleRangeChange(value, data);
    }
  };

  return (
    <>
      <input
        type="range"
        disabled={inputRangeDisable}
        min={min}
        max={max}
        className="form-range bg-warning"
        value={actualValue}
        onChange={handleChange}
        step="1"
        style={{
          borderRadius: "10px",
          height: "7px",
          width: "300px",
          background:
            min === 0 && max === 0
              ? "gray"
              : `linear-gradient(to right, #EFC441 0%, #EFC441 ${
                  min === max ? "0" : ((actualValue - min) / (max - min)) * 100
                }%, #828282 ${
                  min === max ? "0" : ((actualValue - min) / (max - min)) * 100
                }%, #828282 100%)`,
        }}
      />

      {/* <style>
        {`
          input[type=range]::-moz-range-thumb {
            border: none;
            height: 5px;
            width: 5px;
            border-radius: 50%;
            background: yellow;
          }
        `}
      </style> */}
    </>
  );
};

export default CustomRange;
