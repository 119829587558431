import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Login from "./Authentication/Login";
import { setOpenLogin } from "../store/slices/Auth";
import Logo from "../assets/brand/footer_logo.png"
import { getToken } from "../utils/common";

const Footer = () => {
  const currentUser = useSelector((state) => state.login);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentYear = new Date().getFullYear();


  const token = getToken()

  const goToMyOrders = () => {
    if (token) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      navigate("/account-setting", { state: { activeIndex: 3 } });
    } else {
      dispatch(setOpenLogin(true));
    }
  };

  const goToMyWishList = () => {
    if (token) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      navigate("/account-setting", { state: { activeIndex: 2 } });
    } else {
      dispatch(setOpenLogin(true));
    }
  };
  

  const goToMyAccount = () => {
    if (token) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      navigate("/account-setting", { state: { activeIndex: 0 } });
    } else {
      dispatch(setOpenLogin(true));
    }
  };

  const shoppingCart = () => {
    if (token) {
      navigate("/cart");
    } else {
      dispatch(setOpenLogin(true));
    }
  };

  return (
    <footer id="footer-main">
      <div class="footer-top-main">
        <div class="container">
          <div class="row footer-top-row">
            <div class="footer-top-col">
              <div class="footer-top">
                <span>
                  <img src="images/truck_icon.png" alt="" />
                </span>
                <h4>Free Delivery</h4>
                <p>Shipping is free for orders over $100</p>
              </div>
            </div>
            <div className="footer-top-col">
              <div className="footer-top ml">
                <span>
                  <img src="images/customization-icon.png" alt="" />
                </span>
                <h4>Easy Customization</h4>
                <p>Create your mix products as you want</p>
              </div>
            </div>
            <div className="footer-top-col">
              <div className="footer-top ml">
                <span>
                  <img src="images/support_icon.svg" alt="" />
                </span>
                <h4>Support 24/7</h4>
                <p>Support online 24 hours a day</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-middle">
        <div className="container">
          <div className="row">
            <div className="footer-logo-col">
              <div className="footer-logo-main">
                <a href="#" className="footer-logo">
                  <img src={Logo} alt="" />
                </a>
                <a href="#" className="number">
                  +1 561 794-3450
                </a>
                <a href="#">Tech@lavimd.com</a>
                {/* <div className="social-link">
                  <span>Follow:</span>
                  <ul>
                    <li>
                      <a href="#">
                        <img src="images/facebook_icon.svg" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="images/instagram_icon.svg" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="images/youtube_icon.svg" alt="" />
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            {/*  <div className="footer-col">
              <div className="footer">
                <h2>Shop by Category</h2>
                <ul>
                  {categoryData?.map((category) => (
                    <li key={category.id}>
                      <Link onClick={() => fetchData(category)}>
                        {category}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div> */}
            <div className="footer-col">
              <div className="footer pl">
                <h2>My Account</h2>
                <ul>
                  <li onClick={goToMyAccount}>
                    <Link to={""}>My Account</Link>
                  </li>

                  <li onClick={goToMyOrders}>
                    <Link to={""}>My Orders</Link>
                  </li>
                  <li onClick={goToMyWishList}>
                    <Link to={""}>My Wishlist</Link>
                  </li>
                  <li onClick={shoppingCart}>
                    <Link to={""}>Shopping cart</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-col">
              <div className="footer pl">
                <h2>Help</h2>
                <ul>
                  <li>
                    <Link to={"/contact-us"}>Support Center</Link>
                  </li>
                  {token &&  <li>
                  <Link to={"/leave-a-review"}>Leave a review</Link>
                  </li> }
                 
                  {/* <li>
                    <a href="#">FAQ's </a>
                  </li>
                  <li>
                    <a href="#">Shipping</a>
                  </li>
                  <li>
                    <a href="#">Track Your Order</a>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="footer-col">
              <div className="footer pl">
                <h2>Legal</h2>
                <ul>
                  <li>
                    <a target="_blank" href="https://abbsii.com/privacy">
                      Privacy policy
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://abbsii.com/privacy">
                      Terms of use
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom-main">
        <div className="container">
          <div className="row">
            <div className="footer-bottom-col">
              <div className="footer-bottom">
                <p>Copyright ABBSI. All rights reserved. &copy;{currentYear}</p>
                {/* <div className="footer-bottom-right">
                  <a href="https://abbsii.com/privacy">Terms of Use</a>
                  <a href="https://abbsii.com/privacy">Privacy Policy</a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
