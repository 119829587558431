import React, { useState, useEffect } from "react";
import AvailableOffers from "../AskIngredientPopup/AvailableOffers";
import { useSelector, useDispatch } from "react-redux";
import {
  addProductIntoCart,
  getOffers,
} from "../../../store/slices/mixingIngredients/ingredientsSlice";
import { calculateTotalPrice } from "../../../utils/common";

const AddIngredientsOffer = ({ offerHandler, crossHandler }) => {
  const [price, setPrice] = useState();
  const dispatch = useDispatch();
  const {
    originalProduct,
    productMixing,
    productFlavour,
    productSweetness,
    selectedOfferDays,
    isProductAdded,
    mixProductName
  } = useSelector((state) => state.ingredientsSlice);

  const mixing = {
    flavors: productFlavour,
    ingredients: productMixing,
    sweeteners: productSweetness,
  };


  useEffect(() => {
    setPrice(calculateTotalPrice(mixing, originalProduct));
  }, []);

  const addIngredientOfferHandler = () => {
    const productTypeId = originalProduct?.type === "ingredient" ? "ingredientId" : "productId";

    let removePriceTag = [];
    if (productMixing?.items?.length > 0) {
      removePriceTag = productMixing.items?.map((value) => {
        return {
          id: value.id,
          type: value?.type,
          quantity: Number(value?.quantity),
        };
      });
    }

    const data = {
      [productTypeId]: originalProduct?.id,
      mixFromScratchName:mixProductName,
      mixing: {
        flavors: productFlavour,
        ingredients: { ...productMixing, items: removePriceTag },
        sweeteners: productSweetness,
      },
      offerDays: selectedOfferDays,
      quantity: 1,
    };

    // offerHandler()
   

    dispatch(addProductIntoCart({ data, offerHandler }));
  };

  return (
    <>
      <AvailableOffers
        offerHandler={addIngredientOfferHandler}
        price={price}
        btnLoading={isProductAdded}
        crossHandler={crossHandler}
      />
    </>
  );
};

export default AddIngredientsOffer;
