import React from "react";

const RedAlert = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
      >
     <mask
  id="mask0_2857_9757"
  style={{ maskType: 'alpha' }}
  maskUnits="userSpaceOnUse"
  x="0"
  y="0"
  width="44"
  height="44"
>
  <rect
    x="0.821289"
    y="0.334961"
    width="43.1787"
    height="43.1787"
    fill="#D9D9D9"
  />
</mask>
        <g mask="url(#mask0_2857_9757)">
          <path
            d="M22.4105 30.9203C22.9203 30.9203 23.3476 30.7479 23.6924 30.403C24.0372 30.0582 24.2097 29.6309 24.2097 29.1212C24.2097 28.6114 24.0372 28.1841 23.6924 27.8393C23.3476 27.4945 22.9203 27.322 22.4105 27.322C21.9008 27.322 21.4735 27.4945 21.1287 27.8393C20.7838 28.1841 20.6114 28.6114 20.6114 29.1212C20.6114 29.6309 20.7838 30.0582 21.1287 30.403C21.4735 30.7479 21.9008 30.9203 22.4105 30.9203ZM20.6114 23.7238H24.2097V12.9291H20.6114V23.7238ZM22.4105 42.2547L16.3835 36.3176H8.01764V27.9517L2.08057 21.9247L8.01764 15.8977V7.5318H16.3835L22.4105 1.59473L28.4376 7.5318H36.8035V15.8977L42.7405 21.9247L36.8035 27.9517V36.3176H28.4376L22.4105 42.2547Z"
            fill="#EB5757"
          />
        </g>
      </svg>
    </>
  );
};

export default RedAlert;
