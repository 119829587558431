import React from "react";

const IllustrationNoProduct = ({ tittle , imgLink , subTittle}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          textAlign: "center",
        }}
      >
        <img src={imgLink} alt="No search" />{" "}
        <span style={{ fontSize: 20, fontWeight: 500 }}>{tittle}</span>
        <span style={{ fontSize: 15, fontWeight: 500 }}>{subTittle}</span>
      </div>
    </div>
  );
};

export default IllustrationNoProduct;
