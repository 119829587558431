import React from "react";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const SkipButton = ({ skipHandler }) => {
  return (
    <>
      <button onClick={skipHandler}>
        Skip <MdKeyboardDoubleArrowRight size={18} />
      </button>
    </>
  );
};

export default SkipButton;
