import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";

import FrontendLayout from "../layout/FrontendLayout";
import { baseRoutes } from "./RouteConfig";

import Home from "../component/Home";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import HomePage from "../pages/home/HomePage";

const AllRoutes = (props) => {
  return (
    <Fragment>
      <Routes>
        {baseRoutes.map((route, i) => {
          let component = route.component;
          return (
            <Route
              key={i}
              {...route}
              element={
                <FrontendLayout>
                  <PrivateRoute>{component}</PrivateRoute>
                </FrontendLayout>
              }
            />
          );
        })}
        {/* <Route
          index
          element={
            <FrontendLayout>
              <Home />
            </FrontendLayout>
          }
        /> */}
       
      </Routes>
    </Fragment>
  );
};

export default AllRoutes;
