import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { calculatedAmount } from "../../../store/slices/cartSlice/cart";

const TotalAmount = () => {
  const { cartItems, totalAmount } = useSelector((state) => state.cart);

  const dispatch = useDispatch();


  useEffect(() => {
    if (cartItems?.length > 0) {
      dispatch(calculatedAmount(cartItems));
    }
  }, [cartItems]);

  return (
    <div className="cart-checkout-col">
      <div className="cart-checkout">
        <div className="checkout-head">
          <span>PRICE DETAILS</span>
        </div>
        <div className="checkout-field">
          <label>Subtotal items ({cartItems?.length})</label>
          <span>$ {totalAmount?.subtotal}</span>
        </div>
        <div className="checkout-field">
          <label>Tax</label>
          <span>$ {parseFloat(totalAmount?.tax)}</span>
        </div>
        <div className="checkout-field">
          <label>{totalAmount?.isMixingProduct && "Mixing &"} Packaging</label>
          <span>$ {totalAmount?.packaging}</span>
        </div>
        <div className="checkout-field">
          <label>Shipping</label>
          <span>
            {" "}
            {totalAmount?.shipping === 0
              ? "Free "
              : `$ ${totalAmount?.shipping}`}
          </span>
        </div>
        <div className="checkout-total">
          <label>Total</label>
          <span>$ {totalAmount?.finalAmount}</span>
        </div>
      </div>
    </div>
  );
};

export default TotalAmount;
