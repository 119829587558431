import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";

import loaderClasses from "../styles/loader.module.css";

const MixLoader = ({ width = "100%", height = "200px" }) => {
  return (
    <div>
      <div
        style={{
          width,
          height,
        }}
        className="new_loader">
        <div className={loaderClasses.loadingioSpinnerRolling}>
          <div className={loaderClasses.ldioLoader}>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MixLoader;
