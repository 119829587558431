import { createSlice } from "@reduxjs/toolkit";

const ingredientSlice = createSlice({
  name: "ingredient",
  initialState: {
    ingredientData: [],
  },
  reducers: {
    setIngredientData: (state, action) => {
      state.ingredientData = action.payload;
    },
  },
});

export const { setIngredientData } = ingredientSlice.actions;
export default ingredientSlice.reducer;
