import React, { useEffect, useState } from "react";

import "../../styles/ingredientsModal.css";
import { useDispatch, useSelector } from "react-redux";
import { addToMix, updateMixItem } from "../../action/mixActions";
import { toast } from "react-toastify";
import ReactModal from "react-modal";
import { CustomToast } from "../common";
import { getPriceTrunc, textElips } from "../../utils/common";

ReactModal.setAppElement("#root");

const IngredientModal = ({
  isOpen,
  onClose,
  selectedIngredient,
  costOne,
  gender,
}) => {
  const currentUser = useSelector((state) => state.login);
  const [recommendedDose, setRecommendedDose] = useState("");
  const [recommended, setRecommended] = useState("");
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [sliderValue, setSliderValue] = useState(recommended);
  const [cost, setCost] = useState(0);
  const mixItems = useSelector((state) => state.mix.mixItems);
  const [monthlyQuantity, setMonthlyQuantity] = useState(recommended);
  const [perDay, setPerDay] = useState(0); // * sachin
  const [showFullDescription, setShowFullDescription] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  useEffect(() => {
    const costAsNumber = parseFloat(costOne);

    if (!isNaN(costAsNumber)) {
      setCost(costAsNumber);
    }
  }, [costOne]);

  useEffect(() => {
    if (isOpen) {
      const mixItem = mixItems?.find(
        (item) => item?.id === selectedIngredient?.id
      );

      if (mixItem) {
        setCost(Number(mixItem.cost));
        setPerDay(Number(mixItem.perDay));
      } else {
        const initialIngredientPrice =
          selectedIngredient?.quantity?.price?.sale;
        const initialIngredientWeight =
          selectedIngredient?.quantity?.price?.weight;
        const initialSliderValue = parseFloat(recommendedDose) || 0;

        const initialPerDay =
          (initialIngredientPrice / initialIngredientWeight) *
          initialSliderValue;

        setSliderValue(initialSliderValue);
        setMonthlyQuantity(initialSliderValue * 30);
        setPerDay(initialPerDay);
        setShowFullDescription(false); // * sachin
      }
    }
  }, [isOpen, costOne, mixItems, selectedIngredient]);

  const dispatch = useDispatch();

  useEffect(() => {
    setSliderValue(parseFloat(recommendedDose) || 0);
    setMonthlyQuantity(sliderValue * 30);
  }, [recommendedDose, sliderValue]);

  const handleSliderChange = (event) => {
    const value = parseFloat(event.target.value);
    const inputValue = Number(value)
    setSliderValue(inputValue);

    const ingredientPrice = selectedIngredient?.quantity?.price?.sale;
    const ingredientWeight = selectedIngredient?.quantity?.price?.weight;

    const updatedCostOne = (ingredientPrice / ingredientWeight) * value * 30;
    const updatedCostPerDay = (ingredientPrice / ingredientWeight) * value;

    setCost(updatedCostOne);
    setPerDay(updatedCostPerDay);

    const updatedDose = value + 0;
    setRecommendedDose(updatedDose);
    setMonthlyQuantity(value * 30);
  };

  const truncateDescription = (description, maxLength) => {
    if (showFullDescription || description?.length <= maxLength) {
      return description;
    } else {
      return `${description?.substring(0, maxLength)}...`;
    }
  };

  const isAlreadyInMix = mixItems?.some(
    (item) => item?.id === selectedIngredient?.id
  );

  const handleRemoveFromMix = () => {
    const itemIdToRemove = selectedIngredient?.id;

    dispatch({ type: "REMOVE_FROM_MIX", payload: { id: itemIdToRemove } });
    onClose();
  };

  const [isUpdating, setIsUpdating] = useState(isAlreadyInMix);

  useEffect(() => {
    if (currentUser && selectedIngredient && gender && gender.gender) {
      if (currentUser.gender === "Male" && currentUser.weight <= 140) {
        const recommendedDoses =
          selectedIngredient?.quantity?.male[0]?.recommendedDose;
        const minsDoses = selectedIngredient?.quantity?.male[0]?.minDose;
        const maxDoses = selectedIngredient?.quantity?.male[0]?.maxDose;
        setMax(maxDoses);
        setMin(minsDoses);
        setRecommendedDose(recommendedDoses);
        setRecommended(recommendedDoses);
      } else if (
        currentUser.gender === "Male" &&
        currentUser.weight >= 141 &&
        currentUser.weight <= 200
      ) {
        const recommendedDoses =
          selectedIngredient?.quantity?.male[1]?.recommendedDose;
        const minsDoses = selectedIngredient?.quantity?.male[1]?.minDose;
        const maxDoses = selectedIngredient?.quantity?.male[1]?.maxDose;
        setMax(maxDoses);
        setMin(minsDoses);
        setRecommendedDose(recommendedDoses);
        setRecommended(recommendedDoses);
      } else if (currentUser.gender === "Male" && currentUser.weight >= 201) {
        const recommendedDoses =
          selectedIngredient?.quantity?.male[2]?.recommendedDose;
        const minsDoses = selectedIngredient?.quantity?.male[2]?.minDose;
        const maxDoses = selectedIngredient?.quantity?.male[2]?.maxDose;
        setMax(maxDoses);
        setMin(minsDoses);
        setRecommendedDose(recommendedDoses);
        setRecommended(recommendedDoses);
      } else if (
        currentUser.gender === "Female" &&
        currentUser.weight >= 50 &&
        currentUser.weight <= 100
      ) {
        const recommendedDoses =
          selectedIngredient?.quantity?.female[0]?.recommendedDose;
        const minsDoses = selectedIngredient?.quantity?.female[0]?.minDose;
        const maxDoses = selectedIngredient?.quantity?.female[0]?.maxDose;
        setMax(maxDoses);
        setMin(minsDoses);
        setRecommendedDose(recommendedDoses);
        setRecommended(recommendedDoses);
      } else if (
        currentUser.gender === "Female" &&
        currentUser.weight >= 101 &&
        currentUser.weight <= 150
      ) {
        const recommendedDoses =
          selectedIngredient?.quantity?.female[1]?.recommendedDose;
        const minsDoses = selectedIngredient?.quantity?.female[1]?.minDose;
        const maxDoses = selectedIngredient?.quantity?.female[1]?.maxDose;
        setMax(maxDoses);
        setMin(minsDoses);
        setRecommendedDose(recommendedDoses);
        setRecommended(recommendedDoses);
      } else if (currentUser.gender === "Female" && currentUser.weight >= 151) {
        const recommendedDoses =
          selectedIngredient?.quantity?.female[2]?.recommendedDose;
        const minsDoses = selectedIngredient?.quantity?.female[2]?.minDose;
        const maxDoses = selectedIngredient?.quantity?.female[2]?.maxDose;
        setMax(maxDoses);
        setMin(minsDoses);
        setRecommendedDose(recommendedDoses);
        setRecommended(recommendedDoses);
      } else if (gender.gender === "male" && gender.weight === "80-140") {
        const recommendedDoses =
          selectedIngredient?.quantity?.male[0]?.recommendedDose;
        const minsDoses = selectedIngredient?.quantity?.male[0]?.minDose;
        const maxDoses = selectedIngredient?.quantity?.male[0]?.maxDose;
        setMax(maxDoses);
        setMin(minsDoses);
        setRecommendedDose(recommendedDoses);
        setRecommended(recommendedDoses);
      } else if (gender.gender === "male" && gender.weight === "141-200") {
        const recommendedDoses =
          selectedIngredient?.quantity?.male[1]?.recommendedDose;
        const minsDoses = selectedIngredient?.quantity?.male[1]?.minDose;
        const maxDoses = selectedIngredient?.quantity?.male[1]?.maxDose;
        setMax(maxDoses);
        setMin(minsDoses);
        setRecommendedDose(recommendedDoses);
        setRecommended(recommendedDoses);
      } else if (gender.gender === "male" && gender.weight === "200+") {
        const recommendedDoses =
          selectedIngredient?.quantity?.male[2]?.recommendedDose;
        const minsDoses = selectedIngredient?.quantity?.male[2]?.minDose;
        const maxDoses = selectedIngredient?.quantity?.male[2]?.maxDose;
        setMax(maxDoses);
        setMin(minsDoses);
        setRecommendedDose(recommendedDoses);
        setRecommended(recommendedDoses);
      } else if (gender.gender === "female" && gender.weight === "50-100") {
        const recommendedDoses =
          selectedIngredient?.quantity?.female[0]?.recommendedDose;
        const minsDoses = selectedIngredient?.quantity?.female[0]?.minDose;
        const maxDoses = selectedIngredient?.quantity?.female[0]?.maxDose;
        setMax(maxDoses);
        setMin(minsDoses);
        setRecommendedDose(recommendedDoses);
        setRecommended(recommendedDoses);
      } else if (gender.gender === "female" && gender.weight === "101-150") {
        const recommendedDoses =
          selectedIngredient?.quantity?.female[1]?.recommendedDose;
        const minsDoses = selectedIngredient?.quantity?.female[1]?.minDose;
        const maxDoses = selectedIngredient?.quantity?.female[1]?.maxDose;
        setMax(maxDoses);
        setMin(minsDoses);
        setRecommendedDose(recommendedDoses);
        setRecommended(recommendedDoses);
      } else if (gender.gender === "female" && gender.weight === "150+") {
        const recommendedDoses =
          selectedIngredient?.quantity?.female[2]?.recommendedDose;
        const minsDoses = selectedIngredient?.quantity?.female[2]?.minDose;
        const maxDoses = selectedIngredient?.quantity?.female[2]?.maxDose;
        setMax(maxDoses);
        setMin(minsDoses);
        setRecommendedDose(recommendedDoses);
        setRecommended(recommendedDoses);
      }
    }
  }, [selectedIngredient, gender]);

  const handleCancel = () => {
    onClose();
  };

  const handleUpdate = () => {
    const updatedItem = {
      id: selectedIngredient?.id,
      title: selectedIngredient?.title,
      cost: cost,
      perDay: perDay,
      gender: gender,
      recommendedDose,
      unit: selectedIngredient?.quantity.type,
      price: selectedIngredient?.quantity?.price,
    };

    dispatch(updateMixItem(updatedItem));

    setIsUpdating(false);
    onClose();
  };

  const handleAddToMix = () => {
    const isAlreadyInMix = mixItems.some(
      (item) => item.id === selectedIngredient?.id
    );

    if (isAlreadyInMix) {
      toast.error("Ingredient is already in the mix!");
    } else {
      const itemToAdd = {
        id: selectedIngredient?.id,
        title: selectedIngredient?.title,
        perDay: perDay,
        cost: cost,
        gender: gender,
        recommendedDose,
        unit: selectedIngredient?.quantity?.type,
        price: selectedIngredient?.quantity?.price,
      };
      CustomToast("Ingredient added to mix!", "success");
      dispatch(addToMix(itemToAdd));
      onClose();
    }
  };

  // * sachin
  const roundAndDisplay = (value) => {
    const numericValue = parseFloat(value);

    if (numericValue >= 0.01) {
      return numericValue.toFixed(2);
    } else {
      const roundedValue = numericValue.toFixed(3);
      const formattedValue = Math.max(parseFloat(roundedValue), 0.001)
        .toFixed(4)
        .replace(/\.?0+$/, "");

      return formattedValue === "0.001" ? "0.00" : formattedValue;
    }
  };

  const formatPrice = (value) => {
    const numericValue = parseFloat(value);

    if (numericValue >= 0.01) {
      return numericValue.toFixed(2);
    } else {
      const roundedValue = numericValue.toFixed(5);
      const formattedValue = Math.max(parseFloat(roundedValue), 0.001)
        .toFixed(4)
        .replace(/\.?0+$/, "");

      return formattedValue === "0.0010" ? "0.00" : formattedValue;
    }
  };


  const handleChange= (event)=>{
    const {value} = event?.target
    let actualInputValue = value;
    if(value == ""){
      actualInputValue = 0
    }


     if (actualInputValue > max) return;
    const inputValue = parseFloat(actualInputValue)
    setRecommendedDose(actualInputValue);

    const ingredientPrice = selectedIngredient?.quantity?.price?.sale;
    const ingredientWeight = selectedIngredient?.quantity?.price?.weight;

    const updatedCostOne = (ingredientPrice / ingredientWeight) * inputValue * 30;
    const updatedCostPerDay = (ingredientPrice / ingredientWeight) * inputValue;

    setCost(updatedCostOne);
    setPerDay(updatedCostPerDay);
   
    setMonthlyQuantity(inputValue * 30);
    setSliderValue(inputValue)

  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Error Modal"
      className="modal-content"
      overlayClassName="modal-overlay">
      <div className="modal-body">
        <div className="d-flex justify-content-between align-items-center top_div">
          <h2>
            {textElips(selectedIngredient?.title , 25)}
            <span
              style={{ fontSize: "14px", marginLeft: "5px", color: "#828282" }}>
              ({selectedIngredient?.quantity?.price?.weight}
              {selectedIngredient?.quantity?.type})
            </span>
          </h2>

          <h2 style={{ color: "#EB5757" }}>
            ${selectedIngredient?.quantity?.price?.sale}
          </h2>
        </div>
        <p className="dose_modal" style={{ marginTop: "30px" }}>
          <strong>Daily Serving Size</strong> (Slide below to enter {selectedIngredient?.quantity?.type})
        </p>
        <div className="d-flex justify-content-between range_slider">
          <div style={{width:"100%"}}>
            <input
              style={{
                borderRadius: "10px",
                height: "5px",
                // width: "340px",
                background: `linear-gradient(to right, #EFC441 0%, #EFC441 ${
                  ((sliderValue - min) / (max - min)) * 100
                }%, #828282 ${
                  ((sliderValue - min) / (max - min)) * 100
                }%, #828282 100%)`,
              }}
              type="range"
              className="form-range bg-warning"
              min={min}
              max={max}
              step={max < 0.5 ? "0.01" : "0.5"}
              id="customRange3"
              value={sliderValue}
              onChange={handleSliderChange}
            />
            <p>
              <span>{min}</span>
              <span style={{ float: "right" }}>{max}</span>
            </p>
          </div>
          <div className="range_input extra-input-class"  >
            <input
              style={{border:"none" , width:"86%" ,  height: "30px", borderRadius: "6px" , backgroundColor:"transparent" }}
              type="number"
              name=""
              onChange={handleChange}
              value={recommendedDose}
            />
            <input
               disabled
              style={{border:"none",  width:"50px", height: "30px", borderRadius: "6px" , backgroundColor:"transparent" }}
              type="text"
              name=""
              onChange={handleChange}
              value={`${selectedIngredient?.quantity?.type}/day`}
            />
          </div>
        </div>
        <div className="recommended_dose ingredient-dose">
          <h5>Recommended Dose:</h5>
          <h5 style={{ color: "#828282" }}>
            {recommended} {selectedIngredient?.quantity?.type}/day
          </h5>
        </div>
        <div className="recommended_dose ingredient-dose">
          <h5>Total per day:</h5>
          <h5 style={{ color: "#828282" }}>${formatPrice(perDay)}</h5>
        </div>
        <div className="recommended_dose ingredient-dose">
          <h5>Monthly Quantity:</h5>
          <h5 style={{ color: "#828282" }}>
            {Number(monthlyQuantity).toFixed(2)} {selectedIngredient?.quantity?.type}
          </h5>
        </div>
        <div className="total_price ingredient-dose">
          <h5>Total:</h5>
          <h5>${getPriceTrunc(cost)}/month</h5>
        </div>
        <div className="ingredients_details" style={{ whiteSpace: "pre-line" }}>
          <h6>About {selectedIngredient?.title}:</h6>
          <span>
            {selectedIngredient?.description &&
            typeof selectedIngredient?.description === "string"
              ? truncateDescription(selectedIngredient?.description, 200)
              : "Description not available"}
          </span>
          <span>
            {selectedIngredient?.description?.length > 200 && (
              <button
                className="read-more-button"
                onClick={() => setShowFullDescription(!showFullDescription)}>
                {showFullDescription ? "Read Less" : "Read More"}
              </button>
            )}
          </span>
        </div>

        <div style={{ marginTop: "40px", float: "right" , display: "flex", width: "100%", justifyContent:"space-between" }}>
          {isAlreadyInMix ? (
            <>
              <button
                className="modal-cancel-btn"
                onClick={handleRemoveFromMix}>
                Remove
              </button>
              <button className="modal-submit-btn" onClick={handleUpdate}>
                Update
              </button>
            </>
          ) : (
            <>
              <button className="modal-cancel-btn" onClick={handleCancel}>
                Cancel
              </button>
              <button className="modal-submit-btn" onClick={handleAddToMix}>
                Add to mix
              </button>
            </>
          )}
        </div>
      </div>
    </ReactModal>
  );
};

export default IngredientModal;
