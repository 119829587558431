import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../layout/Loader";
import { addToFavoriteProducts } from "../Auth/product";
import { setFoundProductData } from "../store/slices/productSlice";
import { getPriceTrunc, getToken } from "../utils/common";
import {
  addtoCartProduct,
  askForIngredients,
  getIngredients,
  handleEmptyMixing,
  rejectIngredients,
} from "../store/slices/mixingIngredients/ingredientsSlice";
import { setOpenLogin } from "../store/slices/Auth";

const FoundProduct = () => {
  const isLoading = useSelector((state) => state.common.isLoading);
  const productData = useSelector((state) => state.product.foundProductData);
  const currentUser = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const location = useLocation();

  // useEffect(() => {
  //   return () => {
  //     dispatch(setFoundProductData({ products: [] }));
  //   };
  // }, [location.pathname, dispatch]);



  const handleRemoveFromFavorite = async (productId) => {
    try {
      const userId = currentUser.id;
      const action = "remove";
      const updatedProductData = productData?.products?.map((data) =>
        data.id === productId ? { ...data, isFavorite: false } : data
      );

      dispatch(setFoundProductData({ products: updatedProductData }));

      await addToFavoriteProducts(userId, productId, action);
    } catch (error) {
      console.error("Error removing from favorites:", error);
    }
  };

  const handleAddToFavorite = async (productId) => {
    try {
      const userId = currentUser.id;
      const action = "add";
      const updatedProductData = productData?.products?.map((data) =>
        data.id === productId ? { ...data, isFavorite: true } : data
      );

      dispatch(setFoundProductData({ products: updatedProductData }));
      await addToFavoriteProducts(userId, productId, action);
    } catch (error) {
      console.error("Error adding to favorites:", error);
    }
  };
  
  

  const handleClickDetail = (id) => {
    navigate(`/product/detail/${id}`);
  };

  const handleCart = (e, data) => {
    let token = getToken();
    if (token) {
      e.stopPropagation();
      if (data?.productType === "Product") {
        dispatch(rejectIngredients(true));
        dispatch(addtoCartProduct(data));
        dispatch(handleEmptyMixing());
      } else {
        dispatch(askForIngredients(true));
        dispatch(getIngredients(data?.id));
        dispatch(addtoCartProduct(data));
        dispatch(handleEmptyMixing());
      }
    } else {
      dispatch(setOpenLogin(true));
    }
  };

  const handleGoBack = () => {
    dispatch(setFoundProductData({ products: [] }));
    navigate("/mix");
  };


  return (
    <Fragment>
      <section id="banner-main">
        <div className="banner-img mobile-hide-banner">
          <img src="images/mix_product.png" alt="" />
        </div>
        <div className="banner-text text-for-mobile">
          <div className="container">
            <div className="banner">
              <h1>Create your blend</h1>
            </div>
          </div>
        </div>
      </section>
      {isLoading && (
        <div>
          <Loader />
        </div>
      )}
      <section id="product-main" className="found-product-main">
        <div className="container">
          <div className="back-btn bach-btn-hide">
            <button className="go-back_button" onClick={handleGoBack}>
              <img src="/images/arrow_left.svg" alt="" /> Go back
            </button>
          </div>
          <div class="row">
            {productData?.products?.length > 0 ? (
              <>
                <div class="shop-header-col">
                  <div class="shop-header">
                    <h2>
                      {productData?.products?.length} products found!{" "}
                      <p>
                        Please find the products below as per your submitted
                        answers.
                      </p>
                    </h2>
                  </div>
                </div>
                {productData?.products?.map((data, idx) => (
                  <div class="row" key={idx}>
                    <div class="product-hide">
                      <div
                        class="product-col"
                        key={idx}
                        onClick={() => handleClickDetail(data?.id)}>
                        <div class="product">
                          {currentUser?.isLoggedIn &&
                            (data?.isFavorite ? (
                              <button
                                className="like-btn"
                                onClick={(event) => {
                                  event.stopPropagation(); // Prevent event propagation
                                  data?.isFavorite
                                    ? handleRemoveFromFavorite(data?.id)
                                    : handleAddToFavorite(data?.id);
                                }}>
                                <img src="images/unlike_icon.svg" alt="" />
                              </button>
                            ) : (
                              <button
                                className="like-btn"
                                onClick={(event) => {
                                  event.stopPropagation(); // Prevent event propagation
                                  data?.isFavorite
                                    ? handleRemoveFromFavorite(data?.id)
                                    : handleAddToFavorite(data?.id);
                                }}>
                                <img src="images/like_icon.svg" alt="" />
                              </button>
                            ))}
                          <span class="product-img">
                            <img
                              src={
                                data?.product?.images?.[0] ||
                                "images/placeholder_image.png"
                              }
                              alt=""
                            />
                          </span>
                          <div
                            className="detail"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClickDetail(data?.id)}>
                            {/* <span>{data?.product?.category}</span> */}
                            {data?.product?.category?.map((item, index) => (
                            <React.Fragment key={index}>
                              <span>{item?.categoryName}</span>
                              {index !== data?.product?.category?.length - 1 && (
                                <span>,</span>
                              )}
                            </React.Fragment>
                          ))}
                            <h3>{data?.product?.title}</h3>
                            <span className="rating">
                              <img src="images/star_icon.svg" alt="" />
                              {data?.review?.avg.toFixed(2)} (
                              {data?.review?.star5 +
                                data?.review?.star4 +
                                data?.review?.star3 +
                                data?.review?.star2 +
                                data?.review?.star1}{" "}
                              reviews)
                            </span>
                            <div style={{ fontSize:14 , marginTop:7}}>
                        <div>{ data?.type === "product" ?  "Price for 30 servings (Days)" : `Price for 1  ${data?.quantity?.type} * 30 servings (Days)`}</div>
                        </div>
                          </div>
                          <div class="product-detail">
                            <span>
                              $
                              {data?.type === "product"
                                ? getPriceTrunc(data?.product?.salePrice)
                                : getPriceTrunc(data?.ingredientPrice?.salePricePerMonth)}
                            </span>
                            <button onClick={(e) => handleCart(e , data)}>
                              <img src="images/cart_icon.svg" alt="" />
                              Add to cart
                            </button>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                    <div class="productpage-main-slider found-slider">
                      <div class="product-page-slider">
                        <div>
                          {productData?.products?.map((data, idx) => (
                            <div
                              class="product-col product-col-main"
                              onClick={() => handleClickDetail(data?.id)}>
                              <div class="product">
                                {currentUser?.isLoggedIn &&
                                  (data?.isFavorite ? (
                                    <button
                                      className="like-btn"
                                      onClick={(event) => {
                                        event.stopPropagation(); // Prevent event propagation
                                        data.isFavorite
                                          ? handleRemoveFromFavorite(data?.id)
                                          : handleAddToFavorite(data?.id);
                                      }}>
                                      <img
                                        src="images/unlike_icon.svg"
                                        alt=""
                                      />
                                    </button>
                                  ) : (
                                    <button
                                      className="like-btn"
                                      onClick={(event) => {
                                        event.stopPropagation(); // Prevent event propagation
                                        data.isFavorite
                                          ? handleRemoveFromFavorite(data?.id)
                                          : handleAddToFavorite(data?.id);
                                      }}>
                                      <img src="images/like_icon.svg" alt="" />
                                    </button>
                                  ))}
                                <span class="product-img">
                                  <img
                                    src={
                                      data?.product?.images?.[0] ||
                                      "images/placeholder_image.png"
                                    }
                                    alt=""
                                  />
                                </span>
                                <div
                                  className="detail"
                                  style={{ cursor: "pointer" }}>
                                  {/* <span>{data?.product?.category}</span> */}
                                  {data?.product?.category?.map((item, index) => (
                            <React.Fragment key={index}>
                              <span>{item?.categoryName}</span>
                              {index !== data?.product?.category?.length - 1 && (
                                <span>,</span>
                              )}
                            </React.Fragment>
                          ))}
                                  <h3>{data?.product?.title}</h3>
                                  <span className="rating">
                                    <img src="images/star_icon.svg" alt="" />
                                    {data?.review?.avg.toFixed(2)} (
                                    {data?.review?.star5 +
                                      data?.review?.star4 +
                                      data?.review?.star3 +
                                      data?.review?.star2 +
                                      data?.review?.star1}{" "}
                                    reviews)
                                  </span>
                                  <div style={{ fontSize:14 , marginTop:7}}>
                                  <div> { data?.type === "product" ?  "Price for 30 servings (Days)" : `Price for 1  ${data?.quantity?.type} * 30 servings (Days)`} </div>
                                
                        </div>
                                </div>
                                <div class="product-detail">
                                  <span>
                                    $
                                    {data?.type === "product"
                                      ? getPriceTrunc(data?.product?.salePrice)
                                      : getPriceTrunc(data?.ingredientPrice?.salePricePerMonth)}
                                  </span>
                                  <button onClick={(e) => handleCart(e , data)}>
                                    <img src="images/cart_icon.svg" alt="" />
                                    Add to cart
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <img src="images/noProductImage.svg" alt="no_data" />
                </div>
                <h3 style={{ textAlign: "center", marginTop: "20px" }}>
                  No product found
                </h3>
              </>
            )}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default FoundProduct;
