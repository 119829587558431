import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";

import loaderClasses from "../styles/loader.module.css";

const HomeLoader = ({ width = "100%", height = "100%" }) => {
  return (
    <>
      <div
        style={{ width, height }}
        className="d-flex align-items-center justify-content-center">
        <div
          className={loaderClasses.loadingioSpinnerRolling}
          style={{ width: "100%", height: "200px" }}>
          <div className={loaderClasses.ldioLoader}>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeLoader;
