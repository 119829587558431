import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { userRequest } from '../../config/makeRequests/userRequests';
import { getUserId } from '../../utils/common';
import { setWeightLocalStorage } from '../../utils/common/ingredientsUtils';





export const getUserDetails = createAsyncThunk(
  "getUserDetails/login",
  async () => {
    try {
      const userId = getUserId()
      let response  = await userRequest(userId)
      if (response.status === true) {
       
        return response?.user;
      }
    } catch (error) {
      console.log("getUserDetails Error ", error);
    }
  }
);



const initialState = {
  id: '',
  name: '',
  email: '',
  password: '',
  image: '',
  notifications: false,
  isLoggedIn: false,
  phone:'',
  height:'',
  weight:'',
  gender:'',
  country:'',
  createdAt: '',
  updatedAt: '',
  // genderModule: true,
  isLoading:false
}
const authSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
      loginAdmin: (state, action) => {
        state.id = action.payload.id
        state.name = action.payload.name
        state.email = action.payload.email
        state.password = action.payload.password
        state.image = action.payload.image
        state.notifications = action.payload.notifications
        state.isLoggedIn = action.payload.isLoggedIn
        state.phone = action.payload.phone
        state.height = action.payload.height
        state.weight = action.payload.weight
        state.gender = action.payload.gender
        state.country = action.payload.country
        state.createdAt = action.payload.createdAt
        state.updatedAt = action.payload.updatedAt
      },
      logoutAdmin: state => {
        state.id = ''
        state.name = ''
        state.email = ''
        state.password = ''
        state.image = ''
        state.phone = ''
        state.height = ''
        state.weight = ''
        state.gender = ''
        state.notifications = false
        state.isLoggedIn = false
        state.createdAt = ''
        state.updatedAt = ''
      },
      storeAdminData: (state, action) => {
        const data = action.payload
        state.id = data.id
        state.firstName = data.firstName
        state.lastName = data.lastName
        state.email = data.email
        state.password = data.password
        state.image = data.image
        state.notifications = data.notifications
        state.isLoggedIn = data.isLoggedIn
        state.createdAt = data.createdAt
        state.updatedAt = data.updatedAt
      },
          },
          extraReducers: (builder) => {
            builder
              .addCase(getUserDetails.pending, (state) => {
                state.isLoading = true;
              })
              .addCase(getUserDetails.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.name = payload?.name
                state.email = payload?.email
                state.image = payload?.image
                state.notifications = payload?.notifications
                state.isLoggedIn = payload?.isLoggedIn
                state.phone = payload?.phone
                state.height = payload?.height
                state.weight = payload?.weight
                state.gender = payload?.gender
                state.country = payload?.country
                state.createdAt = payload?.createdAt
                state.updatedAt = payload?.updatedAt
                setWeightLocalStorage(payload?.weight , payload?.gender )
              })
              .addCase(getUserDetails.rejected, (state) => {
                state.isLoading = false;
              })
             
          },
  
  })
  
  const { actions, reducer } = authSlice
  export const { loginAdmin, logoutAdmin, storeAdminData } = actions
  export default reducer
  