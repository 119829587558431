import "./spinner.css";
const Loader = ({ width, height }) => {
  return (
    <span
      className="small_spinner"
      style={{ width: width, height: height }}></span>
  );
};

export default Loader;
