import React from 'react';

const OfferIconSvg = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="43" height="47" viewBox="0 0 43 47" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.95488 31.8837C7.93918 31.3825 7.7423 31.089 7.20722 30.9332C5.74814 30.5092 4.31322 30.0019 2.86138 29.549C2.55821 29.4548 2.45433 29.3412 2.52801 29.0127C2.87225 27.486 3.17904 25.9496 3.53294 24.4253C3.61145 24.0883 3.55469 23.8624 3.32761 23.6172C2.28524 22.4855 1.26581 21.3332 0.217398 20.2075C-0.0434977 19.9284 -0.094228 19.7678 0.201695 19.4538C1.28755 18.3015 2.32993 17.1069 3.39887 15.9389C3.5716 15.7493 3.61387 15.5766 3.55831 15.3302C3.21528 13.7877 2.89641 12.2393 2.54613 10.6981C2.46762 10.355 2.5099 10.192 2.8795 10.0796C4.36274 9.62911 5.83269 9.13389 7.31472 8.67974C7.65775 8.57465 7.82685 8.39348 7.93073 8.05407C8.38608 6.57204 8.88251 5.10209 9.33183 3.61764C9.44295 3.24925 9.60722 3.20697 9.943 3.28307C11.5132 3.63938 13.0895 3.97275 14.6609 4.3194C14.836 4.35805 14.9713 4.34235 15.1126 4.21311C16.3724 3.06565 17.6394 1.92544 18.9016 0.780401C18.9427 0.744165 18.9596 0.681358 18.9874 0.631836C19.0478 0.631836 19.1082 0.631836 19.1686 0.631836C19.5816 1.1959 20.1578 1.58966 20.659 2.05951C21.4188 2.77094 22.2075 3.45217 22.9733 4.15634C23.146 4.31578 23.303 4.36409 23.5398 4.31215C25.1112 3.95946 26.6886 3.6273 28.26 3.27461C28.5644 3.20576 28.6997 3.2577 28.8 3.58503C29.2456 5.03928 29.7433 6.47662 30.1829 7.93329C30.3146 8.37174 30.5066 8.64834 30.9777 8.70873C31.1528 8.73168 31.3183 8.82468 31.4874 8.88628C31.6493 9.09766 31.5128 9.29695 31.4439 9.48296C30.9076 10.9275 30.247 12.3166 29.5126 13.6706C28.3277 15.8532 26.9157 17.8811 25.2936 19.7581C23.7729 21.5168 22.1242 23.1498 20.3293 24.633C17.6334 26.8615 14.7164 28.7518 11.634 30.4005C10.6085 30.9489 9.542 31.4066 8.5117 31.9429C8.37763 32.013 8.23873 32.077 8.07929 32.0335C8.01044 32.0069 7.973 31.9538 7.95488 31.8837Z" fill="#FCB52F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.04336 31.9702C8.16898 31.9822 8.27769 31.9351 8.38881 31.8844C12.2877 30.0992 15.956 27.9263 19.35 25.3016C21.1884 23.8812 22.8721 22.2808 24.4363 20.5705C26.3157 18.5159 27.9584 16.2766 29.2967 13.821C30.1047 12.3378 30.8476 10.828 31.4104 9.2336C31.4503 9.12248 31.4648 9.0017 31.4913 8.88574C32.8381 9.24327 34.1438 9.73123 35.4748 10.1359C35.7526 10.2204 35.6717 10.4137 35.6379 10.5695C35.3045 12.153 34.9663 13.7365 34.6112 15.3151C34.5557 15.5651 34.5895 15.733 34.7622 15.9215C35.8601 17.1233 36.9375 18.3432 38.0427 19.5366C38.2674 19.7781 38.2299 19.9062 38.0319 20.1199C36.9702 21.2722 35.9242 22.4402 34.8649 23.5937C34.6342 23.845 34.552 24.0708 34.6378 24.4356C34.9953 25.9527 35.2961 27.4818 35.6282 29.0049C35.6922 29.3008 35.6584 29.4578 35.3214 29.5605C33.8032 30.0267 32.2982 30.534 30.7823 31.0075C30.4659 31.1066 30.2726 31.2479 30.2992 31.5994C30.2883 31.9037 30.1953 32.1924 30.1023 32.4775C29.7291 33.6237 29.3535 34.7688 28.9887 35.9174C28.8003 36.5105 28.6577 36.5793 28.0574 36.4477C26.7626 36.1638 25.4581 35.9186 24.1718 35.5998C23.5461 35.4439 23.0159 35.4464 22.6004 36.0092C22.5557 36.0696 22.4844 36.1046 22.4107 36.1264C21.738 36.4851 21.2778 37.0963 20.7004 37.5685C20.2354 37.949 19.7982 38.3633 19.3694 38.7849C19.1507 38.9986 19.0118 38.9974 18.7836 38.7849C17.8934 37.9539 16.9815 37.147 16.0768 36.3317C15.9789 36.2435 15.9004 36.1191 15.7458 36.1191C15.7313 36.1143 15.712 36.1143 15.7035 36.1046C15.1346 35.4355 14.451 35.4669 13.6683 35.6758C12.5064 35.9851 11.3191 36.1952 10.1426 36.4477C9.48071 36.589 9.38045 36.5177 9.15459 35.8703C8.76203 34.7482 8.44075 33.6032 8.05907 32.4775C8.00351 32.3156 7.89117 32.1429 8.04336 31.9702Z" fill="#FEA73D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M30.6855 32.6915C31.4235 34.5492 32.1651 36.4056 32.8982 38.2657C33.5722 39.9736 34.2378 41.684 34.9057 43.3943C34.9468 43.5006 34.977 43.6105 35.0361 43.7941C34.0324 43.509 33.0963 43.2457 32.1627 42.9776C31.3957 42.7589 30.6275 42.5451 29.8665 42.3084C29.5767 42.219 29.4305 42.2565 29.2856 42.5609C28.6551 43.8762 27.9883 45.1734 27.3349 46.4779C27.2612 46.6241 27.1851 46.769 27.0655 46.9997C25.6427 43.6709 24.2488 40.4097 22.855 37.1497C23.0108 36.733 23.3357 36.7571 23.6811 36.832C25.0955 37.1412 26.5148 37.4347 27.9279 37.7464C28.5838 37.8913 28.5971 37.8985 28.7928 37.2632C29.2191 35.879 29.6817 34.5069 30.119 33.1263C30.1999 32.8751 30.2543 32.5176 30.6855 32.6915Z" fill="#DD4C3C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.2915 37.1416C13.9206 40.3545 12.5496 43.5661 11.1775 46.7778C11.1558 46.8298 11.1123 46.8732 11.0531 46.9554C10.332 45.5132 9.60732 44.1085 8.93214 42.6808C8.74009 42.2762 8.55529 42.2327 8.15307 42.3547C6.62635 42.8173 5.08634 43.2376 3.54996 43.6736C3.42434 43.7099 3.30235 43.792 3.12842 43.7014C3.66833 42.3824 4.20582 41.0623 4.74814 39.7433C5.71563 37.3916 6.68554 35.0399 7.65423 32.6895C7.96706 32.7052 7.95982 32.983 8.02746 33.175C8.51301 34.5387 8.90436 35.9325 9.37542 37.2998C9.57592 37.8796 9.59041 37.8868 10.1798 37.76C11.6377 37.4448 13.098 37.1404 14.5559 36.8251C14.8832 36.7551 15.1622 36.7684 15.2915 37.1416Z" fill="#DD4C3C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M30.6851 32.6913C30.3735 32.6514 30.3179 32.8833 30.2491 33.1007C29.7853 34.5598 29.2997 36.0116 28.8685 37.4804C28.7308 37.949 28.5315 38.0312 28.081 37.9273C26.5664 37.577 25.036 37.2896 23.5262 36.9224C23.188 36.8402 23.0696 37.0552 22.8546 37.1494C22.7061 36.8088 22.5587 36.4682 22.4102 36.1276C22.7858 35.4717 23.1928 35.27 23.9055 35.421C25.3392 35.7242 26.7741 36.0225 28.2006 36.3607C28.5931 36.4537 28.7357 36.3462 28.8456 35.9911C29.2466 34.6866 29.6681 33.3894 30.086 32.0898C30.1404 31.9207 30.1658 31.7371 30.2986 31.6006C30.4303 31.9629 30.6308 32.3011 30.6851 32.6913Z" fill="#C64436"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.2913 37.1416C15.0521 36.8662 14.7695 36.8903 14.441 36.9652C12.9517 37.3046 11.4552 37.6138 9.96467 37.9496C9.61077 38.0293 9.44409 37.9544 9.32934 37.58C8.88485 36.1282 8.40292 34.686 7.92582 33.245C7.8618 33.0518 7.84972 32.8223 7.65405 32.6894C7.69633 32.3995 7.8461 32.1495 7.95481 31.8838C7.9838 31.9128 8.01278 31.9418 8.04298 31.9708C8.48505 33.3537 8.93679 34.7331 9.36195 36.1197C9.45496 36.4253 9.62889 36.4398 9.87166 36.3866C11.3307 36.0678 12.7898 35.7525 14.2477 35.4288C14.9277 35.2778 15.3686 35.4759 15.7442 36.1197C15.5932 36.4603 15.4423 36.8009 15.2913 37.1416Z" fill="#C64436"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M42.1779 3.83487C40.5014 4.25762 39.5134 5.27101 39.0616 6.91368C38.622 5.27584 37.6364 4.27091 35.9731 3.83487C37.6255 3.3771 38.6352 2.38425 39.0604 0.729492C39.494 2.37217 40.4941 3.39039 42.1779 3.83487Z" fill="#FCB52F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.214 14.802C25.1427 14.8781 25.0835 14.9457 25.0195 15.0097C21.4346 18.5958 17.8473 22.1807 14.2648 25.7704C14.115 25.9202 14.0099 25.9927 13.8421 25.7934C13.5642 25.4612 13.0195 25.2136 13.0123 24.844C13.0062 24.5566 13.5147 24.2558 13.8022 23.9683C17.131 20.6371 20.4647 17.3119 23.7839 13.9722C24.0556 13.6992 24.2042 13.6907 24.4349 13.983C24.6112 14.2065 24.8371 14.3913 25.0376 14.5954C25.0992 14.657 25.1524 14.7283 25.214 14.802Z" fill="#FEFEFE"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.297 11.8428C16.0822 11.8488 17.4688 13.2524 17.4616 15.0448C17.4543 16.824 16.0448 18.2178 14.2583 18.2106C12.4804 18.2045 11.0902 16.795 11.095 15.005C11.101 13.221 12.5021 11.8356 14.297 11.8428Z" fill="#FEFEFE"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.8675 25.4183C27.8675 27.183 26.4399 28.6143 24.6752 28.6191C22.925 28.6227 21.4829 27.183 21.4708 25.4207C21.4587 23.685 22.9311 22.209 24.6716 22.2139C26.4254 22.2175 27.8663 23.6621 27.8675 25.4183Z" fill="#FEFEFE"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.2694 16.6234C13.3901 16.6161 12.6545 15.8649 12.6714 14.9916C12.6871 14.1364 13.4275 13.4153 14.2839 13.419C15.1524 13.4226 15.9036 14.1908 15.8831 15.0532C15.8626 15.9156 15.127 16.6306 14.2694 16.6234Z" fill="#FCB52F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M26.2541 25.4393C26.2481 26.3295 25.5681 27.001 24.6718 27.001C23.7756 27.0023 23.0594 26.2836 23.0714 25.3946C23.0823 24.531 23.801 23.8232 24.6706 23.8184C25.556 23.8159 26.2614 24.537 26.2541 25.4393Z" fill="#FEA73D"/>
</svg>
    </>
  );
}

export default OfferIconSvg;
