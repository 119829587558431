import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";

import loaderClasses from "../styles/loader.module.css";

const LoaderOne = ({ width = "100%", height = "100%" }) => {
  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // left: "50%",
        zIndex: "-1",
      }}>
      <div
        style={{ width, height }}
        className="d-flex align-items-center justify-content-center">
        <div className={loaderClasses.loadingioSpinnerRolling}>
          <div className={loaderClasses.ldioLoader}>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoaderOne;
