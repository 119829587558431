import React, { useEffect, useState } from "react";
import MixingIngredients from "./IngredientsSteps/MixingIngredients";
import AddFlavour from "./IngredientsSteps/AddFlavour";
import AddSweetener from "./IngredientsSteps/AddSweetener";
import AvailableOffers from "./AskIngredientPopup/AvailableOffers";
import {
  getFlavors,
  getIngredients,
  getSweeteners,
  handleEmptyMixing,
  ingredientsVisibility,
  setOffersVisibility,
} from "../../store/slices/mixingIngredients/ingredientsSlice";
import { useDispatch  , useSelector} from "react-redux";
import CartSuccess from "./AskIngredientPopup/CartSuccess";
import AddIngredientsOffer from "./IngredientsSteps/AddIngredientsOffer";
import {useNavigate} from "react-router-dom";
import { proceedToCheckout } from "../../store/slices/cartSlice/cart";
import { ToastContainer } from 'react-toastify';


const IngredientMainStep = () => {

  // navigate react-router hook 
  const navigate = useNavigate()

  // hooks 
  const [step, setStep] = useState(1);
  const [showSuccess, setShowSuccess] = useState(false);

   // redux hooks
   const dispatch = useDispatch();
  const { updateProduct } = useSelector(
    (state) => state.ingredientsSlice
  );

 






const handleSkip = ()=>{
  if (step === 4) return;
    setStep(step + 1);
}

  const handleNext = () => {
    if (step === 4) return;
    setStep(step + 1);
  };

  const cancelHandler = ()=>{
    dispatch(ingredientsVisibility(false));
    dispatch(handleEmptyMixing());
  }
  const handlePrev = () => {
    setStep(step - 1);
  };

  const offerHandler = () => {
    setStep(0);
    setShowSuccess(true);
    // dispatch(handleEmptyMixing())
  };

  const handleOk = () => {
    dispatch(ingredientsVisibility(false));
    dispatch(proceedToCheckout(false))
    setShowSuccess(false);
    navigate("/cart")
  };

  return (
    <>
     
        {step === 1 && (
          <MixingIngredients handleNext={handleNext} handlePrev={cancelHandler} handleSkip={handleSkip}  isExtraIngredients={true} handleNoExtraIngredients={null}/>
        )}
        {step === 2 && (
          <AddFlavour handleNext={handleNext} handlePrev={handlePrev}  handleSkip={handleSkip} cancelHandler={cancelHandler} formCount={"2/3"} />
        )}
        {step === 3 && (
          <AddSweetener handleNext={handleNext} handlePrev={handlePrev} setShowSuccess={setShowSuccess} handleSkip={handleSkip} cancelHandler={cancelHandler} formCount={"3/3"}/>
        )}
        {step === 4 && <AddIngredientsOffer offerHandler={offerHandler}  crossHandler={cancelHandler} />}
        {showSuccess && <CartSuccess updateCart={step === 3}  handleOk={handleOk}  />}
        <ToastContainer />
     
    </>
  );
};

export default IngredientMainStep;
