import React from "react";

const CheckoutHeader = ({activeStep}) => {
  return (
    <>
      <div className="cart-itme-head">
        <ul>
        <li className={(activeStep === 1 || activeStep === 2 || activeStep === 3) && "active"}>
            <span>
              <img src="images/right_icon_white.svg" alt="" />
            </span>
            Shipping & billing Address
          </li>
          <li className={ (activeStep === 2 || activeStep === 3) && "active"}>
            <span>{( activeStep === 2 || activeStep === 3) ?  <img src="images/right_icon_white.svg" alt="" /> : 2}</span>
            Order Summary
          </li>
         
        </ul>
      </div>
    </>
  );
};

export default CheckoutHeader;
