import axios from "axios";

// const API_BASE_URL_ONE = "https://zn2j5663-5054.inc1.devtunnels.ms/";
const API_BASE_URL_ONE = process.env.REACT_APP_ABBSI_API_BASE_URL;

const client = axios.create({
  baseURL: API_BASE_URL_ONE,
});

const handleTokenExpiration = () => {
  localStorage.removeItem("token");
  // window.location.href = '/';
};

client.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => Promise.reject(error)
);

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401)
      handleTokenExpiration();
    return Promise.reject(error);
  }
);

export default client;

//* Global Get , Put , Post , Delete  Requests
export const getRequest = async (slug) => {
  const res = await client.get(slug);
  return res?.data;
};

export const postRequest = async (slug, data) => {
  const res = await client.post(slug, data);
  return res?.data;
};

export const putRequest = async (slug, data) => {
  const res = await client.put(slug, data);
  return res?.data;
};

export const deleteRequest = async (slug) => {
  let res = await client.delete(slug);
  return res?.data;
};
