import React, { useEffect, useState } from "react";
import StarRatings from "react-star-ratings";

const RatingComponent = ({ value , handleChange}) => {
  const [rating, setRating] = useState(0);

  useEffect(()=>{
    setRating(value)
  },[value])


  return (
    <>
      <StarRatings
        changeRating={handleChange}
        rating={rating || 0}
        starRatedColor="#efc441"
        starEmptyColor="gray"
        starHoverColor="#efc441"
        numberOfStars={5}
        starDimension="20px"
        starSpacing="2px"
      />
    </>
  );
};

export default RatingComponent;
