import { toast } from 'react-toastify';

// For Converting ft and in into cm 
const convertHeightToCm = (ft, inches) => {
    const totalInches = (parseInt(ft, 10) * 12) + parseInt(inches, 10);
    const cm = totalInches * 2.54;
    return cm;
};

const cmToFeetAndInches = (cm) => {
    const inches = cm / 2.54;
    const feet = Math.floor(inches / 12);
    const remainingInches = Math.round(inches % 12);
    return `${feet} ${remainingInches}`;
  };

  const CustomToast = (message, type) => {
    toast[type](message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
      zIndex: 9999,
    });
};
 export  const errorAlert = (message) => {
    toast.error(message , {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
};



const generateInitials = (name) => {
    const nameParts = name?.split(" ");
    if (nameParts?.length === 1) {
      return nameParts[0].charAt(0).toUpperCase();
    } else if (nameParts?.length > 1) {
      return (
        nameParts[0].charAt(0).toUpperCase() +
        nameParts[nameParts?.length - 1].charAt(0).toUpperCase()
      );
    }
    return "";
  };
export const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: '100px',
      height: "50px",
      borderRadius: "4px 0px 0px 4px",
      display: 'inline-grid',
      gridArea: '1/1/2/3',
      margin:"0px",
      padding:"0px"
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight: '50px', 
      height: '50px',
      borderRadius: "4px 0px 0px 4px",
      margin:"0px",
      paddingBottom:"0px"
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'blue' : 'white',
      cursor: 'pointer',
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none', 
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: 0, 
      }),
  };
export {
    convertHeightToCm,
    cmToFeetAndInches,
    generateInitials,
    CustomToast,
  };
  