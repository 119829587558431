import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: "",
  openLogin: false,
  openRegister: false,
  openForgot: false,
  openVerifyOtp: false,
  genderModule: false,
  openResetPassword: false,
  c: false,
};

const userSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    setOpenLogin: (state, action) => {
      state.openLogin = action.payload;
    },
    setGenderOpen: (state, action) => {
      state.openGender = action.payload;
    },
    setRegisterOpen: (state, action) => {
      state.openRegister = action.payload;
    },
    setForgotOpen: (state, action) => {
      state.openForgot = action.payload;
    },
    setVerifyOtp: (state, action) => {
      state.openVerifyOtp = action.payload;
    },
    setResetPassword: (state, action) => {
      state.openResetPassword = action.payload;
    },
    handleOpenLogin: (state) => {
      state.openLogin = true;
      state.openRegister = false;
      state.openForgot = false;
      state.openVerifyOtp = false;
      state.openResetPassword = false;
      state.genderModule = false;
    },
    handleOpenRegister: (state) => {
      state.openRegister = true;
      state.openLogin = false;
      state.openForgot = false;
      state.openVerifyOtp = false;
      state.openResetPassword = false;
      state.genderModule = false;
    },
    handleOpenForgot: (state) => {
      state.openForgot = true;
      state.openLogin = false;
      state.openRegister = false;
      state.openVerifyOtp = false;
      state.openResetPassword = false;
      state.genderModule = false;
    },
    handleOpenVerifyOtps: (state) => {
      state.openVerifyOtp = true;
      state.openLogin = false;
      state.openRegister = false;
      state.openForgot = false;
      state.openResetPassword = false;
      state.genderModule = false;
    },
    handleOpenResetPassword: (state) => {
      state.openResetPassword = true;
      state.openLogin = false;
      state.openRegister = false;
      state.openForgot = false;
      state.openVerifyOtp = false;
      state.genderModule = false;
    },
    handleClose: (state) => {
      state.openResetPassword = false;
      state.openLogin = false;
      state.openRegister = false;
      state.openForgot = false;
      state.openVerifyOtp = false;
      state.openGender = false; // * sachin
    },
    storeEmail: (state, action) => {
      state.email = action.payload;
    },

    setGenderModule: (state, action) => {
      state.genderModule = action.payload;
      state.openResetPassword = false;
      state.openLogin = false;
      state.openRegister = false;
      state.openForgot = false;
      state.openVerifyOtp = false;
    },
    storeEmail: (state, action) => {
      state.email = action.payload;
    },
  },
});
const { actions, reducer } = userSlice;
export const {
  storeEmail,
  handleClose,
  setOpenLogin,
  setRegisterOpen,
  setForgotOpen,
  setGenderModule,
  setResetPassword,
  setVerifyOtp,
  handleOpenLogin,
  handleOpenRegister,
  handleOpenForgot,
  handleOpenVerifyOtps,
  handleOpenResetPassword,
  setGenderOpen,
} = actions;
export default reducer;
