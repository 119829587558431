import { createSlice } from "@reduxjs/toolkit";

const initialState= {
  productData: "",
  availability: "",
  supplier: [],
  rating: "",
  categories: "",
  rangeValue: "",
  priceMin: 0,
  priceMax: 0,
  minPrice:'',
  priceLimit: {
    max: "",
    min: "",
  },
  allDoctors: "",
  category: [],
  foundProductData: [],
  recommendedProduct: [],
  banner: [],
  addToFavorite : ''
}

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProductData: (state, action) => {
      state.productData = action.payload;
    },
    setMaxPrice: (state, action) => {
      state.maxPrice = action.payload;
    },
    setMinPrice: (state, action) => {
      state.minPrice = action.payload;
    },
    setAvailability: (state, action) => {
      state.availability = action.payload;
    },
    setSupplier: (state, action) => {
      state.supplier = action.payload;
    },
    setRating: (state, action) => {
      state.rating = action.payload;
    },
    setPriceLimit: (state, action) => {
      state.priceLimit = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload.categories;
    },
    setFoundProductData: (state, action) => {
      state.foundProductData = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setSupplier: (state, action) => {
      state.supplier = action.payload;
    },
    setAllDoctors: (state, action) => {
      state.allDoctors = action.payload.doctors;
    },
    setRangeValue: (state, action) => {
      state.rangeValue = action.payload;
    },
    setPriceMax: (state, action) => {
      state.priceMax = action.payload;
    },
    setRecommendedProduct: (state, action) => {
      state.recommendedProduct = action.payload;
    },
    setBanner: (state, action) => {
      state.banner = action.payload;
    },
    setAddToFavorite: (state, action) => {
      state.addToFavorite = action.payload;
    },
  },
});

export const {
  setFoundProductData,
  setProductData,
  setCategories,
  setPriceMax,
  setMinPrice,
  setAvailability,
  setSupplier,
  setRating,
  setRangeValue,
  setPriceLimit,
  setAllDoctors,
  setCategory,
  setRecommendedProduct,
  setBanner,
  setAddToFavorite,
} = productSlice.actions;

export default productSlice.reducer;
