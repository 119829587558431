import React, { Fragment, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading } from "../store/slices/common";
import { Link, useNavigate } from "react-router-dom";
import { setOpenLogin } from "../store/slices/Auth";
import { addToFavoriteProducts, getRecommendedProducts } from "../Auth/product";
import { setBanner, setRecommendedProduct } from "../store/slices/productSlice";
import {
  selectCosts,
  setTopSellingCost,
} from "../store/slices/ingredentCostSlice";
import HomeLoader from "../layout/HomeLoader";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import {
  addtoCartProduct,
  askForIngredients,
  getIngredients,
  handleEmptyMixing,
  ingredientsVisibility,
  rejectIngredients,
} from "../store/slices/mixingIngredients/ingredientsSlice";
import { getPriceTrunc, getToken } from "../utils/common";
// import { setOpenLogin } from "../store/slices/Auth";
import { textElips } from "../../src/utils/common/index";

const Home = () => {
  const isLoading = useSelector((state) => state.common.isLoading);
  const currentUser = useSelector((state) => state.login);
  const { genderModule } = useSelector((state) => state.product);
  const recommendedProduct = useSelector(
    (state) => state.product.recommendedProduct
  );
  const banner = useSelector((state) => state.product.banner);
  const [recommendedDose, setRecommendedDose] = useState([]);
  const dispatch = useDispatch();
  const cost = useSelector(selectCosts);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(ingredientsVisibility(false));
    dispatch(handleEmptyMixing());
  }, []);

  const filterData = localStorage.getItem("gender_weight");

  const fetchData = async () => {
    try {
      if (!filterData) {
        dispatch(setIsLoading(true));
      } else {
        dispatch(setIsLoading(true));

        const parsedFilterData = JSON.parse(filterData);

        let { weight, gender } = parsedFilterData ?? { weight: "", gender: "" };

        weight = weight.replaceAll("+", "%2B");

        const response = await getRecommendedProducts(
          currentUser.id,
          gender,
          weight
        );
        const { data } = response;

        localStorage.setItem("banner", JSON.stringify(data.banners));
        dispatch(setRecommendedProduct(data?.products));
        dispatch(setBanner(data?.banners));
      }
    } catch (error) {
      console.log(error);
      dispatch(setIsLoading(false));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
  useEffect(() => {
    fetchData();
  }, [filterData]);

  useEffect(() => {
    if (!recommendedProduct) {
      return;
    }
    if (currentUser.gender === "male" && currentUser.weight <= 140) {
      const maleIngredients = recommendedProduct?.filter(
        (ingredient) =>
          ingredient &&
          ingredient?.type === "ingredient" &&
          ingredient?.quantity &&
          ingredient?.quantity?.male &&
          ingredient?.quantity?.male[0] &&
          ingredient?.quantity?.male[0]?.recommendedDose
      );

      const recommendedDoses = maleIngredients.map(
        (ingredient) => ingredient.quantity.male[0].recommendedDose
      );
      setRecommendedDose(recommendedDoses);
    } else if (
      currentUser.gender === "male" &&
      currentUser.weight >= 141 &&
      currentUser.weight <= 200
    ) {
      const maleIngredients = recommendedProduct.filter(
        (ingredient) =>
          ingredient &&
          ingredient.type === "ingredient" &&
          ingredient.quantity &&
          ingredient.quantity.male &&
          ingredient.quantity.male[1] &&
          ingredient.quantity.male[1].recommendedDose
      );

      const recommendedDoses = maleIngredients.map(
        (ingredient) => ingredient.quantity.male[1].recommendedDose
      );

      setRecommendedDose(recommendedDoses);
    } else if (currentUser.gender === "male" && currentUser.weight >= 201) {
      const maleIngredients = recommendedProduct.filter(
        (ingredient) =>
          ingredient &&
          ingredient.type === "ingredient" &&
          ingredient.quantity &&
          ingredient.quantity.male &&
          ingredient.quantity.male[2] &&
          ingredient.quantity.male[2].recommendedDose
      );

      const recommendedDoses = maleIngredients.map(
        (ingredient) => ingredient.quantity.male[2].recommendedDose
      );

      setRecommendedDose(recommendedDoses);
    } else if (
      currentUser.gender === "female" &&
      currentUser.weight >= 50 &&
      currentUser.weight <= 100
    ) {
      const femaleIngredients = recommendedProduct.filter(
        (ingredient) =>
          ingredient &&
          ingredient.type === "ingredient" &&
          ingredient.quantity &&
          ingredient.quantity.female &&
          ingredient.quantity.female[0] &&
          ingredient.quantity.female[0].recommendedDose
      );

      const recommendedDoses = femaleIngredients.map(
        (ingredient) => ingredient.quantity.female[0].recommendedDose
      );

      setRecommendedDose(recommendedDoses);
    } else if (
      currentUser.gender === "female" &&
      currentUser.weight >= 101 &&
      currentUser.weight <= 150
    ) {
      const femaleIngredients = recommendedProduct.filter(
        (ingredient) =>
          ingredient &&
          ingredient.type === "ingredient" &&
          ingredient.quantity &&
          ingredient.quantity.female &&
          ingredient.quantity.female[1] &&
          ingredient.quantity.female[1].recommendedDose
      );

      const recommendedDoses = femaleIngredients.map(
        (ingredient) => ingredient.quantity.female[1].recommendedDose
      );
      setRecommendedDose(recommendedDoses);
    } else if (currentUser.gender === "female" && currentUser.weight >= 151) {
      const femaleIngredients = recommendedProduct.filter(
        (ingredient) =>
          ingredient &&
          ingredient.type === "ingredient" &&
          ingredient.quantity &&
          ingredient.quantity.female &&
          ingredient.quantity.female[2] &&
          ingredient.quantity.female[2].recommendedDose
      );

      const recommendedDoses = femaleIngredients.map(
        (ingredient) => ingredient.quantity.female[2].recommendedDose
      );

      setRecommendedDose(recommendedDoses);
    }
  }, [currentUser, recommendedProduct]);

  const handleAddToFavorite = async (productId) => {
    try {
      const userId = currentUser.id;
      const action = "add";
      await addToFavoriteProducts(userId, productId, action);
      fetchData();
    } catch (error) {
      console.error("Error adding to favorites:", error);
      dispatch(setIsLoading(false));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const handleRemoveFromFavorite = async (productId) => {
    try {
      const userId = currentUser.id;
      const action = "remove";
      await addToFavoriteProducts(userId, productId, action);
      fetchData();
    } catch (error) {
      console.error("Error removing from favorites:", error);
      dispatch(setIsLoading(false));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const handleClickDetail = (id) => {
    navigate(`/product/detail/${id}`);
  };

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 1,
          // slidesToScroll: 2,
        },
      },

      {
        breakpoint: 575,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleCart = (e, data) => {
    let token = getToken();
    if (token) {
      e.stopPropagation();
      if (data?.productType === "Product") {
        dispatch(rejectIngredients(true));
        dispatch(addtoCartProduct(data));
        dispatch(handleEmptyMixing());
      } else {
        dispatch(askForIngredients(true));
        dispatch(getIngredients(data?.id));
        dispatch(addtoCartProduct(data));
        dispatch(handleEmptyMixing());
      }
    } else {
      dispatch(setOpenLogin(true));
    }
  };

  // below useEffect for check token present in local Storage
  const token = getToken();

  const redirectToPath = (redirectTo) => {
    switch (redirectTo) {
      case "Blends":
        return "/product";
      case "Mix from Scratch":
        return "/mix";
      default:
        return "/";
    }
  };

  const handleLikeButton = (event, productId) => {
    event.stopPropagation(); // Prevent event propagation
    // Handle like button logic
  };

  return (
    <Fragment>
      <section id="banner-main">
        <Carousel
          autoPlay
          infiniteLoop
          interval={3000}
          // showArrows={false}
          showStatus={false}
          // showIndicators={false}
          showThumbs={false}>
          {banner?.map((ban, index) => (
            <div key={index}>
              <div className="banner-img">
                <img src={ban?.webBackground} alt="" />
              </div>
              <div className="banner-text">
                <div
                  className="container"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <div className="banner" style={{ textAlign: "left" }}>
                    <p style={{ color: "#ffffff", fontSize: "20px" }}>
                      {ban.title}
                    </p>
                    <h1>{ban?.subtitle}</h1>
                    <Link to={redirectToPath(ban?.redirectTo)}>
                      {index === 0 ? "Shop Now" : "Start here"}

                      <img
                        style={{ width: "20px" }}
                        src="images/arrow_right_white.svg"
                        alt=""
                      />
                    </Link>
                  </div>
                  <div style={{ width: "300px", height: "300px" }}>
                    <img src={ban.foreground} alt="" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </section>

      <section id="product-main">
        <div className="container">
          <div className="row">
            <div className="shop-header-col">
              <div className="shop-header">
                <h2>Top Selling</h2>
                <Link to={"/product"}>
                  View All Products
                  <img src="images/arrow_right.svg" alt="" />
                </Link>
              </div>
            </div>
            <div className="product-hide">
              {isLoading ? (
                <div>
                  <HomeLoader />
                </div>
              ) : (
                recommendedProduct &&
                recommendedProduct?.map((data, idx) => {
                  // const matchingCost = cost?.find(
                  //   (c) => c.productId === data?.id
                  // );
                  // const displayPrice = matchingCost
                  //   ? matchingCost.cost
                  //   : data.product.salePrice;
                  return (
                    <div
                      className="product-col"
                      key={idx}
                      onClick={() => handleClickDetail(data?.id)}>
                      <div className="product">
                        {token && (
                          <button
                            className="like-btn"
                            onClick={(event) => {
                              event.stopPropagation(); // Prevent event propagation
                              data.isFavorite
                                ? handleRemoveFromFavorite(data.id)
                                : handleAddToFavorite(data.id);
                            }}>
                            <img
                              src={
                                data?.isFavorite
                                  ? "images/unlike_icon.svg"
                                  : "images/like_icon.svg"
                              }
                              alt=""
                            />
                          </button>
                        )}

                        <span className="product-img">
                          <img
                            src={
                              data?.product?.images?.[0] ||
                              "images/placeholder_image.png"
                            }
                            alt=""
                          />
                        </span>
                        <div className="detail" style={{ cursor: "pointer" }}>
                          {data?.product?.category?.map((item, index) => (
                            <React.Fragment key={index}>
                              <span>{item?.categoryName}</span>
                              {index !==
                                data?.product?.category?.length - 1 && (
                                <span>,</span>
                              )}
                            </React.Fragment>
                          ))}
                          <h3>{textElips(data?.product?.title, 45)}</h3>
                          <span className="rating">
                            <img src="images/star_icon.svg" alt="" />
                            {data?.review?.avg.toFixed(2)} (
                            {data?.review?.star5 +
                              data?.review?.star4 +
                              data?.review?.star3 +
                              data?.review?.star2 +
                              data?.review?.star1}{" "}
                            reviews)
                          </span>
                          <div style={{ fontSize: 14, marginTop: 7 }}>
                            <div>
                              {data?.type === "product"
                                ? "Price for 30 servings (Days)"
                                : `Price for 1  ${data?.quantity?.type} * 30 servings (Days)`}
                            </div>
                          </div>
                        </div>
                        <div className="product-detail">
                          <span>
                            $
                            {data?.type === "product"
                              ? getPriceTrunc(data?.product?.salePrice)
                              : getPriceTrunc(
                                  data?.ingredientPrice?.salePricePerMonth
                                )}
                          </span>
                          {/* Assuming displayPrice is a number */}
                          <button onClick={(e) => handleCart(e, data)}>
                            <img src="images/cart_icon.svg" alt="" />
                            Add to cart
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>

            <div className="product-main-slider" style={{ display: "none" }}>
              <div className="product-slider">
                <Slider {...settings}>
                  <div>
                    {isLoading ? (
                      <div>
                        <HomeLoader />
                      </div>
                    ) : (
                      recommendedProduct?.map((data, idx) => {
                        const matchingCost = cost?.find(
                          (c) => c.productId === data?.id
                        );
                        const displayPrice = matchingCost
                          ? matchingCost.cost
                          : data.product.salePrice;
                        return (
                          <div
                            className="product-col"
                            onClick={() => handleClickDetail(data?.id)}
                            key={idx}>
                            <div className="product">
                              {token && (
                                <>
                                  {data.isFavorite ? (
                                    <button
                                      className="like-btn"
                                      onClick={(event) => {
                                        event.stopPropagation(); // Prevent event propagation
                                        data.isFavorite
                                          ? handleRemoveFromFavorite(data.id)
                                          : handleAddToFavorite(data.id);
                                      }}>
                                      <img
                                        src="images/unlike_icon.svg"
                                        alt=""
                                      />
                                    </button>
                                  ) : (
                                    <button
                                      className="like-btn"
                                      onClick={(event) => {
                                        event.stopPropagation(); // Prevent event propagation
                                        data.isFavorite
                                          ? handleRemoveFromFavorite(data.id)
                                          : handleAddToFavorite(data.id);
                                      }}>
                                      <img src="images/like_icon.svg" alt="" />
                                    </button>
                                  )}
                                </>
                              )}

                              <span className="product-img">
                                <img
                                  src={
                                    data?.product?.images?.[0] ||
                                    "images/placeholder_image.png"
                                  }
                                  alt=""
                                />
                              </span>
                              <div
                                className="detail"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClickDetail(data?.id)}>
                                {data?.product?.category?.map((item, index) => (
                                  <React.Fragment key={index}>
                                    <span>{item?.categoryName}</span>
                                    {index !==
                                      data.product.category.length - 1 && (
                                      <span>,</span>
                                    )}
                                  </React.Fragment>
                                ))}

                                <h3>{textElips(data?.product?.title, 45)}</h3>
                                <span className="rating">
                                  <img src="images/star_icon.svg" alt="" />
                                  {data?.review?.avg.toFixed(2)} (
                                  {data?.review?.star5 +
                                    data?.review?.star4 +
                                    data?.review?.star3 +
                                    data?.review?.star2 +
                                    data?.review?.star1}{" "}
                                  reviews)
                                </span>
                                <div style={{ fontSize: 14, marginTop: 7 }}>
                                  <div>
                                    {data?.type === "product"
                                      ? "Price for 30 servings (Days)"
                                      : `Price for 1  ${data?.quantity?.type} * 30 servings (Days)`}
                                  </div>
                                </div>
                              </div>
                              <div className="product-detail">
                                <span>
                                  $
                                  {data?.type === "product"
                                    ? getPriceTrunc(data?.product?.salePrice)
                                    : getPriceTrunc(
                                        data?.ingredientPrice?.salePricePerMonth
                                      )}
                                </span>{" "}
                                {/* Assuming displayPrice is a number */}
                                <button onClick={(e) => handleCart(e, data)}>
                                  <img src="images/cart_icon.svg" alt="" />
                                  Add to cart
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default Home;
