import React from "react";

const Shopping = () => {
  return (
    <>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.19336 20C5.64336 20 5.17253 19.8042 4.78086 19.4125C4.38919 19.0208 4.19336 18.55 4.19336 18C4.19336 17.45 4.38919 16.9792 4.78086 16.5875C5.17253 16.1958 5.64336 16 6.19336 16C6.74336 16 7.21419 16.1958 7.60586 16.5875C7.99753 16.9792 8.19336 17.45 8.19336 18C8.19336 18.55 7.99753 19.0208 7.60586 19.4125C7.21419 19.8042 6.74336 20 6.19336 20ZM16.1934 20C15.6434 20 15.1725 19.8042 14.7809 19.4125C14.3892 19.0208 14.1934 18.55 14.1934 18C14.1934 17.45 14.3892 16.9792 14.7809 16.5875C15.1725 16.1958 15.6434 16 16.1934 16C16.7434 16 17.2142 16.1958 17.6059 16.5875C17.9975 16.9792 18.1934 17.45 18.1934 18C18.1934 18.55 17.9975 19.0208 17.6059 19.4125C17.2142 19.8042 16.7434 20 16.1934 20ZM4.39336 2H19.1434C19.5267 2 19.8184 2.17083 20.0184 2.5125C20.2184 2.85417 20.2267 3.2 20.0434 3.55L16.4934 9.95C16.31 10.2833 16.0642 10.5417 15.7559 10.725C15.4475 10.9083 15.11 11 14.7434 11H7.29336L6.19336 13H18.1934V15H6.19336C5.44336 15 4.87669 14.6708 4.49336 14.0125C4.11003 13.3542 4.09336 12.7 4.44336 12.05L5.79336 9.6L2.19336 2H0.193359V0H3.44336L4.39336 2Z"
          fill="#828282"
        />
      </svg>
    </>
  );
};

export default Shopping;
